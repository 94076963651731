import * as PIXI from 'pixi.js';
import { Text, Container } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import { Modal } from 'antd';
import { useContext } from 'react';
import { Rectangle } from '../../PixiUI/Rectangle';
import { TabletContext } from '../../TabletProvider';

type Props = {
  y: number;
  text: string;
  height: number;
  width: number;
  correctOffset: number;
  hideBorders: boolean;
  textStyle: PIXI.TextStyle;
  interactive: boolean;
};

export const StringChartItem = observer(({
  text, height, textStyle, width, y,
  hideBorders,
  correctOffset, interactive,
}: Props) => {
  const { themeHex } = useContext(TabletContext);

  function openModal() {
    Modal.info({
      content: text,
    });
  }

  return (
    <Container
      y={y}
      x={0}
      click={() => openModal()}
      buttonMode={interactive}
      interactive={interactive}
    >
      <Rectangle
        width={width + correctOffset}
        height={height}
        fillColor={themeHex.backgroundColor}
        borderColor={themeHex.borderColor}
        lineWeight={hideBorders ? 0 : 2}
      />
      <Text x={correctOffset} y={0} text={text} style={textStyle} />
    </Container>
  );
});
