import axios from 'axios';

export const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('auth_token');
    // config.headers = {
    //   Authorization: `Bearer ${token}`,
    //   Accept: 'application/json',
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // };
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token || undefined,
      },
    };
  },
  (error) => {
    Promise.reject(error);
  },
);
