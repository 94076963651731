import { observer } from 'mobx-react-lite';
import { ValueScaleType } from '../../api/dto/TrackDto';
import { CurveCoordinates } from '../../stores/CoordinatesStorages/CurveCoordinates';
import { DepthCoordinates } from '../../stores/CoordinatesStorages/DepthCoordinates';
import { Tablet } from '../../stores/Tablet';
import { Track } from '../../stores/Tracks';
import { OverlayCurveNumeric } from './charts/OverlayCurveNumeric';

type OverlayTrackProps = {
  tablet: Tablet,
  track: Track,
  offsetX: number,
};

export const OverlayTrack = observer(({
  track, tablet, offsetX,
}: OverlayTrackProps) => (
  <>
    {track.sourcesSorted
      .filter((source) => (source.sourceData?.curveCoordinates instanceof DepthCoordinates
    || source.sourceData?.curveCoordinates instanceof CurveCoordinates)
  && track.params.valueScaleType === ValueScaleType.Numeric)
      .map((source) => {
        if (
          (source.sourceData?.curveCoordinates instanceof DepthCoordinates
      || source.sourceData?.curveCoordinates instanceof CurveCoordinates)
    && track.params.valueScaleType === ValueScaleType.Numeric
        ) {
          return (
            <OverlayCurveNumeric
              coordinates={source.sourceData?.curveCoordinates}
              tablet={tablet}
              source={source}
              track={track}
              offsetX={offsetX}
              key={source.sourceDto.externalId}
            />
          );
        }
        return null;
      })}
  </>
));
