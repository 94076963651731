import { useContext, useRef } from 'react';
import { Container, PixiRef, Sprite } from '@inlet/react-pixi';
import { observer, useLocalObservable } from 'mobx-react-lite';
import * as PIXI from 'pixi.js';
import { MAX_TRACK_WIDTH, MIN_TRACK_WIDTH } from '../../constants';
import { Tablet } from '../../stores/Tablet';
import { Track } from '../../stores/Tracks';
import { TabletContext } from './TabletProvider';

type ISprite = PixiRef<typeof Sprite>;

type DragBorderProps = {
  tablet: Tablet;
  offset: number;
  track: Track;
};

const DragBorder = observer(({
  tablet, offset, track,
}: DragBorderProps) => {
  const sprite = useRef<ISprite>(null);
  const { themeHex } = useContext(TabletContext);

  const params = useLocalObservable(() => ({
    dragging: false,
    startPosition: 0,
    startWidth: 0,
    setDragging(dragging: boolean) {
      this.dragging = dragging;
    },
    setStart(start: number) {
      this.startPosition = start;
    },
    setStartWidth(width: number) {
      this.startWidth = width;
    },
  }));

  function onMouseDown(e: PIXI.InteractionEvent) {
    if (tablet.params.orientation === 'vertical') {
      params.setStart(tablet.coordinator.x);
    } else {
      params.setStart(tablet.coordinator.y);
    }
    params.setDragging(true);
    params.setStartWidth(track.params.trackWidth);

    patchEvents();
    e.stopPropagation();
  }

  function patchEvents() {
    window.addEventListener('mouseup', onMouseUp);

    if (sprite.current) {
      sprite.current.on('pointermove', onMouseMove);
      sprite.current.on('pointerup', onMouseUp);
    }
  }

  function removeEvents() {
    window.removeEventListener('mouseup', onMouseUp);

    if (sprite.current) {
      sprite.current.off('pointermove', onMouseMove);
      sprite.current.off('pointerup', onMouseUp);
    }
  }

  function onMouseMove() {
    if (params.dragging) {
      let offsetY: number;
      if (tablet.params.orientation === 'vertical') {
        offsetY = tablet.coordinator.x - params.startPosition;
      } else {
        offsetY = params.startPosition - tablet.coordinator.y;
      }
      let newWidth = params.startWidth + offsetY;
      newWidth = Math.max(newWidth, MIN_TRACK_WIDTH);
      newWidth = Math.min(newWidth, MAX_TRACK_WIDTH);
      track.setParams({ trackWidth: newWidth });
    }
  }

  function onMouseUp() {
    params.setDragging(false);
    removeEvents();
    tablet.tabletScroll.syncScrollTop();
  }

  return (
    <Sprite
      ref={sprite}
      position={[offset + tablet.scalePosition.tracksOffset + track.params.trackWidth, 0]}
      width={2}
      height={tablet.scale.containerLength}
      texture={PIXI.Texture.WHITE}
      tint={themeHex.borderColor}
      interactive={tablet.editMode}
      pointerdown={(e) => onMouseDown(e)}
      cursor={tablet.params.orientation === 'vertical' ? 'ew-resize' : 'ns-resize'}
    />
  );
});

type Props = { tablet: Tablet };

export const TracksBorders = observer<Props>(({ tablet }) => {
  const rotation = tablet.params.orientation === 'vertical' ? 0 : (3 * Math.PI) / 2;
  const position: [number, number] = tablet.params.orientation === 'vertical'
    ? [0, 0]
    : [0, tablet.tracks.tracksWidth];

  return (
    <Container position={position} rotation={rotation}>
      {tablet.tracks.withOffset.map(([track, offset]) => (
        <DragBorder
          key={track.innerId}
          tablet={tablet}
          offset={offset}
          track={track}
        />
      ))}
    </Container>
  );
});
