import {
  jsonObject, jsonMember, jsonArrayMember,
} from 'typedjson';

import { TrackDto } from './TrackDto';

@jsonObject
export class TracksetDto {
  constructor() {
    this.tracks = new Array<TrackDto>();
  }

  @jsonMember({ constructor: Number })
    id: number = 0;

  @jsonMember({ constructor: Number })
    pos: number = 0;

  @jsonMember({ constructor: Number })
    tracksetType: number = 0; // 0 - time, 1 - depth

  @jsonArrayMember(TrackDto)
    tracks: TrackDto[];

  @jsonMember({ constructor: String })
    description: null;

  @jsonMember({ constructor: String })
    name: null;

  @jsonMember({ constructor: String })
    orientation: 'vertical' | 'horizontal' = 'vertical';
}
