import {
  Button, Input, List, Tooltip,
} from 'antd';
import { DeleteOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { useLocalization } from '@fluent/react';
import { ScreensStore } from '../../stores/screensStore';
import { Screen } from '../../stores/Screen';

type ScreensModalLineProps = {
  screensStore: ScreensStore;
  id: string;
  screen: Screen;
  onClose: () => void;
};
export function ScreensModalLine({
  screensStore, id, screen, onClose,
}: ScreensModalLineProps) {
  const { l10n } = useLocalization();

  return (
    <List.Item
      actions={[
        <Tooltip title={l10n.getString('Open')}>
          <Button
            icon={<FolderOpenOutlined />}
            onClick={() => {
              screensStore.openScreen(id);
              onClose();
            }}
          />
        </Tooltip>,
        <Tooltip title={l10n.getString('DeleteSelected')}>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => screensStore.deleteScreen([id])}
          />
        </Tooltip>,
      ]}
    >
      <Input value={screen.name} disabled />
    </List.Item>
  );
}
