import * as PIXI from 'pixi.js';
import { Container, Graphics } from '@inlet/react-pixi';
import {
  ReactNode, useCallback, useState,
} from 'react';

type ContainerMaskProps = {
  children: ReactNode;
  width: number;
  height: number;
};

export function ContainerMask({ width, height, children }: ContainerMaskProps) {
  const [mask, setMask] = useState<PIXI.Graphics | null>(null);

  const draw = useCallback((g: PIXI.Graphics) => {
    g.clear();
    g.beginFill(0xFFFFFF);
    g.drawRect(0, 0, width, height);
    g.endFill();
  }, [width, height]);

  return (
    <Container mask={mask}>
      {children}
      <Graphics
        draw={draw}
        x={0}
        y={0}
        ref={((ref) => {
          setMask(ref);
        })}
      />
    </Container>
  );
}
