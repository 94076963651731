import { useEffect, useState } from 'react';
import { folderIsWritable } from '../../../api/curvesCache';
import { ObjectDto } from '../../../api/dto/ObjectDto';
import { CreateCurve } from './CreateCurve';

export function PropTimeFolder({ well }: { well: ObjectDto }) {
  const { objectTypeName, text } = well;
  const [writable, setWritable] = useState(false);

  useEffect(() => {
    folderIsWritable(well.externalId).then((value) => {
      setWritable(value);
    });
  }, [well.externalId]);

  return (
    <div>
      <div>
        {objectTypeName}
      </div>
      <div style={{ marginBottom: 8 }}>{text}</div>
      {writable && (
      <div style={{ marginBottom: 8 }}>
        <CreateCurve well={well} />
      </div>
      )}
    </div>
  );
}
