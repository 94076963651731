/* eslint-disable react/jsx-props-no-spreading */
import {
  Context as ContextType, ComponentProps, forwardRef, LegacyRef, useRef,
} from 'react';

import { Stage as PixiStage } from '@inlet/react-pixi';
import { ContextListeners, ContextProviders } from '../ContextProviders';

type StageProps = ComponentProps<typeof PixiStage> & { contexts: ContextType<any>[] };

export const Stage = forwardRef((
  { contexts, children, ...props }: StageProps,
  ref: LegacyRef<PixiStage> | undefined,
) => {
  const values = useRef([]);
  return (
    <>
      <ContextListeners contexts={contexts} values={values.current} />
      <PixiStage ref={ref} {...props}>
        <ContextProviders contexts={contexts} values={values.current}>
          {children}
        </ContextProviders>
      </PixiStage>
    </>
  );
});
