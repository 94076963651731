import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { useRef } from 'react';
import { ReactLocalization, useLocalization } from '@fluent/react';
import { notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { Tablet } from '../../stores/Tablet';
import { Curve, Track } from '../../stores/Tracks';
import { HeaderResolver } from './HeaderResolver';
import { HeaderInfoWrapper } from './TabletTracksInfo.styled';

type Props = {
  track: Track;
  tablet: Tablet;
  source: Curve;
  offset: number;
  collapsed: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>
  onContextMenu: React.MouseEventHandler<HTMLDivElement>
};

export const HeaderInfo = observer(({
  track, tablet, source, offset, collapsed, onClick, onContextMenu,
}: Props) => {
  const { l10n } = useLocalization();
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
  { curve: Curve, track: Track },
  void,
  { handlerId: Identifier | null }
  >({
    accept: 'curve',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover({ curve: item, track: fromTrack }, monitor) {
      if (!ref.current) {
        return;
      }

      if (fromTrack !== track) {
        return;
      }

      if (item.params.legendPosition !== source.params.legendPosition) {
        return;
      }

      const dragIndex = item.params.pos;
      const hoverIndex = source.params.pos;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      let hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      let hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (tablet.params.orientation === 'horizontal') {
        hoverMiddleY = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
        hoverClientY = (clientOffset as XYCoord).x - hoverBoundingRect.left;
      }

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      track.move(dragIndex, hoverIndex);
    },
  }, [source.params.pos, track]);
  const [{ isDragging }, drag] = useDrag({
    type: 'curve',
    item: { curve: source, track },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag() {
      return tablet.editMode;
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<{ name: string, track?: Track }>();
      if (dropResult?.name === 'Trashcan') {
        track.removeSource(item.curve);
      }
      if (dropResult?.name === 'TabletBody') {
        const { track: newTrack } = dropResult;
        if (newTrack) {
          moveCurve(source, track, newTrack, l10n);
        }
      }
    },
  }, [source.params.pos, track]);

  drag(drop(ref));
  return (
    <HeaderInfoWrapper
      ref={ref}
      hide={isDragging}
      data-handler-id={handlerId}
      style={{ pointerEvents: tablet.coordinator.dragTrack ? 'none' : 'auto' }}
    >
      <HeaderResolver
        tablet={tablet}
        source={source}
        collapsed={collapsed}
        onClick={onClick}
        onContextMenu={onContextMenu}
        track={track}
        offset={offset}
      />
    </HeaderInfoWrapper>
  );
});

function moveCurve(curve: Curve, fromTrack:Track, toTrack: Track, l10n: ReactLocalization) {
  const index = toTrack.sourcesSorted
    .findIndex((s) => s.sourceDto.externalId === curve.sourceDto.externalId);
  if (index === -1) {
    const error = toTrack.validators.find((v) => v.validator(toTrack, curve));

    if (error) {
      notification.error({
        placement: 'bottomRight',
        message: l10n?.getString(error.message),
      });
      return;
    }
    fromTrack.hideSource(curve);
    toTrack.addCurve(curve);
  }
}
