import { makeAutoObservable, runInAction } from 'mobx';
import parseJwt from 'jwt-decode';
import { AxiosError } from 'axios';
import { axiosApiInstance as axios } from '../api/axiosApiInstance';
import { folderTreeStore } from './folderTree';

 type TokenParams = {
   sub: string;
   authorities: string[];
   iat: string;
   iss: string;
 };

class AuthStore {
  inProgress = false;

  errors: null | AxiosError | Error = null;

  token: string | null = null;

  constructor() {
    this.token = localStorage.getItem('auth_token') || null;
    makeAutoObservable(this);
  }

  async login({ username, password }: { username: string; password: string; }) {
    this.inProgress = true;
    this.errors = null;

    try {
      const { data: { result } } = await axios.post<{ result: string; }>('/Auth/Token', { username, password });
      runInAction(() => {
        this.setToken(result);
      });
      return result;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e;
      });
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  get tokenParams() {
    return this.token ? parseJwt<TokenParams>(this.token) : null;
  }

  get username() {
    return this.tokenParams ? this.tokenParams.sub : null;
  }

  get isAdmin() {
    return this.tokenParams ? this.tokenParams.authorities.includes('ROLE_ADMIN') : false;
  }

  setToken(token: string) {
    localStorage.setItem('auth_token', token);
    this.token = token;
  }

  logout() {
    this.token = null;
    localStorage.removeItem('auth_token');
    folderTreeStore.clear();
  }
}

export const authStore = new AuthStore();
