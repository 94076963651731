import { Button } from 'antd';
import { useLocalization } from '@fluent/react';
import { useEffect, useState } from 'react';
import { ObjectDto } from '../../../api/dto/ObjectDto';
import { folderTreeStore } from '../../../stores/folderTree';
import { CreateCurve } from './CreateCurve';
import { folderIsWritable } from '../../../api/curvesCache';

export function PropDepthFolder({ well }: { well: ObjectDto }) {
  const { l10n } = useLocalization();
  const [writable, setWritable] = useState(false);

  useEffect(() => {
    folderIsWritable(well.externalId).then((value) => {
      setWritable(value);
    });
  }, [well.externalId]);

  const { objectTypeName, text } = well;
  function exportToLas() {
    folderTreeStore.createSelectToLasExport(well.externalId);
  }
  return (
    <div>
      <div>
        {objectTypeName}
      </div>
      <div style={{ marginBottom: 8 }}>{text}</div>
      {writable && (
      <div style={{ marginBottom: 8 }}>
        <CreateCurve well={well} />
      </div>
      )}

      <div>
        <Button size="small" onClick={() => exportToLas()}>{l10n.getString('Export-to-las')}</Button>
      </div>
    </div>
  );
}
