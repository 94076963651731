import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TracksBlock = styled.div` 
  position: absolute;
  /* display: flex; */
  font-size: 12px;
  line-height: 16px;
  pointer-events: none;
  overflow: hidden;
`;

export const ContainerCurves = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CurveBlock = styled.div<{ color?: string; collapsed: boolean; selected: boolean; reverse: boolean; }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
  height: ${({ collapsed }) => (collapsed ? '17px' : '34px')};
  color: ${({ selected, color }) => (selected ? '#ffffff' : color)};
  border-style: solid;
  border-color: ${({ theme, color }) => color || theme.color};
  position: relative;
  background: ${({ selected, theme }) => (selected ? 'rgb(32, 65, 255)' : theme.backgroundColor)};
  ${({ collapsed }) => (collapsed && css`
    display: flex;
    justify-content: space-between;
  `)};
  border-width: ${({ reverse }) => (reverse ? '1px 0 0 0;' : '0 0 1px 0')};
`;

export const CurveBlockWithScale = styled.div<{ color: string; legendHeight: number; selected: boolean; reverse: boolean; }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
  height: ${({ legendHeight }) => (`${legendHeight}px`)};
  color: ${({ selected, color }) => (selected ? '#ffffff' : color)};
  border-style: solid;
  border-color: ${({ color }) => color};
  position: relative;
  background: ${({ selected, theme }) => (selected ? 'rgb(32, 65, 255)' : theme.backgroundColor)};
  border-width: ${({ reverse }) => (reverse ? '1px 0 0 0;' : '0 0 1px 0')};
`;

export const CollapsedBlock = styled.div`
   display: flex;
   justify-content: space-between;
   gap: 2px;
`;

export const CurveName = styled.div` 
  justify-content: space-between;
  display: flex;
  white-space: nowrap;
`;

export const CurveBody = styled.div` 
  justify-content: space-between;
  display: flex;
`;

export const CoordinatorBadge = styled.div<{ color: string, x: number, reverse: boolean; }>` 
  position: absolute;
  ${({ reverse }) => `${reverse ? 'top' : 'bottom'}: 1px`};
  left: ${({ x }) => `${x}px`};
  color: ${({ color }) => color};
  font-size: 10px;
  background-color: ${(props) => props.theme.backgroundColor};
  min-width: 16px;
  text-align: center;
  transform: translateX(-50%);
  line-height: 12px;
  padding: 0 3px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ color }) => color};
  z-index: 1;
`;

export const CoordinatorScaleBadge = styled.div<{
  color: string,
  x: number,
  rt: 'vertical' | 'horizontal'
  reverse: boolean;
}>` 
  position: absolute;
  ${({ reverse, rt }) => `${reverse ? 'top' : 'bottom'}: ${rt === 'vertical' ? 5 : 7}px`};
  left: ${({ x }) => `${x}px`};
  color: ${({ color }) => color};
  font-size: 10px;
  line-height: 12px;
  background-color: ${(props) => props.theme.backgroundColor};
  min-width: 16px;
  text-align: center;
  transform: translateX(-50%);
  line-height: 12px;
  padding: ${({ rt }) => (rt === 'vertical' ? 0 : 3)}px ${({ rt }) => (rt === 'vertical' ? 3 : 1)}px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ color }) => color};
  writing-mode: ${({ rt }) => (rt === 'vertical' ? 'horizontal-tb' : 'vertical-rl')};
  z-index: 1;
`;

export const CuttingsBlock = styled.div<{ color?: string; collapsed: boolean; selected: boolean; reverse: boolean; }>`
  display: flex;
  align-items: flex-end;
  height: ${({ collapsed }) => (collapsed ? '17px' : '34px')};
  color: ${({ selected, color, theme }) => (selected ? '#ffffff' : (color || theme.color))};
  border-style: solid;
  border-color: ${({ color }) => color};
  border-width: ${({ reverse }) => (reverse ? '1px 0 0 0;' : '0 0 1px 0')};
  background: ${({ selected, theme }) => (selected ? 'rgb(32, 65, 255)' : theme.backgroundColor)};
`;

export const BaseBlock = styled.div<{ color?: string; collapsed: boolean; selected: boolean; reverse: boolean; }>`
  display: flex;
  align-items: end;
  gap: 2px;
  height: ${({ collapsed }) => (collapsed ? '17px' : '34px')};
  color: ${({ selected, color, theme }) => (selected ? '#ffffff' : (color || theme.color))};
  border-style: solid;
  border-color: ${({ color }) => color};
  border-width: ${({ reverse }) => (reverse ? '1px 0 0 0;' : '0 0 1px 0')};
  background: ${({ selected, theme }) => (selected ? 'rgb(32, 65, 255)' : theme.backgroundColor)};
`;

export const DragBlock = styled.div<{ width: number; height: number; }>`
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  pointer-events: none;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  font-size: 12px;
  line-height: 16px;
  background-color:  ${({ theme }) => `${theme.backgroundColor}`};
  border-style: solid;
  border-width: 1px;
  border-color:  ${({ theme }) => `${theme.borderColor}`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderInfoWrapper = styled.div<{ hide: boolean }>`
  opacity: ${(props) => (props.hide ? 0.2 : 1)};
  pointer-events: auto;
  user-select: none;
  position: relative;
`;

export const LoaderBlock = styled.div<{ position: 'absolute' | 'right' }>`
  ${({ position }) => position === 'absolute' && `
    position: absolute;
    left: 0;
    white-space: nowrap;
    z-index: 1;
    margin-left: 50%;
    transform: translateX(-50%);
  `}
  ${({ position }) => position === 'right' && `
    margin-left: auto;
    margin-right: 4px;
  `}
  background-color: ${(props) => props.theme.backgroundColor};
`;

export const ErrorBlock = styled.div<{ collapsed: boolean; }>`
  display: flex;
  align-items: ${({ collapsed }) => (collapsed ? 'end' : 'center')}; 
  justify-content: ${({ collapsed }) => (collapsed ? 'space-between' : 'end')}; 
  flex-direction: ${({ collapsed }) => (collapsed ? 'row' : 'column')};
  padding: 0 2px;
  height: ${({ collapsed }) => (collapsed ? '17px' : '34px')};
  color: white;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ color }) => color};
  background: red;
`;

export const RoundBlock = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
