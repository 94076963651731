import { makeAutoObservable, observable, runInAction } from 'mobx';
import { CommentPoint, folderIsWritable, getComments } from '../../api/curvesCache';
import { immutableSplice } from '../../utils';

export class CommentsCoordinates {
  curveId: number;

  points: CommentPoint[] = [];

  inProgress = false;

  inDownloading = false;

  errors = null;

  onData?: number;

  toData?: number;

  minValue = null;

  maxValue = null;

  fetched = false;

  flat: boolean;

  writable = false;

  constructor(curveId: number, flat: boolean) {
    makeAutoObservable(this, {
      points: observable.ref,
    });
    this.curveId = curveId;
    this.flat = flat;
    if (!flat) {
      this.checkRules();
    }
  }

  async refreshCoords({ force }: { force?: boolean }) {
    if (!force && (this.points.length > 0 || this.inProgress || this.inDownloading)) {
      return;
    }
    this.inProgress = true;
    this.errors = null;
    try {
      const data = await getComments(this.curveId);
      runInAction(() => {
        if (Array.isArray(data)) {
          this.points = data;
          this.onData = data.at(0)?.key;
          this.toData = data.at(-1)?.key;
          this.fetched = true;
        } else {
          this.inDownloading = true;
        }
      });
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  async checkRules() {
    folderIsWritable(this.curveId).then((value) => {
      runInAction(() => {
        this.writable = value;
      });
    });
  }

  getPoint(y: number) {
    return this.points.find((point, index, array) => {
      const nextPoint = array.at(index + 1);
      if (!nextPoint) return true;
      if (point.key <= y && y < nextPoint.key) return true;
      return false;
    });
  }

  addPoint(point: CommentPoint) {
    this.addPoints([point]);
  }

  addPoints(points: CommentPoint[]) {
    points.forEach((point) => {
      const index = this.points.findIndex((p) => point.key === p.key);
      if (index > -1) {
        this.points = immutableSplice(this.points, index, 1, point);
      } else {
        this.points = this.points.concat(point);
      }
    });
  }
}
