/* eslint-disable no-continue */

import { DashLine } from 'pixi-dashed-line';
import * as PIXI from 'pixi.js';
import { CurvePoint, HatchPoint } from '../../../api/curvesCache';

function scalerX(value: number, xMin:number, xRatio:number, ratioUom:number, log10:boolean) {
  const calcX = log10
    ? Math.log10(value <= 0 ? 1 : value * ratioUom)
    : value * ratioUom;
  const calcXMin = (log10 && xMin > 0) ? Math.log10(xMin) : xMin;
  return Math.round((calcX - calcXMin) * xRatio);
}

function scalerY(key:number, yRatio:number, offsetY:number) {
  return Math.round(key * yRatio - offsetY);
}

export function drawLine(
  gr: PIXI.Graphics,
  color: string,
  lineDash: number[],
  lineWidth: number,
  coords: (CurvePoint | HatchPoint)[],
  yRatio: number,
  offsetY:number,
  xRatio: number,
  xMin: number,
  ratioUom: number,
  log10: boolean,
  height: number,
  firstIndex: number,
) {
  gr.clear();
  const g = lineDash.length ? new DashLine(gr, {
    dash: lineDash,
    width: lineWidth,
    color: PIXI.utils.string2hex(color),
    // cap: PIXI.LINE_CAP.ROUND,
    // join: PIXI.LINE_JOIN.ROUND,
  }) : gr.lineStyle(lineWidth, PIXI.utils.string2hex(color), 1);

  const coordsLength = coords.length;
  const firstPoint = coords.at(0);
  if (!firstPoint) {
    return;
  }

  if (Array.isArray(firstPoint.value)) {
    let minX = scalerX(
      (coords[firstIndex] as HatchPoint).value[0],
      xMin,
      xRatio,
      ratioUom,
      log10,
    );

    let maxX = scalerX(
      (coords[firstIndex] as HatchPoint).value[1],
      xMin,
      xRatio,
      ratioUom,
      log10,
    );

    let prevY = scalerY(coords[firstIndex].key, yRatio, offsetY);

    for (let i = firstIndex; i < coords.length; i += 1) {
      const hatch = coords[i] as HatchPoint;
      const y = Math.round((hatch.key) * yRatio - offsetY);
      const x1 = scalerX(hatch.value[0], xMin, xRatio, ratioUom, log10);
      const x2 = scalerX(hatch.value[1], xMin, xRatio, ratioUom, log10);

      if (y > height) {
        g.moveTo(minX, prevY);
        g.lineTo(maxX, prevY);
        break;
      }
      if (y !== prevY) {
        if (minX === maxX) {
          maxX = minX + 1;
        }
        g.moveTo(minX, prevY);
        g.lineTo(maxX, prevY);

        minX = x1;
        maxX = x2;
        prevY = y;
      } else {
        minX = minX > x1 ? x1 : minX;
        maxX = maxX < x2 ? x2 : maxX;
      }
    }
    return;
  }

  // мин и макс в текущем штрихе
  let minX = scalerX(
    (coords[firstIndex] as CurvePoint).value,
    xMin,
    xRatio,
    ratioUom,
    log10,
  );
  let maxX = minX;
  // чем закончился прошлый штрих
  let prevX = minX;
  // где закончился прошлый штрих
  let prevY = scalerY(coords[firstIndex].key, yRatio, offsetY);

  let pointsInPixel = 0;

  g.moveTo(prevX, prevY);

  for (let i = firstIndex; i < coordsLength; i += 1) {
    const coord = coords[i] as CurvePoint;

    const y = scalerY(coord.key, yRatio, offsetY);
    const x = scalerX(coord.value, xMin, xRatio, ratioUom, log10);

    if (y !== prevY) {
      // 1. рисуем прошлый горизонтальный штрих, если он есть
      if (pointsInPixel > 1) {
        g.moveTo(minX, prevY);
        g.lineTo(maxX, prevY);
        g.moveTo(prevX, prevY);
      }
      // 2. рисуем соединение предыдущей точки с текущей
      g.lineTo(x, y);
      // 3. начинаем копить данные про новый штрих
      pointsInPixel = 1;
      // eslint-disable-next-line no-multi-assign
      minX = maxX = prevX = x;
      prevY = y;
    } else {
      // 1. добавляем текущую точку в данные про штрих
      minX = minX > x ? x : minX;
      maxX = maxX < x ? x : maxX;
      prevX = x;
      pointsInPixel += 1;
    }
    if (y > height) {
      g.moveTo(minX, prevY);
      g.lineTo(maxX, prevY);
      break;
    }
  }
}

export function drawDottedLine(
  g: PIXI.Graphics,
  color: string,
  lineWidth: number,
  coords: (CurvePoint | HatchPoint)[],
  yRatio: number,
  offsetY:number,
  xRatio: number,
  xMin: number,
  ratioUom: number,
  log10: boolean,
  height: number,
  firstIndex: number,
) {
  const firstPoint = coords.at(0);
  if (!firstPoint) {
    return;
  }

  g.clear();

  const coordsLength = coords.length;

  g.beginFill(PIXI.utils.string2hex(color));

  if (Array.isArray(firstPoint.value)) {
    for (let i = firstIndex; i < coordsLength; i += 1) {
      const hatch = coords[i] as HatchPoint;
      const y = Math.round((hatch.key) * yRatio - offsetY);
      if (y < 0) {
        continue;
      }
      if (hatch.value[0] === hatch.value[1]) {
        const x = scalerX(hatch.value[0], xMin, xRatio, ratioUom, log10);
        g.drawCircle(x, y, lineWidth / 2);
      } else {
        const x1 = scalerX(hatch.value[0], xMin, xRatio, ratioUom, log10);
        const x2 = scalerX(hatch.value[1], xMin, xRatio, ratioUom, log10);
        if ((x2 - x1) === 0) {
          g.drawCircle(x1, y, lineWidth / 2);
        } else {
          g.drawCircle(x1, y, lineWidth / 2);
          g.drawCircle(x2, y, lineWidth / 2);
        }
      }
      if (y > height) {
        break;
      }
    }
    g.endFill();
    return;
  }

  for (let i = firstIndex; i < coordsLength; i += 1) {
    const coord = coords[i] as CurvePoint;

    const y = Math.round(coord.key * yRatio - offsetY);
    if (y < 0) {
      continue;
    }

    const x = scalerX(coord.value, xMin, xRatio, ratioUom, log10);

    g.drawRect(x - lineWidth / 2, y - lineWidth / 2, lineWidth, lineWidth);

    if (y > height) {
      break;
    }
  }
  g.endFill();
}
