import {
  CopyOutlined, DeleteOutlined, MoreOutlined,
} from '@ant-design/icons';
import { useLocalization } from '@fluent/react';
import {
  Button, Dropdown, MenuProps, message, Tooltip,
} from 'antd';
import { authStore } from '../../stores/auth';
import { templatesStore } from '../../stores/templatesStore';

type Props = {
  id: number;
  system: boolean;
};

function TemplateDropDown({ id, system }: Props) {
  const { l10n } = useLocalization();

  const handleMenuClick: MenuProps['onClick'] = async (e) => {
    switch (e.key) {
      case 'delete': {
        await templatesStore.delete(id);
        message.success(l10n.getString('TemplateSuccessfullyRemoved'));
        break;
      }
      case 'copy': {
        await templatesStore.copy(id, system);
        message.success(l10n.getString('TemplateSuccessfullyCopied'));
        break;
      }
      default:
        break;
    }
  };

  const items = [
    {
      key: 'copy',
      label: `${l10n.getString('Copy-to')} ${l10n.getString(system ? 'Custom' : 'Systemic')}`,
      icon: <CopyOutlined />,
    },
    ...(!system || authStore.isAdmin ? [{
      key: 'delete',
      danger: true,
      icon: <DeleteOutlined />,
      label: l10n.getString('Delete'),
    }] : []),
  ];

  return (
    <Dropdown
      menu={{ items, onClick: handleMenuClick }}
      trigger={['click']}
      disabled={templatesStore.isFetching}
    >
      <Button
        style={{ flexShrink: 0 }}
        icon={<Tooltip title={l10n.getString('Additional-functions')}><MoreOutlined /></Tooltip>}
      />
    </Dropdown>
  );
}

export default TemplateDropDown;
