import { makeAutoObservable, runInAction } from 'mobx';
import { getScaleUnit, saveScaleUnit } from '../api/user';

export const LC_SCALE_UNIT = 'LC_SCALE_UNIT';

export type Units = 'm' | 'ft';

export class ScaleUnitStore {
  inProgress: boolean = false;

  errors = null;

  scaleUnit: Units = (localStorage.getItem(LC_SCALE_UNIT) as Units) || 'm';

  constructor() {
    makeAutoObservable(this);
    this.fetchScaleUnit();
  }

  get kUnit() {
    return this.scaleUnit === 'm' ? 1 : 3.28;
  }

  get fullName() {
    return this.scaleUnit === 'm' ? 'meters' : 'feet';
  }

  async fetchScaleUnit() {
    this.inProgress = true;
    this.errors = null;
    try {
      const scaleUnit = await getScaleUnit();
      runInAction(() => {
        if (scaleUnit) {
          this.scaleUnit = scaleUnit;
        }
      });
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  async saveScaleUnit(value: Units) {
    this.inProgress = true;
    this.errors = null;
    try {
      const result = await saveScaleUnit(value);
      if (result) {
        runInAction(() => {
          this.scaleUnit = value;
          localStorage.setItem(LC_SCALE_UNIT, this.scaleUnit);
        });
      }
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }
}
