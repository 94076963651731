import { ValueScaleType } from '../../api/dto/TrackDto';
import { Curve } from '../../stores/Tracks';
import { CoordinatorBadge as CoordinatorBadgeStyled, CoordinatorScaleBadge } from './TabletTracksInfo.styled';

export function CoordinatorBadge({
  x, trackWidth, sourceParams, scaleFrom, scaleTo, rt = 'vertical', showValueScale = false, reverse = false, valueScaleType,
}:
{
  x: number;
  trackWidth: number;
  sourceParams: Curve['params'];
  scaleFrom: number;
  scaleTo: number;
  rt?: 'vertical' | 'horizontal';
  showValueScale?: boolean;
  reverse?: boolean;
  valueScaleType: ValueScaleType;
}) {
  let value;
  if (valueScaleType === ValueScaleType.Log) {
    const xRatio = trackWidth
    / (Math.log10(scaleTo <= 0 ? 1 : scaleTo) - Math.log10(scaleFrom <= 0 ? 1 : scaleFrom));
    const calcXMin = Math.log10(scaleFrom);
    const logBadge = 10 ** ((x / xRatio) + calcXMin);
    const exp = 10 ** Math.abs(Math.ceil(Math.log10(logBadge)) - 1);
    value = logBadge > 1
      ? Math.round(logBadge)
      : (Math.round(logBadge * exp) / exp);
  } else {
    value = Math.round(
      scaleFrom + ((scaleTo - scaleFrom) / (trackWidth)) * x,
    );
  }

  if (showValueScale) {
    return (
      <CoordinatorScaleBadge color={sourceParams.color} x={x} rt={rt} reverse={reverse}>
        {value}
      </CoordinatorScaleBadge>
    );
  }
  return (
    <CoordinatorBadgeStyled color={sourceParams.color} x={x} reverse={reverse}>
      {value}
    </CoordinatorBadgeStyled>
  );
}
