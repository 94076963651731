import { observer } from 'mobx-react-lite';
import { TilingSprite } from '@inlet/react-pixi';
import { Curve } from '../../../stores/Tracks';
import { Tablet } from '../../../stores/Tablet';
import { lithologyStore } from '../../../stores/lithologyStore';
import { LithologyCoordinates } from '../../../stores/CoordinatesStorages/LithologyCoordinates';

type Props = {
  source: Curve;
  tablet: Tablet,
  width: number,
  offsetX?: number;
  coordinates: LithologyCoordinates
};

export const Lithology = observer(({
  tablet, width, offsetX = 0, coordinates,
}: Props) => {
  const { offsetY: scalerOffsetY, zeroPointKey, yRatio } = tablet.scale;
  const coords = coordinates.points;
  const offsetY = scalerOffsetY + zeroPointKey * yRatio;

  return (
    <>
      {coords.map((point, index, array) => {
        const nextPoint = array.at(index + 1);
        if (!nextPoint) {
          return null;
        }
        const blockHeight = (nextPoint.key - point.key) * yRatio;
        const y = point.key * yRatio - offsetY;
        if (y + blockHeight < 0) {
          return null;
        }
        const lithology = lithologyStore.map.get(point.value);
        if (!lithology) {
          return null;
        }
        return (
          <TilingSprite
            image={lithology.img}
            x={offsetX}
            y={Math.ceil(y)}
            width={width}
            height={Math.ceil(blockHeight)}
            tilePosition={{ x: 0, y: 0 }}
          />
        );
      })}
    </>
  );
});
