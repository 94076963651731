import * as PIXI from 'pixi.js';
import { Graphics, Text } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { format } from 'date-fns';
import { TabletType } from '../../enums/TabletType';
import { TabletContext } from './TabletProvider';
import { TimeZonesStore } from '../../stores/timeZonesStore';
import { ScaleUnitStore } from '../../stores/scaleUnitStore';

const CoordinatorScaleVertical = observer(() => {
  const {
    tablet, themeHex, timeZonesStore, scaleUnitStore,
  } = useContext(TabletContext);
  const baseFontSize = 14;
  const cursorHeight = baseFontSize * (tablet.tabletType === TabletType.Depth ? 1 : 2) + 8;
  const offset = tablet.scalePosition.mainLineX;
  const width = tablet.scalePosition.mainSize;
  const draw = (g: PIXI.Graphics) => {
    g.clear();
    g.lineStyle(1, themeHex.color, 1);
    g.moveTo(offset, tablet.coordinator.y - cursorHeight / 2);
    g.lineTo(offset + width - 2, tablet.coordinator.y - cursorHeight / 2);
    g.moveTo(offset, (tablet.coordinator.y - cursorHeight / 2) + cursorHeight);
    g.lineTo(offset + width - 2, (tablet.coordinator.y - cursorHeight / 2) + cursorHeight);
    g.beginFill(themeHex.backgroundColor);
    g.lineStyle(0);
    g.drawRect(
      offset,
      tablet.coordinator.y - cursorHeight / 2 + 0.5,
      width - 2,
      cursorHeight - 1,
    );
    g.endFill();
  };
  const coordinatorText = tablet.scale.pointToData(tablet.scale.offsetY + tablet.coordinator.y);

  return !tablet.editMode ? (
    <>
      <Graphics draw={draw} />
      <Text
        text={formatScale(
          coordinatorText,
          tablet.tabletType,
          timeZonesStore,
          scaleUnitStore,
        )}
        anchor={[1, 0.5]}
        x={(offset + width) - 10}
        y={tablet.coordinator.y}
        style={new PIXI.TextStyle({
          ...tablet.baseTextStyle,
          fill: themeHex.brightColor,
          wordWrap: true,
          wordWrapWidth: width,
          align: 'right',
        })}
      />
    </>
  ) : null;
});

const CoordinatorScaleHorizontal = observer(() => {
  const {
    tablet, themeHex, timeZonesStore, scaleUnitStore,
  } = useContext(TabletContext);
  const cursorWidth = 90;
  const offset = tablet.scalePosition.y;
  const height = tablet.scalePosition.mainSize;
  const draw = (g: PIXI.Graphics) => {
    g.clear();
    g.lineStyle(1, themeHex.color, 1);
    g.moveTo(tablet.coordinator.x - cursorWidth / 2, offset + 2);
    g.lineTo(tablet.coordinator.x - cursorWidth / 2, offset + height - 1);
    g.moveTo((tablet.coordinator.x - cursorWidth / 2) + cursorWidth, offset + 2);
    g.lineTo((tablet.coordinator.x - cursorWidth / 2) + cursorWidth, offset + height - 1);
    g.beginFill(themeHex.backgroundColor);
    g.lineStyle(0);
    g.drawRect(
      tablet.coordinator.x - cursorWidth / 2 + 0.5,
      offset + 2,
      cursorWidth - 1,
      height - 3,
    );
    g.endFill();
  };
  const coordinatorText = tablet.scale.pointToData(tablet.scale.offsetY + tablet.coordinator.x);

  return !tablet.editMode ? (
    <>
      <Graphics draw={draw} />
      <Text
        text={formatScale(
          coordinatorText,
          tablet.tabletType,
          timeZonesStore,
          scaleUnitStore,
        )}
        anchor={[0.5, 0.5]}
        y={tablet.scalePosition.y + height / 2 - 1}
        x={tablet.coordinator.x}
        style={new PIXI.TextStyle({
          ...tablet.baseTextStyle,
          fill: themeHex.brightColor,
          wordWrap: true,
          wordWrapWidth: 100,
          align: 'center',
        })}
      />
    </>
  ) : null;
});

function formatScale(
  value: number,
  tabletType: TabletType,
  timeZonesStore: TimeZonesStore,
  scaleUnitStore: ScaleUnitStore,
) {
  if (tabletType === TabletType.Depth) {
    return (value * scaleUnitStore.kUnit).toFixed(1);
  }
  return format(timeZonesStore.getDate(value), 'yyyy-MM-dd HH:mm:ss');
}

export function CoordinatorScale() {
  const { tablet } = useContext(TabletContext);
  return tablet.params.orientation === 'vertical' ? <CoordinatorScaleVertical /> : <CoordinatorScaleHorizontal />;
}
