import styled from '@emotion/styled';

export const TabletsWrapper = styled.div` 
  height: 100%;
  width: 100%;
  overflow-x: auto;
  scrollbar-color: rgba(255,255,255,0.5) transparent ;
  scrollbar-width: thin;
  overflow-y: hidden;
  display: flex;
  // padding: 10,
  // gap: 20
`;
