/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import { observable, runInAction, makeAutoObservable } from 'mobx';
import {
  CurveInfo, getTimeCoords, CurvePoint, HatchPoint, Hatch,
} from '../../api/curvesCache';
import { getUtc, Range } from '../../utils';
import { Scale } from '../Scale';

type FetchParams = { onFrame: number; toFrame: number; direction?: 'in' | 'out', force?: boolean };

type ValidatorParams = {
  onFrame: number;
  toFrame: number;
  ratio: [number, number];
  force?: boolean;
  inProgress: boolean;
  currentRequestRange: Range | null;
};

export class CurveFetcher {
  curveId: number;

  points: (CurvePoint | HatchPoint)[] = [];

  inProgress = false;

  errors = null;

  onData: number | null = null;

  toData: number | null = null;

  minValue = 0;

  maxValue = 100;

  pointType: 'point' | 'hatch' = 'point';

  info: CurveInfo;

  dataStep: number;

  scale: Scale;

  currentRequestRange: Range | null = null;

  sharedUpdate: () => void;

  constructor(
    curveId: number,
    dataStep: number,
    scale: Scale,
    info:CurveInfo,
    sharedUpdate: () => void,
  ) {
    this.curveId = curveId;
    this.dataStep = dataStep;
    this.info = info;
    this.scale = scale;
    this.sharedUpdate = sharedUpdate;
    makeAutoObservable(this, {
      points: observable.ref,
      currentRequestRange: false,
    });
  }

  get rangeDB() {
    return new Range(this.info.minKey, this.info.maxKey);
  }

  get fetched() {
    return !!this.toData;
  }

  async refreshCoords(params: FetchParams, ratio: [number, number]) {
    const r = this.timeCoordinatesValidator({
      ...params,
      inProgress: this.inProgress,
      currentRequestRange: this.currentRequestRange,
      ratio,
    });
    if (r) {
      const [start, end, currentRequestRange, segment] = r;
      this.currentRequestRange = currentRequestRange;
      await this.getTimeCoords(start, end, this.dataStep, segment);
    }
  }

  timeCoordinatesValidator(
    {
      onFrame, toFrame, ratio, force, inProgress, currentRequestRange,
    }: ValidatorParams,
  ): [number, number, Range, 'start' | 'end'] | [number, number, Range] | null {
    this.setCache();
    const externalRange = this.getRange(onFrame, toFrame, ratio[1]);

    if (force || (!inProgress && (!this.onData || !this.toData))) {
      return [externalRange.start, externalRange.end, externalRange];
    }

    const loadedRange = this.onData && this.toData ? new Range(this.onData, this.toData) : new Range(0, 0);
    const validateRange = this.getRange(onFrame, toFrame, ratio[0]);

    if (loadedRange.isTargetIn(validateRange)) {
      return null;
    }
    const frameRange = this.getRange(onFrame, toFrame, 0);
    if (!this.rangeDB.isTargetIn(frameRange)) {
      return null;
    }
    if (inProgress && currentRequestRange) {
      if (currentRequestRange.isTargetIn(externalRange)) {
        return null;
      }

      if (currentRequestRange.isTargetIn(frameRange)) {
        return null;
      }
    }

    const [startSegment, endSegment] = loadedRange.getExternalSegments(externalRange);
    if ([startSegment, endSegment].filter((r) => r).length === 1) {
      if (startSegment) {
        return [startSegment.start, startSegment.end, externalRange, 'start'];
      }
      if (endSegment) {
        return [endSegment.start, endSegment.end, externalRange, 'end'];
      }
      return null;
    }

    return [externalRange.start, externalRange.end, externalRange];
  }

  getRange(onFrame: number, toFrame: number, scale: number) {
    const differenceTime = (toFrame - onFrame) * scale;
    const onData = onFrame - differenceTime;
    const toData = toFrame + differenceTime;
    return new Range(
      onData < this.rangeDB.start ? this.rangeDB.start : onData,
      toData > this.rangeDB.end ? this.rangeDB.end : toData,
    );
  }

  async getTimeCoords(
    onData: number,
    toData: number,
    dataStep: number,
    segment?: 'start' | 'end',
  ) {
    try {
      this.inProgress = true;
      const data = await getTimeCoords(
        this.curveId,
        dataStep,
        getUtc(onData),
        getUtc(toData),
      );
      runInAction(() => {
        if (Array.isArray(data)) {
          this.setPoints(data, segment);
        }
      });
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  setPoints(data: (CurvePoint | Hatch)[], segment?: 'start' | 'end') {
    if (Array.isArray(data)) {
      let points: (CurvePoint | HatchPoint)[] = [];
      let on;
      let to;

      if (isHatch(data)) {
        this.pointType = 'hatch';
        points = data.map((h) => ({
          key: h.firstKey + (h.lastKey - h.firstKey) / 2,
          value: [h.minVal, h.maxVal],
        }));

        on = data.at(0)?.firstKey;
        to = data.at(-1)?.lastKey;
      }
      if (isPoints(data)) {
        this.pointType = 'point';
        points = data;
        on = data.at(0)?.key;
        to = data.at(-1)?.key;
      }
      if (segment === 'start') {
        this.points = concatArrayToStart(this.points, points);
        if (on) {
          this.onData = on;
        }
      } else if (segment === 'end') {
        this.points = concatArrayToEnd(this.points, points);
        if (to) {
          this.toData = to;
        }
      } else {
        this.points = points;
        if (on && to) {
          this.onData = on;
          this.toData = to;
        }
      }
    }
  }

  binarySearch(data: (CurvePoint | HatchPoint)[], target: number, start: number, end: number): CurvePoint | HatchPoint | null {
    if (end < 1) return data[0];
    const middle = Math.floor(start + (end - start) / 2);
    if (target === data[middle].key) return data[middle];
    if ((end - 1) === start) {
      return Math.abs(data[start].key - target) > Math.abs(data[end].key - target) ? data[end] : data[start];
    }
    if (target > data[middle].key) return this.binarySearch(data, target, middle, end);
    if (target < data[middle].key) return this.binarySearch(data, target, start, middle);
    return null;
  }

  binarySearchIndex(data: (CurvePoint | HatchPoint)[], target: number, start: number, end: number): number | null {
    if (end < 1) return 0;
    const middle = Math.floor(start + (end - start) / 2);
    if (target === data[middle].key) return middle;
    if ((end - 1) === start) {
      return Math.abs(data[start].key - target) > Math.abs(data[end].key - target) ? end : start;
    }
    if (target > data[middle].key) return this.binarySearchIndex(data, target, middle, end);
    if (target < data[middle].key) return this.binarySearchIndex(data, target, start, middle);
    return null;
  }

  getPoint(y: number) {
    if (this.rangeDB.in(y)) {
      return this.binarySearch(this.points, y, 0, this.points.length - 1);
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  addPoint(point: (CurvePoint)) {
    // noop
  }

  dataSlice(from: number, to: number) {
    if (this.rangeDB.start > from && this.rangeDB.start > to) {
      return null;
    }
    if (this.rangeDB.end < from && this.rangeDB.end < to) {
      return null;
    }
    if (from === to) {
      return null;
    }
    const firstPointIndex = this.binarySearchIndex(
      this.points,
      from,
      0,
      this.points.length - 1,
    );
    if (!firstPointIndex) {
      return null;
    }
    const lastPointIndex = this.binarySearchIndex(
      this.points,
      to,
      0,
      this.points.length - 1,
    );
    if (firstPointIndex == null || lastPointIndex == null) {
      return null;
    }
    const firstPoint = this.points[firstPointIndex];
    const lastPoint = this.points[lastPointIndex];
    const stats = {
      firstKey: firstPoint.key,
      lastKey: lastPoint.key,
      firstValue: Array.isArray(firstPoint.value) ? 0 : firstPoint.value,
      lastValue: Array.isArray(lastPoint.value) ? 0 : lastPoint.value,
      min: Array.isArray(firstPoint.value) ? firstPoint.value[0] : firstPoint.value,
      max: Array.isArray(firstPoint.value) ? firstPoint.value[1] : firstPoint.value,
      sum: 0,
      count: lastPointIndex - firstPointIndex,
    };
    for (let i = firstPointIndex; i < lastPointIndex; i += 1) {
      const point = this.points[i];
      if (!Array.isArray(point.value)) {
        if (point.value >= 0) {
          stats.sum += point.value;
          if (stats.max < point.value) stats.max = point.value;
          if (stats.min > point.value) stats.min = point.value;
        } else { stats.count -= 1; }
      } else {
        if (stats.max < point.value[1]) stats.max = point.value[1];
        if (stats.min > point.value[0]) stats.min = point.value[0];
      }
    }
    return stats;
  }

  lastPointsAdded = 0;

  pointsCache: CurvePoint[] = [];

  timerId: number | null = null;

  setCache() {
    if (!this.toData) {
      return;
    }
    const filtered = this.pointsCache.filter((p) => this.toData! < p.key && p.key < (this.scale.toFrame));
    const last = filtered.at(-1);
    if (last) {
      this.points = this.points.concat(filtered);
      this.toData = last.key;
      this.pointsCache = [];
    }
  }

  setPoint(point: CurvePoint) {
    this.pointsCache.push(point);
    if (point.key - this.lastPointsAdded > 400) {
      if (this.timerId) {
        window.clearTimeout(this.timerId);
        this.timerId = null;
      }
      this.setCache();
      this.lastPointsAdded = point.key;
    } else if (this.timerId === null) {
      this.timerId = window.setTimeout(() => {
        this.setCache();
      }, 2000);
    }
  }

  addPoints(points: (CurvePoint)[]) {
    if (!this.toData) {
      return;
    }
    if (this.pointType === 'point') {
      const filtered = points.filter((p) => this.toData! < p.key && p.key < (this.scale.toFrame));
      if (filtered.length) {
        points.forEach((p) => {
          this.setPoint(p);
        });
      }
    } else {
      const last = points.at(-1);
      if (last && (last.key - this.toData) > (this.dataStep * 1000)) {
        this.sharedUpdate();
      }
    }
  }
}

function isHatch(points: (CurvePoint | Hatch)[]): points is Hatch[] {
  const firstPoint = points.at(0);
  return !!firstPoint && (firstPoint as Hatch).firstKey !== undefined;
}

function isPoints(points: (CurvePoint | Hatch)[]): points is CurvePoint[] {
  const firstPoint = points.at(0);
  return !!firstPoint && (firstPoint as CurvePoint).key !== undefined;
}

class Layer {
  coordinates: CurveFetcher;

  dataStep: number;

  scale: Scale;

  info: CurveInfo;

  constructor(
    curveId: number,
    info: CurveInfo,
    dataStep: number,
    scale: Scale,
    sharedUpdate: () => void,
  ) {
    this.coordinates = new CurveFetcher(curveId, dataStep, scale, info, sharedUpdate);
    this.dataStep = dataStep;
    this.scale = scale;
    this.info = info;
    makeAutoObservable(this);
  }

  get dataPerPixel() {
    return this.dataStep / 120;
  }

  async refreshCoords(params: {
    onFrame: number;
    toFrame: number;
    ratio: [number, number];
    force?: boolean;
  }) {
    await this.coordinates.refreshCoords(params, params.ratio);
  }
}

export class CurveCoordinates {
  layers: Layer[];

  prevScale: number;

  prevLayer: Layer;

  scaleSetDefault: number[];

  curveId: number;

  constructor(
    curveId: number,
    private scale: Scale,
    info: CurveInfo,
    sharedUpdate: () => void,
  ) {
    this.scaleSetDefault = [1, 30, 60, 180, 360, 720, 1440, 10080];
    this.layers = this.scaleSetDefault
      .map((step) => new Layer(curveId, info, step, scale, sharedUpdate));
    this.scale = scale;
    this.prevScale = this.scale.dataPerPixel;
    this.curveId = curveId;
    makeAutoObservable(this);
  }

  async refreshCoords(
    params: { onFrame: number; toFrame: number; force?: boolean; bounded?: boolean },
  ) {
    if (params.bounded) {
      await this.closest.refreshCoords({ ...params, ratio: [0, 0], force: true });
      return;
    }
    await this.closest.refreshCoords({ ...params, ratio: [8, 10] });
    if (this.prevLayer === this.closest) {
      if (this.next && this.prevScale < this.scale.dataPerPixel
         && ((this.scale.dataPerPixel / this.next.dataPerPixel) > 0.8)) {
        this.next?.refreshCoords({ ...params, ratio: [8, 10] });
      } else if (this.prev && this.prevScale > this.scale.dataPerPixel
        && ((this.prev.dataPerPixel / this.scale.dataPerPixel)
          * (this.closest.dataPerPixel / this.prev.dataPerPixel) > 0.8)) {
        this.prev?.refreshCoords({ ...params, ratio: [0.1, 0.2] });
      }
    }
    runInAction(() => {
      this.prevScale = this.scale.dataPerPixel;
      this.prevLayer = this.closest;
    });
  }

  get closest() {
    const goal = this.scale.dataPerPixel;
    if (goal < this.layers[0].dataPerPixel) {
      return this.layers[0];
    }
    for (let i = 0; i < this.layers.length - 1; i += 1) {
      const a = this.layers[i];
      const b = this.layers[i + 1];
      if (a.dataPerPixel <= goal && goal <= b.dataPerPixel) {
        return a;
      }
    }
    return this.layers[this.layers.length - 1];
  }

  get currentIndex() {
    return this.layers.indexOf(this.closest);
  }

  get prev() {
    return this.layers.at(this.currentIndex - 1);
  }

  get next() {
    return this.layers.at(this.currentIndex + 1);
  }

  get points() {
    return this.closest.coordinates.points;
  }

  get inProgress() {
    return this.closest.coordinates.inProgress;
  }

  get fetched() {
    return this.closest.coordinates.fetched;
  }

  get onData() {
    return this.closest.coordinates.onData;
  }

  get toData() {
    return this.closest.coordinates.toData;
  }

  binarySearchIndex(data: (CurvePoint | HatchPoint)[], target: number, start: number, end: number): number | null {
    return this.closest.coordinates.binarySearchIndex(data, target, start, end);
  }

  getPoint(key: number) {
    return this.closest.coordinates.getPoint(key);
  }

  dataSlice(from: number, to: number) {
    return this.closest.coordinates.dataSlice(from, to);
  }

  addPoint(point: CurvePoint) {
    this.closest.coordinates.addPoint(point);
  }

  addPoints(points: (CurvePoint)[]) {
    this.closest.coordinates.addPoints(points);
  }

  updateScaleSet(scaleSet: number[]) {
    const sorted = scaleSet.slice().sort((a, b) => a - b);
    sorted.unshift(1);
    this.layers = this.layers
      .filter((layer) => layer.dataStep <= 1
        || layer.dataStep >= 30
        || sorted.includes(layer.dataStep));
  }

  timeCoordinatesValidator(params: ValidatorParams) {
    return this.closest.coordinates.timeCoordinatesValidator(params);
  }
}

function concatArrayToEnd<T extends { key: number; }>(
  arr1: Array<T>,
  arr2: Array<T>,
): Array<T> {
  const lastPoint = arr1.at(-1);
  const index = (lastPoint && arr2.findIndex((p) => p.key === lastPoint.key)) ?? -1;
  return [...arr1, ...arr2.slice(index > -1 ? index + 1 : 0)];
}

function concatArrayToStart<T extends { key: number; }>(
  arr1: Array<T>,
  arr2: Array<T>,
): Array<T> {
  const firstPoint = arr1.at(0);
  const index = (firstPoint && arr2.findIndex((p) => p.key === firstPoint.key)) ?? -1;
  return [...arr2.slice(0, index > -1 ? index : arr2.length - 1), ...arr1];
}
