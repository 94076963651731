import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { Container } from '@inlet/react-pixi';
import { CommentItem } from './CommentItem';
import { Curve, Track } from '../../../../stores/Tracks';
import { Tablet } from '../../../../stores/Tablet';
import { SourceComments } from '../../../../stores/Comments/SourceComments';
import { CommentsCoordinates } from '../../../../stores/CoordinatesStorages/CommentsCoordinates';

type Props = {
  source: Curve;
  tablet: Tablet;
  width: number;
  track: Track;
  coordinates: CommentsCoordinates;
};

export const CommentsChart = observer(({
  source, tablet, width, track, coordinates,
}: Props) => {
  const { offsetY: scalerOffsetY, zeroPointKey, yRatio } = tablet.scale;
  const { points, writable } = coordinates;

  const sourceComments = useMemo(
    () => new SourceComments(
      source.sourceDto.externalId,
      points,
      tablet.commentsController,
      !writable,
    ),
    [writable, points, source.sourceDto.externalId, tablet.commentsController],
  );
  useEffect(() => {
    tablet.commentsController.register(sourceComments);
    return () => tablet.commentsController.unregister(sourceComments);
  }, [sourceComments, tablet.commentsController]);

  const offsetY = scalerOffsetY + zeroPointKey * yRatio;

  const { layersPriority } = track.params;

  const comments = layersPriority
    ? sourceComments.comments
    : sourceComments.comments
      .slice()
      .sort((a, b) => (a.isOpen ? 1 : 0) - (b.isOpen ? 1 : 0));

  return (
    // @ts-ignore: React remove children from React.FC
    <Container sortableChildren>
      {comments
        .map((comment, index, array) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            <CommentItem
              key={comment.key}
              comment={comment}
              nextCommentKey={array.at(index + 1)?.key}
              yRatio={yRatio}
              offsetY={offsetY}
              width={width}
              layersPriority={layersPriority}
              commentsHeight={track.params.commentsHeight}
              hideBorders={track.params.hideBorders}
              fontSize={track.params.fontSize}
              tabletHeight={tablet.scale.containerLength}
              isOpen={comment.isOpen}
            />
          </>
        ))}
    </Container>
  );
});
