import { ObjectType, ObjectDto } from '../../api/dto/ObjectDto';

import {
  PropCurve,
  PropFolder,
  PropDepthFolder,
  LoaderProperties,
  PropWell,
  PropWellbore,
  PropWellGreen,
  PropWellRed,
  TrajectoryProperties,
  PropTimeFolder,
} from './template';

type Component = (props: { well: ObjectDto }) => JSX.Element;

export const templatesDictionary:
Record<ObjectType, null | Component> = {
  [ObjectType.CURVE]: PropCurve,
  [ObjectType.FOLDER]: PropFolder,
  [ObjectType.WELL]: PropWell,
  [ObjectType.WELL_RED]: PropWellRed,
  [ObjectType.WELL_GREEN]: PropWellGreen,
  [ObjectType.WELL_YELLOW]: PropWell,
  [ObjectType.WELLBORE]: PropWellbore,
  [ObjectType.TRAJECTORY]: TrajectoryProperties,
  [ObjectType.SCREENCAST_FOLDER]: null,
  [ObjectType.SCREENCAST]: null,
  [ObjectType.AUTO_SUPERVISOR]: null,
  [ObjectType.AUTO_SUPERVISOR_ACTIVE]: null,
  [ObjectType.LOG_DEPTH]: PropDepthFolder,
  [ObjectType.LOG_TIME]: PropTimeFolder,
  [ObjectType.RECEIVER]: null,
  [ObjectType.PARSER]: null,
  [ObjectType.PARSER_TEMPLATE]: null,
  [ObjectType.IMAP_PARSER]: null,
  [ObjectType.USER]: null,
  [ObjectType.WITSML_LOADER]: LoaderProperties,
  [ObjectType.WITSML_LOADER_ACTIVE]: null,
  [ObjectType.SUPER_REPORT_FOLDER]: null,
  [ObjectType.DB]: null,
  [ObjectType.WITSML_SENDER]: null,
  [ObjectType.WITSML_SENDER_ACTIVE]: null,
  [ObjectType.RECOGNIZER]: null,
  [ObjectType.RECOGNIZER_ACTIVE]: null,
  [ObjectType.KPI_STATE_MARKER]: null,
  [ObjectType.KPI_STATE_MARKER_ACTIVE]: null,
};
