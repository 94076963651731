// eslint-disable-next-line import/no-extraneous-dependencies
import moment, { Moment } from 'moment';
import type { RangePickerProps } from 'antd/es/date-picker';

export const calcMinScale = (onData: Moment | number, toData: Moment | number) => {
  const hours = moment.duration(moment(toData).diff(onData)).asHours();
  if (hours / 24 > 30) {
    return 20;
  } if (hours / 24 > 20) {
    return 10;
  } if (hours / 24 > 10) {
    return 5;
  } if (hours / 24 > 5) {
    return 3;
  } if (hours / 24 > 1) {
    return 2;
  } if (hours > 6) {
    return 0.5;
  }
  return 0.1;
};

export const disabledGeneration = (minData: number, maxData: number): RangePickerProps['disabledDate'] => {
  const min = moment.utc(minData); // .utcOffset(offsetTimezone);
  const max = moment.utc(maxData); // .utcOffset(offsetTimezone);
  return (current) => current && (current < min || max < current);
};
