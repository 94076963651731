import * as PIXI from 'pixi.js';
import { TextStyle, TextMetrics } from 'pixi.js';
import { Text, Container, Sprite } from '@inlet/react-pixi';
import { useContext, useState } from 'react';
import { Comment as CommentEntity } from '../../../../stores/Comments/SourceComments';
import { Button } from '../../PixiUI/Button';
import { Rectangle } from '../../PixiUI/Rectangle';
import { CommentsHeight, Layers } from '../../../../api/dto/TrackDto';
import { TabletContext } from '../../TabletProvider';

function getCommentHeight(
  nextCommentY: number | undefined,
  currentCommentY: number,
  toData: number | null,
  yRatio: number,
  text: string,
  textStyle: TextStyle,
  layersPriority: Layers,
  commentsHeight: CommentsHeight,
  isOpen: boolean,
) {
  if (layersPriority === Layers.Single) {
    if (!nextCommentY) {
      return TextMetrics.measureText(text, textStyle).height;
    }
    const heightNextComment = (nextCommentY - currentCommentY) * yRatio;
    return heightNextComment;
  }

  if (isOpen) {
    if (!nextCommentY) {
      if (commentsHeight === CommentsHeight.ByRange) {
        return toData ? (toData - currentCommentY) * yRatio : 0;
      }

      return TextMetrics.measureText(text, textStyle).height;
    }

    const heightNextComment = (nextCommentY - currentCommentY) * yRatio;

    if (commentsHeight === CommentsHeight.ByRange) {
      const heightByRange = toData ? (toData - currentCommentY) * yRatio : 0;
      return heightByRange < heightNextComment ? heightByRange : heightNextComment;
    }

    const heightByText = TextMetrics.measureText(text, textStyle).height;
    return heightByText < heightNextComment ? heightByText : heightNextComment;
  }
  return TextMetrics.measureText(text, textStyle).height;
}

type Props = {
  comment: CommentEntity;
  nextCommentKey: number | undefined;
  yRatio: number;
  offsetY: number;
  width: number;
  layersPriority: Layers;
  commentsHeight: CommentsHeight;
  fontSize: number;
  tabletHeight: number;
  hideBorders: boolean;
  isOpen: boolean;
};

export function CommentItem({
  comment, nextCommentKey, yRatio, offsetY, width, layersPriority, commentsHeight,
  fontSize, tabletHeight, hideBorders, isOpen,
}: Props) {
  const { themeHex } = useContext(TabletContext);
  const [mask, setMask] = useState<PIXI.Sprite | null>(null);
  const { text, title } = comment.params;
  const y = comment.key * yRatio - offsetY;
  if (y > tabletHeight) {
    return null;
  }
  const buttonsGap = 4;
  const correctOffset = 1;
  const buttonSize = fontSize + buttonsGap;
  const textWidth = width - (buttonSize + buttonsGap + correctOffset) * 2;
  const currentText = ((isOpen || layersPriority === Layers.Single) ? text : title) ?? text ?? '';

  const textStyle = new TextStyle({
    fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
    fontSize,
    lineHeight: fontSize,
    fontWeight: '400',
    fill: themeHex.color,
    wordWrapWidth: textWidth,
    wordWrap: true,
    breakWords: true,
  });

  let blockHeight = getCommentHeight(
    nextCommentKey,
    comment.key,
    comment.key + (comment.params.length || 0) * 1000,
    yRatio,
    currentText,
    textStyle,
    layersPriority,
    commentsHeight,
    isOpen,
  );

  if (y + blockHeight < 0) {
    return null;
  }

  blockHeight = blockHeight < buttonSize + correctOffset * 2
    ? buttonSize + correctOffset * 2
    : blockHeight;

  if (y + blockHeight < 0) {
    return null;
  }

  const { color, backgroundColor, borderColor } = themeHex;

  const xb1 = textWidth + buttonsGap;
  const xb2 = textWidth + buttonsGap + buttonSize + buttonsGap;

  return (
    <Container y={y} x={0} mask={mask} zIndex={isOpen ? 1 : 0}>
      <Sprite
        texture={PIXI.Texture.WHITE}
        x={0}
        y={0}
        width={width}
        height={blockHeight}
        tint={backgroundColor}
        ref={((ref) => {
          setMask(ref);
        })}
      />
      <Rectangle
        width={width + correctOffset}
        height={blockHeight}
        fillColor={backgroundColor}
        borderColor={borderColor}
        lineWeight={hideBorders ? 0 : 2}
      />
      <Text x={correctOffset} y={0} text={currentText} style={textStyle} />
      {layersPriority === Layers.Multi && (
        <Button
          x={xb2}
          y={correctOffset}
          text={isOpen ? '-' : '+'}
          width={buttonSize}
          height={buttonSize}
          onClick={() => (isOpen ? comment.close() : comment.open())}
          fontSize={fontSize}
          color={color}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
        />
      )}
      <Button
        x={layersPriority === Layers.Multi ? xb1 : xb2}
        y={correctOffset}
        text="✎"
        width={buttonSize}
        height={buttonSize}
        onClick={() => comment.edit()}
        fontSize={fontSize}
        color={color}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
      />
    </Container>
  );
}
