import { SearchOutlined } from '@ant-design/icons';
import { useLocalization } from '@fluent/react';
import {
  Button, Popover, Space, Tooltip,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Tablet } from '../../../stores/Tablet';
import { getScale, ScaleSelectorType, setScale } from './shared';

const TextButtonWidth = 54;
const TimeButtonWidth = 43;

const ScaleSelectorContent = observer(({ tablet, close }:
{ tablet: Tablet; close: () => void }) => {
  const { l10n } = useLocalization();
  const selectedRange = getScale(tablet);
  return (
    <Space direction="vertical">
      <Space.Compact block size="small">
        <Button
          type={selectedRange === 'max' ? 'primary' : 'default'}
          onClick={() => setScaleAndRefresh('max', tablet, close)}
          style={{ width: TextButtonWidth }}
        >
          {l10n.getString('ScaleMax')}
        </Button>
        <Button
          type={selectedRange === '1h' ? 'primary' : 'default'}
          onClick={() => setScaleAndRefresh('1h', tablet, close)}
          style={{ width: TimeButtonWidth }}
        >
          {l10n.getString('Scale1h')}
        </Button>
        <Button
          type={selectedRange === '2h' ? 'primary' : 'default'}
          onClick={() => setScaleAndRefresh('2h', tablet, close)}
          style={{ width: TimeButtonWidth }}
        >
          {l10n.getString('Scale2h')}
        </Button>
        <Button
          type={selectedRange === '4h' ? 'primary' : 'default'}
          onClick={() => setScaleAndRefresh('4h', tablet, close)}
          style={{ width: TimeButtonWidth }}
        >
          {l10n.getString('Scale4h')}
        </Button>
      </Space.Compact>
      <Space.Compact block size="small">
        <Button
          type={selectedRange === 'min' ? 'primary' : 'default'}
          onClick={() => {
            tablet.doFitAll();
            close();
          }}
          style={{ width: TextButtonWidth }}
        >
          {l10n.getString('ScaleMin')}
        </Button>
        <Button
          type={selectedRange === '8h' ? 'primary' : 'default'}
          onClick={() => setScaleAndRefresh('8h', tablet, close)}
          style={{ width: TimeButtonWidth }}
        >
          {l10n.getString('Scale8h')}
        </Button>
        <Button
          type={selectedRange === '12h' ? 'primary' : 'default'}
          onClick={() => setScaleAndRefresh('12h', tablet, close)}
          style={{ width: TimeButtonWidth }}
        >
          {l10n.getString('Scale12h')}
        </Button>
        <Button
          type={selectedRange === '24h' ? 'primary' : 'default'}
          onClick={() => setScaleAndRefresh('24h', tablet, close)}
          style={{ width: TimeButtonWidth }}
        >
          {l10n.getString('Scale24h')}
        </Button>
      </Space.Compact>
    </Space>
  );
});

function setScaleAndRefresh(type: ScaleSelectorType, tablet: Tablet, cb: ()=> void) {
  const centerDate = tablet.scale.pointToData(
    tablet.scale.offsetY + (tablet.scale.containerLength / 2),
  );
  setScale(type, tablet);
  if (tablet.autoScroll) {
    tablet.setOffsetToEnd();
  } else {
    let nextOffset = tablet.scale.dataToPoint(centerDate) - (tablet.scale.containerLength / 2);
    nextOffset = Math.max(nextOffset, tablet.topOffsetY);
    nextOffset = Math.min(nextOffset, tablet.bottomOffsetY);
    tablet.scale.setOffsetY(nextOffset);
  }
  tablet.refreshCoords();
  cb();
}

export const ScaleSelectorPanel = observer(({ tablet }: { tablet: Tablet }) => {
  const { l10n } = useLocalization();
  const [open, setOpen] = useState(false);
  function close() {
    setOpen(false);
  }
  return (
    <Popover
      open={open}
      content={<ScaleSelectorContent tablet={tablet} close={() => close()} />}
      destroyTooltipOnHide
      title={l10n.getString('ScaleSelection')}
      trigger="click"
      onOpenChange={(e) => setOpen(e)}
    >
      <Button
        size="small"
        icon={<Tooltip title={l10n.getString('ScaleSelection')}><SearchOutlined /></Tooltip>}
      />
    </Popover>
  );
});
