import { FluentBundle, FluentResource } from '@fluent/bundle';
import { negotiateLanguages } from '@fluent/langneg';
import { ReactLocalization } from '@fluent/react';
import { makeAutoObservable, runInAction } from 'mobx';
import antRu from 'antd/es/locale/ru_RU';
import dateFnsRu from 'date-fns/locale/ru';

export const LC_SELECTED_LOCALE = 'LC_SELECTED_LOCALE';

const ftl: Record<string, URL> = {
  'en-US': new URL('../locales/en-US.ftl', import.meta.url),
  'ru-Ru': new URL('../locales/ru-RU.ftl', import.meta.url),
};

const DEFAULT_LOCALE = 'en-US';

const AVAILABLE_LOCALES = {
  'en-US': 'En',
  'ru-Ru': 'Ru',
};

async function fetchMessages(locale: string): Promise<[string, string]> {
  const response = await fetch(String(ftl[locale]));
  const messages = await response.text();
  return [locale, messages];
}

function* lazilyParsedBundles(fetchedMessages: Array<[string, string]>) {
  // eslint-disable-next-line no-restricted-syntax
  for (const [locale, messages] of fetchedMessages) {
    const resource = new FluentResource(messages);
    const bundle = new FluentBundle(locale);
    bundle.addResource(resource);
    yield bundle;
  }
}

export class LocalizationStore {
  l10n:ReactLocalization | null = null;

  currentLocales = [DEFAULT_LOCALE];

  locales = AVAILABLE_LOCALES;

  constructor() {
    makeAutoObservable(this);
    const lc = localStorage.getItem(LC_SELECTED_LOCALE);
    if (lc) {
      this.changeLocales([lc]);
    } else {
      this.changeLocales(navigator.languages as Array<string>);
    }
  }

  async changeLocales(userLocales: Array<string>) {
    const list: string[] = await (await fetch('/lang.json')).json();

    const availableLocales = Object.entries(AVAILABLE_LOCALES).filter(([key]) => list.includes(key))
      .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {} as {
        'en-US': string;
        'ru-Ru': string;
      });
    runInAction(() => {
      this.locales = availableLocales;
    });

    const currentLocales = negotiateLanguages(
      userLocales,
      Object.keys(availableLocales),
      { defaultLocale: DEFAULT_LOCALE },
    );
    this.setCurrentLocales(currentLocales);

    const fetchedMessages = await Promise.all(currentLocales.map(fetchMessages));

    const bundles = lazilyParsedBundles(fetchedMessages);
    this.setL10n(new ReactLocalization(bundles));
  }

  setCurrentLocales(locales: string[]) {
    this.currentLocales = locales;
    localStorage.setItem('LC_SELECTED_LOCALE', locales[0]);
  }

  setL10n(instance: ReactLocalization) {
    this.l10n = instance;
  }

  get antd() {
    if (this.currentLocales[0] === 'ru-Ru') {
      return antRu;
    }
    return undefined;
  }

  get dateFns() {
    if (this.currentLocales[0] === 'ru-Ru') {
      return dateFnsRu;
    }
    return undefined;
  }
}
