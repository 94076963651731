import { observer } from 'mobx-react-lite';
import { useContext, useRef } from 'react';
import { Container } from '@inlet/react-pixi';
import { TextStyle, TextMetrics } from 'pixi.js';
import { Track } from '../../../../stores/Tracks';
import { Tablet } from '../../../../stores/Tablet';
import { CommentsCoordinates } from '../../../../stores/CoordinatesStorages/CommentsCoordinates';
import { StringChartItem } from './StringChartItem';
import { TabletContext } from '../../TabletProvider';

type TextItem = { key: number; y: number; text: string; height: number };

type Props = {
  tablet: Tablet;
  width: number;
  track: Track;
  coordinates: CommentsCoordinates;
  trackSelected: boolean;
};

export const StringChart = observer(({
  tablet, width, track, coordinates, trackSelected,
}: Props) => {
  const { themeHex } = useContext(TabletContext);

  const { offsetY: scalerOffsetY, zeroPointKey, yRatio } = tablet.scale;

  const ref = useRef<number>(0);

  ref.current = 0;

  const offsetY = scalerOffsetY + zeroPointKey * yRatio;

  const { fontSize } = track.params;

  const textStyle = new TextStyle({
    fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
    fontSize,
    lineHeight: fontSize,
    fontWeight: '400',
    fill: themeHex.color,
    wordWrapWidth: width,
    wordWrap: true,
    breakWords: true,
  });

  const correctOffset = 1;

  const comments: TextItem[] = [];

  let prevVisibleComment = 0;
  coordinates.points.forEach((point, index, array) => {
    const y = point.key * yRatio - offsetY;
    if (y > tablet.scale.containerLength) {
      return;
    }
    const text = point.value;
    const nextComment = array.at(index + 1);
    if (!nextComment) {
      const { height } = TextMetrics.measureText(text, textStyle);
      comments.push({
        key: point.key, y, text, height,
      });
      return;
    }
    const heightNextComment = (nextComment.key - point.key) * yRatio;
    if (y + heightNextComment < 0) {
      return;
    }
    if ((heightNextComment + y) - prevVisibleComment < 10) {
      return;
    }

    comments.push({
      key: point.key, y, text, height: heightNextComment < fontSize ? fontSize : heightNextComment,
    });
    prevVisibleComment = y + fontSize + 2;
  });

  return (
    <Container sortableChildren>
      {comments
        .map((comment) => (
          <StringChartItem
            key={comment.key}
            y={comment.y}
            text={comment.text}
            correctOffset={correctOffset}
            width={width}
            height={comment.height}
            hideBorders={track.params.hideBorders}
            textStyle={textStyle}
            interactive={!trackSelected}
          />
        ))}
    </Container>
  );
});
