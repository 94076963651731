import * as PIXI from 'pixi.js';
import { observer } from 'mobx-react-lite';
import {
  Container, Graphics, Sprite, Text,
} from '@inlet/react-pixi';
import { useCallback, useContext } from 'react';
import { Curve, Track } from '../../../stores/Tracks';
import { Tablet } from '../../../stores/Tablet';
import { DepthCoordinates } from '../../../stores/CoordinatesStorages/DepthCoordinates';
import { TabletType } from '../../../enums/TabletType';
import { TrackAlign } from '../../../api/dto/TrackDto';
import { round } from '../../../utils';
import { CurveCoordinates } from '../../../stores/CoordinatesStorages/CurveCoordinates';
import { TabletContext } from '../TabletProvider';

type Props = {
  source: Curve;
  tablet: Tablet,
  offsetX?: number;
  coordinates: CurveCoordinates | DepthCoordinates;
  track: Track;
};

export const OverlayCurveNumeric = observer(({
  tablet, offsetX = 0, coordinates, source, track,
}: Props) => {
  const { themeHex } = useContext(TabletContext);

  const textStyle = new PIXI.TextStyle(tablet.baseTextStyle);
  textStyle.fill = source.params.color;

  const coordinatorDataPoint = tablet.scale.pointToData(
    tablet.scale.offsetY + tablet.coordinator.y,
  );

  const point = coordinates.getPoint(coordinatorDataPoint);

  const coordinatorText = point ? getText(point, source.params.fractionDigits) : null;

  const cursorHeight = tablet.baseTextStyle.fontSize
    * (tablet.tabletType === TabletType.Depth ? 1 : 2) + 8;

  const width = track.params.trackWidth;

  const draw = useCallback((g: PIXI.Graphics) => {
    g.clear();
    g.lineStyle(1, themeHex.color, 1);
    g.moveTo(0, 0);
    g.lineTo(width, 0);
    g.moveTo(0, cursorHeight);
    g.lineTo(width, cursorHeight);
    g.beginFill(themeHex.backgroundColor);
    g.lineStyle(0);
    g.drawRect(
      0,
      1,
      width,
      cursorHeight - 2,
    );
    g.endFill();
  }, [cursorHeight, width, themeHex.backgroundColor, themeHex.color]);

  return coordinatorText
      && tablet.coordinator.currentTrack
      && (tablet.coordinator.y > 0 && tablet.coordinator.y < tablet.scale.containerLength)
      && !tablet.editMode ? (
        <Container
          x={offsetX}
          y={tablet.coordinator.mainAxis - cursorHeight / 2}
        >
          <Graphics draw={draw} />
          <Text
            text={coordinatorText}
            anchor={[track.params.align === TrackAlign.Left ? 0 : 1, 0.5]}
            x={(track.params.align === TrackAlign.Left ? 8 : width - 8)}
            y={cursorHeight / 2}
            style={new PIXI.TextStyle({
              ...tablet.baseTextStyle,
              fill: themeHex.brightColor,
              wordWrap: true,
              wordWrapWidth: track.params.trackWidth,
              align: track.params.align === TrackAlign.Left ? 'left' : 'right',
            })}
          />
          <Sprite
            x={width}
            y={0}
            height={cursorHeight}
            width={2}
            texture={PIXI.Texture.WHITE}
            tint={themeHex.borderColor}
          />
        </Container>
    ) : null;
});

function getText(point: {
  key: number;
  value: number | [number, number] | null;
} | null, fractionDigits?: number) {
  if (point != null) {
    if (Array.isArray(point.value)) {
      return String(round(point.value[1], fractionDigits));
    }
    return String(point.value ? round(point.value, fractionDigits) : point.value);
  }
  return null;
}
