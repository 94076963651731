import { CalendarOutlined } from '@ant-design/icons';
import { useLocalization } from '@fluent/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment, { Moment } from 'moment';
import {
  Button, CheckboxOptionType, DatePicker, Popover, Radio, Space, Tooltip,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { RangePickerProps } from 'antd/lib/date-picker';
import { Tablet } from '../../../stores/Tablet';
import { getScale, ScaleSelectorType, setScale } from './shared';

const TextButtonWidth = 54;
const TimeButtonWidth = 43;

const NavigationPanelContent = observer(({ tablet, close }:
{ tablet: Tablet, close: () => void }) => {
  const { l10n } = useLocalization();
  const [type, setType] = useState<ScaleSelectorType>(getScale(tablet) || '12h');
  const centerDate = tablet.scale.pointToData(
    tablet.scale.offsetY + (tablet.scale.containerLength / 2),
  );
  const [date, setDate] = useState<Moment | null>(moment(tablet.timeZones.getDate(centerDate)));

  const optionsTop: CheckboxOptionType[] = [
    { label: l10n.getString('ScaleMax'), value: 'max', style: { width: TextButtonWidth, textAlign: 'center' } },
    { label: l10n.getString('Scale1h'), value: '1h', style: { width: TimeButtonWidth, textAlign: 'center' } },
    { label: l10n.getString('Scale2h'), value: '2h', style: { width: TimeButtonWidth, textAlign: 'center' } },
    { label: l10n.getString('Scale4h'), value: '4h', style: { width: TimeButtonWidth, textAlign: 'center' } },
  ];

  const optionsBottom: CheckboxOptionType[] = [
    { label: l10n.getString('ScaleMin'), value: 'all', style: { width: TextButtonWidth, textAlign: 'center' } },
    { label: l10n.getString('Scale8h'), value: '8h', style: { width: TimeButtonWidth, textAlign: 'center' } },
    { label: l10n.getString('Scale12h'), value: '12h', style: { width: TimeButtonWidth, textAlign: 'center' } },
    { label: l10n.getString('Scale24h'), value: '24h', style: { width: TimeButtonWidth, textAlign: 'center' } },
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current: Moment) => {
    const toDate = current && moment(tablet.timeZones.getInverDate(current.valueOf()));
    const r = toDate && toDate.isBetween(tablet.onDataDB, tablet.toDataDB);
    return !r;
  };

  function submit() {
    if (type === 'all') {
      tablet.doFitAll();
    } else if (date) {
      setScale(type, tablet);
      const toDate = tablet.timeZones.getInverDate(date.valueOf()).valueOf();
      tablet.navigateTo(toDate, 'center');
      tablet.refreshCoords();
    }
    close();
  }

  return (
    <Space direction="vertical">
      <Radio.Group
        options={optionsTop}
        onChange={(value) => setType(value.target.value)}
        value={type}
        optionType="button"
        buttonStyle="solid"
        size="small"
      />
      <Radio.Group
        options={optionsBottom}
        onChange={(value) => setType(value.target.value)}
        value={type}
        optionType="button"
        buttonStyle="solid"
        size="small"
      />
      {type !== 'all' && (
      <DatePicker
        value={date}
        onChange={(e) => setDate(e)}
        format="YYYY-MM-DD HH:mm"
        showTime={{ defaultValue: moment('00:00:00', 'HH:mm') }}
        disabledDate={disabledDate}
        style={{ width: '100%' }}
      />
      )}
      <Button
        type="primary"
        disabled={type !== 'all' && date === null}
        onClick={() => submit()}
        block
      >
        {l10n.getString('Apply')}
      </Button>
    </Space>
  );
});

export const NavigationPanel = observer(({ tablet }: { tablet: Tablet }) => {
  const { l10n } = useLocalization();
  const [open, setOpen] = useState(false);
  function close() {
    setOpen(false);
  }
  if (tablet.autoScroll) {
    return (
      <Button
        size="small"
        icon={<Tooltip title={l10n.getString('Navigation')}><CalendarOutlined /></Tooltip>}
        disabled
      />
    );
  }
  return (
    <Popover
      open={open}
      content={<NavigationPanelContent tablet={tablet} close={() => close()} />}
      destroyTooltipOnHide
      title={l10n.getString('Navigation')}
      trigger="click"
      onOpenChange={(e) => setOpen(e)}
    >
      <Button
        size="small"
        icon={<Tooltip title={l10n.getString('Navigation')}><CalendarOutlined /></Tooltip>}
      />
    </Popover>
  );
});
