import styled from '@emotion/styled';

export const Tooltip = styled.div`
  padding: 4px;
  background-color: ${(props) => props.theme.backgroundColor};
  font-size: 14px;
  border: ${(props) => props.theme.baseBorder};
  z-index: 1000;
  line-height: 1;
`;

export const AnalysisHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px 8px;
  & div:nth-of-type(even) {
    text-align: right;
  }
  width: min-content;
  white-space: nowrap;
  margin-right: 36px;
  margin-bottom: 4px;
`;

export const AnalysisTable = styled.div<{ columnsCount: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columnsCount }) => columnsCount}, 1fr);
  gap: 4px 8px;
  width: min-content;
  min-width: 100%;
`;

export const AnalysisTabletRow = styled.div<{ color: string }>`
  display: contents;
  color: ${({ color }) => color};
`;

export const AnalysisTabletHeader = styled.div`
  text-align: center;
  white-space: nowrap;
`;

export const AnalysisTabletName = styled.div`
  white-space: nowrap;
`;

export const AnalysisTabletValue = styled.div`
  text-align: right;
`;

export const AnalysisClose = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  pointer-events: auto;
`;
