import styled from '@emotion/styled';

export const TemplateCard = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.color};
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  border-radius: 2px;
  padding: 24px;
  border: 1px solid ${(props) => props.theme.borderColor};
  cursor: pointer;
  box-shadow: ${(props) => (props.active ? `${props.theme.color} 0 0 3px` : '')};
  height: 96px;
  &:hover {
    box-shadow: ${(props) => props.theme.color} 0 0 5px;
  }
`;

export const TemplateCardText = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;
