/* eslint-disable react/require-default-props */
import { Button, Popover } from 'antd';
import { SketchPicker } from 'react-color';
import { useTheme } from '@emotion/react';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};
export function ColorPicker({ value = '#ffffff', onChange }: Props) {
  const theme = useTheme();
  return (
    <Popover
      trigger="click"
      content={(
        <SketchPicker
          styles={{
            default: {
              picker: {
                padding: 0,
                boxShadow: 'none',
                background: theme.backgroundColor,
              },
            },
          }}
          disableAlpha
          color={value}
          onChange={(e) => onChange?.(e.hex)}
          presetColors={[
            '#999999',
            '#FF00FF',
            '#00FFFF',
            '#9933FF',
            '#00FF00',
            '#CCCC00',
            '#FF9900',
            '#FF0000',
            '#666666',
            '#993366',
            '#0000FF',
            '#009999',
            '#009900',
            '#999900',
            '#FF6600',
            '#663300',
          ]}
        />
      )}
    >
      <Button block>
        <div style={{
          background: value, minWidth: 14, height: 14, width: '100%', border: '1px solid black',
        }}
        />
      </Button>
    </Popover>
  );
}
