import { useTheme } from '@emotion/react';
import RotatingLines from '../Loader/RotatingLines';
import {
  TabletWrapper, TabletHeader, TabletBody, PointsLoadSpiner,
} from './Tablet.styled';

export function TabletPreloader() {
  const theme = useTheme();
  return (
    <TabletWrapper style={{ width: 400 }}>
      <TabletHeader />
      <TabletBody>
        <PointsLoadSpiner>
          <RotatingLines width="96" strokeColor={theme.lightPrimary} />
        </PointsLoadSpiner>
      </TabletBody>
    </TabletWrapper>
  );
}
