import { useCallback } from 'react';
import { Graphics } from '@inlet/react-pixi';
import * as PIXI from 'pixi.js';

type Props = {
  x?: number;
  y?: number;
  width: number;
  height: number;
  fillColor?: number;
  borderColor?: number;
  lineWeight?: number;
};

export function Rectangle({
  x = 0, y = 0, width, height, fillColor = 0xfff_fff, borderColor = 0x000_000, lineWeight = 1,
}: Props) {
  const draw = useCallback((g: PIXI.Graphics) => {
    g.clear();
    g.beginFill(fillColor);
    g.lineStyle(lineWeight, borderColor);
    g.drawRect(x, y, width, height);
    g.endFill();
  }, [fillColor, lineWeight, borderColor, x, y, width, height]);

  return <Graphics draw={draw} />;
}
