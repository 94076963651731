import { observer } from 'mobx-react-lite';
import { Tablet } from '../../stores/Tablet';
import { Track, Curve } from '../../stores/Tracks';
import { CurveHeader } from './CurveHeader';
import { CuttingsHeader } from './CuttingsHeader';
import { LithologyHeader } from './LithologyHeader';
import { ImageHeader } from './ImageHeader';
import { KpiHeader } from './KpiHeader';
import { CommentsHeader } from './CommentsHeader';
import { ErrorHeader } from './ErrorHeader';
import { InnerChartType } from '../../stores/SourceDataMap';
import { ValueScaleType } from '../../api/dto/TrackDto';
import { CurveNumericHeader } from './CurveNumericHeader';
import { CurveHeaderWithScale } from './CurveHeaderWithScale';

type Props = {
  track: Track;
  tablet: Tablet;
  source: Curve;
  offset: number;
  collapsed: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
  onContextMenu: React.MouseEventHandler<HTMLDivElement>
};

export const HeaderResolver = observer(({
  track, tablet, source, offset, collapsed, onClick, onContextMenu,
}: Props) => {
  if (source.sourceData?.errors) {
    return (
      <ErrorHeader
        tablet={tablet}
        source={source}
        collapsed={collapsed}
        onClick={onClick}
        onContextMenu={onContextMenu}
      />
    );
  }
  if (source.sourceData?.innerChartType === InnerChartType.Cuttings) {
    return (
      <CuttingsHeader
        tablet={tablet}
        source={source}
        collapsed={collapsed}
        onClick={onClick}
        onContextMenu={onContextMenu}
      />
    );
  }
  if (source.sourceData?.innerChartType === InnerChartType.Lithology) {
    return (
      <LithologyHeader
        tablet={tablet}
        source={source}
        collapsed={collapsed}
        onClick={onClick}
        onContextMenu={onContextMenu}
      />
    );
  }
  if (source.sourceData?.innerChartType === InnerChartType.Block) {
    return (
      <KpiHeader
        tablet={tablet}
        source={source}
        collapsed={collapsed}
        onClick={onClick}
        onContextMenu={onContextMenu}
      />
    );
  }
  if (source.sourceData?.innerChartType === InnerChartType.Image) {
    return (
      <ImageHeader
        tablet={tablet}
        source={source}
        collapsed={collapsed}
        onClick={onClick}
        onContextMenu={onContextMenu}
      />
    );
  }
  if (source.sourceData?.innerChartType === InnerChartType.DepthCurve
    || source.sourceData?.innerChartType === InnerChartType.TimeCurve
  ) {
    if (track.params.valueScaleType === ValueScaleType.Numeric) {
      return (
        <CurveNumericHeader
          tablet={tablet}
          source={source}
          collapsed={collapsed}
          onClick={onClick}
          onContextMenu={onContextMenu}
        />
      );
    }
    if (source.params.showValueScale) {
      return (
        <CurveHeaderWithScale
          tablet={tablet}
          track={track}
          source={source}
          offset={offset}
          onClick={onClick}
          onContextMenu={onContextMenu}
        />
      );
    }
    return (
      <CurveHeader
        tablet={tablet}
        track={track}
        source={source}
        offset={offset}
        collapsed={collapsed}
        onClick={onClick}
        onContextMenu={onContextMenu}
      />
    );
  }
  if (source.sourceData?.innerChartType === InnerChartType.Comments) {
    return (
      <CommentsHeader
        tablet={tablet}
        source={source}
        collapsed={collapsed}
        onClick={onClick}
        onContextMenu={onContextMenu}
      />
    );
  }
  return null;
});
