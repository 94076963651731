import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import * as PIXI from 'pixi.js';
import { Graphics, Text } from '@inlet/react-pixi';
import { getTextWidth } from '../../../utils';

type Block = {
  key: number;
  y: number;
  blockHeight: number;
  name: string;
  fontStyle: PIXI.TextStyle;
  textWidth: number;
  color: string;
  opacity: number;
  display: 'horizontal' | 'vertical' | 'none';
};

type Props = {
  blocks: {
    key: number;
    y: number;
    blockHeight: number;
    name: string;
    background: string;
    opacity: number;
    textColor: string;
  }[],
  width: number;
  height: number;
  offsetX: number;
  tabletOrientation: 'horizontal' | 'vertical';
};

export const Blocks = observer(({
  blocks, width, height, offsetX, tabletOrientation,
}: Props) => {
  const toDrawBlocks = useMemo(() => {
    const innerBlocks: Block[] = [];
    blocks.forEach((block) => {
      if (block.y + block.blockHeight < 0 || block.y > height) {
        return;
      }

      let display: 'horizontal' | 'vertical' | 'none' = 'none';

      const fontStyle = new PIXI.TextStyle({
        fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
        fontSize: 12,
        fontWeight: '400',
        fill: block.textColor,
      });

      let textWidth = 0;

      if (block.blockHeight > 20 && block.name) {
        textWidth = getTextWidth(block.name, fontStyle);
        if (block.blockHeight > textWidth) {
          if (block.blockHeight > textWidth * 10) {
            fontStyle.fontSize = 32;
            textWidth = getTextWidth(block.name, fontStyle);
          } else if (block.blockHeight > textWidth * 5) {
            fontStyle.fontSize = 24;
            textWidth = getTextWidth(block.name, fontStyle);
          } else if (block.blockHeight > textWidth * 2) {
            fontStyle.fontSize = 18;
            textWidth = getTextWidth(block.name, fontStyle);
          }
          display = 'vertical';
        } else if (textWidth < width) {
          display = 'horizontal';
        }
      }
      innerBlocks.push({
        key: block.key,
        y: block.y,
        blockHeight: block.blockHeight,
        name: block.name,
        fontStyle,
        textWidth,
        color: block.background,
        opacity: block.opacity,
        display,
      });
    });
    return innerBlocks;
  }, [blocks, width, height]);

  const draw = useCallback((g: PIXI.Graphics) => {
    g.clear();
    toDrawBlocks.forEach((block) => {
      g.beginFill(PIXI.utils.string2hex(block.color), block.opacity);
      g.drawRect(offsetX + 1, block.y, width - 2, block.blockHeight);
      g.endFill();
    });
  }, [toDrawBlocks, offsetX, width]);

  return (
    <>
      <Graphics draw={draw} />
      {toDrawBlocks.map((block) => {
        if (block.display === 'none') {
          return null;
        }
        const rotation = block.display === 'vertical'
          ? ((tabletOrientation === 'vertical' ? 3 : -3) * Math.PI) / 2
          : 0;
        return (
          <Text
            key={block.key}
            text={block.name}
            anchor={[0.5, 0.5]}
            x={offsetX + width / 2}
            y={block.y + block.blockHeight / 2}
            rotation={rotation}
            style={block.fontStyle}
          />
        );
      }) }
    </>
  );
});
