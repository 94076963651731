import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

function DarkThemeSvg() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor">
      <path fillRule="evenodd" d="M14.53 10.53a7 7 0 0 1-9.058-9.058A7.003 7.003 0 0 0 8 15a7.002 7.002 0 0 0 6.53-4.47z" />
    </svg>
  );
}

export function DarkThemeIcon(props: Partial<CustomIconComponentProps>) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Icon component={DarkThemeSvg} {...props} />;
}
