import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocalization } from '@fluent/react';
import { Tablet } from '../../../stores/Tablet';
import { Blocks } from './Blocks';
import { CurvePoint } from '../../../api/curvesCache';

type Block = {
  key: number;
  y: number;
  blockHeight: number;
  name: string;
  background: string;
  opacity: number;
  textColor: string;
};

export const DefectsChart = observer(({
  tablet, width, offsetX = 0, rangesDefectData,
}:
{ tablet: Tablet, width: number, offsetX?: number, rangesDefectData: CurvePoint[][] }) => {
  const { l10n } = useLocalization();
  const { offsetY: scalerOffsetY, zeroPointKey, yRatio } = tablet.scale;

  const toDrawBlocks = useMemo(() => {
    const blocks: Block[] = [];
    const offsetY = scalerOffsetY + zeroPointKey * yRatio;
    rangesDefectData.forEach(([first, last]) => {
      const y = first.key * yRatio - offsetY;
      const blockHeight = (last.key - first.key) * yRatio;

      if (y + blockHeight < 0) {
        return;
      }

      blocks.push({
        key: first.key,
        y,
        blockHeight,
        name: l10n.getString('False-data'),
        background: '#f99090',
        opacity: 1,
        textColor: '#000000',
      });
    });
    return blocks;
  }, [scalerOffsetY, zeroPointKey, yRatio, rangesDefectData, l10n]);

  return (
    <Blocks
      blocks={toDrawBlocks}
      width={width}
      offsetX={offsetX}
      height={tablet.scale.containerLength}
      tabletOrientation={tablet.params.orientation}
    />
  );
});
