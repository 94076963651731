import { TabletParams } from './TabletParams';
import { Tracks } from './Tracks';

export class TabletSizes {
  tabletParams: TabletParams;

  tracks: Tracks;

  constructor(tabletParams: TabletParams, tracks: Tracks) {
    this.tabletParams = tabletParams;
    this.tracks = tracks;
  }

  get tabletWidth() {
    if (this.tabletParams.orientation === 'vertical') {
      return this.tracks.tracksWidth;
    }
    return this.tabletParams.canvasSize[0];
  }

  get tabletHeight() {
    if (this.tabletParams.orientation === 'horizontal') {
      return this.tracks.tracksWidth;
    }
    return this.tabletParams.canvasSize[1];
  }
}
