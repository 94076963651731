import { memo, useCallback } from 'react';
import * as PIXI from 'pixi.js';
import { Graphics } from '@inlet/react-pixi';
import { DashLine } from 'pixi-dashed-line';

type Props = {
  x: number;
  y: number;
  width: number;
  height: number;
  dash?: [number, number];
  lineWidth: number;
  color: number;
};

export const DashedRect = memo(({
  x, y, width, height, dash = [5, 5], color, lineWidth,
}: Props) => {
  const draw = useCallback((g: PIXI.Graphics) => {
    g.clear();
    const dashG = new DashLine(g, {
      dash,
      width: lineWidth,
      color,
    });
    dashG.drawRect(x, y, width, height);
  }, [x, y, width, height, dash, color, lineWidth]);
  return <Graphics draw={draw} />;
});
