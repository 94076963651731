import {
  InputRef, message,
  Input, Checkbox, Tooltip, Button,
} from 'antd';

import { useState, useRef, useEffect } from 'react';
import { useLocalization } from '@fluent/react';
import { DownloadOutlined } from '@ant-design/icons';
import { templatesStore } from '../../stores/templatesStore';
import TemplateDropDown from './TemplateDropDown';
import {
  TemplateItemWrapper, TemplateEdit, TemplateEditButton,
} from './Template.styled';

type Props = {
  id: number;
  name: string;
  system: boolean;
};

function TemplateItem({ id, name, system }: Props) {
  const { l10n } = useLocalization();

  const [value, setValue] = useState(name);
  const [lastName, setLastName] = useState(name);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    return value !== lastName;
  };

  const save = async () => {
    const refresh = toggleEdit();

    if (refresh) {
      setLastName(value);
      await templatesStore.rename(id, value);
      message.success(l10n.getString('TemplateSuccessfullyRenamed'));
    }
  };

  return (
    <TemplateItemWrapper>
      <Tooltip title={l10n.getString('Select')}>
        <Checkbox value={id} />
      </Tooltip>
      <TemplateEdit>
        {
          editing
            ? (
              <Input
                ref={inputRef}
                onPressEnter={save}
                disabled={templatesStore.isFetching}
                onBlur={save}
                onChange={(e) => setValue(e.target.value)}
                value={value}
              />
            )
            : (
              <TemplateEditButton disabled={templatesStore.isFetching} type="button" onClick={toggleEdit}>
                {value}
              </TemplateEditButton>
            )
        }
      </TemplateEdit>
      <Button
        onClick={() => templatesStore.download(id, name)}
        style={{ flexShrink: 0, marginRight: 4 }}
        icon={(<Tooltip title={l10n.getString('ExportTemplate')}><DownloadOutlined /></Tooltip>)}
      />
      <TemplateDropDown id={id} system={system} />
    </TemplateItemWrapper>
  );
}

export default TemplateItem;
