import { Container } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import { Tablet } from '../../stores/Tablet';
import { Track } from './Track';

type Props = {
  tablet: Tablet;
  isOver: boolean;
  canDrop: boolean;
};

export const Tracks = observer<Props>(({ tablet, isOver, canDrop }) => {
  const rotation = tablet.params.orientation === 'vertical' ? 0 : (3 * Math.PI) / 2;
  const position: [number, number] = tablet.params.orientation === 'vertical' ? [0, 0] : [0, tablet.tracks.tracksWidth];

  return (
    <Container position={position} rotation={rotation}>
      {tablet.tracks.withOffset.map(([track, offset]) => (
        <Track
          key={track.innerId}
          track={track}
          tablet={tablet}
          offset={offset + tablet.scalePosition.tracksOffset}
          canDrop={isOver || canDrop}
        />
      ))}
    </Container>
  );
});
