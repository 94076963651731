import * as PIXI from 'pixi.js';
import { observer } from 'mobx-react-lite';
import { Sprite, Text } from '@inlet/react-pixi';
import { useContext } from 'react';
import { Curve, Track } from '../../../stores/Tracks';
import { Tablet } from '../../../stores/Tablet';
import { DepthCoordinates } from '../../../stores/CoordinatesStorages/DepthCoordinates';
import { TrackAlign } from '../../../api/dto/TrackDto';
import { round } from '../../../utils';
import { CurveCoordinates } from '../../../stores/CoordinatesStorages/CurveCoordinates';
import { dataToPoint } from '../../../stores/Scale';
import { TabletType } from '../../../enums/TabletType';
import { TabletContext } from '../TabletProvider';
import { TimeZonesStore } from '../../../stores/timeZonesStore';

type ScalePointType = {
  textStyle: PIXI.TextStyle;
  width: number;
  offsetX: number;
  track: Track;
  line: Line;
};

type Props = {
  source: Curve;
  tablet: Tablet,
  width: number,
  offsetX?: number;
  coordinates: CurveCoordinates | DepthCoordinates;
  track: Track;
};

type Line = { key: number;y: number; text: string; };
const isLine = (item:Line | null): item is Line => !!item;

const ScalePoint = observer(({
  textStyle,
  line,
  width,
  offsetX,
  track,
}: ScalePointType) => {
  const { themeHex } = useContext(TabletContext);
  return (
    <>
      <Text
        text={line.text}
        anchor={[track.params.align === TrackAlign.Left ? 0 : 1, 0.5]}
        x={(track.params.align === TrackAlign.Left ? 8 : width - 8) + offsetX}
        y={Math.round(line.y - 1)}
        style={textStyle}
      />
      <Sprite
        x={(track.params.align === TrackAlign.Left ? 0 : width - 4) + offsetX}
        y={line.y}
        height={1}
        width={4}
        texture={PIXI.Texture.WHITE}
        tint={themeHex.borderColor}
      />
    </>
  );
});

export const CurveNumeric = observer(({
  tablet, width, offsetX = 0, coordinates, source, track,
}: Props) => {
  const { fontSize } = tablet.baseTextStyle;

  const topOffset = fontSize / 2;
  const bottomOffset = fontSize / 2;

  const textStyle = new PIXI.TextStyle(tablet.baseTextStyle);
  textStyle.fill = source.params.color;

  if (coordinates.onData == null) {
    return null;
  }

  const {
    containerLength, offsetY, zeroPointKey, dataPerPixel,
  } = tablet.scale;

  const k = tablet.tabletType === TabletType.Time ? 1000 : 1;

  const list = tablet.scaleLine.line.map((value) => {
    if (!isBigMark(value, tablet.scaleLine.zoom[0] * k, tablet.timeZones)) {
      return null;
    }
    const y = Math.round(
      dataToPoint(value, zeroPointKey, dataPerPixel, tablet.tabletType) - offsetY,
    );
    if (y >= topOffset && y <= (containerLength - bottomOffset)) {
      const point = coordinates.getPoint(value);
      if (point == null) {
        return null;
      }
      const text = getText(point, source.params.ratioUom, source.params.fractionDigits);
      if (text == null) {
        return null;
      }

      return { key: value, y, text };
    }
    return null;
  }).filter(isLine);

  return (
    <>
      {list.map((line) => (
        <ScalePoint
          textStyle={textStyle}
          key={line.key}
          line={line}
          width={width}
          offsetX={offsetX}
          track={track}
        />
      ))}
    </>
  );
});

function getText(point: {
  key: number;
  value: number | [number, number] | null;
} | null, ratioUom: number, fractionDigits?: number) {
  if (point != null) {
    if (Array.isArray(point.value)) {
      return String(round(point.value[1] * ratioUom, fractionDigits));
    }
    return String(point.value ? round(point.value * ratioUom, fractionDigits) : point.value);
  }
  return null;
}

function isBigMark(date: number, zoom: number, timeZones: TimeZonesStore) {
  return (timeZones.selectedMs + date) % zoom === 0;
}
