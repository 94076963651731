import { observer } from 'mobx-react-lite';
import { BASE_LEGEND_HEIGHT, COLLAPSED_LEGEND_HEIGHT } from '../../constants';
import { Tablet } from '../../stores/Tablet';
import { Track } from '../../stores/Tracks';
import { DashedRect } from './DashedRect';

type Props = {
  track: Track,
  tablet: Tablet;
};

export const TrackDropArea = observer(({ track, tablet }: Props) => {
  let headerHeightTop = 0;
  let headerHeightBottom = 0;
  if (tablet.trackInfoVisible) {
    headerHeightTop = track.topSources.reduce((sum, source) => {
      if (source.params.showValueScale) {
        if (tablet.params.orientation === 'horizontal') {
          return sum + source.params.legendHeight;
        }
        return sum + BASE_LEGEND_HEIGHT;
      }
      return sum + (tablet.trackInfoCollapsed ? COLLAPSED_LEGEND_HEIGHT : BASE_LEGEND_HEIGHT);
    }, 0);
    headerHeightBottom = track.bottomSources.reduce((sum, source) => {
      if (source.params.showValueScale) {
        if (tablet.params.orientation === 'horizontal') {
          return sum + source.params.legendHeight;
        }
        return sum + BASE_LEGEND_HEIGHT;
      }
      return sum + (tablet.trackInfoCollapsed ? COLLAPSED_LEGEND_HEIGHT : BASE_LEGEND_HEIGHT);
    }, 0);
  }

  return (
    <DashedRect
      x={0 + 10}
      y={headerHeightTop + 10}
      width={track.params.trackWidth - 20}
      height={tablet.scale.containerLength - headerHeightTop - headerHeightBottom - 20}
      color={0x333333}
      lineWidth={2}
    />
  );
});
