import { makeAutoObservable, observable, set } from 'mobx';
import { nanoid } from 'nanoid';
import { ObjectDto } from '../api/dto/ObjectDto';
import { SourceDto } from '../api/dto/SourceDto';
import {
  CommentsHeight, Layers, TrackAlign, TrackDto, ValueScaleType,
} from '../api/dto/TrackDto';
import { TracksetDto } from '../api/dto/TracksetDto';
import { CuttingsLogData } from './CuttingsLogData';
import {
  SourceDataMap, SourceData, CurveStatus, InnerChartType,
} from './SourceDataMap';
import { CuttingsCoordinates } from './CoordinatesStorages/CuttingsCoordinates';
import { curveInfoToCurveParams } from '../mapping/curveInfoToCurveParams';
import { throwError } from '../errorHandler';
import { CurvePoint } from '../api/curvesCache';
import { normalizeUom } from '../mapping/normalizeUom';

export class Curve {
  sourceDto: SourceDto;

  sourceDataMap: SourceDataMap;

  sourceData: SourceData | null;

  innerId: string;

  params: {
    color: string;
    lineWidth: number;
    lineType: number;
    currentUom: string;
    ratioUom: number;
    scaleFrom: number;
    scaleTo: number;
    pos: number;
    dynamicRange: boolean;
    legendPosition: 'top' | 'bottom';
    showValueScale: boolean;
    legendHeight: number;
    fractionDigits?: number;
  };

  trackParams: Partial<Track['params']>;

  constructor(sourceDto: SourceDto, sourceDataMap: SourceDataMap, trackParams: Partial<Track['params']>) {
    this.sourceDto = sourceDto;
    this.params = {
      color: sourceDto.color,
      lineWidth: sourceDto.lineWidth,
      lineType: sourceDto.lineType,
      currentUom: normalizeUom(sourceDto.currentUom),
      ratioUom: sourceDto.ratioUom || 1,
      scaleFrom: sourceDto.minVal,
      scaleTo: sourceDto.maxVal,
      pos: sourceDto.pos,
      dynamicRange: sourceDto.dynamicRange ?? true,
      legendPosition: sourceDto.legendPosition,
      showValueScale: sourceDto.showValueScale,
      legendHeight: sourceDto.legendHeight,
      fractionDigits: sourceDto.fractionDigits,
    };

    this.sourceDataMap = sourceDataMap;
    this.sourceData = this.sourceDataMap.register(sourceDto.externalId, this);
    this.innerId = nanoid();
    makeAutoObservable(this);
    this.trackParams = trackParams;
  }

  get scaleFrom() {
    if (this.trackParams.valueScaleType === ValueScaleType.Log) {
      return this.trackParams.scaleFrom ?? 1;
    }
    return this.params.scaleFrom ?? this.sourceData?.minValue;
  }

  get scaleTo() {
    if (this.trackParams.valueScaleType === ValueScaleType.Log) {
      return this.trackParams.scaleTo ?? 1;
    }
    return this.params.scaleTo ?? this.sourceData?.maxValue;
  }

  refreshParams() {
    if (this.params.scaleFrom == null && this.params.scaleTo == null
      && this.sourceData?.minValue != null && this.sourceData?.maxValue != null) {
      this.setParams({
        scaleFrom: this.sourceData.minValue,
        scaleTo: this.sourceData.maxValue,
      });
    }
    if (this.params.currentUom == null && this.sourceData?.info?.unit) {
      this.params.currentUom = this.sourceData.info.unit;
    }
  }

  setParams(params: Partial<Curve['params']>) {
    this.params = { ...this.params, ...params };
    if (this.params.lineWidth == null) {
      this.params.lineWidth = 1;
    }
  }

  getSourceDto() {
    const sourceDto = new SourceDto();
    Object.assign(sourceDto, this.sourceDto, {
      color: this.params.color,
      lineWidth: this.params.lineWidth,
      lineType: this.params.lineType,
      currentUom: this.params.currentUom,
      ratioUom: this.params.ratioUom || 1,
      minVal: this.params.scaleFrom,
      maxVal: this.params.scaleTo,
      pos: this.params.pos,
      dynamicRange: this.params.dynamicRange ?? true,
      legendPosition: this.params.legendPosition,
      showValueScale: this.params.showValueScale,
      legendHeight: this.params.legendHeight,
      fractionDigits: this.params.fractionDigits,
    });
    return sourceDto;
  }

  unregister() {
    this.sourceDataMap.unregister(this.sourceDto.externalId, this);
  }
}

export class Track {
  trackDto: TrackDto;

  offset: number;

  sources: Curve[] = [];

  params: {
    gridBaseVert: number;
    gridSecondVert: number;
    trackWidth: number;
    valueScaleType: ValueScaleType;
    fontSize:number;
    layersPriority: Layers;
    commentsHeight: CommentsHeight;
    hideBorders: boolean;
    hideCurves: boolean;
    gridShowHoris: boolean;
    gridShowVert: boolean;
    align: TrackAlign;
    scaleFrom: number;
    scaleTo: number;
  };

  pos: number;

  innerId: string;

  updateRangeDataDB : (on: number, to: number) => void;

  sourceDataMap: SourceDataMap;

  validators = [
    {
      validator: (track: Track) => {
        const countCurves = track.sources.length;
        if (track.params.valueScaleType === ValueScaleType.Comments
          || track.params.valueScaleType === ValueScaleType.Numeric) {
          return countCurves > 0;
        }
        return false;
      },
      message: 'OnlyOneParamOnTrack',
    },
    {
      validator: (track: Track, curve: Curve) => {
        if (track.params.valueScaleType === ValueScaleType.Comments) {
          return curve.sourceData?.innerChartType !== InnerChartType.Comments;
        }
        if (track.params.valueScaleType === ValueScaleType.Numeric) {
          return curve.sourceData && !(curve.sourceData.innerChartType === InnerChartType.DepthCurve
            || curve.sourceData.innerChartType === InnerChartType.TimeCurve);
        }
        return false;
      },
      message: 'SelectedParamDoesNoMatchTrackType',
    },
    {
      validator: (track: Track, curve: Curve) => {
        const index = track.sources
          .findIndex((s) => s.sourceDto.externalId === curve.sourceDto.externalId);
        return index > -1;
      },
      message: 'CurveAlreadyExists',
    },
  ];

  constructor(trackDto: TrackDto, sourceDataMap: SourceDataMap) {
    this.trackDto = trackDto;
    this.sourceDataMap = sourceDataMap;

    this.innerId = nanoid();

    this.pos = trackDto.pos;

    makeAutoObservable(this);

    this.params = observable.object({
      gridBaseVert: trackDto.gridBaseVert,
      gridSecondVert: trackDto.gridSecondVert,
      trackWidth: trackDto.trackWidth,
      valueScaleType: trackDto.valueScaleType,
      fontSize: trackDto.fontSize || 12,
      layersPriority: trackDto.layersPriority,
      commentsHeight: trackDto.commentsHeight,
      hideBorders: trackDto.hideBorders,
      hideCurves: trackDto.hideCurves,
      gridShowHoris: trackDto.gridShowHoris,
      gridShowVert: trackDto.gridShowVert,
      align: trackDto.align,
      scaleFrom: trackDto.scaleFrom,
      scaleTo: trackDto.scaleTo,
    });

    this.sources = trackDto.sources.map((s) => new Curve(s, sourceDataMap, this.params));
    this.sourcesSortByPosition();
  }

  get sourcesSorted() {
    return this.sources
      .filter(
        (source) => !(source.sourceData?.status === CurveStatus.NoData && this.params.hideCurves),
      )
      .sort(
        (trackA: Curve, trackB: Curve) => trackA.params.pos - trackB.params.pos,
      );
  }

  get topSources() {
    return this.sourcesSorted.filter((s) => s.params.legendPosition === 'top');
  }

  get bottomSources() {
    return this.sourcesSorted.filter((s) => s.params.legendPosition === 'bottom');
  }

  sourcesSortByPosition() {
    this.sources.sort((a, b) => {
      if (a.params.legendPosition === 'top' && b.params.legendPosition === 'bottom') {
        return -1;
      }
      return a.params.pos - b.params.pos;
    }).forEach((s, i) => {
      s.setParams({ pos: i });
    });
  }

  get cuttingCurves() {
    return this.sourcesSorted.reduce<CuttingsCoordinates[]>((acc, value) => {
      if (value.sourceData?.curveCoordinates instanceof CuttingsCoordinates) {
        return acc.concat(value.sourceData?.curveCoordinates);
      }
      return acc;
    }, []);
  }

  get cuttingData() {
    if (this.cuttingsLogData) {
      this.cuttingsLogData.clear();

      const map = new Map<number, CurvePoint[][]>();

      this.cuttingCurves.forEach((curveCoordinates) => {
        const coords = curveCoordinates.points || [];

        if (coords.length === 0) {
          return;
        }

        if (this.cuttingsLogData) {
          this.cuttingsLogData.checkGaps(coords);
        }
      });

      this.cuttingCurves.forEach((curveCoordinates) => {
        const coords = curveCoordinates.points || [];

        if (coords.length === 0) {
          return;
        }

        const values = this.cuttingsLogData?.getValues(coords, curveCoordinates.curveId) || [];
        map.set(curveCoordinates.curveId, values);
      });

      return [map, this.cuttingsLogData.getRangesDefectData()] as const;
    }
    return [];
  }

  setPosition(pos: number) {
    this.pos = pos;
  }

  move(prevIndex: number, newIndex: number) {
    const prevIndexItem = this.sourcesSorted[prevIndex];
    const newIndexItem = this.sourcesSorted[newIndex];
    if (prevIndexItem && newIndexItem) {
      const i = prevIndexItem.params.pos;
      prevIndexItem.params.pos = newIndexItem.params.pos;
      newIndexItem.params.pos = i;
    }
    this.sourcesSortByPosition();
  }

  setParams(params: Partial<Track['params']>) {
    set(this.params, params);
    if (this.params.trackWidth == null) {
      this.params.trackWidth = 40;
    }
  }

  removeSource(source: Curve) {
    const index = this.sources.findIndex((s) => s === source);
    if (index > -1) {
      source.unregister();
      this.sources.splice(index, 1);
    }
    this.sourcesSortByPosition();
  }

  hideSource(source: Curve) {
    const index = this.sources.findIndex((s) => s === source);
    if (index > -1) {
      this.sources.splice(index, 1);
    }
    this.sourcesSortByPosition();
  }

  removeSources() {
    this.sources.forEach((source) => {
      source.unregister();
    });
    this.sources = [];
  }

  addSource(sourceDto: SourceDto) {
    const curve = new Curve(sourceDto, this.sourceDataMap, this.params);

    const error = this.validators.find((v) => v.validator(this, curve));

    if (error) {
      // notification.error({
      //   placement: 'bottomRight',
      //   message: localizationStore.l10n?.getString(error.message),
      // });
      return null;
    }

    // curve.setParams({ pos: this.sources.length });
    // this.sources.push(curve);
    this.addCurve(curve);

    return curve;
  }

  addCurve(curve: Curve) {
    curve.setParams({ pos: this.sources.length });
    this.sources.push(curve);
    this.sourcesSortByPosition();
  }

  async addSourceFromRequest(object: ObjectDto) {
    try {
      const sourceData = await this.sourceDataMap.preloadSourceData(object.externalId);
      if (sourceData.info) {
        const source = curveInfoToCurveParams(sourceData.info);
        const curve = this.addSource(source);
        if (curve && curve.sourceData) {
          await curve.sourceData.fetchSource();
          await curve.sourceData.refreshCoords({
            onFrame: curve.sourceData.scale.onFrame,
            toFrame: curve.sourceData.scale.toFrame,
          });
        }
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
      throwError('Curve-info-loading-error ')(e);
      throw e;
    }
  }

  get hasCuttings() {
    return !!this.cuttingCurves.length;
  }

  get cuttingsLogData() {
    if (this.hasCuttings) {
      return new CuttingsLogData();
    }
    return null;
  }

  getTrackDto() {
    const trackDto = new TrackDto();
    trackDto.gridBaseVert = this.params.gridBaseVert;
    trackDto.gridSecondVert = this.params.gridSecondVert;
    trackDto.trackWidth = this.params.trackWidth;
    trackDto.valueScaleType = this.params.valueScaleType;
    trackDto.fontSize = this.params.fontSize;
    trackDto.layersPriority = this.params.layersPriority;
    trackDto.commentsHeight = this.params.commentsHeight;
    trackDto.hideBorders = this.params.hideBorders;
    trackDto.hideCurves = this.params.hideCurves;
    trackDto.gridShowHoris = this.params.gridShowHoris;
    trackDto.gridShowVert = this.params.gridShowVert;
    trackDto.sources = this.sources
      .map((s) => s.getSourceDto())
      .sort((a, b) => a.pos - b.pos);
    trackDto.pos = this.pos;
    trackDto.align = this.params.align;
    return trackDto;
  }
}

export class Tracks {
  list: Track[];

  selected: Track | null;

  selectedSource: Curve | null = null;

  tracksPadding = 2;

  updateRangeDataDB: (on: number, to: number) => void;

  sourceDataMap: SourceDataMap;

  constructor(
    tracks: TrackDto[],
    sourceDataMap: SourceDataMap,
  ) {
    makeAutoObservable(this);
    this.sourceDataMap = sourceDataMap;

    this.list = tracks.map((track) => new Track(track, sourceDataMap));

    this.list.sort((a, b) => a.pos - b.pos).forEach((s, index) => {
      s.setPosition(index);
    });
  }

  get sorted() {
    return this.list.slice().sort(
      (trackA: Track, trackB: Track) => trackA.pos - trackB.pos,
    );
  }

  get withOffset() {
    const array: [Track, number][] = [];
    let offset = 0;
    this.sorted.forEach((track) => {
      array.push([track, offset]);
      offset += track.params.trackWidth + this.tracksPadding;
    });
    return array;
  }

  get tracksWidth() {
    return this.list.reduce(
      (sum, track) => track.params.trackWidth + sum + this.tracksPadding,
      0,
    );
  }

  move(track: Track, position: -1 | 1) {
    const newPos = track.pos + position;

    if (Number.isNaN(newPos) || newPos < 0) {
      return;
    }

    const pair = this.list.find(
      (x: Track) => x.pos === newPos,
    );

    if (!pair) {
      return;
    }

    pair.setPosition(track.pos);
    track.setPosition(newPos);
  }

  add(track: Track, position: 1 | -1, type: ValueScaleType) {
    const trackDto = new TrackDto();
    trackDto.trackWidth = 150;
    trackDto.gridShowVert = true;
    trackDto.gridBaseVert = 2;
    trackDto.gridSecondVert = 5;
    trackDto.pos = position < 0 ? track.pos : track.pos + 1;
    trackDto.valueScaleType = type;
    let pos = 0;

    this.sorted
      .forEach((x: Track) => {
        if (trackDto.pos === x.pos) {
          pos += 1;
        }

        x.setPosition(pos);
        pos += 1;
      });

    this.list.push(new Track(trackDto, this.sourceDataMap));
  }

  removeTrack(track: Track) {
    const index = this.list.findIndex((t) => t === track);
    if (index > -1) {
      track.removeSources();
      const { pos } = track;
      this.list.splice(index, 1);
      this.list.forEach((t) => t.pos > pos && t.setPosition(t.pos - 1));
    }
  }

  removeTracks() {
    this.list.forEach((track) => {
      track.removeSources();
    });
    this.list = [];
  }

  setSelected(track: Track | null) {
    if (this.selectedSource) {
      this.setSelectedSource(null);
    }
    this.selected = track;
  }

  setSelectedSource(source: Curve | null) {
    if (this.selected) {
      this.setSelected(null);
    }
    this.selectedSource = source;
  }

  getTracksetDto() {
    const tracksetDto = new TracksetDto();
    tracksetDto.description = null;
    tracksetDto.name = null;
    tracksetDto.pos = 0;
    tracksetDto.tracks = this.sorted.map((t) => t.getTrackDto());
    tracksetDto.tracksetType = 0;
    tracksetDto.orientation = 'vertical';
    return tracksetDto;
  }
}
