import { useLocalization } from '@fluent/react';
import {
  Form, Select, InputNumber, Typography, Checkbox,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { getListUoms, getRatioUom, UomListItem } from '../../api/uom';
import { BASE_LEGEND_HEIGHT } from '../../constants';
import { Tablet } from '../../stores/Tablet';
import { Curve } from '../../stores/Tracks';
import { ColorPicker } from '../ColorPicker';

export const CurveParams = observer(({ tablet, curve } : { tablet: Tablet; curve: Curve }) => {
  const { l10n } = useLocalization();

  const [form] = Form.useForm();
  const [uomItems, setUomItems] = useState<UomListItem[]>([]);

  useEffect(() => {
    form.resetFields();
  }, [form, curve]);

  useEffect(() => {
    if (curve.sourceData?.info?.unit) {
      getListUoms(curve.sourceData.info.unit).then((items) => {
        setUomItems(items);
      });
    }
  }, [curve.sourceData?.info?.unit, setUomItems]);

  useEffect(() => {
    if (curve.sourceData?.info?.unit) {
      if (curve.sourceData.info.unit === curve.params.currentUom) {
        curve.setParams({ ratioUom: 1 });
        return;
      }
      getRatioUom(curve.sourceData.info.unit, curve.params.currentUom).then((ration) => {
        if (ration) {
          curve.setParams({ ratioUom: ration });
        } else {
          curve.setParams({ ratioUom: 1 });
        }
      });
    }
  }, [curve, curve.sourceData?.info?.unit, curve.params.currentUom]);

  return (
    <Form
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      form={form}
      onValuesChange={(value) => {
        curve.setParams(value);
      }}
      initialValues={curve.params}
      size="small"
    >
      <Typography.Title level={5} style={{ marginBottom: 16 }}>
        {l10n.getString('CurveSettings')}
      </Typography.Title>
      <Form.Item name="color" label={l10n.getString('ColorLine')}>
        <ColorPicker />
      </Form.Item>

      <Form.Item name="lineWidth" label={l10n.getString('WidthLine')}>
        <InputNumber min={1} />
      </Form.Item>

      <Form.Item name="lineType" label={l10n.getString('TypeLine')}>
        <Select>
          <Select.Option value={0} key="solid">{l10n.getString('Solid')}</Select.Option>
          <Select.Option value={1} key="dashed">{l10n.getString('Dashed')}</Select.Option>
          <Select.Option value={2} key="dotted">{l10n.getString('Dotted')}</Select.Option>
          <Select.Option value={3} key="dashDot">{l10n.getString('DashDot')}</Select.Option>
          <Select.Option value={4} key="dashDoubleDot">{l10n.getString('DashDoubleDot')}</Select.Option>
          <Select.Option value={5} key="dottedMode">{l10n.getString('Dotted-mode')}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="currentUom" label={l10n.getString('Units')}>
        <Select>
          {uomItems.length === 0 ? (
            <Select.Option value={curve.params.currentUom} key="currentUom">
              {curve.params.currentUom}
            </Select.Option>
          ) : uomItems.map((uom) => (
            <Select.Option value={uom.Uid} key={uom.Uid}>
              {uom.Uid}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="scaleFrom" label={l10n.getString('From')}>
        <InputNumber />
      </Form.Item>
      <Form.Item name="scaleTo" label={l10n.getString('To')}>
        <InputNumber />
      </Form.Item>
      <Form.Item name="legendPosition" label={l10n.getString('Header-position')}>
        <Select>
          <Select.Option value="top" key="top">{l10n.getString('Top-or-left')}</Select.Option>
          <Select.Option value="bottom" key="bottom">{l10n.getString('Bottom-or-right')}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="showValueScale" valuePropName="checked" label={l10n.getString('Value-scale')}>
        <Checkbox />
      </Form.Item>
      {curve.params.showValueScale && (
      <Form.Item name="legendHeight" label={l10n.getString('Value-scale-height')}>
        <InputNumber
          min={BASE_LEGEND_HEIGHT}
          max={BASE_LEGEND_HEIGHT * 4}
          disabled={tablet.params.orientation === 'vertical'}
        />
      </Form.Item>
      )}
      <Form.Item name="fractionDigits" label={l10n.getString('Rounding')}>
        <InputNumber min={0} max={5} />
      </Form.Item>
    </Form>
  );
});
