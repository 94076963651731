import { jsonObject, jsonMember } from 'typedjson';

export enum ObjectType {
  CURVE, // Кривая
  FOLDER, // Папка
  WELL, // Скважина (старая)
  WELL_RED, // Скважина (недавняя)
  WELL_GREEN, // Скважина (активная)
  WELL_YELLOW, // Скважина (свежая)
  WELLBORE, // Ствол
  TRAJECTORY, // Траектория
  SCREENCAST_FOLDER, // Папка трансляции экрана
  SCREENCAST, // Трансляция экрана
  AUTO_SUPERVISOR, // Автосупервайзер(остановленный)
  AUTO_SUPERVISOR_ACTIVE, // Автосупервайзер(активный)
  LOG_DEPTH, // Логи по глубине
  LOG_TIME, // Логи по времени
  RECEIVER, // Приемник данных WITS
  PARSER, // Парсер данных WITS
  PARSER_TEMPLATE, // Шаблон парсера
  IMAP_PARSER, // IMAP парсер
  USER, // Пользователь
  WITSML_LOADER,
  WITSML_LOADER_ACTIVE,
  SUPER_REPORT_FOLDER, // Папка отчетов супервайзера
  DB, // DB,(Вместо виртуальной корневой папки)
  WITSML_SENDER,
  WITSML_SENDER_ACTIVE,
  RECOGNIZER,
  RECOGNIZER_ACTIVE,
  KPI_STATE_MARKER,
  KPI_STATE_MARKER_ACTIVE,
}

@jsonObject
export class ObjectDto {
  @jsonMember({ constructor: Boolean })
    children: boolean;

  @jsonMember({ constructor: Number })
    externalId: number;

  @jsonMember({ constructor: Number })
    externalParentId: number;

  @jsonMember({ constructor: Date })
    startDate: Date | null;

  @jsonMember({ constructor: Date })
    lastDate: Date | null;

  @jsonMember({ constructor: Number })
    startDepth: number | null;

  @jsonMember({ constructor: Number })
    lastDepth: number | null;

  @jsonMember({ constructor: Number })
    logType: number | null;

  @jsonMember({ constructor: Number })
    maxVal: null;

  @jsonMember({ constructor: Number })
    minVal: null;

  @jsonMember({ constructor: Number })
    objectTypeId: ObjectType;

  @jsonMember({ constructor: Number })
    sourceTypeId: null;

  @jsonMember({ constructor: String })
    text: string;

  @jsonMember({ constructor: String })
    typeExt?: 'rigis' | 'image';

  @jsonMember({ constructor: String })
    objectTypeName?: string;
}
