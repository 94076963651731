import { observer } from 'mobx-react-lite';
import * as PIXI from 'pixi.js';
import { Graphics } from '@inlet/react-pixi';
import { useCallback } from 'react';
import { Curve } from '../../../stores/Tracks';
import { Tablet } from '../../../stores/Tablet';
import { CurvePoint } from '../../../api/curvesCache';

export const CuttingsLogPolygon = observer(({
  source, tablet, width, offsetX = 0, coords,
}:
{ source: Curve; tablet: Tablet, width: number, offsetX?: number, coords: CurvePoint[] }) => {
  const { offsetY: scalerOffsetY, zeroPointKey, yRatio } = tablet.scale;

  const draw = useCallback((g: PIXI.Graphics) => {
    if (!coords[0]) {
      return;
    }
    const xRatio = (width - 4) / 100;
    const offsetY = scalerOffsetY + zeroPointKey * yRatio;

    const image = source.sourceData?.imageLithology?.image;
    const texture = image ? PIXI.Texture.from(image) : PIXI.Texture.WHITE;

    const yStart = Math.ceil(((coords[0] as any).y || coords[0].key) * yRatio - offsetY);

    g.clear();
    g.beginTextureFill({ texture });
    g.moveTo(offsetX + 2, yStart);

    coords.forEach((coord) => {
      const y = Math.ceil(((coord as any).y || coord.key) * yRatio - offsetY);
      const x = Math.ceil(coord.value * xRatio + offsetX + 2);
      g.lineTo(x, y);
    });

    g.endFill();
  }, [
    coords,
    offsetX,
    scalerOffsetY,
    width,
    yRatio,
    zeroPointKey,
    source.sourceData?.imageLithology?.image,
  ]);

  return (
    <Graphics draw={draw} />
  );
});
