import dark from './dark.theme.less';
import light from './light.theme.less';

interface LazyStyle {
  use: () => void;
  unuse: () => void;
}

export type ThemeTypes = 'light' | 'dark';

interface Theme {
  id: ThemeTypes;
  displayName: string;
  styles: LazyStyle;
}

export const themeMap: Record<string, Theme> = {
  light: {
    id: 'light',
    displayName: 'Light',
    styles: light,
  },
  dark: {
    id: 'dark',
    displayName: 'Dark',
    styles: dark,
  },
};

export const themes: Theme[] = Object.values(themeMap);
