import {
  Button,
  Form, Input, InputNumber, Select,
} from 'antd';
import styled from '@emotion/styled';
import { useState } from 'react';
import lithologyConfig from '../lithologyConfig.json';
import patterns from '../patterns.json';
import { lithologyStore } from '../stores/lithologyStore';

const { Option } = Select;

// console.log(
//   JSON.stringify(lithologyConfig.map((item, i) => ({ ...item, id: i }))),
// );

const fillColors = ['#006412', '#333300', '#939292', '#C0C0C0', '#FFFF00', '#FFA747', '#FF0002', '#03FFFF', '#09CCFF', '#800000', '#FD7F81', '#01EB06', '#555555', '#A5CBF7'];

const { TextArea } = Input;

interface LithoPickerProps {
  litho: LithoElem,
  id: number,
  update: (index: number, change: Partial<LithoElem>) => void,
  deleteLitho: (index: number) => void
}

interface LithoElem {
  clientId: number,
  name: string,
  translate: {
    'en-US': string,
    'ru-RU': string
  },
  message: string,
  fillColor: string,
  pattern: string,
}

const Wrapper = styled.div`
  padding: 15px;
  height: fit-content;
`;

const LithoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const HeaderName = styled.div` 
  text-align: center;
  font-size: 25px;
  line-height: 30px;
  min-width: 76px;
  margin-bottom: 25px;
`;

// const TranslateName = styled.div`
//   height: 32px;
//   color: rgba(255, 255, 255, 0.85);
//   font-size: 14px;
// `;

export function LithologyBuilder() {
  const [config, setConfig] = useState(lithologyConfig);
  const updateConfig = (index: number, change: Partial<LithoElem>) => {
    setConfig((existing) => [...existing.slice(0, index), {
      ...existing[index],
      ...change,
    }, ...existing.slice(index + 1)]);
  };
  const deleteLitho = (index: number) => {
    setConfig((existing) => [...existing.slice(0, index), ...existing.slice(index + 1)]);
  };
  const addNewLitho = () => {
    setConfig((existing) => [...existing.slice(0), {
      clientId: 0,
      name: '',
      translate: {
        'en-US': '',
        'ru-RU': '',
      },
      message: '',
      fillColor: '',
      pattern: '',
    }]);
  };
  return (
    <Wrapper>
      <HeaderName>Lithology</HeaderName>
      <LithoWrapper>
        {config.map((elem, id) => (
          <LithoPicker
            deleteLitho={deleteLitho}
            update={updateConfig}
            id={id}
            key={elem.name}
            litho={elem}
          />
        ))}
        <Button size="middle" style={{ width: 100 }} ghost onClick={addNewLitho}>Добавить+</Button>
      </LithoWrapper>

      <TextArea rows={10} value={JSON.stringify(config)} />
    </Wrapper>
  );
}

function LithoPicker({
  litho, id, update, deleteLitho,
} : LithoPickerProps) {
  return (
    <Form initialValues={litho} style={{ overflowY: 'auto', height: 'auto' }} layout="inline">
      <Form.Item name="clientId" label="Code">
        <InputNumber
          readOnly={!!litho.message}
          controls={false}
          style={{ width: 64 }}
          onChange={(value) => {
            update(id, { clientId: Number(value) || litho.clientId });
          }}
        />
      </Form.Item>
      <Form.Item name="name" label="Name">
        <Input
          readOnly={!!litho.message}
          onBlur={(e) => {
            update(id, { name: e.currentTarget.value || litho.name });
          }}
        />
      </Form.Item>
      <Form.Item name="fillColor" label="Fill Color">
        <Select
          disabled={!!litho.message}
          onSelect={(value) => {
            update(id, { fillColor: value });
          }}
          style={{ width: 120 }}
          optionLabelProp="label"
        >
          {fillColors.map((color) => (
            <Option style={{ backgroundColor: color, color: 'black' }} value={color} label={color} key={color}>
              {color}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="pattern" label="Pattern code">
        <Select
          style={{ width: 150 }}
          disabled={!!litho.message}
          onSelect={(value) => {
            update(id, { pattern: value });
          }}
          showSearch
        >
          {
          Object.entries<string>(patterns)
            .map((item) => (
              <Option key={item[0]} value={item[0]} label={item[0]}>
                <div>{item[0]}</div>
                <img src={item[1]} alt={item[0]} style={{ background: '#fff' }} />
              </Option>
            ))
        }
        </Select>
      </Form.Item>
      <Form.Item name="message" label="Message">
        <Input
          readOnly
          onPressEnter={(e) => {
            update(id, { message: e.currentTarget.value || '' });
          }}
        />
      </Form.Item>
      {/* {litho.message && (
      <div style={{ display: 'flex', marginRight: 8 }}>
        <TranslateName style={{ marginRight: 8 }}>Translate:</TranslateName>
        <div>
          <div>
            <span>en-US</span>
            <Input
              readOnly
              value={litho.translate['en-US']}
              onPressEnter={(e) => {
                update(id, { translate: { ...litho.translate,
                   'en-US': e.currentTarget.value || '' } });
              }}
            />
          </div>
          <div>
            <span>ru-RU</span>
            <Input
              readOnly
              value={litho.translate['ru-RU']}
              onPressEnter={(e) => {
                update(id, { translate: { ...litho.translate,
                  'ru-RU': e.currentTarget.value || '' } });
              }}
            />
          </div>
        </div>

      </div>
      )} */}
      {!litho.message ? (
        <>
          <img src={generateLithologyImage(litho.pattern, litho.fillColor)} alt="none" />
          <Button onClick={() => deleteLitho(id)} danger>Удалить</Button>
        </>
      ) : (
        <img src={getImage(litho.name)} alt="none" />
      )}
    </Form>
  );
}

function getImage(name: string) {
  const img = lithologyStore.getLithologyForName(name.split('|')[0]);
  return img?.img.src;
}

export function generateLithologyImage(
  patternCode: string,
  color: string,
): string {
  const pattern = (patterns as any)[patternCode];
  if (!pattern || !color) {
    return '';
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = 128;
  canvas.height = 64;

  if (!context) {
    return '';
  }

  context.fillStyle = color;
  context.fillRect(0, 0, canvas.width, canvas.height);
  const patternImg = new Image();

  patternImg.src = pattern;
  const ptrn = context.createPattern(patternImg, 'repeat');
  if (!ptrn) {
    return '';
  }
  context.fillStyle = ptrn;
  context.fillRect(0, 0, canvas.width, canvas.height);

  return canvas.toDataURL();
}
