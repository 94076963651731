import { observer } from 'mobx-react-lite';
import { CurveStatus } from '../../stores/SourceDataMap';
import { Tablet } from '../../stores/Tablet';
import { Track, Curve } from '../../stores/Tracks';
import { round } from '../../utils';
import { CoordinatorBadge } from './CoordinatorBadge';
import { LoaderString } from './LoaderString';
import {
  CurveBody, CurveBlock, CurveName, RoundBlock, CollapsedBlock,
} from './TabletTracksInfo.styled';

type Props = {
  track: Track;
  tablet: Tablet;
  source: Curve;
  offset: number;
  collapsed: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
  onContextMenu: React.MouseEventHandler<HTMLDivElement>
};

export const CurveHeader = observer(({
  track, tablet, source, offset, collapsed, onClick, onContextMenu,
} : Props) => (
  <CurveBlock
    color={source.params.color}
    key={source.sourceDto.externalId}
    onClick={onClick}
    onContextMenu={onContextMenu}
    collapsed={collapsed}
    selected={tablet.tracks.selectedSource === source}
    reverse={source.params.legendPosition === 'bottom'}
  >
    {collapsed ? (
      <CollapsedBlock>
        <div>{source.scaleFrom}</div>
        <RoundBlock>
          {source.sourceData?.status === CurveStatus.ServerFetching
            || source.sourceData?.status === CurveStatus.MetaDataFetching
            ? <LoaderString source={source} position="absolute" /> : (
              <span>
                {source.sourceDto.name}
                {' '}
                {source.params.currentUom}
              </span>
            )}
        </RoundBlock>
        <div>{source.scaleTo}</div>
      </CollapsedBlock>
    ) : (
      <>
        <CurveName>
          <RoundBlock>
            {source.sourceDto.name}
          </RoundBlock>
          <div>{source.params.currentUom}</div>
        </CurveName>

        <CurveBody>
          <div>{source.scaleFrom}</div>
          <div>
            <div>
              {source.sourceData?.status === CurveStatus.ServerFetching
                || source.sourceData?.status === CurveStatus.MetaDataFetching
                || source.sourceData?.status === CurveStatus.NoData
                ? <LoaderString source={source} position="right" />
                : (
                  <span>
                    {source.sourceData && round(
                      Number(source.sourceData.lastValue) * source.params.ratioUom,
                      source.params.fractionDigits,
                    )}
                  </span>
                )}
            </div>
          </div>
          <div>{source.scaleTo}</div>
        </CurveBody>
      </>
    )}
    {tablet.coordinator.currentTrack === track && tablet.editMode === false
    && source.scaleFrom != null && source.scaleTo != null
    && source.sourceData?.status !== CurveStatus.NoData && (
    <CoordinatorBadge
      x={tablet.params.orientation === 'vertical'
        ? tablet.coordinator.x - offset - tablet.scalePosition.tracksOffset
        : (tablet.tabletScroll.height - tablet.coordinator.y
          + tablet.tabletScroll.scrollTop) - offset}
      trackWidth={track.params.trackWidth}
      sourceParams={source.params}
      scaleFrom={source.scaleFrom}
      scaleTo={source.scaleTo}
      valueScaleType={track.params.valueScaleType}
    />
    )}
  </CurveBlock>
));
