import { TypedJSON } from 'typedjson';
import { axiosApiInstance as axios } from './axiosApiInstance';
import { TemplateViewModel } from './dto/TabletDto';

const templateSerializer = new TypedJSON(TemplateViewModel);

export function getTemplates() {
  return axios.get<{ result: object[] }>('/Template/GetAll', { params: { context: true } })
    .then(({ data }) => data.result.map((t) => templateSerializer.parse(t)!));
}

export function uploadTemplate(file: File, system: boolean) {
  const formData = new FormData();
  formData.append(file.name, file);
  return axios.post<{ result: boolean }>(`/Template/Upload?system=${system}`, formData, {
    headers:
    { 'Content-Type': 'multipart/form-data' },
  }).then(({ data }) => data.result);
}

export function deleteTemplate(id: number) {
  return axios.delete<{ result: boolean }>('/Template/Delete', { params: { id } })
    .then(({ data }) => data.result);
}

export function renameTemplate(id: number, name: string) {
  return axios.get<{ result: boolean }>('/Tablet/Rename', { params: { templateId: id, name } })
    .then(({ data }) => data.result);
}

export function setSelectedTemplates(ids: number[]) {
  return axios.post<{ result: boolean }>('/Template/SetSelected', ids)
    .then(({ data }) => data.result);
}

export function copyTemplate(id: number, system: boolean) {
  return axios.get<{ result: number }>('/Template/Copy', { params: { id, system } })
    .then(({ data }) => data.result);
}

export function downloadTemplate(id: number, name: string) {
  return axios.get<Blob>('/Template/Download', {
    params: { id },
    responseType: 'blob',
  }).then(({ data }) => {
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.json`);
    link.click();
    URL.revokeObjectURL(url);
  });
}
