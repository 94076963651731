import { notification } from 'antd';
import axios from 'axios';
import { localizationStore } from './stores/init-stores-global';

export function throwError(title: string, message?: string) {
  return (e: unknown) => {
    let description: string | undefined = message && localizationStore.l10n?.getString(message);
    if (axios.isAxiosError(e) && e.response) {
      // eslint-disable-next-line no-console
      console.error(e);
      description = description ? localizationStore.l10n?.getString(description) : '';
      if (e.response.status === 403) {
        description = localizationStore.l10n?.getString('HTTP-forbidden');
      }
      if (e.response.status >= 500) {
        description = `${localizationStore.l10n?.getString('HTTP-unknown')}`;
      }
    }
    notification.error({
      message: title ? localizationStore.l10n?.getString(title) : '',
      description,
      placement: 'bottomRight',
    });
    throw e as Error || new Error(title);
  };
}
