import { useLocalization } from '@fluent/react';
import { Button, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { TabletType } from '../../enums/TabletType';
import { mapFormDataToCommentDto } from '../../mapping/CommentDtoProfile';
import { Tablet } from '../../stores/Tablet';
import { CommentForm } from '../CommentForm/CommentForm';

export const CommentModal = observer(({ tablet }: { tablet: Tablet }) => {
  const { l10n } = useLocalization();

  const { commentsController: controller } = tablet;

  async function onFinish(values: any) {
    await controller.saveComment(mapFormDataToCommentDto(values));
  }
  async function onDelete() {
    await controller.delete();
  }
  function onPickRange() {
    controller.getRange();
  }
  function onClose() {
    controller.close();
  }
  return controller.currentForm ? (
    <Modal
      visible
      title={
        l10n.getString(tablet.tabletType === TabletType.Time ? 'Comments-Time' : 'Comments-Depth')
      }
      onCancel={() => onClose()}
      destroyOnClose
      footer={controller.type !== 'preview' ? (
        <div style={{ display: 'flex' }}>
          <Button disabled={controller.type === 'edit'} onClick={() => onPickRange()} key="picker">
            {l10n.getString('Point-Track')}
          </Button>
          <Button danger onClick={() => onDelete()} key="delete" style={{ marginLeft: 'auto' }}>
            {l10n.getString('Delete')}
          </Button>
          <Button form="CommentForm" key="submit" htmlType="submit" type="primary">
            {l10n.getString('Save')}
          </Button>
        </div>
      ) : null}
    >
      <CommentForm
        tablet={tablet}
        tabletType={tablet.tabletType}
        params={controller.currentForm}
        onFinish={(values: any) => onFinish(values)}
        onPickRange={() => onPickRange()}
        type={controller.type}
      />
    </Modal>
  ) : null;
});
