import * as PIXI from 'pixi.js';
import { Graphics } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import { Tablet } from '../../../stores/Tablet';
import { Curve as CurveEntity } from '../../../stores/Tracks';
import { drawLine, drawDottedLine } from './CurveShape';
import { DepthCoordinates } from '../../../stores/CoordinatesStorages/DepthCoordinates';
import { CurveCoordinates } from '../../../stores/CoordinatesStorages/CurveCoordinates';

function setTypeLine(params: CurveEntity['params']) {
  switch (params.lineType) {
    case 1:
      return [5, 5];
    case 2:
      return [1, 5];
    case 3:
      return [5, 5, 1, 5];
    case 4:
      return [5, 5, 1, 5, 1, 5];
    default:
      return [];
  }
}

function getTypeLine(params: CurveEntity['params']): number[] {
  const lineTypes = setTypeLine(params);
  if (params.lineWidth === 1 || lineTypes == null) {
    return lineTypes;
  }
  const workLineType = [];
  if (lineTypes.length > 0) {
    for (let i = 0; i < lineTypes.length; i += 1) {
      workLineType[i] = lineTypes[i] * params.lineWidth;
    }
  }
  return workLineType;
}

type Props = {
  source: CurveEntity;
  tablet: Tablet;
  log10: boolean;
  width: number;
  coordinates: DepthCoordinates | CurveCoordinates;
};

export const CurveChart = observer(({
  source, tablet, log10, width, coordinates,
}: Props) => {
  const coords = coordinates.points;

  const {
    color, lineWidth, ratioUom, lineType,
  } = source.params;
  const { scaleFrom, scaleTo } = source;

  // console.log(tablet.scale.pointsInterval);

  const { yRatio, zeroPointKey, offsetY } = tablet.scale;

  const draw = (g: PIXI.Graphics) => {
    const scaledOffsetY = offsetY + zeroPointKey * yRatio;

    let xRatio = log10
      ? width / (
        Math.log10(scaleTo <= 0 ? 1 : scaleTo) - Math.log10(scaleFrom <= 0 ? 1 : scaleFrom)
      )
      : width / (scaleTo - scaleFrom);
    xRatio = xRatio === Infinity ? 1 : (xRatio || 1);

    const firstPointIndex = coordinates.binarySearchIndex(
      coords,
      tablet.onFrame,
      0,
      coords.length - 1,
    ) || 1;

    if (lineType === 5) {
      drawDottedLine(
        g,
        color,
        lineWidth,
        coords,
        yRatio,
        scaledOffsetY,
        xRatio,
        scaleFrom,
        ratioUom,
        log10,
        tablet.scale.containerLength,
        firstPointIndex - 1,
      );
    } else {
      drawLine(
        g,
        color,
        tablet.scale.dataPerPixel > 0.25 ? [] : getTypeLine(source.params),
        lineWidth,
        coords,
        yRatio,
        scaledOffsetY,
        xRatio,
        scaleFrom,
        ratioUom,
        log10,
        tablet.scale.containerLength,
        firstPointIndex - 1,
      );
    }
  };
  return (
    <Graphics draw={draw} />
  );
});
