import { Container } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import { Tablet } from '../../stores/Tablet';
import { OverlayTrack } from './OverlayTrack';

type Props = { tablet: Tablet; };

export const OverlayTracks = observer<Props>(({ tablet }) => {
  const rotation = tablet.params.orientation === 'vertical' ? 0 : (3 * Math.PI) / 2;
  const position: [number, number] = tablet.params.orientation === 'vertical' ? [0, 0] : [0, tablet.tracks.tracksWidth];

  return (
    <Container position={position} rotation={rotation}>
      {tablet.tracks.withOffset.map(([track, offset]) => (
        <OverlayTrack
          key={track.innerId}
          track={track}
          tablet={tablet}
          offsetX={offset + tablet.scalePosition.tracksOffset}
        />
      ))}
    </Container>
  );
});
