import { observer } from 'mobx-react-lite';
import { Tablet } from '../../stores/Tablet';
import { Track, Curve } from '../../stores/Tracks';
import { ContainerCurves } from './TabletTracksInfo.styled';
import { ContextMenuStore } from '../../stores/ContextMenu';
import { HeaderInfo } from './HeaderInfo';

type Props = {
  track: Track;
  tablet: Tablet;
  offset: number;
  collapsed: boolean;
  contextMenu: ContextMenuStore;
  position: 'top' | 'bottom';
};
export const TrackInfo = observer(({
  track, tablet, offset, collapsed, contextMenu, position,
} : Props) => {
  function onContextMenu(event: React.MouseEvent<HTMLDivElement, MouseEvent>, source: Curve) {
    event.preventDefault();
    if (tablet.editMode) {
      contextMenu.openSourceEditMenu(event.clientX, event.clientY, tablet, track, source);
    } else {
      contextMenu.openTabletMenu(event.clientX, event.clientY, tablet, track);
    }
  }
  return (
    <ContainerCurves style={{ justifyContent: position === 'top' ? 'start' : 'end' }}>
      {(position === 'top' ? track.topSources : track.bottomSources)
        .map((source) => (
          <HeaderInfo
            key={source.sourceDto.externalId}
            tablet={tablet}
            track={track}
            source={source}
            offset={offset}
            onClick={() => tablet.editMode && tablet.tracks.setSelectedSource(source)}
            onContextMenu={(e) => onContextMenu(e, source)}
            collapsed={collapsed}
          />
        ))}
    </ContainerCurves>
  );
});
