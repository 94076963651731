import { Form, InputNumber, Checkbox } from 'antd';
import { observer } from 'mobx-react-lite';
import { Curve } from '../../stores/Tracks';

export const ImageParams = observer(({ curve } : { curve: Curve }) => {
  const [form] = Form.useForm();

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      form={form}
      onValuesChange={(value) => curve.setParams(value)}
      initialValues={curve.params}
      size="small"
    >
      <Form.Item name="scaleFrom" label="Scale from">
        <InputNumber step={0.1} max={curve.params.scaleTo} />
      </Form.Item>
      <Form.Item name="scaleTo" label="Scale to">
        <InputNumber step={0.1} min={curve.params.scaleFrom} />
      </Form.Item>
      <Form.Item name="dynamicRange" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
        <Checkbox>Dynamic range</Checkbox>
      </Form.Item>
    </Form>
  );
});
