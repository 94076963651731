/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Modal, Button, Tooltip, notification,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useLocalization } from '@fluent/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import Form from './Form';
import { downloadPdf } from './DocumentPdf';
import { ExportPdfRangeType } from '../../../enums/ExportPdfRangeType';
import { TabletType } from '../../../enums/TabletType';
import { round } from '../../../utils';
import { scaleUnitStore, timeZonesStore } from '../../../stores/init-stores-global';
import { Tablet } from '../../../stores/Tablet';

export const ExportPdfModal = observer(({ tablet }: { tablet: Tablet }) => {
  const { l10n } = useLocalization();
  const [disabled, setDisabled] = useState(false);
  async function handlerFinishForm(values: any) {
    setDisabled(true);
    let onData = moment.isMoment(values.onData)
      ? timeZonesStore.getInverDate(values.onData.valueOf()).valueOf()
      : values.onData;
    let toData = moment.isMoment(values.toData)
      ? timeZonesStore.getInverDate(values.toData.valueOf()).valueOf()
      : values.toData;
    if (values.rangeType === ExportPdfRangeType.AllRange) {
      onData = tablet.onDataDB;
      toData = tablet.toDataDB;
    }
    let { scale } = values;
    if (tablet.tabletType === TabletType.Depth) {
      scale = Number(round(scale / scaleUnitStore.kUnit));
      onData = Number(round(onData / scaleUnitStore.kUnit));
      toData = Number(round(toData / scaleUnitStore.kUnit));
    }
    await downloadPdf({
      ...values,
      tablet,
      onData,
      toData,
      scale,
      l10n,
    }).catch((e) => {
      notification.error({
        message: l10n?.getString('Pdf-generation-error'),
        description: (e as Error)?.message,
        placement: 'bottomRight',
      });
    });
    setDisabled(false);
  }
  return (
    <Modal
      title={l10n.getString('Export-PDF')}
      open={tablet.showPdfForm}
      onCancel={() => tablet.closePdfForm()}
      footer={[
        <Button disabled={disabled} loading={disabled} form="ExportPdfForm" key="submit" htmlType="submit" type="primary">
          {l10n.getString('Build')}
        </Button>,
      ]}
      closeIcon={<Tooltip title={l10n.getString('Close-window')}><CloseOutlined /></Tooltip>}
      destroyOnClose
    >
      <Form
        tabletType={tablet.tabletType}
        minData={tablet.onDataDB || 0}
        maxData={tablet.toDataDB || 0}
        onData={Math.max(tablet.onFrame, 0)}
        toData={tablet.toFrame}
        wellName={tablet.templateData.well.text || 'unknown'}
        onFinish={handlerFinishForm}
        disabled={disabled}
      />
    </Modal>
  );
});
