import React from 'react';
import { observer } from 'mobx-react-lite';
import { CurveStatus } from '../../stores/SourceDataMap';
import { Tablet } from '../../stores/Tablet';
import { Curve } from '../../stores/Tracks';
import { LoaderString } from './LoaderString';
import { BaseBlock } from './TabletTracksInfo.styled';

type Props = {
  tablet: Tablet;
  source: Curve;
  collapsed: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
  onContextMenu: React.MouseEventHandler<HTMLDivElement>
};

export const CommentsHeader = observer(({
  tablet, source, collapsed, onClick, onContextMenu,
} : Props) => (
  <BaseBlock
    key={source.sourceDto.externalId}
    onClick={onClick}
    onContextMenu={onContextMenu}
    collapsed={collapsed}
    selected={tablet.tracks.selectedSource === source}
    reverse={source.params.legendPosition === 'bottom'}
  >
    <div>{source.sourceDto.name}</div>
    {(source.sourceData?.status === CurveStatus.ServerFetching
      || source.sourceData?.status === CurveStatus.MetaDataFetching
      || source.sourceData?.status === CurveStatus.NoData)
      && (<LoaderString source={source} position="right" />)}
  </BaseBlock>
));
