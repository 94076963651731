const CANVAS_WIDTH = 40;
const CANVAS_HEIGHT = 40;

const images = [
  {
    id: 3,
    name: 'Песчаник|Sandstone',
    fillColor: '#FFFF99',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#FFFF99';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, 1, 1);

      context.fillRect(0, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, 0, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2, 1, 1);

      context.fillRect(0, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, 0, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        (CANVAS_HEIGHT / 4) * 3,
        1,
        1,
      );

      context.fillRect(CANVAS_WIDTH / 2, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(0, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, 0, 1, 1);

      context.fillRect(CANVAS_WIDTH / 8, CANVAS_HEIGHT / 8, 1, 1);
      context.fillRect(CANVAS_WIDTH / 8, (CANVAS_HEIGHT / 8) * 3, 1, 1);
      context.fillRect(CANVAS_WIDTH / 8, (CANVAS_HEIGHT / 8) * 5, 1, 1);
      context.fillRect(CANVAS_WIDTH / 8, (CANVAS_HEIGHT / 8) * 7, 1, 1);
      context.fillRect((CANVAS_WIDTH / 8) * 3, CANVAS_HEIGHT / 8, 1, 1);
      context.fillRect((CANVAS_WIDTH / 8) * 5, CANVAS_HEIGHT / 8, 1, 1);
      context.fillRect((CANVAS_WIDTH / 8) * 7, CANVAS_HEIGHT / 8, 1, 1);

      context.fillRect(
        (CANVAS_WIDTH / 8) * 3,
        (CANVAS_HEIGHT / 8) * 3,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 3,
        (CANVAS_HEIGHT / 8) * 5,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 3,
        (CANVAS_HEIGHT / 8) * 7,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 5,
        (CANVAS_HEIGHT / 8) * 3,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 5,
        (CANVAS_HEIGHT / 8) * 5,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 5,
        (CANVAS_HEIGHT / 8) * 7,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 7,
        (CANVAS_HEIGHT / 8) * 3,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 7,
        (CANVAS_HEIGHT / 8) * 5,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 7,
        (CANVAS_HEIGHT / 8) * 7,
        1,
        1,
      );
    },
  },
  {
    id: 7,
    name: 'Алевролит|Siltstone',
    fillColor: '#CCCC00',
    fill: (context: CanvasRenderingContext2D) => {
      const OFFSET = 4;
      context.fillStyle = '#CCCC00';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

      context.fillStyle = '#000';

      context.fillRect(0, 0, 1, 1);
      context.fillRect(OFFSET + 1, 0, CANVAS_WIDTH - OFFSET * 2, 1);

      context.fillRect(
        0,
        CANVAS_HEIGHT / 8,
        CANVAS_WIDTH / 2 - OFFSET,
        1,
      );
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 8, 1, 1);
      context.fillRect(
        CANVAS_WIDTH / 2 + OFFSET + 1,
        CANVAS_HEIGHT / 8,
        CANVAS_WIDTH / 2,
        1,
      );

      context.fillRect(0, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(
        OFFSET + 1,
        CANVAS_HEIGHT / 4,
        CANVAS_WIDTH - OFFSET * 2,
        1,
      );

      context.fillRect(
        0,
        (CANVAS_HEIGHT / 8) * 3,
        CANVAS_WIDTH / 2 - OFFSET,
        1,
      );
      context.fillRect(CANVAS_WIDTH / 2, (CANVAS_HEIGHT / 8) * 3, 1, 1);
      context.fillRect(
        CANVAS_WIDTH / 2 + OFFSET + 1,
        (CANVAS_HEIGHT / 8) * 3,
        CANVAS_WIDTH / 2,
        1,
      );

      context.fillRect(0, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(
        OFFSET + 1,
        CANVAS_HEIGHT / 2,
        CANVAS_WIDTH - OFFSET * 2,
        1,
      );

      context.fillRect(
        0,
        (CANVAS_HEIGHT / 8) * 5,
        CANVAS_WIDTH / 2 - OFFSET,
        1,
      );
      context.fillRect(CANVAS_WIDTH / 2, (CANVAS_HEIGHT / 8) * 5, 1, 1);
      context.fillRect(
        CANVAS_WIDTH / 2 + OFFSET + 1,
        (CANVAS_HEIGHT / 8) * 5,
        CANVAS_WIDTH / 2,
        1,
      );

      context.fillRect(0, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect(
        OFFSET + 1,
        (CANVAS_HEIGHT / 4) * 3,
        CANVAS_WIDTH - OFFSET * 2,
        1,
      );

      context.fillRect(
        0,
        (CANVAS_HEIGHT / 8) * 7,
        CANVAS_WIDTH / 2 - OFFSET,
        1,
      );
      context.fillRect(CANVAS_WIDTH / 2, (CANVAS_HEIGHT / 8) * 7, 1, 1);
      context.fillRect(
        CANVAS_WIDTH / 2 + OFFSET + 1,
        (CANVAS_HEIGHT / 8) * 7,
        CANVAS_WIDTH / 2,
        1,
      );
    },
  },
  {
    id: 31,
    name: 'Аргиллит|Claystone',
    fillColor: '#595959',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#595959';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, CANVAS_WIDTH / 4, 1);
      context.fillRect(CANVAS_WIDTH / 2, 0, CANVAS_WIDTH / 4, 1);
      context.fillRect(
        CANVAS_WIDTH / 4,
        CANVAS_HEIGHT / 4,
        CANVAS_WIDTH / 4,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        CANVAS_HEIGHT / 4,
        CANVAS_WIDTH / 4,
        1,
      );

      context.fillRect(0, CANVAS_HEIGHT / 2, CANVAS_WIDTH / 4, 1);
      context.fillRect(
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 2,
        CANVAS_WIDTH / 4,
        1,
      );
      context.fillRect(
        CANVAS_WIDTH / 4,
        (CANVAS_HEIGHT / 4) * 3,
        CANVAS_WIDTH / 4,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        (CANVAS_HEIGHT / 4) * 3,
        CANVAS_WIDTH / 4,
        1,
      );
    },
  },
  {
    id: 8,
    name: 'Глина|Clay',
    fillColor: '#05E904',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, CANVAS_WIDTH / 2, CANVAS_HEIGHT / 10);
      context.fillRect(
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 2,
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 10,
      );
    },
  },
  {
    id: 20,
    name: 'Гипс|Gypsum',
    fillColor: '#FF66FF',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#FF66FF';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      for (let i = -CANVAS_WIDTH; i < CANVAS_WIDTH; i += 10) {
        context.moveTo(i, 0);
        context.lineTo(CANVAS_WIDTH + i, CANVAS_HEIGHT);
      }

      context.stroke();
    },
  },
  {
    id: 13,
    name: 'Доломит|Dolomite',
    fillColor: '#006699',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#006699';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      for (let i = 0; i < CANVAS_HEIGHT; i += 10) {
        context.moveTo(0, i);
        context.lineTo(CANVAS_WIDTH, i);

        for (let j = 0; j <= CANVAS_WIDTH; j += 20) {
          context.moveTo(j, i);
          context.lineTo(j - 10, i + 10);
        }
      }

      context.stroke();
    },
  },
  {
    id: 35,
    name: 'Доломит ангид|Anhydrite Dolomite',
    fillColor: '#006699',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#006699';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      for (let i = 0; i < CANVAS_WIDTH; i += 5) {
        context.moveTo(i, 0);
        context.lineTo(i, CANVAS_HEIGHT);
      }

      context.stroke();
    },
  },
  {
    id: 36,
    name: 'Доломит глин|Clay Dolomite',
    fillColor: '#006699',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#006699';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      let shift = false;

      for (let i = 5; i <= CANVAS_HEIGHT; i += 10) {
        if (shift) {
          context.moveTo(0, i);
          context.lineTo(15, i);
          context.moveTo(20, i);
          context.lineTo(35, i);
          context.moveTo(5, i - 3);
          context.lineTo(5, i);
        } else {
          context.moveTo(0, i);
          context.lineTo(5, i);
          context.moveTo(10, i);
          context.lineTo(25, i);
          context.moveTo(30, i);
          context.lineTo(40, i);
          context.moveTo(15, i - 3);
          context.lineTo(15, i);
        }

        shift = !shift;
      }

      context.stroke();
    },
  },
  {
    id: 11,
    name: 'Известняк|Limestone',
    fillColor: '#66CCFF',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#66CCFF';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      let shift = false;
      for (let i = 0; i < CANVAS_HEIGHT; i += 10) {
        context.moveTo(0, i);
        context.lineTo(CANVAS_WIDTH, i);

        if (shift) {
          context.moveTo(20, i);
          context.lineTo(20, i + 10);
        } else {
          context.moveTo(10, i);
          context.lineTo(10, i + 10);
          context.moveTo(30, i);
          context.lineTo(30, i + 10);
        }

        shift = !shift;
      }

      context.stroke();
    },
  },
  {
    id: 24,
    name: 'Ангидрит|Anhydrite',
    fillColor: '#CC00FF',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#CC00FF';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      let shift = false;
      for (let i = 0; i < CANVAS_HEIGHT; i += 10) {
        if (shift) {
          context.moveTo(5, i + 5);
          context.lineTo(10, i);
          context.lineTo(15, i + 5);
          context.moveTo(25, i + 5);
          context.lineTo(30, i);
          context.lineTo(35, i + 5);
        } else {
          context.moveTo(0, i);
          context.lineTo(5, i + 5);
          context.moveTo(15, i + 5);
          context.lineTo(20, i);
          context.lineTo(25, i + 5);
          context.moveTo(35, i + 5);
          context.lineTo(40, i);
        }

        shift = !shift;
      }

      context.stroke();
    },
  },
  {
    id: 25,
    name: 'Каменная соль|Salt',
    fillColor: '#9999FF',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#9999FF';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      let shift = false;
      for (let i = 0; i < CANVAS_HEIGHT; i += 10) {
        if (shift) {
          context.moveTo(5, i);
          context.lineTo(5, i + 5);
          context.lineTo(10, i + 5);
          context.moveTo(15, i);
          context.lineTo(15, i + 5);
          context.lineTo(20, i + 5);
          context.moveTo(25, i);
          context.lineTo(25, i + 5);
          context.lineTo(30, i + 5);
          context.moveTo(35, i);
          context.lineTo(35, i + 5);
          context.lineTo(40, i + 5);
        } else {
          context.moveTo(0, i);
          context.lineTo(0, i + 5);
          context.lineTo(5, i + 5);
          context.moveTo(10, i);
          context.lineTo(10, i + 5);
          context.lineTo(15, i + 5);
          context.moveTo(20, i);
          context.lineTo(20, i + 5);
          context.lineTo(25, i + 5);
          context.moveTo(30, i);
          context.lineTo(30, i + 5);
          context.lineTo(35, i + 5);
        }

        shift = !shift;
      }

      context.stroke();
    },
  },
  {
    id: 32,
    name: 'Кора выветривания',
    fillColor: '#F8CBAD',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#F8CBAD';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, 1, 1);

      context.fillRect(0, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, 0, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2, 1, 1);

      context.fillRect(0, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, 0, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        (CANVAS_HEIGHT / 4) * 3,
        1,
        1,
      );

      context.fillRect(CANVAS_WIDTH / 2, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(0, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, 0, 1, 1);

      context.fillRect(CANVAS_WIDTH / 8, CANVAS_HEIGHT / 8, 1, 1);
      context.fillRect(CANVAS_WIDTH / 8, (CANVAS_HEIGHT / 8) * 3, 1, 1);
      context.fillRect(CANVAS_WIDTH / 8, (CANVAS_HEIGHT / 8) * 5, 1, 1);
      context.fillRect(CANVAS_WIDTH / 8, (CANVAS_HEIGHT / 8) * 7, 1, 1);
      context.fillRect((CANVAS_WIDTH / 8) * 3, CANVAS_HEIGHT / 8, 1, 1);
      context.fillRect((CANVAS_WIDTH / 8) * 5, CANVAS_HEIGHT / 8, 1, 1);
      context.fillRect((CANVAS_WIDTH / 8) * 7, CANVAS_HEIGHT / 8, 1, 1);

      context.fillRect(
        (CANVAS_WIDTH / 8) * 3,
        (CANVAS_HEIGHT / 8) * 3,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 3,
        (CANVAS_HEIGHT / 8) * 5,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 3,
        (CANVAS_HEIGHT / 8) * 7,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 5,
        (CANVAS_HEIGHT / 8) * 3,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 5,
        (CANVAS_HEIGHT / 8) * 5,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 5,
        (CANVAS_HEIGHT / 8) * 7,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 7,
        (CANVAS_HEIGHT / 8) * 3,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 7,
        (CANVAS_HEIGHT / 8) * 5,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 7,
        (CANVAS_HEIGHT / 8) * 7,
        1,
        1,
      );
    },
  },
  {
    id: 33,
    name: 'Кристал. фундамент',
    fillColor: '#FF99FF',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#FF99FF';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
    },
  },
  {
    id: 34,
    name: 'Магмат. порода',
    fillColor: '#FF9900',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#FF9900';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      let shift = false;
      for (let i = 0; i < CANVAS_HEIGHT; i += 10) {
        if (shift) {
          context.moveTo(5, i);
          context.lineTo(10, i + 5);
          context.lineTo(15, i);
          context.lineTo(5, i);

          context.moveTo(25, i);
          context.lineTo(30, i + 5);
          context.lineTo(35, i);
          context.lineTo(25, i);
        } else {
          context.moveTo(0, i + 5);
          context.lineTo(5, i);
          context.lineTo(0, i);

          context.moveTo(15, i);
          context.lineTo(20, i + 5);
          context.lineTo(25, i);
          context.lineTo(15, i);

          context.moveTo(40, i + 5);
          context.lineTo(35, i);
          context.lineTo(40, i);
        }

        shift = !shift;
      }

      context.stroke();
    },
  },
  {
    id: 15,
    name: 'Мергель|Marl',
    fillColor: '#00FF99',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#00FF99';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.arc(5, 5, 3, Math.PI, 2 * Math.PI, false);
      context.arc(12, 5, 3, Math.PI, 2 * Math.PI, true);

      context.moveTo(25, 15);
      context.arc(28, 15, 3, Math.PI, 2 * Math.PI, false);
      context.arc(35, 15, 3, Math.PI, 2 * Math.PI, true);

      context.moveTo(2, 25);
      context.arc(5, 25, 3, Math.PI, 2 * Math.PI, false);
      context.arc(12, 25, 3, Math.PI, 2 * Math.PI, true);

      context.moveTo(25, 35);
      context.arc(28, 35, 3, Math.PI, 2 * Math.PI, false);
      context.arc(35, 35, 3, Math.PI, 2 * Math.PI, true);

      context.stroke();
    },
  },
  {
    id: 21,
    name: 'Цемент|Cement',
    fillColor: '#BFBFBF',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#BFBFBF';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      let shift = false;
      for (let i = 0; i < CANVAS_WIDTH; i += 5) {
        for (let j = 0; j < CANVAS_HEIGHT; j += 10) {
          context.fillRect(i, j + 5 * Number(shift), 5, 5);
        }
        shift = !shift;
      }
    },
  },
  {
    id: 8,
    name: 'Clay',
    fillColor: '#00FF99',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#00FF99';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, CANVAS_WIDTH / 4, 1);
      context.fillRect(CANVAS_WIDTH / 2, 0, CANVAS_WIDTH / 4, 1);
      context.fillRect(
        CANVAS_WIDTH / 4,
        CANVAS_HEIGHT / 4,
        CANVAS_WIDTH / 4,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        CANVAS_HEIGHT / 4,
        CANVAS_WIDTH / 4,
        1,
      );

      context.fillRect(0, CANVAS_HEIGHT / 2, CANVAS_WIDTH / 4, 1);
      context.fillRect(
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 2,
        CANVAS_WIDTH / 4,
        1,
      );
      context.fillRect(
        CANVAS_WIDTH / 4,
        (CANVAS_HEIGHT / 4) * 3,
        CANVAS_WIDTH / 4,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        (CANVAS_HEIGHT / 4) * 3,
        CANVAS_WIDTH / 4,
        1,
      );
    },
  },
  {
    id: 9,
    name: 'Shale',
    fillColor: '#017F02',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#017F02';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, CANVAS_WIDTH / 4, 1);
      context.fillRect(CANVAS_WIDTH / 2, 0, CANVAS_WIDTH / 4, 1);
      context.fillRect(
        CANVAS_WIDTH / 4,
        CANVAS_HEIGHT / 4,
        CANVAS_WIDTH / 4,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        CANVAS_HEIGHT / 4,
        CANVAS_WIDTH / 4,
        1,
      );

      context.fillRect(0, CANVAS_HEIGHT / 2, CANVAS_WIDTH / 4, 1);
      context.fillRect(
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 2,
        CANVAS_WIDTH / 4,
        1,
      );
      context.fillRect(
        CANVAS_WIDTH / 4,
        (CANVAS_HEIGHT / 4) * 3,
        CANVAS_WIDTH / 4,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        (CANVAS_HEIGHT / 4) * 3,
        CANVAS_WIDTH / 4,
        1,
      );
    },
  },
  {
    id: 2,
    name: 'Песок|Sand',
    fillColor: '#CCCC00',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#CCCC00';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, 1, 1);

      context.fillRect(0, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, 0, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2, 1, 1);

      context.fillRect(0, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, 0, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        (CANVAS_HEIGHT / 4) * 3,
        1,
        1,
      );

      context.fillRect(CANVAS_WIDTH / 2, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(0, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, 0, 1, 1);
    },
  },
  {
    id: 1,
    name: 'Conglomerate|Конгломерат',
    fillColor: '#66CCFF',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#66CCFF';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      let shift = false;
      for (let i = 0; i < CANVAS_HEIGHT; i += 10) {
        context.moveTo(0, i);
        context.lineTo(CANVAS_WIDTH, i);

        if (shift) {
          context.moveTo(20, i);
          context.lineTo(20, i + 10);
        } else {
          context.moveTo(10, i);
          context.lineTo(10, i + 10);
          context.moveTo(30, i);
          context.lineTo(30, i + 10);
        }

        shift = !shift;
      }

      context.stroke();
    },
  },
  {
    id: 4,
    name: 'Congl. Sandstone',
    fillColor: '#66CCFF',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#66CCFF';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, 1, 1);

      context.fillRect(0, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, 0, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2, 1, 1);

      context.fillRect(0, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, 0, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        (CANVAS_HEIGHT / 4) * 3,
        1,
        1,
      );

      context.fillRect(CANVAS_WIDTH / 2, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(0, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, 0, 1, 1);
    },
  },
  {
    id: 5,
    name: 'Limy Sandstone',
    fillColor: '#66CCFF',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#66CCFF';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, 1, 1);

      context.fillRect(0, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, 0, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2, 1, 1);

      context.fillRect(0, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, 0, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        (CANVAS_HEIGHT / 4) * 3,
        1,
        1,
      );

      context.fillRect(CANVAS_WIDTH / 2, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(0, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, 0, 1, 1);
    },
  },
  {
    id: 6,
    name: 'Silt',
    fillColor: '#999900',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#999900';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, CANVAS_WIDTH / 4, 1);
      context.fillRect(CANVAS_WIDTH / 2, 0, CANVAS_WIDTH / 4, 1);
      context.fillRect(
        CANVAS_WIDTH / 4,
        CANVAS_HEIGHT / 4,
        CANVAS_WIDTH / 4,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        CANVAS_HEIGHT / 4,
        CANVAS_WIDTH / 4,
        1,
      );

      context.fillRect(0, CANVAS_HEIGHT / 2, CANVAS_WIDTH / 4, 1);
      context.fillRect(
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 2,
        CANVAS_WIDTH / 4,
        1,
      );
      context.fillRect(
        CANVAS_WIDTH / 4,
        (CANVAS_HEIGHT / 4) * 3,
        CANVAS_WIDTH / 4,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        (CANVAS_HEIGHT / 4) * 3,
        CANVAS_WIDTH / 4,
        1,
      );
    },
  },
  {
    id: 10,
    name: 'Silty Shale',
    fillColor: '#999900',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, CANVAS_WIDTH / 2, CANVAS_HEIGHT / 10);
      context.fillRect(
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 2,
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 10,
      );
    },
  },
  {
    id: 12,
    name: 'Доломит изв|Dolomitic Limestone',
    fillColor: '#006699',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      let shift = false;
      for (let i = 0; i < CANVAS_HEIGHT; i += 10) {
        context.moveTo(0, i);
        context.lineTo(CANVAS_WIDTH, i);

        if (shift) {
          context.moveTo(20, i);
          context.lineTo(20, i + 10);
          context.moveTo(20, i + 10);
          context.lineTo(25, i + 5);

          context.moveTo(0, i + 10);
          context.lineTo(5, i + 5);
        } else {
          context.moveTo(10, i);
          context.lineTo(10, i + 10);
          context.moveTo(10, i + 10);
          context.lineTo(15, i + 5);
          context.moveTo(30, i);
          context.lineTo(30, i + 10);
          context.moveTo(30, i + 10);
          context.lineTo(35, i + 5);
        }

        shift = !shift;
      }

      context.stroke();
    },
  },
  {
    id: 14,
    name: 'Dolomitic Breccia',
    fillColor: '#292EFF',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      for (let i = 0; i < CANVAS_HEIGHT; i += 10) {
        context.moveTo(0, i);
        context.lineTo(CANVAS_WIDTH, i);

        for (let j = 0; j <= CANVAS_WIDTH; j += 20) {
          context.moveTo(j, i);
          context.lineTo(j - 10, i + 10);
        }
      }

      context.stroke();
    },
  },
  {
    id: 16,
    name: 'Silty Marl',
    fillColor: '#999900',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.arc(5, 5, 3, Math.PI, 2 * Math.PI, false);
      context.arc(12, 5, 3, Math.PI, 2 * Math.PI, true);

      context.moveTo(25, 15);
      context.arc(28, 15, 3, Math.PI, 2 * Math.PI, false);
      context.arc(35, 15, 3, Math.PI, 2 * Math.PI, true);

      context.moveTo(2, 25);
      context.arc(5, 25, 3, Math.PI, 2 * Math.PI, false);
      context.arc(12, 25, 3, Math.PI, 2 * Math.PI, true);

      context.moveTo(25, 35);
      context.arc(28, 35, 3, Math.PI, 2 * Math.PI, false);
      context.arc(35, 35, 3, Math.PI, 2 * Math.PI, true);

      context.stroke();
    },
  },
  {
    id: 17,
    name: 'Clayey Marl',
    fillColor: '#008001',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.arc(5, 5, 3, Math.PI, 2 * Math.PI, false);
      context.arc(12, 5, 3, Math.PI, 2 * Math.PI, true);

      context.moveTo(25, 15);
      context.arc(28, 15, 3, Math.PI, 2 * Math.PI, false);
      context.arc(35, 15, 3, Math.PI, 2 * Math.PI, true);

      context.moveTo(2, 25);
      context.arc(5, 25, 3, Math.PI, 2 * Math.PI, false);
      context.arc(12, 25, 3, Math.PI, 2 * Math.PI, true);

      context.moveTo(25, 35);
      context.arc(28, 35, 3, Math.PI, 2 * Math.PI, false);
      context.arc(35, 35, 3, Math.PI, 2 * Math.PI, true);

      context.stroke();
    },
  },
  {
    id: 18,
    name: 'Limy Marl',
    fillColor: '#66CCFF',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.arc(5, 5, 3, Math.PI, 2 * Math.PI, false);
      context.arc(12, 5, 3, Math.PI, 2 * Math.PI, true);

      context.moveTo(25, 15);
      context.arc(28, 15, 3, Math.PI, 2 * Math.PI, false);
      context.arc(35, 15, 3, Math.PI, 2 * Math.PI, true);

      context.moveTo(2, 25);
      context.arc(5, 25, 3, Math.PI, 2 * Math.PI, false);
      context.arc(12, 25, 3, Math.PI, 2 * Math.PI, true);

      context.moveTo(25, 35);
      context.arc(28, 35, 3, Math.PI, 2 * Math.PI, false);
      context.arc(35, 35, 3, Math.PI, 2 * Math.PI, true);

      context.stroke();
    },
  },
  {
    id: 19,
    name: 'Coal|Уголь',
    fillColor: '#000000',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
    },
  },
  {
    id: 22,
    name: 'Quartzite|Кварцит',
    fillColor: '#ffffff',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      let shift = false;
      for (let i = 0; i < CANVAS_HEIGHT; i += 10) {
        if (shift) {
          context.moveTo(5, i);
          context.lineTo(10, i + 5);
          context.lineTo(15, i);
          context.lineTo(5, i);

          context.moveTo(25, i);
          context.lineTo(30, i + 5);
          context.lineTo(35, i);
          context.lineTo(25, i);
        } else {
          context.moveTo(0, i + 5);
          context.lineTo(5, i);
          context.lineTo(0, i);

          context.moveTo(15, i);
          context.lineTo(20, i + 5);
          context.lineTo(25, i);
          context.lineTo(15, i);

          context.moveTo(40, i + 5);
          context.lineTo(35, i);
          context.lineTo(40, i);
        }

        shift = !shift;
      }

      context.stroke();
    },
  },
  {
    id: 23,
    name: 'Gneiss',
    fillColor: '#ffffff',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      for (let i = 0; i < CANVAS_WIDTH; i += 5) {
        context.moveTo(i, 0);
        context.lineTo(i, CANVAS_HEIGHT);
      }

      context.stroke();
    },
  },
  {
    id: 26,
    name: 'Chert',
    fillColor: '#ffffff',
    fill: (context: CanvasRenderingContext2D) => {
      context.fillStyle = '#F8CBAD';
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, 1, 1);

      context.fillRect(0, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, 0, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2, 1, 1);

      context.fillRect(0, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, 0, 1, 1);
      context.fillRect(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, CANVAS_HEIGHT / 4, 1, 1);
      context.fillRect(CANVAS_WIDTH / 4, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect(
        (CANVAS_WIDTH / 4) * 3,
        (CANVAS_HEIGHT / 4) * 3,
        1,
        1,
      );

      context.fillRect(CANVAS_WIDTH / 2, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, CANVAS_HEIGHT / 2, 1, 1);
      context.fillRect(0, (CANVAS_HEIGHT / 4) * 3, 1, 1);
      context.fillRect((CANVAS_WIDTH / 4) * 3, 0, 1, 1);

      context.fillRect(CANVAS_WIDTH / 8, CANVAS_HEIGHT / 8, 1, 1);
      context.fillRect(CANVAS_WIDTH / 8, (CANVAS_HEIGHT / 8) * 3, 1, 1);
      context.fillRect(CANVAS_WIDTH / 8, (CANVAS_HEIGHT / 8) * 5, 1, 1);
      context.fillRect(CANVAS_WIDTH / 8, (CANVAS_HEIGHT / 8) * 7, 1, 1);
      context.fillRect((CANVAS_WIDTH / 8) * 3, CANVAS_HEIGHT / 8, 1, 1);
      context.fillRect((CANVAS_WIDTH / 8) * 5, CANVAS_HEIGHT / 8, 1, 1);
      context.fillRect((CANVAS_WIDTH / 8) * 7, CANVAS_HEIGHT / 8, 1, 1);

      context.fillRect(
        (CANVAS_WIDTH / 8) * 3,
        (CANVAS_HEIGHT / 8) * 3,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 3,
        (CANVAS_HEIGHT / 8) * 5,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 3,
        (CANVAS_HEIGHT / 8) * 7,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 5,
        (CANVAS_HEIGHT / 8) * 3,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 5,
        (CANVAS_HEIGHT / 8) * 5,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 5,
        (CANVAS_HEIGHT / 8) * 7,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 7,
        (CANVAS_HEIGHT / 8) * 3,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 7,
        (CANVAS_HEIGHT / 8) * 5,
        1,
        1,
      );
      context.fillRect(
        (CANVAS_WIDTH / 8) * 7,
        (CANVAS_HEIGHT / 8) * 7,
        1,
        1,
      );
    },
  },
  {
    id: 27,
    name: 'Schist',
    fillColor: '#ffffff',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      let shift = false;

      for (let i = 5; i <= CANVAS_HEIGHT; i += 10) {
        if (shift) {
          context.moveTo(0, i);
          context.lineTo(15, i);
          context.moveTo(20, i);
          context.lineTo(35, i);
          context.moveTo(5, i - 3);
          context.lineTo(5, i);
        } else {
          context.moveTo(0, i);
          context.lineTo(5, i);
          context.moveTo(10, i);
          context.lineTo(25, i);
          context.moveTo(30, i);
          context.lineTo(40, i);
          context.moveTo(15, i - 3);
          context.lineTo(15, i);
        }

        shift = !shift;
      }

      context.stroke();
    },
  },
  {
    id: 28,
    name: 'Pyroclastic Rock',
    fillColor: '#ffffff',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      let shift = false;
      for (let i = 0; i < CANVAS_HEIGHT; i += 10) {
        if (shift) {
          context.moveTo(5, i + 5);
          context.lineTo(10, i);
          context.lineTo(15, i + 5);
          context.moveTo(25, i + 5);
          context.lineTo(30, i);
          context.lineTo(35, i + 5);
        } else {
          context.moveTo(0, i);
          context.lineTo(5, i + 5);
          context.moveTo(15, i + 5);
          context.lineTo(20, i);
          context.lineTo(25, i + 5);
          context.moveTo(35, i + 5);
          context.lineTo(40, i);
        }

        shift = !shift;
      }

      context.stroke();
    },
  },
  {
    id: 29,
    name: 'Intrusive Rock',
    fillColor: '#006666',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      for (let i = -CANVAS_WIDTH; i < CANVAS_WIDTH; i += 10) {
        context.moveTo(i, 0);
        context.lineTo(CANVAS_WIDTH + i, CANVAS_HEIGHT);
      }

      context.stroke();
    },
  },
  {
    id: 30,
    name: 'Extrusives',
    fillColor: '#ffffff',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';
      context.translate(0.5, 0.5);

      let shift = false;
      for (let i = 0; i < CANVAS_HEIGHT; i += 10) {
        if (shift) {
          context.moveTo(5, i);
          context.lineTo(5, i + 5);
          context.lineTo(10, i + 5);
          context.moveTo(15, i);
          context.lineTo(15, i + 5);
          context.lineTo(20, i + 5);
          context.moveTo(25, i);
          context.lineTo(25, i + 5);
          context.lineTo(30, i + 5);
          context.moveTo(35, i);
          context.lineTo(35, i + 5);
          context.lineTo(40, i + 5);
        } else {
          context.moveTo(0, i);
          context.lineTo(0, i + 5);
          context.lineTo(5, i + 5);
          context.moveTo(10, i);
          context.lineTo(10, i + 5);
          context.lineTo(15, i + 5);
          context.moveTo(20, i);
          context.lineTo(20, i + 5);
          context.lineTo(25, i + 5);
          context.moveTo(30, i);
          context.lineTo(30, i + 5);
          context.lineTo(35, i + 5);
        }

        shift = !shift;
      }

      context.stroke();
    },
  },
  {
    id: 37,
    name: 'Аргиллит Битуминозный',
    fillColor: '#202A2C',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, CANVAS_WIDTH / 2, CANVAS_HEIGHT / 10);
      context.fillRect(
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 2,
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 10,
      );
    },
  },
  {
    id: 38,
    name: 'Шоколадные глины',
    fillColor: '#7F2B0A',
    fill(context: CanvasRenderingContext2D) {
      context.fillStyle = this.fillColor;
      context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      context.fillStyle = '#000';

      context.fillRect(0, 0, CANVAS_WIDTH / 2, CANVAS_HEIGHT / 10);
      context.fillRect(
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 2,
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 10,
      );
    },
  },
];

export type LithologyImage = {
  img: HTMLImageElement;
  fillColor: string;
  name: string;
  message: string;
};

export function generateLithologyImage(
  id: number,
  message: string,
): LithologyImage | null {
  const image = images.find((x) => x.id === id);

  if (!image) {
    return null;
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = CANVAS_WIDTH;
  canvas.height = CANVAS_HEIGHT;

  if (!context) {
    return null;
  }

  image.fill(context);

  const img = new Image();
  img.src = canvas.toDataURL();

  return {
    img,
    fillColor: image.fillColor,
    name: image.name,
    message,
  };
}
