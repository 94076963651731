import { makeAutoObservable } from 'mobx';
import { eachOfInterval } from '../utils';
import { Scale } from './Scale';
import { TabletParams } from './TabletParams';
import { TimeZonesStore } from './timeZonesStore';

const t: [number, number][] = [
  [5, 1], [10, 2], [20, 5], [30, 5], [60, 10], [2 * 60, 20], [5 * 60, 60], [10 * 60, 60 * 2],
  [20 * 60, 5 * 60], [30 * 60, 5 * 60], [60 * 60, 10 * 60], [2 * 60 * 60, 30 * 60],
  [4 * 60 * 60, 60 * 60], [8 * 3600, 3600], [12 * 3600, 2 * 3600], [24 * 3600, 12 * 3600],
  [2 * 24 * 3600, 12 * 3600], [5 * 24 * 3600, 12 * 3600], [10 * 24 * 3600, 24 * 3600],
];

export class ScaleTimeline {
  scale: Scale;

  timeZones: TimeZonesStore;

  textHeight: number;

  fixZoom?: [number, number];

  tabletParams: TabletParams;

  constructor(
    scale: Scale,
    timeZones: TimeZonesStore,
    textHeight: number,
    tabletParams: TabletParams,
    fixZoom?: [number, number],
  ) {
    makeAutoObservable(this);
    this.scale = scale;
    this.textHeight = textHeight;
    this.fixZoom = fixZoom;
    this.tabletParams = tabletParams;
    this.timeZones = timeZones;
  }

  get textSize() {
    return this.tabletParams.orientation === 'vertical' ? this.textHeight + 14 : 50;
  }

  get zoom() {
    if (this.fixZoom) {
      return this.fixZoom;
    }
    const goal = t.find(([textMarks]) => {
      const h = textMarks / (this.scale.dataPerPixel * 60);
      return h > (this.textSize);
    });
    return goal || t[t.length - 1];
  }

  get line() {
    const step = this.zoom[1] * 1000;

    const { selectedMs } = this.timeZones;

    if (this.zoom[1] >= 60 * 60) {
      const onDate = Math.trunc((this.scale.onFrame) / step) * step
        - (selectedMs % (this.zoom[0] * 1000));
      const toDate = Math.trunc(this.scale.toFrame / step) * step + step
        - (selectedMs % (this.zoom[0] * 1000));
      return eachOfInterval(onDate, toDate, this.zoom[1] * 1000);
    }

    const onDate = Math.trunc((this.scale.onFrame) / step) * step;
    const toDate = Math.trunc(this.scale.toFrame / step) * step + step;
    return eachOfInterval(onDate, toDate, this.zoom[1] * 1000);
  }
}
