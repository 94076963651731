import { makeAutoObservable } from 'mobx';
import { Scale } from './Scale';
import { Track } from './Tracks';
import { Coordinator } from './Coordinator';

export class TracksAnalysisCoordinator {
  coordinator: Coordinator;

  fromPosition: number | null = null;

  scale: Scale;

  all: boolean = false;

  endedPosition: {
    x: number;
    y: number;
    from: number;
    to: number;
    tracks: Track[];
  } | null = null;

  close: () => void;

  constructor(
    coordinator: Coordinator,
    scale: Scale,
    close: () => void,
  ) {
    makeAutoObservable(this);
    this.coordinator = coordinator;
    this.scale = scale;
    this.close = close;
  }

  get toPosition() {
    return this.scale.pointToData(this.scale.offsetY + this.coordinator.mainAxis);
  }

  get x() {
    return this.endedPosition ? this.endedPosition.x : this.coordinator.globalX;
  }

  get y() {
    return this.endedPosition ? this.endedPosition.y : this.coordinator.globalY;
  }

  get from() {
    return this.endedPosition ? this.endedPosition.from : this.fromPosition;
  }

  get to() {
    return this.endedPosition ? this.endedPosition.to : this.toPosition;
  }

  get selectedTracks() {
    if (this.endedPosition) {
      return this.endedPosition.tracks;
    }
    if (this.all) {
      return this.coordinator.tracks.list;
    }
    if (this.coordinator.currentTrack) {
      return [this.coordinator.currentTrack];
    }
    return [];
  }

  start() {
    const { currentTrack } = this.coordinator;
    if (currentTrack) {
      this.fromPosition = this.toPosition;
      this.all = this.coordinator.shiftKey;
    }
  }

  end() {
    if (this.from && this.coordinator.currentTrack && this.to !== this.from) {
      this.endedPosition = {
        x: this.coordinator.globalX,
        y: this.coordinator.globalY,
        from: this.from,
        to: this.toPosition,
        tracks: this.selectedTracks,
      };
      this.fromPosition = null;
    } else {
      this.close();
    }
  }
}
