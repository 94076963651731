import { ReloadOutlined } from '@ant-design/icons';
import { useLocalization } from '@fluent/react';
import { Button, Input, Tooltip } from 'antd';
import { useState } from 'react';
import { useDebouncedCallback } from '../../hooks';
import { folderTreeStore } from '../../stores/folderTree';
import { TabletsWrapper } from './WellsSearch.styled';

export function WellsSearch() {
  const { l10n } = useLocalization();
  const [query, setQuery] = useState('');
  const search = useDebouncedCallback((str: string) => {
    folderTreeStore.onSearch(str.trim());
  }, 300);
  const onSearch = (value: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(value.target.value);
    search(value.target.value);
  };
  const onReset = () => {
    folderTreeStore.refresh();
  };
  return (
    <TabletsWrapper>
      <Input
        value={query}
        allowClear
        onChange={(e) => onSearch(e)}
        placeholder={l10n.getString('Well-search-placeholder')}
        onPressEnter={() => search(query)}
      />
      <Tooltip title={l10n.getString('RefreshWells')}>
        <Button onClick={onReset} icon={<ReloadOutlined />} />
      </Tooltip>
    </TabletsWrapper>
  );
}
