import { observer } from 'mobx-react-lite';
import {
  Card, Checkbox, Empty, Tooltip,
} from 'antd';
import React, { useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useLocalization } from '@fluent/react';
import TemplateItem from './TemplateItem';
import TemplateUpload from './TemplateUpload';
import { templatesStore } from '../../stores/templatesStore';
import { TemplateViewModel } from '../../api/dto/TabletDto';

type Props = {
  templates: TemplateViewModel[],
  system: boolean
};

function TemplatesCard({ templates, system }: Props) {
  const { l10n } = useLocalization();

  const ids = templates.map((x) => x.id);
  const selectedIds = system
    ? templatesStore.selectedSystemTemplates
    : templatesStore.selectedUserTemplates;

  const [checkedList, setCheckedList] = useState(selectedIds);
  const [indeterminate, setIndeterminate] = useState(!!selectedIds.length
    && selectedIds.length < ids.length);
  const [checkAll, setCheckAll] = useState(selectedIds.length === ids.length);

  const onChange = async (list: CheckboxValueType[]) => {
    setCheckedList(list as number[]);
    setIndeterminate(!!list.length && list.length < ids.length);
    setCheckAll(list.length === ids.length);
    await templatesStore.setSelected(list as number[], system);
  };

  const onCheckAllChange = async (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? ids : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    await templatesStore.setSelected(e.target.checked ? ids : [], system);
  };

  return (
    <Card
      title={(
        <Tooltip title={l10n.getString('Select-all')}>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            disabled={templatesStore.isFetching}
          >
            {l10n.getString(system ? 'Systemic' : 'Custom')}
          </Checkbox>
        </Tooltip>
      )}
      extra={
        <TemplateUpload system={system} />
      }
      size="small"
      style={{ width: '100%' }}
      bodyStyle={{
        maxHeight: '50vh',
        overflowY: 'auto',
        scrollbarColor: 'rgba(255,255,255,0.5) transparent',
        scrollbarWidth: 'thin',
      }}
    >
      {templates.length
        ? (
          <Checkbox.Group
            onChange={onChange}
            value={checkedList}
            style={{ width: '100%' }}
            disabled={templatesStore.isFetching}
          >
            {templates.map((x) => (
              <TemplateItem
                key={x.id}
                id={x.id}
                name={x.tabletName}
                system={x.system}
              />
            ))}
          </Checkbox.Group>
        )
        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    </Card>
  );
}

export default observer(TemplatesCard);
