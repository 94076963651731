import {
  Button, Upload, message, Tooltip,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { useLocalization } from '@fluent/react';
import { templatesStore } from '../../stores/templatesStore';

type Props = {
  system: boolean
};

function TemplateUpload({ system }: Props) {
  const { l10n } = useLocalization();

  function onChangeHandler(info: UploadChangeParam) {
    if (info.file.status === 'done') {
      message.success(l10n.getString('File-uploaded-successfully', { file: info.file.name }));
    } else if (info.file.status === 'error') {
      message.error(l10n.getString('File-could-not-be-loaded', { file: info.file.name }));
    }
  }

  return (
    <Upload
      accept=".json"
      showUploadList={false}
      customRequest={(option) => templatesStore.upload(option, system)}
      onChange={(info) => onChangeHandler(info)}
    >
      <Tooltip title={l10n.getString('Import-template')}>
        <Button
          loading={templatesStore.isFetching}
          disabled={templatesStore.isFetching}
          icon={<UploadOutlined />}
        />
      </Tooltip>
    </Upload>
  );
}

export default TemplateUpload;
