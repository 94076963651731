import React, { useCallback } from 'react';
import styled, {} from '@emotion/styled';
import { keyframes } from '@emotion/react';

type RotatingLinesProps = {
  width?: string;
  visible?: boolean;
  strokeWidth?: string;
  strokeColor?: string;
  animationDuration?: string;
};

const spin = keyframes`
 to {
    transform: rotate(360deg);
  }
`;

const POINTS = [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330];

const Svg = styled.svg`
  animation: ${spin} 0.75s steps(12, end) infinite;
  animation-duration: 0.75s;
`;

const Polyline = styled.polyline<{ width: string; }>`
  stroke-width: ${(props) => props.width}px;
  stroke-linecap: round;
  &:nth-of-type(12n + 0) {
    stroke-opacity: 0.08;
  }
  &:nth-of-type(12n + 1) {
    stroke-opacity: 0.17;
  }
  &:nth-of-type(12n + 2) {
    stroke-opacity: 0.25;
  }
  &:nth-of-type(12n + 3) {
    stroke-opacity: 0.33;
  }
  &:nth-of-type(12n + 4) {
    stroke-opacity: 0.42;
  }
  &:nth-of-type(12n + 5) {
    stroke-opacity: 0.5;
  }
  &:nth-of-type(12n + 6) {
    stroke-opacity: 0.58;
  }
  &:nth-of-type(12n + 7) {
    stroke-opacity: 0.66;
  }
  &:nth-of-type(12n + 8) {
    stroke-opacity: 0.75;
  }
  &:nth-of-type(12n + 9) {
    stroke-opacity: 0.83;
  }
  &:nth-of-type(12n + 11) {
    stroke-opacity: 0.92;
  }
`;

export default function RotatingLines({
  strokeColor = '#000000',
  strokeWidth = '4',
  animationDuration = '0.75',
  width = '96',
  visible = true,
}: RotatingLinesProps): React.ReactElement | null {
  const lines = useCallback(
    () => POINTS.map((point) => (
      <Polyline
        key={point}
        points="24,12 24,4"
        width={strokeWidth}
        transform={`rotate(${point}, 24, 24)`}
      />
    )),
    [strokeWidth],
  );
  return !visible ? null : (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={width}
      stroke={strokeColor}
      speed={animationDuration}
    >
      {lines()}
    </Svg>
  );
}
