import styled from '@emotion/styled';

export const ScreenTabsWrapper = styled.div`
  display: flex;
  margin: 8px 10px 0;
  gap: 4px;
  height: 32px;
  &:not(:hover) {
    .ScrollbarsCustom-Track {
      opacity: 0;
    }
  }
`;

export const ScreenTabsContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const ScreenTab = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  border: 1px solid ${({ theme }) => theme.borderColor};
  padding: 0 0px 0 4px;
  cursor: pointer;
  background-color: ${({ theme, active }) => (active ? theme.lightPrimary : 'transparent')};
  border-radius: 2px;
  line-height: 22px;
  height: 24px;
`;

export const ScreenTabClose = styled.div`
  font-size: 12px;
  margin-left: 4px;
  width: 16px;
  &:hover {
    color: ${({ theme }) => theme.brightColor};
  }
`;
