/* eslint-disable react/no-array-index-key */
import {
  pdf, Page, Text, View, Document, Font, StyleSheet, Image,
} from '@react-pdf/renderer';
import { ReactLocalization } from '@fluent/react';
import { Orientation } from '@react-pdf/types/page';
import { format } from 'date-fns';
import { TabletType } from '../../../enums/TabletType';
import { getTabletImages } from '../../../toPdf';
import { Tablet } from '../../../stores/Tablet';
import InterRegular from '../../../assets/fonts/Inter-Regular.ttf';
import InterBold from '../../../assets/fonts/Inter-Bold.ttf';
import { scaleUnitStore, themingStore, timeZonesStore } from '../../../stores/init-stores-global';

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: InterRegular,
      fontWeight: 'normal',
    },
    {
      src: InterBold,
      fontWeight: 'bold',
    },
  ],
});
// Create styles
const styles: any = StyleSheet.create({
  page: {
    fontFamily: 'Inter',
    padding: 20,
  },
  section: {
    display: 'flex',
    marginBottom: 10,
  },
  title: {
    fontSize: 8,
    textAlign: 'center',
  },
  light: { backgroundColor: '#FFFFFF', color: '#000000' },
  dark: { backgroundColor: '#0A2333', color: '#A5A5AA' },
  pageNumbers: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 10,
    fontSize: 8,
    textAlign: 'right',
  },
  image: {
    objectFit: 'contain',
    objectPosition: 'top left',
  },
});

type Props = {
  l10n: ReactLocalization;
  tabletType: TabletType;
  onData: number;
  toData: number;
  orientation: Orientation;
  scale: number;
  wellName: number;
  themeName: 'light' | 'dark';
  images: string[];
};

function DocumentPdf({
  l10n, tabletType, onData, toData, orientation, scale, wellName, themeName, images,
}: Props) {
  const timeZone = timeZonesStore.selected;
  return (
    <Document>
      <Page size="A4" orientation={orientation} style={[styles.page, styles[themeName]]} wrap={false}>
        <View style={[styles.section, { justifyContent: 'center' }]}>
          <Text style={[styles.title, { fontSize: 10 }]}>
            {tabletType === TabletType.Time ? l10n.getString('Time-Data-Report') : l10n.getString('Depth-Data-Report')}
          </Text>
          <Text style={[styles.title, { fontSize: 10 }]}>
            {l10n.getString('Well')}
            {': '}
            {wellName}
          </Text>
        </View>
        {tabletType === TabletType.Time && (
        <View style={[styles.section, { justifyContent: 'space-between', flexDirection: 'row' }]} fixed>
          <Text style={styles.title}>
            {l10n.getString('Data')}
            {': '}
            {format(timeZonesStore.getDate(onData), 'yyyy-MM-dd HH:mm:ss')}
            {' '}
            <Text style={{ textTransform: 'lowercase' }}>{l10n.getString('To')}</Text>
            {' '}
            {format(timeZonesStore.getDate(toData), 'yyyy-MM-dd HH:mm:ss')}
            {' '}
            (UTC
            {timeZone < 0 ? timeZone : `+${timeZone}`}
            )
          </Text>
          <Text style={styles.title}>
            {l10n.getString('Scale-step')}
            {': '}
            {scale}
            {' min'}
          </Text>
        </View>
        )}
        {tabletType === TabletType.Depth && (
        <View style={[styles.section, { justifyContent: 'space-between', flexDirection: 'row' }]} fixed>
          <Text style={styles.title}>
            {l10n.getString('Data')}
            {': '}
            {Math.round(onData * scaleUnitStore.kUnit)}
            {' - '}
            {Math.round(toData * scaleUnitStore.kUnit)}
            {' '}
            {scaleUnitStore.scaleUnit}
          </Text>
          <Text style={styles.title}>
            {l10n.getString('Scale-step')}
            {': '}
            {Math.round(scale * scaleUnitStore.kUnit)}
            {' '}
            {scaleUnitStore.scaleUnit}
          </Text>
        </View>
        )}
        {images.map((image, index) => <Image style={styles.image} key={index} source={image} />)}
        <Text
          style={styles.pageNumbers}
          render={({ pageNumber }) => (
            `${pageNumber}`
          )}
          fixed
        />
      </Page>
    </Document>
  );
}

export const downloadPdf = async (params: {
  tablet: Tablet;
  l10n: ReactLocalization;
  tabletType: TabletType;
  onData: number;
  toData: number;
  orientation: Orientation;
  scale: number;
  wellName: number;
  themeName: 'dark' | 'light';
}) => {
  const emotionTheme = params.themeName === 'dark' ? themingStore.emotionThemes.dark : themingStore.emotionThemes.light;

  const images = await getTabletImages(
    params.tablet,
    params.onData,
    params.toData,
    emotionTheme,
    params.scale,
    params.orientation === 'portrait',
  );
  // eslint-disable-next-line react/jsx-props-no-spreading
  const blob = await pdf(<DocumentPdf images={images} {...params} />).toBlob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = `${params.tablet.templateData.well.text}.pdf`;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};
