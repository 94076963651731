import { Theme } from '@emotion/react';

export const theme: Theme = {
  backgroundColor: '#ffffff',
  color: '#626262',
  brightColor: '#303030',
  navbarBackground: '#ffffff',
  borderColor: '#f0f0f0',
  baseBorder: '1px solid #f0f0f0',
  gridColor: '#bfbfbf',
  lightPrimary: '#bfbfbf',
};
