import { makeAutoObservable } from 'mobx';
import { eachOfInterval } from '../utils';
import { Scale } from './Scale';
import { TabletParams } from './TabletParams';

const depthMarks: [number, number][] = [
  [1, 0.2], [5, 1], [10, 2], [50, 10], [25, 5], [100, 20], [500, 100],
  [1000, 20], [2000, 500], [5000, 1000], [10000, 2000], [20000, 5000],
  [25000, 5000], [50000, 10000],
];

export class ScaleDepthLine {
  scale: Scale;

  textHeight: number;

  fixZoom?: [number, number];

  tabletParams: TabletParams;

  constructor(
    scale: Scale,
    textHeight: number,
    tabletParams: TabletParams,
    fixZoom?: [number, number],
  ) {
    makeAutoObservable(this);
    this.scale = scale;
    this.textHeight = textHeight;
    this.tabletParams = tabletParams;
    this.fixZoom = fixZoom;
  }

  get textSize() {
    return this.tabletParams.orientation === 'vertical' ? this.textHeight + 14 : 50;
  }

  get zoom() {
    if (this.fixZoom) {
      return this.fixZoom;
    }
    const goal = depthMarks.find(([textMarks]) => {
      const h = textMarks / (this.scale.dataPerPixel);
      return h > (this.textSize);
    });
    return goal || depthMarks[depthMarks.length - 1];
  }

  get line() {
    const step = this.zoom[1];
    const onDate = Math.trunc((this.scale.onFrame) / step) * step;
    const toDate = (Math.trunc(this.scale.toFrame / step) * step + step);
    return eachOfInterval(onDate, toDate, this.zoom[1], 10);
  }
}
