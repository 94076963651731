import { createContext, ReactNode, useMemo } from 'react';
import { LocalizationStore } from '../../stores/localizationStore';
import { ScaleUnitStore } from '../../stores/scaleUnitStore';
import { Tablet } from '../../stores/Tablet';
import { TimeZonesStore } from '../../stores/timeZonesStore';
import { ThemeHex } from '../../types/ThemeHex';

export type TabletContextType = {
  tablet: Tablet;
  themeHex: ThemeHex;
  localizationStore: LocalizationStore;
  timeZonesStore: TimeZonesStore;
  scaleUnitStore: ScaleUnitStore;
};

export const TabletContext = createContext<TabletContextType>({} as TabletContextType);

export type TabletProviderProps = {
  tablet: Tablet;
  children: ReactNode;
  themeHex: ThemeHex;
  localizationStore: LocalizationStore;
  timeZonesStore: TimeZonesStore;
  scaleUnitStore: ScaleUnitStore;
};

export function TabletProvider({
  children, tablet, localizationStore, themeHex, timeZonesStore, scaleUnitStore,
}: TabletProviderProps) {
  const data = useMemo<TabletContextType>(() => ({
    tablet,
    localizationStore,
    timeZonesStore,
    scaleUnitStore,
    themeHex,
  }), [
    tablet,
    localizationStore,
    timeZonesStore,
    scaleUnitStore,
    themeHex,
  ]);
  return (
    <TabletContext.Provider value={data}>
      {children}
    </TabletContext.Provider>
  );
}
