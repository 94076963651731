import chroma from 'chroma-js';
import { CurveInfo } from '../api/curvesCache';
import { SourceDto } from '../api/dto/SourceDto';

export function curveInfoToCurveParams(info: CurveInfo) {
  const source = new SourceDto();
  source.color = chroma.random().hex();
  source.lineWidth = 2;
  source.lineType = 0;
  source.currentUom = info.unit;
  source.ratioUom = 1;
  source.minVal = info.minValue;
  source.maxVal = info.maxValue;
  source.pos = 0;
  source.dynamicRange = true;
  source.name = info.mnemonic;
  source.externalId = info.id;
  source.namePattern = `/^${info.mnemonic}$`;
  return source;
}
