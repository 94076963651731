type Events = {
  WakeUp: number
};
type Key = keyof Events;
type MessageFn = (message: Events[Key]) => void;

class SleepDetect {
  handlers: { [key: string]: (MessageFn)[] } = {};

  constructor() {
    this.publish = this.publish.bind(this);
    function workerSource() {
      let lastTime = (new Date()).getTime();
      const checkInterval = 1000;

      setInterval(() => {
        const currentTime = (new Date()).getTime();

        if (currentTime > (lastTime + checkInterval * 20)) { // ignore small delays
          postMessage('wakeup');
        }

        lastTime = currentTime;
      }, checkInterval);
    }
    // @ts-ignore
    const blob = new Blob(['(', workerSource, ')(self);'], { type: 'application/javascript' });
    const url = URL.createObjectURL(blob);
    const worker = new Worker(url);
    URL.revokeObjectURL(url);
    const { publish } = this;
    worker.onmessage = function onessage(ev) {
      if (ev && ev.data === 'wakeup') {
        publish('WakeUp', Date.now());
      }
    };
  }

  publish(event: keyof Events, msg: Events[Key]) {
    if (this.handlers[event]) {
      this.handlers[event].forEach((h) => h(msg));
    }
  }

  subscribe<K extends keyof Events>(
    event: K,
    callback: (message: Events[K]) => void,
  ) {
    const list = this.handlers[event] ?? [];
    list.push(callback as MessageFn);
    this.handlers[event] = list;
  }

  unsubscribe<K extends keyof Events>(
    event: K,
    callback: (message: Events[K]) => void,
  ) {
    let list = this.handlers[event] ?? [];
    list = list.filter((h) => h !== callback);
    this.handlers[event] = list;
  }
}

export const sleepDetect = new SleepDetect();
