import { action, makeAutoObservable } from 'mobx';
import { ScalePosition } from './ScalePosition';
import { TabletParams } from './TabletParams';
import { TabletSizes } from './TabletSizes';

type VerticalParams = {
  dragging: boolean;
  pageY: number;
  startTop: number;
};

const MIN_SIZE = 30;

export class TabletScroll {
  tabletParams: TabletParams;

  tabletSizes: TabletSizes;

  scalePosition: ScalePosition;

  scrollWidth: number = 8;

  scrollTop: number = 0;

  scrollLeft: number = 0;

  startTop = 0;

  verticalParams: VerticalParams = {
    dragging: false,
    pageY: 0,
    startTop: 0,
  };

  constructor(tabletParams: TabletParams, tabletSizes: TabletSizes, scalePosition: ScalePosition) {
    makeAutoObservable(this, {
      setScrollTop: action.bound,
      setVerticalParams: action.bound,
    });
    this.tabletParams = tabletParams;
    this.tabletSizes = tabletSizes;
    this.scalePosition = scalePosition;
  }

  get height() {
    return this.tabletParams.canvasSize[1] - this.scalePosition.height;
  }

  get scrollHeight() {
    return this.tabletSizes.tabletHeight;
  }

  get spinHeight() {
    const k = this.height / this.scrollHeight;
    let baseHeight = this.height * k;
    baseHeight = Math.max(baseHeight, MIN_SIZE);
    return Math.floor(baseHeight);
  }

  get enableScrollRange() {
    const { scrollHeight, height } = this;
    return scrollHeight - height || 0;
  }

  get enableHeightRange() {
    return this.height - this.spinHeight - 6 || 0;
  }

  get top() {
    const { scrollTop, enableScrollRange, enableHeightRange } = this;
    if (scrollTop === 0 || enableScrollRange === 0) {
      return 0;
    }
    const ptg = scrollTop / enableScrollRange;
    return ptg * enableHeightRange;
  }

  get scrollbarX() {
    return this.tabletSizes.tabletWidth - this.scrollWidth;
  }

  setScrollTop(scrollTop: number) {
    this.scrollTop = scrollTop;
  }

  setVerticalParams(params: Partial<VerticalParams>) {
    Object.assign(this.verticalParams, params);
  }

  keepInRange(newScrollTop: number) {
    let newTop = newScrollTop;
    newTop = Math.min(newTop, this.enableScrollRange);
    newTop = Math.max(newTop, 0);
    return newTop;
  }

  syncScrollTop() {
    const alignedTop = this.keepInRange(this.scrollTop);
    this.setScrollTop(alignedTop);
  }
}
