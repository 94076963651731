import { useTheme } from '@emotion/react';
import { useRef } from 'react';
import { Track } from '../../stores/Tracks';
import { round } from '../../utils';
import { useThrottle } from '../../hooks/useThrottle';
import { LineProps } from './types';
import { CurveStatus } from '../../stores/SourceDataMap';
import { CurveCoordinates } from '../../stores/CoordinatesStorages/CurveCoordinates';
import { DepthCoordinates } from '../../stores/CoordinatesStorages/DepthCoordinates';

export function useLines(
  currentFrom: number | null,
  currentTo: number | null,
  tracks: Track[],
): (LineProps | null)[] {
  const theme = useTheme();
  const currentLines = useRef<(LineProps | null)[]>([]);
  const getLines = useThrottle((from: number, to: number) => {
    const [start, end] = from > to ? [to, from] : [from, to];
    const lines = tracks.flatMap((track) => track.sourcesSorted.map((source) => {
      if (source.sourceData == null || source.sourceData.status === CurveStatus.NoData) {
        return null;
      }

      if (source.sourceData.curveCoordinates instanceof CurveCoordinates
        || source.sourceData.curveCoordinates instanceof DepthCoordinates) {
        const slice = source.sourceData?.curveCoordinates.dataSlice(start, end);
        if (slice == null) {
          return null;
        }

        let type:'point' | 'hatch' = 'point';
        if (source.sourceData.curveCoordinates instanceof CurveCoordinates) {
          type = source.sourceData.curveCoordinates.closest.coordinates.pointType;
        }

        return {
          firstKey: slice.firstKey,
          lastKey: slice.lastKey,
          firstValue: round(
            slice.firstValue * source.params.ratioUom,
            source.params.fractionDigits,
          ),
          lastValue: round(
            slice.lastValue * source.params.ratioUom,
            source.params.fractionDigits,
          ),
          delta: round(
            Math.abs(slice.lastValue - slice.firstValue) * source.params.ratioUom,
            source.params.fractionDigits,
          ),
          min: round(
            slice.min * source.params.ratioUom,
            source.params.fractionDigits,
          ),
          max: round(
            slice.max * source.params.ratioUom,
            source.params.fractionDigits,
          ),
          average: round(
            (slice.sum / slice.count) * source.params.ratioUom,
            source.params.fractionDigits,
          ),
          color: source.params.color || theme.color,
          mnemonic: source.sourceDto.name,
          innerId: source.innerId,
          type,
        };
      }

      return null;
    }).filter((i) => !!i));
    currentLines.current = lines;
  }, 100, [tracks]);
  if (tracks.length === 0 || currentFrom == null || currentTo == null) {
    return [];
  }
  getLines(currentFrom, currentTo);
  return currentLines.current;
}
