/* eslint-disable no-nested-ternary */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
import * as PIXI from 'pixi.js';

export function getTextWidth(
  text: string,
  style: PIXI.TextStyle | Partial<PIXI.ITextStyle> | undefined,
) {
  if (style instanceof PIXI.TextStyle) {
    return PIXI.TextMetrics.measureText(text, style).width;
  }
  const s = new PIXI.TextStyle(style);
  return PIXI.TextMetrics.measureText(text, s).width;
}

export function inversionColor(hex: string) {
  let newHex = hex;
  if (newHex.length < 7) {
    if (newHex.length === 0) {
      newHex = '#';
    }

    for (let i = newHex.length; i < 7; i += 1) {
      newHex += 0;
    }
  }

  const color = newHex.match(/^#?([\dabcdef]{2})([\dabcdef]{2})([\dabcdef]{2})$/i);
  if (!color) return newHex;
  let result = '#';

  for (let i = 1; i <= 3; i += 1) { result += (255 - parseInt(color[i], 16)).toString(16).toUpperCase().replace(/^(.)$/, '0$1'); }
  return result;
}

export function eachOfInterval(start: number, end: number, period: number, safeNumber = 1) {
  const ans = [];
  for (let i = start; i <= end; i += period) {
    if (safeNumber) {
      ans.push(Math.round(i * safeNumber) / safeNumber);
    } else {
      ans.push(i);
    }
  }
  return ans;
}

export function isBetween(value: number, min: number, max: number, boundaries = true) {
  return boundaries ? (min <= value) && (value <= max) : (min < value) && (value < max);
}

export function isTargetInRange(
  target: [number, number],
  array: [number, number],
  boundaries = true,
) {
  return (isBetween(target[0], array[0], array[1], boundaries)
    && isBetween(target[1], array[0], array[1], boundaries));
}

export function isRangeCrossed(
  target: [number, number],
  array: [number, number],
  boundaries = true,
) {
  return (isBetween(target[0], array[0], array[1], boundaries)
    || isBetween(target[1], array[0], array[1], boundaries))
  || (isBetween(array[0], target[0], target[1], boundaries)
    || isBetween(array[1], target[0], target[1], boundaries));
}

export function lightenColor(color: string, percent: number) {
  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const B = (num >> 8 & 0x00FF) + amt;
  const G = (num & 0x0000FF) + amt;

  return (0x1000000
    + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000
    + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100
    + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
}

export class Range {
  start: number;

  end: number;

  constructor(start: number, end: number) {
    this.start = start;
    this.end = end;
  }

  in(target: number, boundaries = true) {
    return boundaries
      ? (this.start <= target) && (target <= this.end)
      : (this.start < target) && (target < this.end);
  }

  isTargetIn(target: Range, boundaries = true) {
    return (this.in(target.start, boundaries)
      && this.in(target.end, boundaries));
  }

  isRangeCrossed(range: Range, boundaries = true) {
    return (this.in(range.start, boundaries)
      || this.in(range.end, boundaries))
      || (range.in(this.start, boundaries)
      || range.in(this.end, boundaries));
  }

  getExternalSegments(external: Range): [Range | null, Range | null] {
    const inner = this;
    let startSegment: Range | null = null;
    let endSegment: Range | null = null;
    if (!inner.isRangeCrossed(external, false)) {
      return [null, null];
    }
    if (external.start < inner.start) {
      startSegment = new Range(external.start, inner.start);
    }
    if (external.end > inner.end) {
      endSegment = new Range(inner.end, external.end);
    }
    return [startSegment, endSegment];
  }

  equal(range: Range) {
    return this.start === range.start && this.end === range.end;
  }

  intersection(b: Range) {
    const a = this;
    const min = (a.start < b.start ? a : b);
    const max = (min === a ? b : a);

    // min ends before max starts -> no intersection
    if (min.end < max.start) { return null; } // the ranges don't intersect

    return new Range(max.start, (min.end < max.end ? min.end : max.end));
    // const one =
    // return new Range();
  }

  get byArray() {
    return [this.start, this.end];
  }

  get positive() {
    if (this.start > this.end) {
      return [this.end, this.start];
    }
    return [this.start, this.end];
  }

  static group(list: Range[]) {
    if (list.length === 0) {
      return null;
    }
    const start = Math.min(...list.map((r) => r.start));
    const end = Math.max(...list.map((r) => r.end));
    return new Range(start, end);
  }
}

export function waitPromise(delay: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export function getUtc(date: number) {
  return date + new Date().getTimezoneOffset() * 60000;
}

export function round(num: number, fractionDigits?: number | null) {
  if (typeof num !== 'number') {
    return num;
  }
  if (typeof fractionDigits === 'number') {
    return num.toFixed(fractionDigits);
  }
  if (Number.isInteger(num)) {
    return num.toString();
  }
  if (Math.trunc(num) === 0) {
    const str = num.toFixed(20).split('.')[1];
    const i = Array.from(str).findIndex((ch) => ch !== '0') + 2;
    return i === -1 ? num.toString() : (Math.round(num * (10 ** i)) / (10 ** i)).toString();
  }
  if (Math.abs(num) < 10) {
    return (Math.round(num * 100) / 100).toString();
  }
  return (Math.round(num * 10) / 10).toString();
}

export function debounce<T extends Function>(cb: T, wait = 20) {
  let h = 0;
  const callable = (...args: any) => {
    clearTimeout(h);
    h = setTimeout(() => cb(...args), wait) as any;
  };
  return callable as unknown as T;
}

interface Cancel {
  cancel: () => void;
}

interface NoReturn<Args extends unknown[]> {
  (...args: Args): void
}

export function throttle<Args extends unknown[]>(
  fn: (...args: Args) => void,
  cooldown: number,
): Cancel & NoReturn<Args> {
  let lastArgs: Args | undefined;

  const run = () => {
    if (lastArgs) {
      fn(...lastArgs);
      lastArgs = undefined;
    }
  };

  let timeoutId: number;

  function throttled(...args: Args) {
    const isOnCooldown = !!lastArgs;

    lastArgs = args;

    if (isOnCooldown) {
      return;
    }

    timeoutId = window.setTimeout(run, cooldown);
  }

  // @ts-ignore
  throttled.cancel = function Cancel() {
    clearTimeout(timeoutId);
  };

  return throttled as Cancel & NoReturn<Args>;
}

export const memoizePromiseFn = <Args extends unknown[], R extends unknown>
  (fn: (...args: Args) => Promise<R>) => {
  const cache = new Map<string, Promise<R>>();

  return (...args: Args) => {
    const key = JSON.stringify(args);

    if (cache.has(key)) {
      return cache.get(key)!;
    }

    cache.set(key, fn(...args).catch((error: any) => {
      cache.delete(key);
      return Promise.reject(error);
    }));

    return cache.get(key)!;
  };
};

export function immutableSplice<T>(arr: T[], start: number, deleteCount: number, ...addItem: T[]) {
  const result = [];
  if (start > 0) {
    result.push(...arr.slice(0, start));
  }
  result.push(...addItem);
  const len = result.length - addItem.length;
  const count = deleteCount <= 0 ? len : len + deleteCount;
  if (arr[count]) {
    result.push(...arr.slice(count));
  }
  return result;
}

export function allPointsUnique<T extends { key: number; }>(points: Array<T>) {
  const length1 = points.length;
  const length2 = Array.from(new Set(points.map((p) => p.key))).length;
  return length1 === length2;
}

export function pointsOutOfOrder<T extends { key: number; }>(points: Array<T>):[string, T?, T?] {
  for (let i = 0; i < points.length; i += 1) {
    const next = points.at(i + 1);
    if (next && points[i].key > next.key) {
      return ['yes, not good', points[i], next];
    }
  }
  return ['is fine'];
}

export function getWebsocketAddress(urn?: string) {
  const loc = window.location;
  let newUri = '';
  if (loc.protocol === 'https:') {
    newUri = 'wss:';
  } else {
    newUri = 'ws:';
  }
  newUri += `//${loc.host}`;
  if (urn) {
    newUri += urn;
  }
  return newUri;
}

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;
