import { DragOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { Tablet } from '../../stores/Tablet';
import { Track } from '../../stores/Tracks';

type Props = {
  tablet: Tablet;
  offset: number;
  track: Track;
};

const SwitcherButton = styled(Button)`
  position: absolute;
  transition: none;
`;

export const TrackSwitcher = observer<Props>(({ tablet, offset, track }) => {
  const buttonRef = useRef<HTMLDivElement>(null);

  function patchEvents() {
    window.addEventListener('mouseup', onMouseUp);

    if (buttonRef.current) {
      buttonRef.current.addEventListener('touchend', onMouseUp);
    }
  }

  function removeEvents() {
    window.removeEventListener('mouseup', onMouseUp);

    if (buttonRef.current) {
      buttonRef.current.removeEventListener('touchstart', onMouseDown);
      buttonRef.current.removeEventListener('touchend', onMouseUp);
    }
  }

  const offsetY = tablet.tabletScroll.height + tablet.tabletScroll.scrollTop
    - offset - track.params.trackWidth / 2 - 16;
  let left = tablet.params.orientation === 'vertical'
    ? offset + track.params.trackWidth / 2 - 16 - tablet.tabletScroll.scrollLeft
    : tablet.params.canvasSize[0] / 2 - 16;
  let top = tablet.params.orientation === 'horizontal'
    ? offsetY
    : tablet.params.canvasSize[1] / 2 - 16;

  function onMouseDown(e: React.MouseEvent | TouchEvent) {
    tablet.coordinator.setDragTrack(track);
    tablet.coordinator.setPoint(left + 16, top + 16);

    patchEvents();
    e.stopPropagation();
    e.preventDefault();
  }

  function onMouseUp() {
    tablet.coordinator.setDragTrack(null);
    removeEvents();
  }

  if (tablet.coordinator.dragTrack === track) {
    if (tablet.params.orientation === 'vertical') {
      left = tablet.coordinator.x - 16;
    } else {
      top = tablet.coordinator.y - 16;
    }
  }

  return (
    <SwitcherButton
      ref={buttonRef}
      icon={<DragOutlined />}
      style={{
        left,
        top,
        pointerEvents: tablet.coordinator.dragTrack === track ? 'none' : undefined,
      }}
      onMouseDown={(e) => onMouseDown(e)}
    />
  );
});
