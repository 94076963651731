import styled from '@emotion/styled';

export const Tooltip = styled.div`
  padding: 4px;
  background-color: ${(props) => props.theme.backgroundColor};
  font-size: 14px;
  border: ${(props) => props.theme.baseBorder};
  z-index: 1000;
  pointer-events: none;
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  gap: 4px;
  line-height: 1;
`;

export const TooltipLine = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  display: contents;
`;

export const TooltipLineValue = styled.div<{ minWidth: number }>`
  text-align: right;
  min-width: ${({ minWidth }) => `${minWidth}px`};
  white-space: nowrap;
`;
