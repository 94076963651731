import { memo } from 'react';
import { TooltipLine, TooltipLineValue } from './CoordinatorTooltip.styled';
import { LineProps } from './types';

export const CoordinatorTooltipBody = memo(
  ({ lines }: { lines: (LineProps | null)[] }) => (
    <>
      {lines.map((line) => line && (
        <TooltipLine color={line.color} key={line.key}>
          <div>
            {line.name}
          </div>
          <TooltipLineValue minWidth={line.minWidth}>
            {line.value}
          </TooltipLineValue>
          <div>
            {line.currentUom}
          </div>
        </TooltipLine>
      ))}
    </>
  ),
);
