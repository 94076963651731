import { useLocalization } from '@fluent/react';
import { Button, notification } from 'antd';
import { createCurve } from '../../../api/curvesCache';
import { ObjectDto } from '../../../api/dto/ObjectDto';
import promptAction from '../../../prompt';
import { folderTreeStore } from '../../../stores/folderTree';

export function CreateCurve({ well }: { well: ObjectDto }) {
  const { l10n } = useLocalization();

  function create() {
    promptAction({
      title: l10n.getString('Create-curve'),
      placeholder: l10n.getString('Name'),
      rules: [{ required: true, message: l10n.getString('TheNameCannotBeEmpty') }],
      modalProps: {
        okText: l10n.getString('Create'),
        cancelText: l10n.getString('Cancel'),
      },
    }).then(async (str = '') => {
      try {
        await createCurve(well.externalId, str);
        await folderTreeStore.fetchChildren(well.externalId);
        notification.success({
          placement: 'bottomRight',
          message: l10n.getString('Create-curve-success'),
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        notification.error({
          placement: 'bottomRight',
          message: l10n.getString((e as Error).message, { name: str }),
        });
      }
    });
  }
  return <Button size="small" onClick={() => create()}>{l10n.getString('Create-curve')}</Button>;
}
