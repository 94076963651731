import * as PIXI from 'pixi.js';
import { Sprite, Text } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Tablet } from '../../stores/Tablet';
import { TabletContext } from './TabletProvider';
import { ScaleUnitStore } from '../../stores/scaleUnitStore';

type ScalePointType = {
  tablet: Tablet;
  textStyle: PIXI.TextStyle;
  value: number;
  height: number;
  topOffset: number;
  bottomOffset: number;
  zoom: [number, number];
};

function ScalePointVertical({
  tablet, textStyle, value, height, topOffset, bottomOffset, zoom,
}: ScalePointType) {
  const { themeHex, scaleUnitStore } = useContext(TabletContext);
  const y = tablet.scale.dataToPoint(value) - tablet.scale.offsetY;
  if (y >= topOffset && y <= (height - bottomOffset)) {
    return (
      <>
        {value % zoom[0] === 0 && (
        <Text
          key={value}
          text={pointFormat(value, scaleUnitStore)}
          anchor={[1, 0.5]}
          x={tablet.scalePosition.width - 10}
          y={y - 1}
          style={textStyle}
        />
        )}
        <Sprite
          key={`${value}-sprite`}
          x={tablet.scalePosition.width - 1}
          y={y}
          height={1}
          width={value % zoom[0] === 0 ? 7 : 4}
          anchor={[1, 0.5]}
          texture={PIXI.Texture.WHITE}
          tint={themeHex.borderColor}
        />
      </>
    );
  }
  return null;
}
function ScalePointHorizontal({
  tablet, textStyle, value, height, topOffset, bottomOffset, zoom,
}: ScalePointType) {
  const { themeHex, scaleUnitStore } = useContext(TabletContext);
  const x = tablet.scale.dataToPoint(value) - tablet.scale.offsetY;
  if (x >= topOffset && x <= (height - bottomOffset)) {
    return (
      <>
        {value % zoom[0] === 0 && (
        <Text
          key={value}
          text={pointFormat(value, scaleUnitStore)}
          anchor={[0.5, 0]}
          y={tablet.scalePosition.y + 10}
          x={x - 1}
          style={textStyle}
        />
        )}
        <Sprite
          key={`${value}-sprite`}
          y={tablet.scalePosition.y + 1}
          x={x}
          width={1}
          height={(value % zoom[0]) === 0 ? 7 : 4}
          anchor={[0.5, 0]}
          texture={PIXI.Texture.WHITE}
          tint={themeHex.borderColor}
        />
      </>
    );
  }
  return null;
}

type Props = {
  tablet: Tablet;
};

export const DepthScale = observer(({ tablet }: Props) => {
  const { themeHex, scaleUnitStore } = useContext(TabletContext);

  const { fontSize } = tablet.baseTextStyle;

  const bd = tablet.mode === 'tablet';

  const headerOffset = bd ? tablet.minHeaderHeight : tablet.headerHeight;
  const topOffset = headerOffset + (bd ? fontSize * 1.5 : 0);
  const bottomOffset = (bd ? fontSize : 0) + (fontSize / 2);

  const textStyle = new PIXI.TextStyle(tablet.baseTextStyle);
  textStyle.fill = themeHex.color;

  return (
    <>
      {
        tablet.params.orientation === 'vertical'
          ? tablet.scaleLine.line.map((value) => (
            <ScalePointVertical
              key={value}
              tablet={tablet}
              value={value}
              textStyle={textStyle}
              height={tablet.scale.containerLength}
              topOffset={topOffset}
              bottomOffset={bottomOffset}
              zoom={tablet.scaleLine.zoom}
            />
          )) : tablet.scaleLine.line.map((value) => (
            <ScalePointHorizontal
              key={value}
              tablet={tablet}
              value={value}
              textStyle={textStyle}
              height={tablet.scale.containerLength}
              topOffset={topOffset}
              bottomOffset={bottomOffset}
              zoom={tablet.scaleLine.zoom}
            />
          ))
      }
      <Sprite
        x={tablet.params.orientation === 'vertical' ? tablet.scalePosition.tracksOffset - 2 : 0}
        y={tablet.params.orientation === 'vertical' ? 0 : tablet.scalePosition.y - 2}
        height={tablet.params.orientation === 'vertical' ? tablet.params.canvasSize[1] : 2}
        width={tablet.params.orientation === 'vertical' ? 2 : tablet.params.canvasSize[0]}
        texture={PIXI.Texture.WHITE}
        tint={themeHex.gridColor}
      />
      {bd && (
        <>
          <Text
            key="onDataDB"
            text={formatDBPoint(tablet.onDataDB || 0, scaleUnitStore)}
            anchor={[1, 0]}
            x={tablet.scalePosition.x - 10}
            y={headerOffset}
            style={new PIXI.TextStyle({
              ...tablet.baseTextStyle,
              fill: '#FF0000',
            })}
          />
          <Text
            key="toDataDB"
            text={formatDBPoint(tablet.toDataDB || 0, scaleUnitStore)}
            anchor={[1, 1]}
            x={tablet.scalePosition.x - 10}
            y={tablet.scale.containerLength}
            style={new PIXI.TextStyle({
              ...tablet.baseTextStyle,
              fill: '#FF0000',
            })}
          />
        </>
      )}
    </>
  );
});

function pointFormat(value: number, scaleUnit: ScaleUnitStore) {
  return (value * scaleUnit.kUnit).toFixed(0);
}

function formatDBPoint(value: number, scaleUnit: ScaleUnitStore) {
  return (value * scaleUnit.kUnit).toFixed(1);
}
