/* eslint-disable no-continue */
import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocalization } from '@fluent/react';
import chroma from 'chroma-js';
import { Curve } from '../../../stores/Tracks';
import { Tablet } from '../../../stores/Tablet';
import { Blocks } from './Blocks';
import { inversionColor } from '../../../utils';
import kpiMap from '../../../dictionaries/kpiMap.json';
import { SyntheticCoordinates } from '../../../stores/CoordinatesStorages/SyntheticCoordinates';

type Block = {
  key: number;
  y: number;
  blockHeight: number;
  name: string;
  background: string;
  opacity: number;
  textColor: string;
};

type Props = {
  source: Curve;
  tablet: Tablet,
  width: number,
  offsetX?: number;
  coordinates: SyntheticCoordinates;
};

export const Kpi = observer(({
  tablet, width, offsetX = 0, coordinates,
}: Props) => {
  const { l10n } = useLocalization();
  const { offsetY: scalerOffsetY, zeroPointKey, yRatio } = tablet.scale;
  const coords = coordinates.points;

  const toDrawBlocks = useMemo(() => {
    const blocks: Block[] = [];
    const offsetY = scalerOffsetY + zeroPointKey * yRatio;
    const lastCoord: { y1?: number; y2?: number } = {};
    for (let i = 0; i < coords.length; i += 1) {
      const next = coords.at(i + 1);
      if (!next) return blocks;
      const lastY = next.key;
      const blockHeight = Math.ceil((lastY - coords[i].key) * yRatio);
      const y = Math.ceil(coords[i].key * yRatio - offsetY);

      if (y + blockHeight < 0 || y > tablet.scale.containerLength) continue;

      if (lastCoord.y1 === y && lastCoord.y2 === y + blockHeight) continue;

      lastCoord.y1 = y;
      lastCoord.y2 = y + blockHeight;

      if (!kpiMap[coords[i].value]) {
        blocks.push({
          key: coords[i].key,
          y,
          blockHeight,
          name: String(coords[i].value),
          background: '#FFFFFF',
          opacity: 0,
          textColor: '#000000',
        });
      } else {
        const { message } = kpiMap[coords[i].value];

        const color = chroma(kpiMap[coords[i].value].color ?? '#ff0000');

        if (!message) {
        // eslint-disable-next-line no-console
          console.warn('unknown kpi type', coords[i].value);
        }

        blocks.push({
          key: coords[i].key,
          y,
          blockHeight,
          name: message ? l10n.getString(message) : '',
          background: color.hex('rgb'),
          opacity: color.rgba()[3],
          textColor: inversionColor(color.hex()),
        });
      }
    }
    return blocks;
  }, [coords, l10n, scalerOffsetY, zeroPointKey, yRatio, tablet.scale.containerLength]);

  return (
    <Blocks
      blocks={toDrawBlocks}
      width={width}
      offsetX={offsetX}
      height={tablet.scale.containerLength}
      tabletOrientation={tablet.params.orientation}
    />
  );
});
