import { useLocalization } from '@fluent/react';
import { Button } from 'antd';
import { resetCurve } from '../../../api/curvesCache';
import { ObjectDto } from '../../../api/dto/ObjectDto';

export function PropCurve({ well }: { well: ObjectDto }) {
  const {
    objectTypeName, externalId, startDate, lastDate, startDepth, lastDepth,
  } = well;
  const { l10n } = useLocalization();
  function reset() {
    resetCurve(externalId);
  }
  return (
    <div>
      <div>
        {objectTypeName}
        {' '}
        [
        {externalId}
        ]
      </div>
      <div>
        <span>
          Samples
        </span>
        <div style={{ marginBottom: 8 }}>
          {startDate && lastDate && (
          <>
            <span>
              {l10n.getString('From')}
              {' '}
              {startDate.toLocaleString()}
            </span>
            <span>
              {' '}
              {l10n.getString('To')}
              {' '}
              {lastDate.toLocaleString()}
            </span>
          </>
          )}
          {startDepth && lastDepth && (
          <>
            <span>
              {l10n.getString('From')}
              {' '}
              {startDepth}
            </span>
            <span>
              {l10n.getString('To')}
              {' '}
              {lastDepth}
            </span>
          </>
          )}
        </div>
      </div>
      <Button onClick={() => reset()}>
        {l10n.getString('ResetCache')}
      </Button>
    </div>
  );
}
