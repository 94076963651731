import { makeAutoObservable } from 'mobx';
import { CommentDto, CommentPoint } from '../../api/curvesCache';
import { CommentsController } from './CommentsController';

export class Comment {
  sourceId: number;

  key: number;

  isOpen = false;

  controller: CommentsController;

  disabled: boolean;

  params: CommentDto = {
    text: '',
    title: '',
    length: 0,
  };

  constructor(
    point: CommentPoint,
    controller: CommentsController,
    sourceId: number,
    disabled: boolean,
  ) {
    makeAutoObservable(this);
    this.key = point.key;
    this.controller = controller;
    this.sourceId = sourceId;
    this.disabled = disabled;
    try {
      const dataComment = JSON.parse(point.value) as CommentDto;
      Object.assign(this.params, dataComment);
    } catch (e) {
      this.params.text = String(point.value);
    }
  }

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  edit() {
    this.controller.editComment(
      {
        curveId: this.sourceId,
        key: this.key,
        comment: this.params,
      },
      this.disabled,
    );
  }
}

export class SourceComments {
  sourceId: number;

  comments: Comment[] = [];

  constructor(
    sourceId: number,
    points: CommentPoint[],
    controller: CommentsController,
    disabled: boolean,
  ) {
    makeAutoObservable(this);
    this.sourceId = sourceId;
    this.comments = points
      .sort((a, b) => a.key - b.key)
      .map((point) => new Comment(point, controller, sourceId, disabled));
  }
}
