import { observer } from 'mobx-react-lite';
import { Button } from 'antd';
import styled from '@emotion/styled';
import { scaleUnitStore } from '../../stores/init-stores-global';

const ButtonContainer = styled.div`
  display: inline-block;
`;

type Props = {
  style?: React.CSSProperties;
  collapsed: boolean;
};

export const ScaleUnitSwitcher = observer(({ style, collapsed }: Props) => (
  <ButtonContainer style={style}>
    <Button
      onClick={() => scaleUnitStore.saveScaleUnit(scaleUnitStore.scaleUnit === 'm' ? 'ft' : 'm')}
      loading={scaleUnitStore.inProgress}
      disabled={scaleUnitStore.inProgress}
      size="small"
      block
    >
      {collapsed ? scaleUnitStore.scaleUnit : scaleUnitStore.fullName}
    </Button>
  </ButtonContainer>
));
