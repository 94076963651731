import {
  Context as ContextType, memo, ReactNode, useContext,
} from 'react';

type Props = {
  contexts: ContextType<any>[];
  values: any[];
  children: ReactNode;
};

const ContextListener = memo(({ context, values, index }: {
  context: ContextType<any>;
  values: object[];
  index: number;
}) => {
  // eslint-disable-next-line no-param-reassign
  values[index] = useContext(context);
  return null;
});

export function ContextListeners({
  contexts,
  values,
}: {
  contexts: ContextType<any>[];
  values: any[];
}) {
  return (
    <>
      {contexts.map(
        (context, index) => (
          <ContextListener
            key={context.displayName || index}
            context={context}
            values={values}
            index={index}
          />
        ),
      )}
    </>
  );
}

export function ContextProviders({
  contexts,
  values,
  children,
}: Props): JSX.Element {
  if (!contexts || !values) {
    return children as JSX.Element;
  }

  return contexts.reduce(
    (child, Context, index) => (
      <Context.Provider value={values[index]}>
        {child}
      </Context.Provider>
    ),
    children as JSX.Element,
  );
}
