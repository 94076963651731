import { axiosApiInstance as axios } from './axiosApiInstance';
import { memoizePromiseFn } from '../utils';

export type UomListItem = {
  baseUomUid: string;
  isBase: boolean;
  symbol: string;
  Uid: string;
};

export async function getListUoms(uom: string) {
  return axios.get<{ result: UomListItem[] }>('/Curve/GetListUoms', {
    params: { uom },
  }).then(({ data }) => data.result);
}

export async function getRatioUom(
  currentUom: string,
  selectedUom: string,
) {
  return axios.get<{ result: number }>('/Curve/GetRatioUom', {
    params: { currentUom, selectedUom },
  }).then(({ data }) => data.result);
}

export const getRatioUomMemo = memoizePromiseFn(getRatioUom);
