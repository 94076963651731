import { useEffect, useState } from 'react';
import { useDragDropManager, XYCoord } from 'react-dnd';
import { createPortal } from 'react-dom';
import { objectDictionary } from '../../dictionary';
import { FolderObject } from '../../stores/folderTree';
import {
  NodeDragBlock, TreeContentWrapper, TreeFolderImage, TreeIconWrapper,
} from './FolderTree.styled';

export function DragNode() {
  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();

  const itemType = monitor.getItemType();

  const draggableItem: FolderObject | null = itemType === 'well' ? monitor.getItem()?.folderNode ?? null : null;

  const [offset, setOffset] = useState<XYCoord | null>(null);
  useEffect(() => {
    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      setOffset(monitor.getClientOffset());
    });
    return () => {
      unsubscribe();
    };
  }, [monitor]);
  const meta = draggableItem ? objectDictionary[draggableItem.objectTypeId] : null;
  return draggableItem && createPortal(
    (
      <NodeDragBlock currentOffset={offset}>
        <TreeContentWrapper>
          <TreeIconWrapper>
            {meta && (
              <TreeFolderImage
                src={meta.iconImage?.(draggableItem.object.typeExt)}
              />
            )}
          </TreeIconWrapper>
          {draggableItem.object.text}
        </TreeContentWrapper>
      </NodeDragBlock>
    ), document.body,
  );
}
