import { observer } from 'mobx-react-lite';
import { Tablet } from '../../stores/Tablet';
import { TracksBlock } from './TabletTracksInfo.styled';
import { TrackInfo } from './TrackInfo';
import { ContextMenuStore } from '../../stores/ContextMenu';
import { DragHeader } from './DragHeader';
import { Track } from '../../stores/Tracks';

export const TabletTracksInfo = observer(
  ({ tablet, contextMenu, position }: { tablet: Tablet; contextMenu: ContextMenuStore; position: 'top' | 'bottom' }) => {
    const size = position === 'top' ? tablet.headerHeightTop : tablet.headerHeightBottom;

    return (
      <TracksBlock style={{
        width: tablet.params.orientation === 'horizontal'
          ? size
          : tablet.tracks.tracksWidth,
        height: tablet.params.orientation === 'vertical'
          ? size
          : tablet.params.canvasSize[1] - tablet.scalePosition.height,
        top: tablet.params.orientation === 'horizontal' || position === 'top' ? 0 : 'auto',
        bottom: tablet.params.orientation === 'horizontal' || position === 'top' ? 'auto' : 0,
        left: tablet.params.orientation === 'vertical' || position === 'top' ? tablet.scalePosition.tracksOffset : 'auto',
        right: tablet.params.orientation === 'vertical' || position === 'top' ? 'auto' : 0,
      }}
      >
        <div
          style={{
            display: 'flex',
            width: tablet.tracks.tracksWidth,
            height: size,
            ...(tablet.params.orientation === 'horizontal' ? {
              transform: `translate(${0}px, ${tablet.tabletScroll.height + tablet.tabletScroll.scrollTop}px) rotate(-${90}deg)`,
              transformOrigin: 'top left',
            } : {}),
          }}
        >
          {tablet.tracks.withOffset.map(([track, offset]) => (
            <div
              style={{
                width: track.params.trackWidth,
                marginRight: tablet.tracks.tracksPadding,
                ...(tablet.coordinator.dragTrack === track ? {
                  transform: `translate(${getTrackDragOffset(tablet, track, offset)}px, 0)`,
                  transformOrigin: 'top left',
                } : {}),
              }}
              key={track.innerId}
            >
              <TrackInfo
                track={track}
                offset={offset}
                collapsed={tablet.trackInfoCollapsed}
                tablet={tablet}
                contextMenu={contextMenu}
                key={track.innerId}
                position={position}
              />
            </div>
          ))}
        </div>
        <DragHeader tablet={tablet} key="drag-element" />
      </TracksBlock>
    );
  },
);

function getTrackDragOffset(tablet: Tablet, track:Track, offset: number) {
  let trackDragOffset = 0;
  if (tablet.coordinator.dragTrack === track) {
    trackDragOffset = (tablet.params.orientation === 'vertical'
      ? (tablet.coordinator.x + tablet.tabletScroll.scrollLeft
        - tablet.scalePosition.tracksOffset)
      : (tablet.tabletScroll.height - tablet.coordinator.y
          + tablet.tabletScroll.scrollTop)) - offset - track.params.trackWidth / 2;
  }
  return trackDragOffset;
}
