import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { Tablet } from '../../stores/Tablet';
import { ScrollBar, ScrollBarThumb } from './Tablet.styled';

type Props = { tablet: Tablet };

function getPageY(e: React.MouseEvent | MouseEvent | TouchEvent) {
  return 'touches' in e ? e.touches[0].pageY : e.pageY;
}

export const ScrollBarHeight = observer<Props>(({ tablet }) => {
  const {
    height, scrollHeight,
    top, setScrollTop, enableHeightRange, enableScrollRange, spinHeight,
    verticalParams, setVerticalParams,
  } = tablet.tabletScroll;

  const scrollbarRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);

  const maxScrollHeight = scrollHeight - height;
  const maxScrollHeightRef = useRef(maxScrollHeight);
  maxScrollHeightRef.current = maxScrollHeight;

  function onScrollbarTouchStart(e: TouchEvent) {
    e.preventDefault();
  }

  function patchEvents() {
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);

    if (thumbRef.current) {
      thumbRef.current.addEventListener('touchmove', onMouseMove);
      thumbRef.current.addEventListener('touchend', onMouseUp);
    }
  }

  function removeEvents() {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);

    scrollbarRef.current?.removeEventListener('touchstart', onScrollbarTouchStart);

    if (thumbRef.current) {
      thumbRef.current.removeEventListener('touchstart', onMouseDown);
      thumbRef.current.removeEventListener('touchmove', onMouseMove);
      thumbRef.current.removeEventListener('touchend', onMouseUp);
    }
  }

  function onMouseDown(e: React.MouseEvent | TouchEvent) {
    setVerticalParams({
      dragging: true,
      pageY: getPageY(e),
      startTop: top,
    });

    patchEvents();
    e.stopPropagation();
    e.preventDefault();
  }

  function onMouseMove(e: MouseEvent | TouchEvent) {
    if (verticalParams.dragging) {
      const offsetY = verticalParams.pageY - getPageY(e);
      const newTop = verticalParams.startTop + offsetY;

      const ptg = enableHeightRange ? newTop / enableHeightRange : 0;
      let newScrollTop = Math.ceil(ptg * enableScrollRange);
      if (!Number.isNaN(maxScrollHeightRef.current)) {
        newScrollTop = Math.min(newScrollTop, maxScrollHeightRef.current);
      }
      newScrollTop = Math.max(newScrollTop, 0);
      setScrollTop(newScrollTop);
    }
  }

  function onMouseUp() {
    setVerticalParams({ dragging: false });
    removeEvents();
  }

  if (tablet.tabletScroll.enableHeightRange < 0) {
    return null;
  }

  return (
    <ScrollBar ref={scrollbarRef}>
      <ScrollBarThumb
        ref={thumbRef}
        style={{ top: height - top - spinHeight - 6, height: spinHeight }}
        onMouseDown={(e) => onMouseDown(e)}
      />
    </ScrollBar>
  );
});
