import axios from 'axios';
import { format } from 'date-fns';
import qs from 'qs';
import axiosRetry from 'axios-retry';
import { RecordIndex } from '../enums/RecordIndex';
import { waitPromise } from '../utils';

export const axiosApiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_CURVES_CACHE}/v1`,
});

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('auth_token');
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    };
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosRetry(axiosApiInstance, {
  retries: 2, retryDelay: (retryCount) => retryCount * 800,
});

enum IndexType {
  time = 'DATE_TIME',
  depth = 'MEASURED_DEPTH',
}

enum LogDataType {
  DATE_TIME = 'DATE_TIME',
  DOUBLE = 'DOUBLE',
  LONG = 'LONG',
  STRING = 'STRING',
  UNKNOWN = 'UNKNOWN',
}
export type CommentDto = {
  title: string;
  text: string;
  length: number;
};

export type CommentParamsDto = {
  curveId: number;
  key: number;
  index?: RecordIndex;
  comment: CommentDto;
};

export type CurveInfo = {
  id :number;
  mnemonic: string;
  indexType: IndexType;
  unit: string;
  lastValue: string;
  classWitsml: 'RIGIS' | 'COMMENTS' | 'FREEZE' | null;
  typeLogData: LogDataType;
  minValue: number;
  maxValue: number;
  minKey: number;
  maxKey: number;
  scaleSet: number[];
  axisDefinition:{
    order:number,
    count:16,
    name:null,
    propertyType:null,
    uom:null,
    doubleValues: number[],
    stringValues:[],
    uid:'Axis-16'
  }[] | null
  status: 'IN_QUEUE' | 'IN_PROGRESS' | 'BLOCKED' | 'LOADED' | 'UNKNOWN'
};

export interface CurvePoint {
  key: number;
  value: number;
}

export interface HatchPoint {
  key: number;
  value: [number, number];
}
export interface Hatch {
  firstKey: number;
  lastKey: number;
  maxVal: number;
  minVal: number;
}

export interface ImagePoint {
  key: number;
  value: number[];
}

export interface CommentPoint {
  key: number;
  value: string;
}

export async function getCurve(id: number, ms = 800) {
  const response = await axiosApiInstance.get<CurveInfo>(`/curve/${id}`);
  let { data } = response;
  if (response.status === 202) {
    if (ms > 800 * 8) {
      throw new Error('Time out');
    }
    await waitPromise(ms);
    data = await getCurve(id, ms * 2);
    return data;
  }
  return data;
}

export async function getCurveMulti(ids: number[], ms = 800) {
  const response = await axiosApiInstance.get<CurveInfo[]>('/curve/multi', {
    params: { ids },
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat', encode: false }),
  });
  let { data } = response;

  if (response.status === 202) {
    if (ms > 800 * 8) {
      throw new Error('Time out');
    }
    await waitPromise(ms);
    data = await getCurveMulti(ids, ms);
    return data;
  }
  return data;
}

export async function createCurve(logId: number, curveName: string) {
  try {
    const response = await axiosApiInstance.post<number>('/curve', {
      logId,
      curveName,
      typeCurve: 'COMMENTS',
    });
    return response.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e) && e.response) {
      if (e.response.status === 403) {
        throw new Error('Create-curve-forbidden');
      }
      if (e.response.status === 400) {
        throw new Error('Create-curve-name-taken');
      }
    }

    throw new Error('ErrorToSave');
  }
}

export function folderIsWritable(curveId: number) {
  return axiosApiInstance.get<boolean>('/curve/writable', {
    params: {
      id: curveId,
    },
  }).then(({ data }) => data);
}

let cancellable: AbortController[] = [];

export async function getTimeCoords(
  id: number,
  scale?: number,
  onDate?: number,
  toDate?: number,
) {
  const controller = new AbortController();

  cancellable.forEach((c) => {
    c.abort();
  });

  cancellable = [];
  const params = {
    from: onDate && format(onDate, 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\''),
    to: toDate && format(toDate, 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\''),
    scale,
  };
  const start = new Date();

  // eslint-disable-next-line no-console
  console.log('curveId:', id, 'start:', start.toJSON(), 'params:', params);
  const response = await axiosApiInstance
    .get<Array<CurvePoint | Hatch>>(`/curve/${id}/coordinates/by-time`, {
    params,
    signal: controller.signal,
  });
  const end = new Date();
  // eslint-disable-next-line no-console
  console.log('curveId:', id, 'end:', end.toJSON(), 'params:', params, 'time:', end.valueOf() - start.valueOf());

  cancellable.push(controller);

  if (response.status === 202) {
    return { status: 'downloading' };
  }

  return response.data;
}

export async function getSyntheticCoords(id: number) {
  return axiosApiInstance.get<Array<CurvePoint>>(`/curve/${id}/coordinates/by-time`)
    .then((response) => {
      if (response.status === 202) {
        return { status: 'downloading' };
      }

      return response.data;
    });
}

export async function getDepthCoords(id: number) {
  return axiosApiInstance.get<Array<CurvePoint>>(`/curve/${id}/coordinates/by-depth`)
    .then((response) => {
      if (response.status === 202) {
        return { status: 'downloading' };
      }

      return response.data;
    });
}

export async function getImageCoords(id: number) {
  return axiosApiInstance.get<Array<ImagePoint>>(`/curve/${id}/coordinates/image`)
    .then((response) => {
      if (response.status === 202) {
        return { status: 'downloading' };
      }

      return response.data;
    });
}

export async function getComments(id: number) {
  return axiosApiInstance.get<Array<CommentPoint>>(`/curve/${id}/coordinates/comments`)
    .then((response) => {
      if (response.status === 202) {
        return { status: 'downloading' };
      }

      return response.data;
    });
}

export function addComment(params: CommentParamsDto) {
  return axiosApiInstance.post<{ result: boolean }>(`/curve/${params.curveId}/comments`, {
    key: params.key,
    recordIndex: params.index ? 'TIME' : 'DEPTH',
    length: params.comment.length,
    title: params.comment.title,
    text: params.comment.text,
  })
    .then(({ data }) => data.result);
}

export function editComment(params: CommentParamsDto) {
  return axiosApiInstance.put<{ result: object[] }>(`/curve/${params.curveId}/comments`, {
    key: params.key,
    recordIndex: params.index ? 'TIME' : 'DEPTH',
    length: params.comment.length,
    title: params.comment.title,
    text: params.comment.text,
  })
    .then(({ data }) => data.result);
}

export function deleteComment(params: CommentParamsDto) {
  return axiosApiInstance.delete<{ result: object[] }>(`/curve/${params.curveId}/comments?key=${params.key}`)
    .then(({ data }) => data.result);
}

export function resetCurve(id: number) {
  return axiosApiInstance.delete(`/curve/${id}`);
}
export type VersionObject = {
  git: {
    commit: {
      id: string;
      message: {
        full: string;
      };
      time: string;
    };
    branch: string;
  };
  build: {
    artifact: string;
    name: string;
    time: number;
    version: string;
    group: string;
  };
};

export async function getVersionCache() {
  const { data } = await axios.get<VersionObject>('/cache/api/actuator/info');
  return data;
}
