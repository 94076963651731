import { observer } from 'mobx-react-lite';
import { useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import {
  useFloating, offset, flip, shift,
} from '@floating-ui/react-dom';
import { Menu } from 'antd';
import { ContextMenuContainer } from './Tablet.styled';
import { ContextMenuStore } from '../../stores/ContextMenu';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

export const ContextMenu = observer(({ contextMenu } :{ contextMenu: ContextMenuStore }) => {
  const {
    x: tooltipX, y: tooltipY, reference, floating, strategy, refs,
  } = useFloating({
    placement: 'right-start',
    middleware: [offset(2), flip(), shift()],
  });

  useOnClickOutside(refs.floating, () => contextMenu.reset());

  const { x, y } = contextMenu;
  useLayoutEffect(() => {
    reference({
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x,
          y,
          left: x,
          right: x,
          top: y,
          bottom: y,
        };
      },
    });
  }, [reference, x, y]);

  return (contextMenu.currentMenu)
    ? createPortal(
      <ContextMenuContainer
        ref={floating}
        style={{
          position: strategy,
          top: tooltipY ?? '',
          left: tooltipX ?? '',
        }}
      >
        <Menu items={contextMenu.currentMenu} onSelect={() => contextMenu.reset()} />
      </ContextMenuContainer>,
      document.body,
    )
    : null;
});
