// eslint-disable-next-line import/no-extraneous-dependencies
import moment, { Moment } from 'moment';
import { CommentDto, CommentParamsDto } from '../api/curvesCache';
import { RecordIndex } from '../enums/RecordIndex';

export function mapCommentDtoToFormData(commentDto: CommentParamsDto) {
  const result: { [k: string]: any } = {
    title: commentDto.comment.title,
    text: commentDto.comment.text,
  };

  if (commentDto.index === RecordIndex.TIME) {
    result.onDate = moment.utc(commentDto.key);
    result.toDate = moment.utc(commentDto.key + commentDto.comment.length * 1000);
    return result;
  }

  result.onDepth = commentDto.key;
  result.toDepth = commentDto.key + commentDto.comment.length;
  return result;
}

export function mapFormDataToCommentDto(data: any): CommentDto {
  const result = {
    title: data.title || '',
    text: data.text || '',
    length: 0,
  } as CommentDto;

  if (data.onDate && data.toDate) {
    result.length = (data.toDate as Moment).valueOf() - (data.onDate as Moment).valueOf();
    result.length /= 1000;
    return result;
  }
  if (data.onDepth && data.toDepth) {
    result.length = data.toDepth - data.onDepth;
    return result;
  }

  return result;
}
