import { Client, StompSubscription } from '@stomp/stompjs';

type LoadedMsg = { msgType: 'LOADED' };

type PartMsg = { msgType: 'PART'; from: number; to: number };

type PointMsg = { msgType: 'POINT'; key: number; value: any };

export type SocketCurveMessage = LoadedMsg | PartMsg | PointMsg;

export class StompPublisher {
  subscribes: { [key: string]:
  { cb: ((value: SocketCurveMessage) => void)[];
    subscription?: StompSubscription;
  } } = {};

  client: Client;

  get connected() {
    return this.client.connected;
  }

  constructor() {
    this.client = new Client();
  }

  init(url: string) {
    const token = localStorage.getItem('auth_token');

    this.client.configure({
      brokerURL: url,
      connectHeaders: {
        Authorization: `Bearer ${token}`,
      },
      onConnect: () => {
        // eslint-disable-next-line no-console
        console.log('onConnect');
        Object.entries(this.subscribes).forEach(([key]) => {
          const subscription = this.client.subscribe(key, (message) => {
            const data = JSON.parse(message.body);
            this.publish(key, data);
          });
          this.subscribes[key].subscription = subscription;
        });
      },
      onWebSocketClose() {
        // eslint-disable-next-line no-console
        console.log('onWebSocketClose');
      },
      onDisconnect() {
        // eslint-disable-next-line no-console
        console.log('onDisconnect');
      },
    });

    this.client.activate();
  }

  publish(key: string, msg: SocketCurveMessage) {
    if (this.subscribes[key]) {
      this.subscribes[key].cb.forEach((h) => h(msg));
    }
  }

  subscribe(key: string, cb: (value: SocketCurveMessage) => void) {
    if (!this.subscribes[key]) {
      this.subscribes[key] = { cb: [] };
    }
    const list = this.subscribes[key].cb;
    list.push(cb);
    this.subscribes[key].cb = list;
    if (this.client.connected && list.length === 1) {
      const subscription = this.client.subscribe(key, (message) => {
        const data = JSON.parse(message.body);
        this.publish(key, data);
      });
      this.subscribes[key].subscription = subscription;
    }
  }

  unsubscribe(key: string, cb: (value: SocketCurveMessage) => void) {
    if (this.subscribes[key]?.cb == null) {
      return;
    }
    let list = this.subscribes[key].cb ?? [];
    list = list.filter((h) => h !== cb);
    this.subscribes[key].cb = list;
    if (list.length === 0 && this.subscribes[key].subscription) {
      this.subscribes[key].subscription?.unsubscribe();
      delete this.subscribes[key];
    }
  }
}

export const stompPublisher = new StompPublisher();
