import * as PIXI from 'pixi.js';
import { Sprite, Text } from '@inlet/react-pixi';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { TimeZonesStore } from '../../stores/timeZonesStore';
import { TabletContext } from './TabletProvider';
import { ThemeHex } from '../../types/ThemeHex';

const houseMs = 60 * 60 * 1000;
const dayMs = 24 * houseMs;

type ScalePointType = {
  offset: number;
  textStyle: PIXI.TextStyle;
  text: string;
  theme: ThemeHex;
  widthScalePanel: number;
};

function ScalePointVertical({
  offset, textStyle, text, theme, widthScalePanel,
}: ScalePointType) {
  return (
    <>
      <Text
        text={text}
        anchor={[1, 0.5]}
        x={widthScalePanel - 10}
        y={offset - 1}
        style={textStyle}
      />
      <Sprite
        x={widthScalePanel - 1}
        y={offset}
        height={1}
        anchor={[1, 0.5]}
        width={4}
        texture={PIXI.Texture.WHITE}
        tint={theme.borderColor}
      />
    </>
  );
}

function ScalePointHorizontal({
  offset, textStyle, text, theme, widthScalePanel,
}: ScalePointType) {
  return (
    <>
      <Text
        text={text}
        anchor={[0.5, 0]}
        y={widthScalePanel + 10}
        x={offset - 1}
        style={textStyle}
      />
      <Sprite
        y={widthScalePanel + 3}
        x={offset}
        height={4}
        anchor={[0.5, 0]}
        width={1}
        texture={PIXI.Texture.WHITE}
        tint={theme.borderColor}
      />
    </>
  );
}

export const TimeFullScale = observer(() => {
  const { tablet, themeHex } = useContext(TabletContext);
  const textStyle = new PIXI.TextStyle(tablet.baseTextStyle);
  textStyle.fill = themeHex.color;
  const textHeight = tablet.params.orientation === 'vertical' ? tablet.baseTextStyle.fontSize + 14 : 90;
  const topDate = tablet.scale.pointToData(
    ((tablet.onDataDB - tablet.scale.zeroPointKey) / 60000.0) / tablet.scale.dataPerPixel
    - tablet.offsetToEnd,
  );
  const bottomDate = tablet.scale.pointToData(
    ((tablet.toDataDB - tablet.scale.zeroPointKey) / 60000.0) / tablet.scale.dataPerPixel
    + tablet.offsetToEnd,
  );
  const zeroPointKey = topDate;
  const dataPerPixel = (bottomDate - topDate) / tablet.scale.containerLength;
  const markStep = getMarkHeight(
    textHeight,
    dataPerPixel,
  );
  const zoomDivider = markStep > dayMs ? dayMs : markStep;
  const onDate = Math.trunc(topDate / zoomDivider) * zoomDivider
    - tablet.timeZones.selectedMs;
  const toDate = Math.trunc(bottomDate / zoomDivider) * zoomDivider + zoomDivider
    - tablet.timeZones.selectedMs;

  const line: { offset: number; text: string }[] = [];

  for (let date = onDate; date <= toDate; date += markStep) {
    const offset = dataToPoint(date, dataPerPixel, zeroPointKey);
    if (offset >= (textHeight / 2) && offset <= tablet.scale.containerLength - (textHeight / 2)) {
      line.push({
        offset: dataToPoint(date, dataPerPixel, zeroPointKey),
        text: pointFormat(date, tablet.timeZones),
      });
    }
  }

  return (
    <>
      {
        tablet.params.orientation === 'vertical'
          ? line.map((value) => (
            <ScalePointVertical
              key={value.offset}
              offset={value.offset}
              text={value.text}
              theme={themeHex}
              textStyle={textStyle}
              widthScalePanel={tablet.scalePosition.subSize}
            />
          ))
          : line.map((value) => (
            <ScalePointHorizontal
              key={value.offset}
              offset={value.offset}
              text={value.text}
              theme={themeHex}
              textStyle={textStyle}
              widthScalePanel={tablet.scalePosition.y + tablet.scalePosition.subSize}
            />
          ))
      }
      <Sprite
        x={tablet.params.orientation === 'vertical' ? (tablet.scalePosition.x + tablet.scalePosition.width / 2) - 2 : 0}
        y={tablet.params.orientation === 'vertical' ? 0 : (tablet.scalePosition.y + tablet.scalePosition.height / 2)}
        height={tablet.params.orientation === 'vertical' ? tablet.params.canvasSize[1] : 2}
        width={tablet.params.orientation === 'vertical' ? 2 : tablet.params.canvasSize[0]}
        texture={PIXI.Texture.WHITE}
        tint={themeHex.gridColor}
      />
    </>
  );
});

const t: number[] = [
  houseMs, houseMs * 4, houseMs * 12, houseMs * 24, houseMs * 24 * 2,
  houseMs * 24 * 3, houseMs * 24 * 7,
];

function getMarkHeight(textHeight: number, dataPerPixel: number) {
  const goal = t.find((textMarks) => {
    const h = textMarks / dataPerPixel;
    return h > textHeight;
  });
  return goal || t[t.length - 1];
}

function dataToPoint(date: number, dataPerPixel: number, zeroPointKey: number) {
  return (date - zeroPointKey) / dataPerPixel;
}

function pointFormat(value: number, timeZones: TimeZonesStore) {
  const date = timeZones.getDate(value);
  if (date.getHours() === 0 && date.getMinutes() === 0) {
    return format(date, 'yyyy-MM-dd');
  }
  return format(date, 'HH:mm');
}
