import { useState } from 'react';
import {
  Button, Col, Menu, MenuProps, Row, Select, Space, Typography,
} from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ThemeProvider } from '@emotion/react';
import { useLocalization } from '@fluent/react';
import { ExportOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import {
  AppWrapper,
  ScreensContainer,
  SidebarBottomContainer,
  SidebarContainer,
  SidebarTopContainer,
  TreePanelContainer,
} from '../components/MainLayout';
import { ThemeSwitcher } from '../components/ThemeSwitcher';
import { ScaleUnitSwitcher } from '../components/ScaleUnitSwitcher';
import { localizationStore, themingStore, timeZonesStore } from '../stores/init-stores-global';
import { LogoBlock } from '../components/LogoBlock';
import { authStore } from '../stores/auth';
import { cameraStore } from '../stores/CameraStore';

export const CameraPage = observer(() => {
  const [collapsed] = useState(true);
  const { l10n } = useLocalization();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const wellId = searchParams.get('wellId');
  const selected = cameraStore.wellList.find((w) => w.wellId === wellId) ?? cameraStore.wellList[0];

  function selectWell(id: string) {
    setSearchParams({ wellId: id });
  }
  const items: Required<MenuProps>['items'] = [
    {
      label: authStore.username,
      icon: <UserOutlined />,
      key: 'user',
      children: [
        { label: authStore.username, key: 'username', type: 'group' },
        { type: 'divider' },
        { label: l10n.getString('Logout'), key: 'user-logout', onClick: () => authStore.logout() },
      ],
    },
    {
      label: l10n.getString('Tablets-page'),
      icon: <HomeOutlined />,
      key: 'home',
      onClick: () => navigate('/'),
    },
  ];

  if (!selected) {
    return null;
  }

  return (
    <ThemeProvider theme={themingStore.emotionTheme}>
      <AppWrapper wellsOpened>
        <SidebarContainer collapsed={collapsed}>
          <SidebarTopContainer>
            <LogoBlock />
            <Menu items={items} inlineCollapsed={collapsed} mode="inline" selectable={false} />
          </SidebarTopContainer>
          <SidebarBottomContainer>
            <ThemeSwitcher collapsed={collapsed} />
            {Object.keys(localizationStore.locales).length > 1 && (
            <Select
              style={{ width: collapsed ? 64 : 140, marginBottom: 8 }}
              size="small"
              value={localizationStore.currentLocales[0]}
              onSelect={(locale: string) => localizationStore.changeLocales([locale])}
            >
              {localizationStore.locales['en-US'] && (
              <Select.Option key="en-US" value="en-US">
                {collapsed ? 'En' : 'English'}
              </Select.Option>
              )}
              {localizationStore.locales['ru-Ru'] && (
              <Select.Option key="ru-Ru" value="ru-Ru">
                {collapsed ? 'Ru' : 'Русский'}
              </Select.Option>
              )}
            </Select>
            )}
            <Select
              style={{ width: collapsed ? 64 : 140, marginBottom: 8 }}
              size="small"
              value={timeZonesStore.selected}
              onSelect={(time: number) => timeZonesStore.select(time)}
            >
              {timeZonesStore.list.map((time) => (
                <Select.Option key={time} value={time}>
                  {!collapsed && 'UTC '}
                  {time < 0 ? time : `+${time}`}
                </Select.Option>
              ))}
            </Select>
            <ScaleUnitSwitcher
              style={{ width: collapsed ? 64 : 140, marginBottom: 24 }}
              collapsed={collapsed}
            />
          </SidebarBottomContainer>
        </SidebarContainer>
        <TreePanelContainer visible>
          <div style={{ padding: '24px 12px' }}>
            {cameraStore.wellList.map((well) => (
              <Button
                onClick={() => selectWell(well.wellId)}
                block
                style={{ marginBottom: 8 }}
                type={selected.wellId === well.wellId ? 'primary' : 'default'}
                key={well.wellId}
              >
                {well.title}
              </Button>
            ))}
          </div>
        </TreePanelContainer>
        <ScreensContainer style={{ display: 'block', overflowY: 'auto' }}>
          <div style={{ padding: '24px 12px' }}>
            <Row gutter={[16, 20]}>
              {selected.list.map((camera, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col className="gutter-row" md={12} span={24} key={index}>
                  <Typography.Title level={5}>
                    <Space>
                      {camera.title}
                      <Button
                        icon={<ExportOutlined />}
                        size="small"
                        onClick={() => {
                          const w = window.open(
                            camera.link,
                            camera.title,
                            'popup=yes,scrollbars=no,status=no,toolbar=no,menubar=no,width=400,height=300',
                          );
                          if (w) {
                            setTimeout(() => {
                              w.document.title = camera.title ?? '';
                            }, 0);
                          }
                        }}
                      />
                    </Space>
                  </Typography.Title>
                  <div style={{
                    position: 'relative',
                    paddingBottom: '56.25%',
                    paddingTop: '25px',
                    height: 0,
                  }}
                  >
                    <iframe
                      src={camera.link}
                      title={camera.title}
                      width="400"
                      height="300"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 'none',
                      }}
                      allowFullScreen
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </ScreensContainer>
      </AppWrapper>
    </ThemeProvider>
  );
});
