import { Button, Popover, Tooltip } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FullscreenOutlined,
  RotateRightOutlined,
  SearchOutlined,
  SettingOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { useLocalization } from '@fluent/react';
import {
  TabletHeader as Header,
  TabletHeaderActions,
  TabletHeaderLoader,
  TabletHeaderLoaderStub,
  TabletHeaderName,
  TabletHeaderNameInner,
} from './Tablet.styled';
import { Tablet as TabletEntity } from '../../stores/Tablet';
import { NavigationPanel } from './sup-panels/NavigationPanel';
import { ScaleSelectorPanel } from './sup-panels/ScaleSelectorPanel';
import { TabletType } from '../../enums/TabletType';

export const TabletHeader = observer(({ tablet, isFullscreen }:
{ tablet: TabletEntity; isFullscreen: boolean }) => {
  const { l10n } = useLocalization();
  return (
    <Header>
      <TabletHeaderActions>
        <Button
          size="small"
          icon={<Tooltip title={l10n.getString('Autoscroll')}><DownOutlined /></Tooltip>}
          type={tablet.autoScroll ? 'primary' : 'default'}
          onClick={() => tablet.setAutoScroll(!tablet.autoScroll)}
        />
        <Button
          size="small"
          icon={<Tooltip title={l10n.getString('Zoom-in')}><ZoomInOutlined /></Tooltip>}
          disabled={tablet.isMaxZoom}
          onClick={() => tablet.zoomIn()}
        />
        <Button
          size="small"
          icon={<Tooltip title={l10n.getString('Zoom-out')}><ZoomOutOutlined /></Tooltip>}
          disabled={tablet.isMinZoom}
          onClick={() => tablet.zoomOut()}
        />
        {tablet.tabletType === TabletType.Time ? (
          <>
            <ScaleSelectorPanel tablet={tablet} />
            <NavigationPanel tablet={tablet} />
          </>
        ) : (
          <Button
            size="small"
            icon={<Tooltip title={l10n.getString('Show-all-data')}><SearchOutlined /></Tooltip>}
            onClick={() => tablet.doFitAll()}
          />
        )}
        {process.env.NODE_ENV === 'development' && (
        <Button
          size="small"
          icon={<Tooltip title={l10n.getString('Service-info')}><SettingOutlined /></Tooltip>}
          type={tablet.serviceInfo ? 'primary' : 'default'}
          onClick={() => (tablet.serviceInfo
            ? tablet.setServiceInfo(false)
            : tablet.setServiceInfo(true))}
        />
        )}
        <Button
          size="small"
          icon={<Tooltip title={l10n.getString('Orientation')}><RotateRightOutlined /></Tooltip>}
          onClick={() => (tablet.params.orientation === 'horizontal'
            ? tablet.setOrientation('vertical')
            : tablet.setOrientation('horizontal'))}
        />
      </TabletHeaderActions>
      <Popover
        trigger="click"
        content={(
          <div>
            {tablet.templateData?.well.text}
            /
            {tablet.templateData?.bole.text}
            {tablet.templateData?.log?.text && `/${tablet.templateData?.log?.text}`}
            <br />
            {tablet.templateData?.templateName}
          </div>
    )}
      >
        <TabletHeaderName>
          <TabletHeaderNameInner>
            {tablet.templateData?.well.text}
            /
            {tablet.templateData?.bole.text}
            {tablet.templateData?.log?.text && `/${tablet.templateData?.log?.text}`}
            {tablet.sharedCacheLoader && (
              <>
                {' ('}
                {l10n.getString('LoadingShort')}
                {' '}
                {tablet.sharedCacheLoader}
                %)
              </>
            )}
          </TabletHeaderNameInner>
        </TabletHeaderName>
      </Popover>
      <TabletHeaderActions>
        <Button
          size="small"
          icon={<Tooltip title={l10n.getString('Edit-mode')}><EditOutlined /></Tooltip>}
          type={tablet.editMode ? 'primary' : 'default'}
          onClick={() => (tablet.editMode ? tablet.disableEditing() : tablet.enableEditing())}
        />
        <Button
          size="small"
          icon={<Tooltip title={l10n.getString('Full-screen')}><FullscreenOutlined /></Tooltip>}
          type={isFullscreen ? 'primary' : 'default'}
          onClick={() => tablet.setFullscreen(!tablet.fullscreen)}
        />
        <Button
          size="small"
          icon={(
            <Tooltip title={l10n.getString('Header-mode')}>
              {tablet.trackInfoCollapsed ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
            </Tooltip>
          )}
          onClick={() => tablet.toggleTrackInfoCollapsed()}
        />
        <Button
          size="small"
          icon={(
            <Tooltip title={l10n.getString('Hide-show-header')}>
              {tablet.trackInfoVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </Tooltip>
          )}
          onClick={() => tablet.toggleTrackInfoVisible()}
        />
        <Button
          size="small"
          icon={<Tooltip title={l10n.getString('Close-template')}><CloseOutlined /></Tooltip>}
          onClick={() => {
            tablet.close();
          }}
        />
      </TabletHeaderActions>
      {!!tablet.sharedCacheLoader && (
      <TabletHeaderLoader percent={tablet.sharedCacheLoader}>
        <TabletHeaderLoaderStub />
      </TabletHeaderLoader>
      )}
    </Header>
  );
});
