import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { folderTreeStore } from '../../stores/folderTree';
import { HeaderActions } from './FolderTree.styled';

export const LasLoaderActions = observer(() => (folderTreeStore.selectToLasExport ? (
  <HeaderActions>
    <Space>
      <Button
        size="small"
        onClick={() => folderTreeStore.selectToLasExport?.download(false)}
        disabled={folderTreeStore.selectToLasExport?.inProgress}
      >
        .las (base)
      </Button>
      <Button
        size="small"
        onClick={() => folderTreeStore.selectToLasExport?.download(true)}
        disabled={folderTreeStore.selectToLasExport?.inProgress}
      >
        .las (interpolated)
      </Button>
      <Button
        icon={<CloseCircleOutlined />}
        size="small"
        onClick={() => folderTreeStore.removeSelectToLasExport()}
        disabled={folderTreeStore.selectToLasExport?.inProgress}
        loading={folderTreeStore.selectToLasExport?.inProgress}
      />
    </Space>
  </HeaderActions>
) : null));
