import axios from 'axios';
import { format } from 'date-fns';
import { makeAutoObservable, runInAction } from 'mobx';
import qs from 'qs';
import { axiosApiInstance, CurvePoint, Hatch } from '../../api/curvesCache';
import { getUtc, Range } from '../../utils';
import { Scale } from '../Scale';
import { CurveFetcher, CurveCoordinates } from './CurveCoordinates';

export type CurveLine = {
  t: number;
  v: (number | null)[];
};

type Hatches = {
  t: [number, number];
  v: ([number, number] | null)[];
};

export type HatchLine = {
  t: number;
  v: [number, number][];
};

type FetchParams = { onFrame: number; toFrame: number; direction?: 'in' | 'out', force?: boolean };

class Fetcher {
  getCurves: () => CurveFetcher[];

  inProgress = false;

  errors = null;

  shared: SharedTimeCurvesCoordinates;

  dataStep: number;

  scale: Scale;

  currentRequestRange: Range | null = null;

  abortController: AbortController | null = null;

  fetched = false;

  constructor(
    getCurves: () => CurveFetcher[],
    dataStep: number,
    scale: Scale,
    shared: SharedTimeCurvesCoordinates,
  ) {
    this.getCurves = getCurves;
    this.dataStep = dataStep;
    this.shared = shared;
    this.scale = scale;
    makeAutoObservable(this, {
      abortController: false,
      currentRequestRange: false,
    });
  }

  async refreshCoords(params: FetchParams, ratio: [number, number]) {
    const curves = this.getCurves();
    const validated = curves
      .map((curve) => [
        curve.curveId,
        curve.timeCoordinatesValidator({
          ...params,
          inProgress: this.inProgress,
          currentRequestRange: this.currentRequestRange,
          ratio,
        })!,
      ] as const)
      .filter(([,v]) => !!v);
    if (validated.length === 0) {
      return;
    }

    const ids = validated.map(([id]) => id);
    const startSegment = validated.every(([,[,,,s]]) => s === 'start');
    const endSegment = validated.every(([,[,,,s]]) => s === 'end');
    const start = Math.min(...validated.map(([,[v]]) => v));
    const end = Math.max(...validated.map(([,[,v]]) => v));

    this.currentRequestRange = Range.group(validated.map(([, [,,r]]) => r));
    if (startSegment) {
      await this.getTimeCoords(ids, start, end, this.dataStep, 'start');
    } else if (endSegment) {
      await this.getTimeCoords(ids, start, end, this.dataStep, 'end');
    } else {
      await this.getTimeCoords(ids, start, end, this.dataStep);
    }
  }

  async getTimeCoords(
    ids: number[],
    onData: number,
    toData: number,
    dataStep: number,
    segment?: 'start' | 'end',
  ) {
    const { data } = await this.fetchTimeCoords(
      ids,
      dataStep,
      getUtc(onData),
      getUtc(toData),
    );
    runInAction(() => {
      if (Array.isArray(data)) {
        const curves = new Array(ids.length).fill(null)
          .map(() => [] as (CurvePoint | Hatch)[]);
        if (isHatch(data)) {
          data.forEach((line) => {
            line.v.forEach((v, index) => {
              if (v) {
                const point: Hatch = {
                  firstKey: line.t[0],
                  lastKey: line.t[1],
                  minVal: v[0],
                  maxVal: v[1],
                };
                curves[index].push(point);
              }
            });
          });
          const ls = this.getCurves();
          ids.forEach((id, index) => {
            const curve = ls.find((l) => l.curveId === id);
            if (curve) {
              curve.setPoints(curves[index], segment);
            }
          });
        }
        if (isPoints(data)) {
          data.forEach((line) => {
            line.v.forEach((v, index) => {
              if (v != null) {
                const point: CurvePoint = {
                  key: line.t,
                  value: v,
                };
                curves[index].push(point);
              }
            });
          });
          const ls = this.getCurves();
          ids.forEach((id, index) => {
            const curve = ls.find((l) => l.curveId === id);
            if (curve) {
              curve.setPoints(curves[index], segment);
            }
          });
        }
      }
    });
  }

  async fetchTimeCoords(
    ids: number[],
    scale?: number,
    onDate?: number,
    toDate?: number,
  ) {
    const params = {
      ids,
      from: onDate && format(onDate, 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\''),
      to: toDate && format(toDate, 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\''),
      scale,
    };
    this.errors = null;
    if (this.inProgress && this.abortController?.signal.aborted === false) {
      this.abortController.abort();
    }
    this.inProgress = true;
    this.abortController = new AbortController();
    try {
      const response = await axiosApiInstance
        .get<{ ids: number[]; data: Array<CurveLine | Hatches> }>('/curve/multi/coordinates/by-time', {
        params,
        paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat', encode: false }),
        signal: this.abortController.signal,
      });
      runInAction(() => {
        this.inProgress = false;
        this.errors = null;
        this.fetched = true;
      });
      return response.data;
    } catch (e: any) {
      if (!axios.isCancel(e)) {
        runInAction(() => {
          this.errors = e.response && e.response.body && e.response.body.errors;
          this.inProgress = false;
        });
      }
      throw e;
    }
  }
}

class Layer {
  coordinates: Fetcher;

  dataStep: number;

  constructor(
    getLayers: () => CurveCoordinates[],
    shared: SharedTimeCurvesCoordinates,
    dataStep: number,
    private scale: Scale,
  ) {
    const getLayerList = () => getLayers()
      .map((l) => l.layers.find((l2) => l2.dataStep === dataStep)!.coordinates);
    this.coordinates = new Fetcher(getLayerList, dataStep, scale, shared);
    this.dataStep = dataStep;
    this.scale = scale;
    makeAutoObservable(this);
  }

  get dataPerPixel() {
    return this.dataStep / 120;
  }

  async refreshCoords(params: {
    onFrame: number;
    toFrame: number;
    ratio: [number, number];
    force?: boolean;
  }) {
    await this.coordinates.refreshCoords(params, params.ratio);
  }
}

export class SharedCurveLayers {
  layers: Layer[];

  prevScale: number;

  prevLayer: Layer;

  scale: Scale;

  scaleSetDefault: number[];

  constructor(
    curveIds: () => CurveCoordinates[],
    scale: Scale,
    shared: SharedTimeCurvesCoordinates,
  ) {
    this.scaleSetDefault = [1, 30, 60, 180, 360, 720, 1440, 10080];
    this.layers = this.scaleSetDefault
      .map((step) => new Layer(curveIds, shared, step, scale));
    this.scale = scale;
    this.prevScale = this.scale.dataPerPixel;
    makeAutoObservable(this);
  }

  async refreshCoords(
    params: { onFrame: number; toFrame: number; force?: boolean; bounded?: boolean },
  ) {
    if (params.bounded) {
      await this.closest.refreshCoords({ ...params, ratio: [0, 0], force: true });
      return;
    }
    await this.closest.refreshCoords({ ...params, ratio: [8, 10] });
    if (this.prevLayer === this.closest) {
      if (this.next && this.prevScale < this.scale.dataPerPixel
         && ((this.scale.dataPerPixel / this.next.dataPerPixel) > 0.8)) {
        this.next?.refreshCoords({ ...params, ratio: [8, 10] });
      } else if (this.prev && this.prevScale > this.scale.dataPerPixel
        && ((this.prev.dataPerPixel / this.scale.dataPerPixel)
          * (this.closest.dataPerPixel / this.prev.dataPerPixel) > 0.8)) {
        this.prev?.refreshCoords({ ...params, ratio: [0.1, 0.2] });
      }
    }
    runInAction(() => {
      this.prevScale = this.scale.dataPerPixel;
      this.prevLayer = this.closest;
    });
    this.distantLayers.forEach((l) => {
      if (l.coordinates.abortController) {
        if (!l.coordinates.abortController.signal.aborted) {
          l.coordinates.abortController.abort();
        }
      }
    });
  }

  get size() {
    return this.layers.length;
  }

  get closest() {
    const goal = this.scale.dataPerPixel;
    if (goal < this.layers[0].dataPerPixel) {
      return this.layers[0];
    }
    for (let i = 0; i < this.layers.length - 1; i += 1) {
      const a = this.layers[i];
      const b = this.layers[i + 1];
      if (a.dataPerPixel <= goal && goal <= b.dataPerPixel) {
        return a;
      }
    }
    return this.layers[this.layers.length - 1];
  }

  get distantLayers() {
    return this.layers.filter((l, i) => {
      if (i > this.currentIndex - 2 && this.currentIndex + 2 > i) {
        return false;
      }
      return true;
    });
  }

  get currentIndex() {
    return this.layers.indexOf(this.closest);
  }

  get prev() {
    return this.layers.at(this.currentIndex - 1);
  }

  get next() {
    return this.layers.at(this.currentIndex + 1);
  }

  get inProgress() {
    return this.closest.coordinates.inProgress;
  }

  updateScaleSet(scaleSet: number[]) {
    const sorted = scaleSet.slice().sort((a, b) => a - b);
    sorted.unshift(1);
    this.layers = this.layers
      .filter((layer) => layer.dataStep <= 1
        || layer.dataStep >= 30
        || sorted.includes(layer.dataStep));
  }
}

export class SharedTimeCurvesCoordinates {
  registered = new Map<number, CurveCoordinates>();

  layers: SharedCurveLayers;

  constructor(scale: Scale) {
    this.layers = new SharedCurveLayers(() => this.list, scale, this);
    makeAutoObservable(this);
  }

  get list() {
    return Array.from(this.registered.values());
  }

  addCurve(object: CurveCoordinates) {
    this.registered.set(object.curveId, object);
  }

  deleteCurve(object: CurveCoordinates) {
    this.registered.delete(object.curveId);
  }
}

function isHatch(points: (CurveLine | Hatches)[]): points is Hatches[] {
  const firstPoint = points.at(0);
  return !!firstPoint && Array.isArray(firstPoint.t);
}

function isPoints(points: (CurveLine | Hatches)[]): points is CurveLine[] {
  const firstPoint = points.at(0);
  return !!firstPoint && !Array.isArray(firstPoint.t);
}
