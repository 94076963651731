import { makeAutoObservable, observable, runInAction } from 'mobx';
import { getSyntheticCoords, CurvePoint } from '../../api/curvesCache';
import {
  allPointsUnique, immutableSplice, pointsOutOfOrder, Range,
} from '../../utils';

export class SyntheticCoordinates {
  curveId: number;

  points: CurvePoint[] = [];

  inProgress = false;

  inDownloading = false;

  errors = null;

  onData?: number;

  toData?: number;

  minValue = 0;

  maxValue = 100;

  fetched = false;

  constructor(curveId: number) {
    makeAutoObservable(this, {
      points: observable.ref,
    });
    this.curveId = curveId;
  }

  get range() {
    return new Range(this.onData || 0, this.toData || 0);
  }

  async refreshCoords({ force }: { force?: boolean }) {
    if (!force && (this.points.length > 0 || this.inProgress || this.inDownloading)) {
      return;
    }
    this.inProgress = true;
    this.errors = null;
    try {
      const data = await getSyntheticCoords(this.curveId);
      runInAction(() => {
        if (Array.isArray(data)) {
          this.points = data as CurvePoint[];
          this.onData = data.at(0)?.key;
          this.toData = data.at(-1)?.key;
          // eslint-disable-next-line no-console
          console.info(
            'Curve id:',
            this.curveId,
            'Amount of points:',
            this.points.length,
            'Points out of order:',
            pointsOutOfOrder(this.points),
            'Keys are unique:',
            allPointsUnique(this.points) ? 'Yes' : 'No',
          );
          this.fetched = true;
        } else {
          this.inDownloading = true;
        }
      });
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  binarySearchIndex(data: CurvePoint[], target: number, start: number, end: number): number | null {
    if (end < 1) return 0;
    const middle = Math.floor(start + (end - start) / 2);
    if (target === data[middle].key) return middle;
    if ((end - 1) === start) {
      return start;
    }
    if (target > data[middle].key) return this.binarySearchIndex(data, target, middle, end);
    if (target < data[middle].key) return this.binarySearchIndex(data, target, start, middle);
    return null;
  }

  getPoint(y: number) {
    if (this.range.in(y)) {
      const index = this.binarySearchIndex(this.points, y, 0, this.points.length - 1);
      return index != null ? this.points[index] : null;
    }
    return null;
  }

  addPoint(point: CurvePoint) {
    const index = this.points.findIndex((p, i) => {
      const next = this.points.at(i + 1);
      if (next) {
        return p.key < point.key && point.key < next.key;
      }
      return false;
    });
    if (index > -1 && index < this.points.length) {
      this.points = immutableSplice(this.points, index + 1, 0, point);
    }
  }

  addPoints(points: CurvePoint[]) {
    this.points = this.points.concat(...points.filter((p) => p.key > (this.toData || 0)));
  }
}
