import { DependencyList, useEffect, useMemo } from 'react';
import { throttle } from '../utils';

export function useThrottle<Args extends unknown[]>(
  cb: (...args: Args) => void,
  cooldown: number,
  deps: DependencyList,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const t = useMemo(() => throttle(cb, cooldown), deps);
  useEffect(() => () => {
    t.cancel();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return t;
}
