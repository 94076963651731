import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Col,
  Divider,
  List,
  Modal,
  Row,
  Select,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useLocalization } from '@fluent/react';
import { useNavigate } from 'react-router-dom';
import { getWellObject, getWellChildren } from '../../api/wells';
import { ObjectDto } from '../../api/dto/ObjectDto';
import { templatesStore } from '../../stores/templatesStore';
import { TemplateViewModel } from '../../api/dto/TabletDto';
import { TemplateCard, TemplateCardText } from './OpenWellModal.styled';
import { ScreensStore } from '../../stores/screensStore';

type Props = {
  wellId: number;
  visible: boolean;
  screensStore: ScreensStore;
};

export const OpenWellModal = observer(({ screensStore, visible, wellId }: Props) => {
  const { l10n } = useLocalization();
  useEffect(() => {
    screensStore.fetchList();
  }, [screensStore]);

  const navigate = useNavigate();

  const [well, setWell] = useState<ObjectDto | null>(null);
  const [wellbores, setWellbores] = useState<ObjectDto[]>([]);

  const [wellbore, setWellbore] = useState<number | null>(null);
  const [template, setTemplate] = useState<TemplateViewModel | null>(null);

  useEffect(() => {
    getWellObject(wellId).then((obj) => {
      setWell(obj);
    });
    getWellChildren(wellId).then((list) => {
      setWellbores(list);
      const first = list.at(0);
      if (first) {
        setWellbore(first.externalId);
      }
    });
  }, [wellId]);

  useEffect(() => {
    templatesStore.getAll();
  }, []);

  function onOk() {
    if (template && wellbore && well) {
      screensStore.saveScreen(well.text, [{
        tabletId: template.id,
        boleId: wellbore,
        boleName: wellbores.find((w) => w.externalId === wellbore)?.text,
        templateName: template.tabletName,
        wellboreId: well.externalId,
        wellboreName: well.text,
      }]).then(({ id: screenId }) => {
        navigate({ pathname: '/', search: `?screenId=${screenId}` });
      });
    }
    if (template == null) {
      message.error(l10n.getString('TemplateNotSelectedError'));
    }
    return false;
  }

  function onClose() {
    navigate({ pathname: '/' });
    return false;
  }

  const { userTemplates, systemTemplates } = templatesStore;

  return (
    <Modal
      title={l10n.getString('ChooseTemplate')}
      open={visible}
      onCancel={() => onClose()}
      cancelText={l10n.getString('Cancel')}
      okText={l10n.getString('Open')}
      onOk={() => onOk()}
      width={800}
      closeIcon={<Tooltip title={l10n.getString('Close-window')}><CloseOutlined /></Tooltip>}
      bodyStyle={{ maxHeight: 500, overflowY: 'auto' }}
    >
      <Row gutter={16}>
        <Col span={6}>
          <Typography.Title level={5}>
            {l10n.getString('SelectedWell')}
          </Typography.Title>
        </Col>
        <Col span={6}>
          {well?.text}
        </Col>
        <Col span={8} offset={4}>
          <Select value={wellbore} onSelect={(value: number) => setWellbore(value)} style={{ width: '100%' }}>
            {wellbores.map((w) => (
              <Select.Option value={w.externalId} key={w.externalId}>
                {w.text}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Divider plain orientation="left">{l10n.getString('UserTemplates')}</Divider>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={userTemplates.filter((t) => t.selected)}
        locale={{
          emptyText: l10n.getString('NoUserTemplates'),
        }}
        renderItem={(t) => (
          <List.Item>
            <TemplateCard active={template === t} onClick={() => setTemplate(t)}>
              <TemplateCardText>
                {t.tabletName}
              </TemplateCardText>
            </TemplateCard>
          </List.Item>
        )}
      />
      <Divider plain orientation="left">{l10n.getString('SystemTemplates')}</Divider>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={systemTemplates.filter((t) => t.selected)}
        locale={{
          emptyText: l10n.getString('NoSystemTemplates'),
        }}
        renderItem={(t) => (
          <List.Item>
            <TemplateCard active={template === t} onClick={() => setTemplate(t)}>
              <TemplateCardText>
                {t.tabletName}
              </TemplateCardText>
            </TemplateCard>
          </List.Item>
        )}
      />
    </Modal>
  );
});
