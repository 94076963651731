import { Container } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { TabletContext } from './TabletProvider';

export const ScrollContainer = observer(({ children }) => {
  const { tablet } = useContext(TabletContext);
  const { height, scrollTop } = tablet.tabletScroll;

  let y = 0;
  if (tablet.params.orientation === 'horizontal') {
    y = -tablet.tracks.tracksWidth + height + scrollTop;
  }

  return (
    <Container x={0} y={y}>
      {children}
    </Container>
  );
});
