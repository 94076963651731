import { ObjectType } from './api/dto/ObjectDto';
import TreeviewCarottage from './assets/icons/TreeviewCarottage.png';
import TreeFolderIcon from './assets/icons/treeview_folder.png';
import TreeWellIcon from './assets/icons/treeview_well.png';
import TreeWellRedIcon from './assets/icons/treeview_well_red.png';
import TreeWellGreenIcon from './assets/icons/treeview_well_green.png';
import TreeWellYellowIcon from './assets/icons/treeview_well_yellow.png';
import TreeTrajectoryIcon from './assets/icons/treeview_trajectory.png';
import TreeSctfolderIcon from './assets/icons/treeview_sctfolder.png';
import TreeFolderTimeIcon from './assets/icons/FolderTime.png';
import WitsmlLoaderWhiteIcon from './assets/icons/witsml_loader_white.png';
import TreeviewBoreholeImage from './assets/icons/TreeviewBoreholeImage.png';

export const objectDictionary:
Record<ObjectType, {
  iconImage?: (typeExt?: string) => string,
  actions?: boolean,
  title?: (typeExt?: string) => string,
  unavailable?: boolean,
}> = {
  [ObjectType.CURVE]: {
    iconImage: (typeExt?: string) => (typeExt === 'image' ? TreeviewBoreholeImage : TreeviewCarottage),
    title: (typeExt?: string) => (typeExt === 'image' ? 'Image' : 'Curve'),
  },
  [ObjectType.FOLDER]: {
    iconImage: () => TreeFolderIcon,
    title: () => 'Folder',
  },
  [ObjectType.WELL]: {
    iconImage: () => TreeWellIcon,
    title: () => 'Finished-well',
  },
  [ObjectType.WELL_RED]: {
    iconImage: () => TreeWellRedIcon,
    title: () => 'Non-active-well',
  },
  [ObjectType.WELL_GREEN]: {
    iconImage: () => TreeWellGreenIcon,
    title: () => 'Active-well',
  },
  [ObjectType.WELL_YELLOW]: {
    iconImage: () => TreeWellYellowIcon,
    title: () => 'Fresh-well',
  },
  [ObjectType.WELLBORE]: {
    iconImage: () => TreeFolderIcon,
    actions: true,
    title: () => 'Wellbore',
  },
  [ObjectType.TRAJECTORY]: {
    iconImage: () => TreeTrajectoryIcon,
    unavailable: true,
    title: () => 'Trajectory',
  },
  [ObjectType.SCREENCAST_FOLDER]: {
    unavailable: true,
  },
  [ObjectType.SCREENCAST]: {
    unavailable: true,
  },
  [ObjectType.AUTO_SUPERVISOR]: {
    unavailable: true,
  },
  [ObjectType.AUTO_SUPERVISOR_ACTIVE]: {
    unavailable: true,
  },
  [ObjectType.LOG_DEPTH]: {
    iconImage: () => TreeSctfolderIcon,
    actions: true,
    title: () => 'LogsByDepth',
  },
  [ObjectType.LOG_TIME]: {
    iconImage: () => TreeFolderTimeIcon,
    actions: true,
    title: () => 'LogsByTime',
  },
  [ObjectType.RECEIVER]: {
    unavailable: true,
  },
  [ObjectType.PARSER]: {
    iconImage: () => TreeFolderIcon,
  },
  [ObjectType.PARSER_TEMPLATE]: {
    unavailable: true,
  },
  [ObjectType.IMAP_PARSER]: {
    unavailable: true,
  },
  [ObjectType.USER]: {
    unavailable: true,
  },
  [ObjectType.WITSML_LOADER]: {
    iconImage: () => WitsmlLoaderWhiteIcon,
    unavailable: true,
  },
  [ObjectType.WITSML_LOADER_ACTIVE]: {
    unavailable: true,
  },
  [ObjectType.SUPER_REPORT_FOLDER]: {
  },
  [ObjectType.DB]: {
  },
  [ObjectType.WITSML_SENDER]: {
    unavailable: true,
  },
  [ObjectType.WITSML_SENDER_ACTIVE]: {
    unavailable: true,
  },
  [ObjectType.RECOGNIZER]: {
    unavailable: true,
  },
  [ObjectType.RECOGNIZER_ACTIVE]: {
    unavailable: true,
  },
  [ObjectType.KPI_STATE_MARKER]: {
    unavailable: true,
  },
  [ObjectType.KPI_STATE_MARKER_ACTIVE]: {
    unavailable: true,
  },
};
