export type Camera = {
  title: string;
  description: string;
  link: string;
};

export type CameraWell = {
  title: string;
  wellId: string;
  list: Camera[];
};

class CameraStore {
  wellList: CameraWell[] = [];

  constructor() {
    this.fetchCameraList();
  }

  async fetchCameraList() {
    const list = await (await fetch('/camera.json')).json();
    this.wellList = list;
  }
}

export const cameraStore = new CameraStore();
