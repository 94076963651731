import {
  action, computed, makeObservable, observable,
} from 'mobx';
import { TabletType } from '../enums/TabletType';
import { TabletParams } from './TabletParams';

export abstract class Scale {
  zeroPointKey: number = 0;

  offsetY: number = 0;

  dataPerPixel = 0.1;

  tabletParams: TabletParams;

  abstract get yRatio(): number;

  abstract dataToPoint(value: number): number;

  abstract pointToData(value: number): number;

  abstract get onFrame():number;

  abstract get toFrame():number;

  get containerLength() {
    if (this.tabletParams.orientation === 'vertical') {
      return this.tabletParams.canvasSize[1];
    }
    return this.tabletParams.canvasSize[0];
  }

  constructor(tabletParams: TabletParams) {
    this.tabletParams = tabletParams;
  }

  setZeroPoint(zeroPointKey: number) {
    if (this.zeroPointKey === 0) {
      this.zeroPointKey = zeroPointKey;
    }
  }

  setOffsetY(offset: number) {
    this.offsetY = offset;
  }

  setDensity(value: number) {
    this.dataPerPixel = value;
  }
}

export class TimeScale extends Scale {
  get pixelsPerMinute() {
    return 1 / this.dataPerPixel;
  }

  dataToPoint(value: number) {
    const diffMinutes = (value - this.zeroPointKey) / 60000.0;
    return diffMinutes / this.dataPerPixel;
  }

  pointToData(point: number) {
    const minutes = point * this.dataPerPixel;
    return Math.round(this.zeroPointKey + minutes * 60000.0);
  }

  get onFrame() {
    const minutes = this.offsetY * this.dataPerPixel;
    return Math.floor(this.zeroPointKey + minutes * 60000.0);
  }

  get toFrame() {
    const minutes = (this.offsetY + this.containerLength) * this.dataPerPixel;
    return Math.ceil(this.zeroPointKey + minutes * 60000.0);
  }

  get yRatio() {
    return 1 / 60000.0 / this.dataPerPixel;
  }
}

class DepthScale extends Scale {
  get pixelsPerMeter() {
    return 1 / this.dataPerPixel;
  }

  dataToPoint(value: number) {
    return (value - this.zeroPointKey) / this.dataPerPixel;
  }

  pointToData(point: number) {
    const deep = (point * this.dataPerPixel);
    return Math.round((this.zeroPointKey + deep) * 1000) / 1000;
  }

  get onFrame() {
    const deep = this.offsetY * this.dataPerPixel;
    return Math.floor((this.zeroPointKey + deep) * 100) / 100;
  }

  get toFrame() {
    const deep = (this.offsetY + this.containerLength) * this.dataPerPixel;
    return Math.ceil((this.zeroPointKey + deep) * 100) / 100;
  }

  get yRatio() {
    return 1 / this.dataPerPixel;
  }
}

export function getScale(type: TabletType, tabletParams: TabletParams): Scale {
  const fabricMap = {
    [TabletType.Time]: TimeScale,
    [TabletType.Depth]: DepthScale,
  };
  return makeObservable(new fabricMap[type](tabletParams), {
    dataPerPixel: observable,
    zeroPointKey: observable,
    offsetY: observable,
    setZeroPoint: action,
    setOffsetY: action,
    setDensity: action,
    dataToPoint: action.bound,
    pointToData: action.bound,
    containerLength: computed,
    yRatio: computed,
    onFrame: computed,
    toFrame: computed,
  });
}

export function dataToPoint(
  value: number,
  zeroPointKey: number,
  dataPerPixel: number,
  type: TabletType,
) {
  if (type === TabletType.Time) {
    const diffMinutes = (value - zeroPointKey) / 60000.0;
    return diffMinutes / dataPerPixel;
  }
  const pnt = (value - zeroPointKey) / dataPerPixel;
  return Math.floor(pnt);
}
