import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import { Tablet } from '../../stores/Tablet';
import { NavigationLineThumb, NavigationLineSlider } from './Tablet.styled';

const MIN_SIZE = 30;

export const NavigationLine = observer(({ tablet }: { tablet: Tablet }) => {
  const slider = useRef<HTMLDivElement>(null);
  const thumb = useRef<HTMLDivElement>(null);

  const shiftY = useRef(0);

  const containerHeight = tablet.scale.containerLength;
  const contentHeight = tablet.scaleHeight + containerHeight;

  const k = containerHeight / (contentHeight);
  const posOn = tablet.scale.offsetY * k;
  const posTo = (tablet.scale.offsetY + tablet.scale.containerLength) * k;

  let thumbSize = Math.floor(posTo - posOn);
  thumbSize = Math.max(thumbSize, MIN_SIZE);

  const enableScrollRange = contentHeight - containerHeight;
  const enableHeightRange = containerHeight - thumbSize;

  const ptg = Math.abs(tablet.topOffsetY - tablet.scale.offsetY) / enableScrollRange;
  const top = ptg * enableHeightRange;

  const refreshCoords = useDebouncedCallback(() => {
    tablet.refreshCoords();
  }, 300);

  if (enableScrollRange < 0) {
    return null;
  }

  function onPointerDown(event: React.PointerEvent<HTMLDivElement>) {
    event.preventDefault();

    function onPointerMove(e: PointerEvent) {
      if (slider.current && thumb.current) {
        let newTop: number;
        if (tablet.params.orientation === 'vertical') {
          newTop = e.clientY - slider.current.getBoundingClientRect().top - thumbSize / 2;
        } else {
          newTop = e.clientX - slider.current.getBoundingClientRect().left - thumbSize / 2;
        }

        const ptg2 = newTop / enableHeightRange;
        const newScrollTop = Math.ceil(ptg2 * enableScrollRange);

        let offset = tablet.topOffsetY + newScrollTop;
        offset = Math.max(offset, tablet.topOffsetY);
        offset = Math.min(offset, tablet.bottomOffsetY);

        tablet.scale.setOffsetY(offset);
        refreshCoords();
      }
    }
    function onPointerUp() {
      thumb.current?.removeEventListener('pointermove', onPointerMove);
      thumb.current?.removeEventListener('pointerup', onPointerUp);
      tablet.refreshCoords();
    }

    if (thumb.current) {
      shiftY.current = event.clientY - thumb.current.getBoundingClientRect().top;

      thumb.current.setPointerCapture(event.pointerId);

      thumb.current.addEventListener('pointermove', onPointerMove);
      thumb.current.addEventListener('pointerup', onPointerUp);
    }
  }

  return (
    <NavigationLineSlider
      ref={slider}
      height={tablet.params.orientation === 'vertical' ? containerHeight : 1}
      width={tablet.params.orientation === 'vertical' ? 1 : containerHeight}
      offsetX={tablet.params.orientation === 'vertical' ? tablet.scalePosition.width - 4 : tablet.scalePosition.x}
      offsetY={tablet.scalePosition.y}
    >
      <NavigationLineThumb
        ref={thumb}
        onDragStart={() => false}
        size={10}
        thumbSize={Math.round(thumbSize)}
        thumbOffset={Math.round(top)}
        offsetY={tablet.scalePosition.subSize}
        orientation={tablet.params.orientation}
        onPointerDown={(e) => onPointerDown(e)}
      />
    </NavigationLineSlider>
  );
});
