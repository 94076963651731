import { makeAutoObservable, runInAction } from 'mobx';
import { TabletDto, TemplateViewModel } from '../api/dto/TabletDto';
import { saveTemplate } from '../api/tablets';
import {
  getTemplates,
  setSelectedTemplates,
  deleteTemplate,
  copyTemplate,
  uploadTemplate,
  renameTemplate,
  downloadTemplate,
} from '../api/templates';
import { throwError } from '../errorHandler';

class TabletsStore {
  inProgress = false;

  errors = null;

  list: TemplateViewModel[] = [];

  public userTemplates: TemplateViewModel[] = [];

  public systemTemplates: TemplateViewModel[] = [];

  public selectedUserTemplates: number[] = [];

  public selectedSystemTemplates: number[] = [];

  public isFetching = false;

  constructor() {
    makeAutoObservable(this);
  }

  public get selectedList() {
    return this.list.filter((x) => this.selectedSystemTemplates.includes(x.id)
      || this.selectedUserTemplates.includes(x.id));
  }

  async saveTemplate(tablet: TabletDto, name?: string) {
    if (name) {
      // eslint-disable-next-line no-param-reassign
      tablet.name = name;
    }

    this.inProgress = true;
    this.errors = null;
    this.list = [];

    try {
      const data = await saveTemplate(tablet);
      await this.getAll();
      const found = this.list.find((t) => t.tabletName === name);
      if (tablet.id === 0 && found) {
        runInAction(() => {
          this.selectedUserTemplates.push(found.id);
        });
        await this.setSelected(this.selectedUserTemplates, false);
      }
      return data;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('ErrorToSave')(e);
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  public async setSelected(ids: number[], system: boolean) {
    if (system) {
      this.selectedSystemTemplates = ids;
    } else {
      this.selectedUserTemplates = ids;
    }

    this.isFetching = true;
    this.errors = null;

    try {
      await setSelectedTemplates([...this.selectedSystemTemplates, ...this.selectedUserTemplates]);
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('ErrorToSave')(e);
    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  }

  public async getAll() {
    this.isFetching = true;
    this.errors = null;

    try {
      const templates = await getTemplates();
      runInAction(() => {
        this.userTemplates = templates
          .filter((x) => !x.system);
        this.systemTemplates = templates
          .filter((x) => x.system);
        this.selectedUserTemplates = this.userTemplates
          .filter((x) => x.selected)
          .map((x) => x.id);
        this.selectedSystemTemplates = this.systemTemplates
          .filter((x) => x.selected)
          .map((x) => x.id);
        this.list = templates;
      });

      return templates;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('ErrorToFetch')(e);
      throw e;
    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  }

  public async delete(id: number) {
    this.isFetching = true;
    this.errors = null;

    try {
      await deleteTemplate(id);
      await this.getAll();
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('AnErrorOccurredWhileDeleting')(e);
    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  }

  public async copy(id: number, system: boolean) {
    this.isFetching = true;
    this.errors = null;

    try {
      await copyTemplate(id, !system);
      await this.getAll();
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('ErrorToSave')(e);
    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  }

  public async rename(id: number, name: string) {
    this.isFetching = true;
    this.errors = null;

    try {
      await renameTemplate(id, name);
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('ErrorToSave')(e);
    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  }

  // options -> UploadRequestOption -> node_modules/rc-upload/lib/interface.d.ts
  public async upload(options: any, system: boolean) {
    const { onSuccess, onError, file } = options;

    this.isFetching = true;
    this.errors = null;

    try {
      await uploadTemplate(file, system);
      await this.getAll();
      onSuccess('Ok');
    } catch (e: any) {
      onError({ e });
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('ErrorToSave')(e);
      throw e;
    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  }

  public async download(id: number, name: string) {
    this.isFetching = true;
    this.errors = null;

    try {
      await downloadTemplate(id, name);
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('ErrorToOpen')(e);
      throw e;
    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  }
}

export const templatesStore = new TabletsStore();
