/* eslint-disable @typescript-eslint/no-unused-vars */
import { Theme } from '@emotion/react';
import * as PIXI from 'pixi.js';
import { render, Sprite, unmountComponentAtNode } from '@inlet/react-pixi';
import { when } from 'mobx';
import { Tablet } from './stores/Tablet';
import { AppLocalizationProvider } from './localization';
import { Track } from './components/Tablet/Track';
import { Coordinator } from './stores/Coordinator';
import { CurveHeadersPixi } from './components/Tablet/CurveHeadersPixi';
import { TabletType } from './enums/TabletType';
import { TimeMainScale } from './components/Tablet/TimeMainScale';
import { DepthScale } from './components/Tablet/DepthScale';
import { ScaleDepthLine } from './stores/ScaleDepthLine';
import { ScaleTimeline } from './stores/ScaleTimeline';
import { TracksBorders } from './components/Tablet/TracksBorders';
import { TabletProvider } from './components/Tablet/TabletProvider';
import { localizationStore, scaleUnitStore, timeZonesStore } from './stores/init-stores-global';

const PDF_MAIN_PAGE_TITLE_HIGHT = 78 * 2; // 117;
const PDF_PAGE_TITLE_HIGHT = 48 * 2; // 77;

export async function getTabletImages(
  originalTablet: Tablet,
  onData: number,
  toData: number,
  theme: Theme,
  scale: number,
  portrait = true,
) {
  const tabletDto = originalTablet.getTabletDto(originalTablet.tabletDto.id);
  const tablet = new Tablet(timeZonesStore, tabletDto, () => {}, originalTablet.templateData, 'pdf');

  tablet.params.setOrientation('vertical');
  tablet.params.isPDF = true;
  if (tablet.tabletType === TabletType.Depth) {
    tablet.scaleLine = new ScaleDepthLine(
      tablet.scale,
      tablet.baseTextStyle.fontSize,
      tablet.params,
      [scale, scale / 5],
    );
  } else if (tablet.tabletType === TabletType.Time) {
    tablet.scaleLine = new ScaleTimeline(
      tablet.scale,
      timeZonesStore,
      tablet.baseTextStyle.fontSize,
      tablet.params,
      [scale * 60, (scale * 60) / 5],
    );
  }
  tablet.scale.setDensity(scale / 30);

  tablet.setTrackInfoCollapsed(true);

  tablet.coordinator = new Coordinator(
    tablet.tracks,
    tablet.params,
    tablet.scalePosition,
    tablet.tabletScroll,
  );

  await tablet.sourceDataMap.fetchSources();
  await tablet.sourceDataMap.refreshCoordsPromise(onData, toData, true);
  await when(() => Array.from(tablet.sourceDataMap.map.values())
    .every((c) => (c.curveCoordinates?.inProgress === false)));
  await when(() => tablet.sourceDataMap.sharedTimeStore.layers.inProgress === false);

  tablet.scale.setZeroPoint(toData);

  const [height, width] = portrait ? [877 * 2, 620 * 2] : [620 * 2, 877 * 2];
  const images: string[] = [];
  const app = new PIXI.Application({
    width,
    height: height - PDF_PAGE_TITLE_HIGHT,
    backgroundColor: PIXI.utils.string2hex(theme.backgroundColor),
    // resolution: 2,
  });

  tablet.params.setCanvasSize(width, height - PDF_MAIN_PAGE_TITLE_HIGHT);

  const startY = tablet.scale.dataToPoint(onData) - tablet.headerHeight;

  tablet.scale.setOffsetY(startY);

  // Pre render component for masks
  tablet.scale.setOffsetY(tablet.scale.offsetY - 1);
  tabletToBase64(
    app,
    tablet,
    width,
    0,
    theme,
    toData,
  );
  tablet.scale.setOffsetY(tablet.scale.offsetY + 1);

  const mainPageImage = tabletToBase64(
    app,
    tablet,
    width,
    height - PDF_MAIN_PAGE_TITLE_HIGHT,
    theme,
    toData,
  );
  images.push(mainPageImage);

  tablet.scale.setOffsetY(tablet.scale.offsetY + height - PDF_MAIN_PAGE_TITLE_HIGHT);

  while (tablet.scale.offsetY < 0) {
    app.view.height = height - PDF_PAGE_TITLE_HIGHT;
    tablet.params.setCanvasSize(width, height - PDF_PAGE_TITLE_HIGHT);
    const image = tabletToBase64(
      app,
      tablet,
      width,
      height - PDF_PAGE_TITLE_HIGHT,
      theme,
      toData,
    );
    images.push(image);
    tablet.scale.setOffsetY(
      tablet.scale.offsetY + height - PDF_PAGE_TITLE_HIGHT - tablet.headerHeight,
    );
  }

  unmountComponentAtNode(app.stage);
  app.destroy();

  return images;
}

export function tabletToBase64(
  app: PIXI.Application,
  tablet: Tablet,
  width: number,
  height: number,
  theme: Partial<Theme>,
  toData: number,
) {
  // eslint-disable-next-line no-param-reassign
  app.view.height = height;
  render(
    <TabletComponent tablet={tablet} height={height} theme={theme} toData={toData} />,
    app.stage,
  );
  app.render();
  return app.view.toDataURL();
}

type TabletComponentProps = {
  tablet: Tablet;
  height: number;
  theme: Partial<Theme>;
  toData: number;
};

function TabletComponent({
  tablet, height, theme, toData,
}: TabletComponentProps) {
  const themeHex = {
    backgroundColor: PIXI.utils.string2hex(theme.backgroundColor!),
    color: PIXI.utils.string2hex(theme.color!),
    brightColor: PIXI.utils.string2hex(theme.brightColor!),
    borderColor: PIXI.utils.string2hex(theme.borderColor!),
    gridColor: PIXI.utils.string2hex(theme.gridColor!),
    lightPrimary: PIXI.utils.string2hex(theme.lightPrimary!),
  };
  return (
    <AppLocalizationProvider>
      <TabletProvider
        tablet={tablet}
        themeHex={themeHex}
        localizationStore={localizationStore}
        timeZonesStore={timeZonesStore}
        scaleUnitStore={scaleUnitStore}
      >
        {tablet.tabletType === TabletType.Time ? (
          <TimeMainScale key="time-scale" />
        ) : (
          <DepthScale tablet={tablet} key="depth-scale" />
        )}
        {tablet.tracks.withOffset.map(([track, offset]) => (
          <>
            <Track
              key={track.innerId}
              track={track}
              tablet={tablet}
              offset={offset + tablet.scalePosition.tracksOffset}
              canDrop={false}
              maskTo={toData}
            />
            <CurveHeadersPixi
              key={`${track.innerId}-header`}
              track={track}
              offset={offset + tablet.scalePosition.tracksOffset}
            />
          </>
        ))}
        <TracksBorders tablet={tablet} />
        <Sprite
          x={tablet.scalePosition.tracksOffset}
          y={0}
          height={2}
          width={tablet.tracks.tracksWidth}
          texture={PIXI.Texture.WHITE}
          tint={PIXI.utils.string2hex(theme.borderColor as string)}
        />
        <Sprite
          x={tablet.scalePosition.tracksOffset}
          y={height - 2}
          height={2}
          width={tablet.tracks.tracksWidth}
          texture={PIXI.Texture.WHITE}
          tint={PIXI.utils.string2hex(theme.borderColor as string)}
        />
      </TabletProvider>
    </AppLocalizationProvider>
  );
}
