import { observer } from 'mobx-react-lite';
import { InnerChartType } from '../../stores/SourceDataMap';
import { Tablet } from '../../stores/Tablet';
import { Curve } from '../../stores/Tracks';
import { CurveParams } from './CurveParams';
import { ImageParams } from './ImageParams';

export const CurveParamsResolver = observer(({ tablet, curve }:
{ tablet: Tablet, curve: Curve }) => {
  if (curve.sourceData?.innerChartType === InnerChartType.TimeCurve
    || curve.sourceData?.innerChartType === InnerChartType.DepthCurve) {
    return (
      <CurveParams tablet={tablet} curve={curve} />
    );
  }
  if (curve.sourceData?.innerChartType === InnerChartType.Image) {
    return (
      <ImageParams curve={curve} />
    );
  }
  return null;
});
