import { action, makeAutoObservable, runInAction } from 'mobx';
import { notification } from 'antd';
import { FolderShort } from './Tablet';
import { FolderObject } from './folderTree';
import { ObjectDto } from '../api/dto/ObjectDto';
import { TabletLoading } from './TabletLoading';
import { ScreenDto, TabletInfoDto } from '../api/screens';
import { throwError } from '../errorHandler';
import { localizationStore } from './init-stores-global';

const TABLET_MAX_COUNT = 5;

export class TabletsStore {
  inProgress = false;

  errors = null;

  list: TabletLoading[] = [];

  saveCurrentScreen: () => void;

  constructor(saveCurrentScreen: () => void) {
    this.saveCurrentScreen = saveCurrentScreen;
    makeAutoObservable(this, { closeTablet: action.bound });
  }

  get tablets() {
    return this.list;
  }

  createTablet() {
    const tabletLoading = new TabletLoading(this.closeTablet);
    this.list.push(tabletLoading);
    return tabletLoading;
  }

  closeTablet(tablet: TabletLoading) {
    const index = this.list.findIndex((t) => t === tablet);
    this.list.splice(index, 1);
    this.saveCurrentScreen();
  }

  async createTabletByCurve(
    tabletName: string,
    object: FolderObject,
    well: ObjectDto,
    bole: ObjectDto,
    log?: ObjectDto,
  ) {
    if (this.list.length === TABLET_MAX_COUNT) {
      notification.error({
        message: localizationStore.l10n?.getString('Tablet-limit-reached'),
        description: localizationStore.l10n?.getString('Please-use-new-browser-tab'),
        placement: 'bottomRight',
      });
      return;
    }

    const tabletLoading = this.createTablet();
    tabletLoading.createTabletByCurve(tabletName, object, well, bole, log);
  }

  async createTabletById(
    tabletId: number,
    templateName: string,
    well: FolderShort,
    bole: FolderShort,
    log?: FolderShort,
  ) {
    if (this.list.length === TABLET_MAX_COUNT) {
      notification.error({
        message: localizationStore.l10n?.getString('Tablet-limit-reached'),
        description: localizationStore.l10n?.getString('Please-use-new-browser-tab'),
        placement: 'bottomRight',
      });
      return;
    }
    const tabletLoading = this.createTablet();
    await tabletLoading.createTabletById(tabletId, templateName, well, bole, log);
  }

  async createTabletsFromScreenId(screen: ScreenDto) {
    try {
      runInAction(() => {
        this.list = [];
        (screen.screenInfo || []).forEach((tablet: TabletInfoDto) => {
          const well = { externalId: tablet.wellboreId, text: tablet.wellboreName };
          const bole = { externalId: tablet.boleId, text: tablet.boleName };
          const log = tablet.logId ? { externalId: tablet.logId, text: tablet.logName } : undefined;
          this.createTabletById(tablet.tabletId, tablet.templateName, well, bole, log);
        });
      });
      return screen;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('Open-screen-error')(e);
      throw e;
    }
  }

  get editingTablet() {
    return this.list.find((tablet) => tablet.tablet?.editMode)?.tablet || null;
  }

  get editingTrack() {
    return this.editingTablet?.tracks.selected || null;
  }

  get editingCurve() {
    return this.editingTablet?.tracks.selectedSource || null;
  }
}
