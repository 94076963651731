import { Navigate, useSearchParams } from 'react-router-dom';
import { authStore } from '../stores/auth';

export function RedirectAuth() {
  const [params] = useSearchParams();
  const token = params.get('token');
  const wellId = params.get('wellboreId');
  if (token) {
    authStore.setToken(token);
  }
  return <Navigate to={wellId ? `/?wellId=${wellId}` : '/'} replace />;
}
