import { TypedJSON } from 'typedjson';
import { axiosApiInstance as axios } from './axiosApiInstance';
import { TabletDto } from './dto/TabletDto';

const tabletSerializer = new TypedJSON(TabletDto);

export function getTablet(tabletId: number, wellId: number, boleId: number, logId = 0) {
  return axios.get<{ result: object }>('/Tablet/GetTablet', {
    params: {
      tabletId, wellId, boleId, logId,
    },
  }).then(({ data }) => tabletSerializer.parse(data.result)!);
}

export function saveTemplate(tablet: TabletDto) {
  return axios.post<{ result: TabletDto }>('/Tablet/SaveTemplate', tablet)
    .then(({ data }) => data.result);
}
