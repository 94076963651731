import * as PIXI from 'pixi.js';
import { Graphics } from '@inlet/react-pixi';
import { useCallback, useContext } from 'react';
import { ValueScaleType } from '../../api/dto/TrackDto';
import { TabletContext } from './TabletProvider';

type Props = {
  height: number;
  trackWidth: number;
  gridBaseVert: number;
  gridSecondVert: number;
  valueScaleType: ValueScaleType;
  scaleFrom: number;
  scaleTo: number;
};

export function TrackGrid({
  height, trackWidth, gridBaseVert, gridSecondVert, valueScaleType, scaleFrom, scaleTo,
}: Props) {
  const { themeHex } = useContext(TabletContext);

  const draw = useCallback((g: PIXI.Graphics) => {
    g.clear();
    g.lineStyle(1, themeHex.gridColor, 0.3);
    if (valueScaleType === ValueScaleType.Log) {
      if (scaleFrom <= 0) {
        return;
      }
      const xRatio = trackWidth
        / (Math.log10(scaleTo <= 0 ? 1 : scaleTo) - Math.log10(scaleFrom <= 0 ? 1 : scaleFrom));
      const points = [];

      const start = 10 ** Math.floor(Math.log10(scaleFrom));
      for (let i = start; i < scaleTo; i *= 10) {
        for (let j = 2; j <= 10; j += 2) {
          if (j * i <= scaleTo) {
            points.push(Math.log10(j * i));
          }
        }
      }
      points.forEach((point) => {
        const xPoint = Math.round((point - Math.log10(scaleFrom)) * xRatio);
        if (point % 1 === 0) {
          g.lineStyle(1, themeHex.gridColor, 0.7);
        } else {
          g.lineStyle(1, themeHex.gridColor, 0.3);
        }
        if (xPoint < trackWidth - 1) {
          g.moveTo(
            xPoint,
            0,
          );
          g.lineTo(
            xPoint,
            height,
          );
        }
      });
    }
    if (valueScaleType === ValueScaleType.Linear) {
      const stepBaseVert = +(trackWidth / gridBaseVert).toFixed(1);
      const stepSecondVert = +(trackWidth / (gridBaseVert * gridSecondVert)).toFixed(1);

      for (let i = 0; i < gridBaseVert; i += 1) {
        for (let j = 0; j < gridSecondVert; j += 1) {
          g.moveTo(
            stepBaseVert * i + stepSecondVert * j,
            0,
          );
          g.lineTo(
            stepBaseVert * i + stepSecondVert * j,
            height,
          );
        }
      }
    }
    if (valueScaleType === ValueScaleType.Comments) {
      g.moveTo(0, 0);
      g.lineTo(0, height);
    }
  }, [themeHex.gridColor, valueScaleType, trackWidth,
    scaleTo, scaleFrom, height, gridBaseVert, gridSecondVert]);
  return <Graphics draw={draw} />;
}
