import { css, Global } from '@emotion/react';
import { useLocalization } from '@fluent/react';
import { Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { themingStore } from '../../stores/init-stores-global';
import { DarkThemeIcon, LightThemeIcon } from '../custom-icon';
import { ThemeSwitcherWrapper } from './ThemeSwitcher.styled';

type Props = {
  collapsed: boolean;
};
export const ThemeSwitcher = observer(({ collapsed }: Props) => {
  const { l10n } = useLocalization();

  const [animationOff, setAnimationOff] = useState(false);

  const timer = useRef<number | undefined>(undefined);

  function onChange(value: boolean) {
    clearTimeout(timer.current);
    setAnimationOff(true);
    themingStore.changeTheme(value ? 'dark' : 'light');
    timer.current = setTimeout(() => setAnimationOff(false), 300) as unknown as number;
  }

  return (
    <ThemeSwitcherWrapper>
      {animationOff && (
        <Global
          styles={css`
            * {
              transition: all 0s !important;
            }
          `}
        />
      )}
      <Switch
        size="default"
        checkedChildren={<DarkThemeIcon />}
        unCheckedChildren={<LightThemeIcon />}
        checked={themingStore.current === 'dark'}
        onChange={(value) => onChange(value)}
      />
      {!collapsed && <div>{l10n.getString('DayNight')}</div>}
    </ThemeSwitcherWrapper>
  );
});
