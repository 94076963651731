import { observer } from 'mobx-react-lite';
import { Modal, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useLocalization } from '@fluent/react';
import { useTheme } from '@emotion/react';
import TemplatesCard from './TemplatesCard';
import { templatesStore } from '../../stores/templatesStore';
import {
  TemplateModalContent,
} from './Template.styled';
import RotatingLines from '../Loader/RotatingLines';

type Props = {
  visible: boolean;
  onClose: () => void;
};

export const TemplatesModal = observer(({ visible, onClose }: Props) => {
  const { l10n } = useLocalization();
  const theme = useTheme();

  useEffect(() => {
    templatesStore.getAll();
  }, []);

  return (
    <Modal
      title={l10n.getString('ManagingTemplates')}
      open={visible}
      width={1000}
      onCancel={() => onClose()}
      footer={null}
      closeIcon={<Tooltip title={l10n.getString('Close-window')}><CloseOutlined /></Tooltip>}
    >
      {templatesStore.inProgress
        ? (
          <div style={{ textAlign: 'center' }}>
            <RotatingLines width="32" strokeColor={theme.lightPrimary} />
          </div>
        )
        : (
          <TemplateModalContent style={{ gap: 10 }}>
            <TemplatesCard templates={templatesStore.userTemplates} system={false} />
            <TemplatesCard templates={templatesStore.systemTemplates} system />
          </TemplateModalContent>
        )}
    </Modal>
  );
});
