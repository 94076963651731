import { observer } from 'mobx-react-lite';
import React from 'react';
import { CurveStatus } from '../../stores/SourceDataMap';
import { Tablet } from '../../stores/Tablet';
import { Curve } from '../../stores/Tracks';
import { LoaderString } from './LoaderString';
import { CurveBlock, CurveBody, CurveName } from './TabletTracksInfo.styled';

type Props = {
  tablet: Tablet;
  source: Curve;
  collapsed: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
  onContextMenu: React.MouseEventHandler<HTMLDivElement>
};

export const ImageHeader = observer(({
  tablet, source, collapsed, onClick, onContextMenu,
} : Props) => (
  <CurveBlock
    key={source.sourceDto.externalId}
    onClick={onClick}
    onContextMenu={onContextMenu}
    collapsed={collapsed}
    selected={tablet.tracks.selectedSource === source}
    reverse={source.params.legendPosition === 'bottom'}
  >
    {collapsed ? (
      <>
        <div>{!source.params.dynamicRange && source.scaleFrom}</div>
        <div>
          {source.sourceDto.name}
        </div>
        <div>{!source.params.dynamicRange && source.scaleTo}</div>
      </>
    ) : (
      <>
        <CurveName>
          <div />
          <div style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
            {source.sourceDto.name}
          </div>
          <div />
        </CurveName>

        <CurveBody>
          {!source.params.dynamicRange && (
            <>
              <div>{source.scaleFrom}</div>
              <div>
                {(source.sourceData?.status === CurveStatus.ServerFetching
                  || source.sourceData?.status === CurveStatus.MetaDataFetching
                  || source.sourceData?.status === CurveStatus.NoData)
                  && (<LoaderString source={source} position="absolute" />)}
              </div>
              <div>{source.scaleTo}</div>
            </>
          )}
        </CurveBody>
      </>
    )}
  </CurveBlock>
));
