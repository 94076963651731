import { makeAutoObservable } from 'mobx';
import { ThemeTypes, themeMap } from '../theming';

import { theme as lightThemeObject } from '../theming/light.theme';
import { theme as darkThemeObject } from '../theming/dark.theme';

export const LC_SELECTED_THEME = 'LC_SELECTED_THEME';

const defaultTheme = (localStorage.getItem(LC_SELECTED_THEME) as ThemeTypes) || 'dark';
export class ThemingStore {
  current: ThemeTypes = defaultTheme;

  emotionTheme;

  emotionThemes = {
    light: lightThemeObject,
    dark: darkThemeObject,
  };

  constructor() {
    themeMap[this.current].styles.use();
    makeAutoObservable(this);
    this.emotionTheme = this.emotionThemes[this.current];
  }

  changeTheme(id: ThemeTypes) {
    themeMap[this.current].styles.unuse();
    this.current = id;
    themeMap[this.current].styles.use();
    this.emotionTheme = this.emotionThemes[this.current];
    localStorage.setItem(LC_SELECTED_THEME, this.current);
  }
}

// export const themingStore = new ThemingStore();
