import { useTheme } from '@emotion/react';
import { useLocalization } from '@fluent/react';
import { useEffect, useState } from 'react';
import { lithologyStore } from '../../stores/lithologyStore';
import { Track } from '../../stores/Tracks';
import { round } from '../../utils';
import kpiMap from '../../dictionaries/kpiMap.json';
import { useThrottle } from '../../hooks/useThrottle';
import { LineProps } from './types';
import { InnerChartType } from '../../stores/SourceDataMap';
import { ValueScaleType } from '../../api/dto/TrackDto';

export function useLines(
  currentY: number,
  tracks: Track[],
): (LineProps | null)[] {
  const theme = useTheme();
  const { l10n } = useLocalization();

  const [currentLines, setCurrentLines] = useState<(LineProps | null)[]>([]);
  const getLines = useThrottle((y: number) => {
    const lines = tracks.flatMap((track) => track.sourcesSorted.map((source) => {
      const point = source.sourceData?.curveCoordinates?.getPoint(y);

      if (point == null) {
        return null;
      }

      if (track?.params.valueScaleType === ValueScaleType.Numeric) {
        return null;
      }

      if (source.sourceData?.innerChartType === InnerChartType.Comments) {
        return null;
      }

      if (source.sourceData?.innerChartType === InnerChartType.Cuttings) {
        if (track?.cuttingsLogData) {
          const index = (track?.cuttingsLogData.currentValues[source.sourceDto.externalId] || [])
            .findIndex((el, i, array) => {
              const nextEl = array.at(i + 1);
              if (!nextEl) {
                return false;
              }

              if (track.cuttingsLogData?.maxStep
            && (track.cuttingsLogData?.maxStep < nextEl.key - el.key)) {
                return false;
              }

              if (el.key <= y && y < nextEl.key) {
                return true;
              }

              return false;
            });

          if (index > -1) {
            const dot1 = track.cuttingsLogData
              .currentValues[source.sourceDto.externalId][index];
            const dot2 = track.cuttingsLogData
              .currentValues[source.sourceDto.externalId][index + 1];

            return {
              key: source.sourceDto.externalId,
              name: source.sourceDto.name,
              value: Math.round(
                ((y - dot1.key) / (dot2.key - dot1.key)) * (dot2.value - dot1.value) + dot1.value,
              ),
              color: source.sourceData?.imageLithology?.color ?? theme.color,
              range: null,
              minWidth: 48,
              currentUom: source.params.currentUom,
              priority: 0,
            };
          }
        }
      }

      if (source.sourceData?.innerChartType === InnerChartType.Lithology) {
        const lithology = lithologyStore.map.get(Number(point.key));
        let name = (lithology?.message && l10n.getString(lithology.message)) ?? '';
        if (lithology?.message === '') {
          name = lithology.name;
        }
        return {
          key: source.sourceDto.externalId,
          name: source.sourceDto.name,
          value: name,
          color: lithology?.fillColor ?? theme.color,
          minWidth: 48,
          currentUom: source.params.currentUom,
          priority: 0,
        };
      }

      if (source.sourceData?.innerChartType === InnerChartType.DepthCurve) {
        return {
          key: source.sourceDto.externalId,
          name: source.sourceDto.name,
          value: point ? round(
            Number(point.value) * source.params.ratioUom,
            source.params.fractionDigits,
          ) : '',
          color: source.params.color || theme.color,
          minWidth: 48,
          currentUom: source.params.currentUom,
          priority: 0,
        };
      }

      if (source.sourceData?.innerChartType === InnerChartType.TimeCurve) {
        if (Array.isArray(point.value)) {
          const min = point.value[0] * source.params.ratioUom;
          const max = point.value[1] * source.params.ratioUom;
          return {
            key: source.sourceDto.externalId,
            name: source.sourceDto.name,
            value: `${round(min, source.params.fractionDigits)} — ${round(max, source.params.fractionDigits)}`,
            color: source.params.color || theme.color,
            minWidth: 120,
            currentUom: source.params.currentUom,
            priority: 0,
          };
        }
        return {
          key: source.sourceDto.externalId,
          name: source.sourceDto.name,
          value: point ? round(
            Number(point.value) * source.params.ratioUom,
            source.params.fractionDigits,
          ) : '',
          color: source.params.color || theme.color,
          minWidth: 48,
          currentUom: source.params.currentUom,
          priority: 0,
        };
      }

      if (source.sourceData?.innerChartType === InnerChartType.Block) {
        const kpi = point && kpiMap[Number(point.value)];
        return {
          key: source.sourceDto.externalId,
          name: source.sourceDto.name,
          value: kpi?.message ? l10n.getString(kpi?.message) : '',
          color: kpi ? kpi.color : theme.color,
          minWidth: 48,
          currentUom: null,
          priority: 1,
        };
      }

      if (source.sourceData?.innerChartType === InnerChartType.Image) {
        return {
          key: source.sourceDto.externalId,
          name: source.sourceDto.name,
          value: '',
          color: theme.color,
          minWidth: 48,
          currentUom: source.params.currentUom,
          priority: 0,
        };
      }

      return null;
    }).filter((i) => !!i)).sort((a, b) => (b?.priority || 0) - (a?.priority || 0));
    setCurrentLines(lines);
  }, 100, [tracks, tracks.length]);
  useEffect(() => {
    getLines(currentY);
  }, [currentY, getLines, tracks]);
  return currentLines;
}
