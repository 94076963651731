import { CurvePoint as Point } from '../api/curvesCache';

export class CuttingsLogData {
  min: number = null as unknown as number;

  max: number = null as unknown as number;

  currentStep: [number, number] = [null, null] as unknown as [number, number];

  steps:Record<number, number> = {};

  accumulatedValues: Record<number, Point> = {};

  gaps: Record<number, Point> = {};

  maxStep: number;

  currentValues: Record<number, Point[]> = {};

  setAccumulatedValues(coords: Point[]) {
    coords.forEach((point) => {
      if (this.accumulatedValues[point.key]) {
        this.accumulatedValues[point.key].value += point.value;
        return;
      }
      this.accumulatedValues[point.key] = { ...point };
    });
  }

  checkGaps(coords: Point[]) {
    coords.forEach((point, index, array) => {
      const nextPoint = array.at(index + 1);
      this.setStep(point.key, nextPoint?.key);
      this.accumulatedValues[point.key] = { ...point, value: 0 };
      if (this.gaps[point.key]) {
        this.gaps[point.key].value += point.value;
        return;
      }
      this.gaps[point.key] = { ...point };
    });
  }

  getValues(coords: Point[], externalId: number) {
    if (coords.length === 0) {
      return [];
    }
    // const mutationCoords: Point[] = [];

    this.currentValues[externalId] = [];

    // mutationCoords.push(this.setZeroStart(coords[0]));

    // проходим по координатам и проверяем на наличие разрывов,
    // заполняем новый массив координат для текущей кривой
    // coords.forEach((point) => {
    // const nextPoint = array.at(index + 1);
    // mutationCoords.push({ ...point });
    // if (nextPoint && this.maxStep < (nextPoint.key - point.key)) {
    //   mutationCoords.push(this.setUp(point));
    //   mutationCoords.push(this.setDown(nextPoint));
    // }
    // });

    const mutationCoords = coords.map((p) => ({ ...p }));
    // mutationCoords.push(this.setZeroEnd(mutationCoords[mutationCoords.length - 1]));
    // добавляем новый массив к накопленным координатам
    this.setAccumulatedValues(mutationCoords);

    // достаём накопленные координаты в зависимости от текущих
    const result = this.getAccumulatedValues(mutationCoords, externalId);
    return result;
  }

  clear() {
    this.accumulatedValues = {};
  }

  setUp(coord: Point) {
    const upValue = { y: coord.key, value: 0, key: coord.key };

    if (this.gaps[coord.key + this.currentStep[0]]) {
      upValue.key += this.currentStep[0];
      upValue.y = upValue.key;
    } else {
      const checkStep = this.checkStepUp(coord.key);

      if (checkStep) {
        upValue.key += checkStep;
        upValue.y = upValue.key;
      } else {
        upValue.key += 1;
      }
    }

    return upValue;
  }

  setDown(coord: Point) {
    const downValue = { y: coord.key, value: 0, key: coord.key };

    if (this.gaps[coord.key - this.currentStep[0]]) {
      downValue.key -= (this.currentStep[0] || 0);
      downValue.y = downValue.key;
    } else {
      downValue.key -= 1;
    }

    return downValue;
  }

  getRangesDefectData() {
    const rangesDefectData = [];

    const accumulatedValues = [...Object.values(this.accumulatedValues)];

    const range = [];
    for (let i = 0; i < accumulatedValues.length; i += 1) {
      if (range.length === 0 && accumulatedValues[i].value > 100) {
        range.push(accumulatedValues[(i > 0 ? i : 1) - 1]);
      }

      if (range.length === 1 && accumulatedValues[i].value <= 100) {
        range.push(accumulatedValues[i]);
      }

      if (range.length === 2) {
        rangesDefectData.push([...range]);
        range.length = 0;
      }
    }
    if (range.length === 1) {
      range.push(accumulatedValues[accumulatedValues.length - 1]);
      rangesDefectData.push([...range]);
    }

    return rangesDefectData;
  }

  checkStepUp(key: number) {
    for (let i = this.currentStep[0]; i > 0; i -= 1) {
      if (this.gaps[key + i]) {
        return i;
      }
    }

    return false;
  }

  setZeroStart(coord: Point) {
    const zeroStartValue = { y: coord.key, value: 0, key: coord.key };

    if (this.min < coord.key - this.currentStep[0] && this.gaps[coord.key - this.currentStep[0]]) {
      zeroStartValue.y -= this.currentStep[0];
    } else {
      zeroStartValue.key -= 1;
    }

    return zeroStartValue;
  }

  setZeroEnd(coord: Point) {
    const zeroEndValue = { y: coord.key, value: 0, key: coord.key };

    if (coord.key + this.currentStep[0] < this.max && this.gaps[coord.key + this.currentStep[0]]) {
      zeroEndValue.y += this.currentStep[0];
    } else {
      zeroEndValue.key += 1;
    }

    return zeroEndValue;
  }

  getAccumulatedValues(mutationCoords: Point[], externalId: number) {
    const result: Point[] = [];
    Object.values(this.accumulatedValues).forEach((point, index, array) => {
      result.push({ ...point });
      const nextPoint = array.at(index + 1);
      if (nextPoint && this.maxStep < Math.abs(nextPoint.key - point.key)) {
        result.push(this.setUp(point));
        result.push(this.setDown(nextPoint));
      }
    });

    const mutationCoordsToObj = mutationCoords.reduce<Record<number, Point>>((target, value) => {
      // eslint-disable-next-line no-param-reassign
      target[value.key] = value;
      return target;
    }, {});

    for (let i = 0; i < result.length; i += 1) {
      const coord = { ...result[i], value: (mutationCoordsToObj[result[i].key]?.value || 0) };
      this.currentValues[externalId].push(coord);
    }

    for (let i = result.length - 1; i >= 0; i -= 1) {
      const point = {
        ...result[i],
        value: result[i].value - (mutationCoordsToObj[result[i].key]?.value || 0),
      };
      result.push(point);
    }

    const polygons: Point[][] = [[]];

    let currentPolygon = polygons[0];

    let currentBlock = 0;

    const length = result.length / 2;

    for (let i = 0; i < length; i += 1) {
      if ((result[i] as any).y) {
        // eslint-disable-next-line for-direction
        for (let j = i; j > currentBlock; j -= 1) {
          currentPolygon.push(result[result.length - j]);
        }
        i += 1;
        if (result[i + 1]) {
          currentBlock = i + 1;
          const polygonsCount = polygons.push([]);
          currentPolygon = polygons[polygonsCount - 1];
        }
      } else {
        currentPolygon.push(result[i]);
      }
      if (i === length - 1) {
        for (let j = i + 1; j > currentBlock; j -= 1) {
          currentPolygon.push(result[result.length - j]);
        }
      }
    }

    return polygons;
  }

  setStep(currentKey?: number, nextKey?: number) {
    if (currentKey == null) {
      return;
    }

    if (!this.min || currentKey < this.min) {
      this.min = currentKey;
    }

    if (!this.max || this.max < currentKey) {
      this.max = currentKey;
    }

    if (nextKey == null) {
      return;
    }

    const newStep = nextKey - currentKey;

    if (!this.steps[newStep]) {
      this.steps[newStep] = 1;
    } else {
      this.steps[newStep] += 1;
    }

    Object.entries(this.steps).forEach(([key, value]) => {
      if (this.currentStep[1] && value < this.currentStep[1]) {
        return;
      }
      this.currentStep[0] = Number(key);
      this.currentStep[1] = Number(value);
      this.maxStep = this.currentStep[0] * 3;
    });
  }
}
