import { makeAutoObservable } from 'mobx';
import { Scale } from './Scale';
import { Track } from './Tracks';
import { Range } from '../utils';
import { Coordinator } from './Coordinator';

export class CoordinatorRange {
  coordinator: Coordinator;

  onFinish: (range: Range) => void;

  from: number | null = null;

  track: Track | null = null;

  scale: Scale;

  constructor(coordinator: Coordinator, scale: Scale, onFinish: (range: Range) => void) {
    makeAutoObservable(this);
    this.coordinator = coordinator;
    this.scale = scale;
    this.onFinish = onFinish;
  }

  get to() {
    return this.scale.offsetY + this.coordinator.y;
  }

  start() {
    const { currentTrack } = this.coordinator;
    if (currentTrack) {
      this.from = this.to;
      this.track = this.coordinator.currentTrack;
    }
  }

  end() {
    if (this.from) {
      const range = new Range(this.from, this.to);
      this.onFinish(range);
    }
  }
}
