import { ObjectDto } from '../../../api/dto/ObjectDto';

export function LoaderProperties({ well }: { well: ObjectDto }) {
  const { objectTypeName, text } = well;
  return (
    <div>
      <div>
        {objectTypeName}
      </div>
      <div>{text}</div>
    </div>
  );
}
