import * as PIXI from 'pixi.js';
import { Container, Sprite, Text } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import { useContext } from 'react';
import { Tablet } from '../../stores/Tablet';
import { LocalizationStore } from '../../stores/localizationStore';
import { TabletContext } from './TabletProvider';
import { ThemeHex } from '../../types/ThemeHex';
import { TimeZonesStore } from '../../stores/timeZonesStore';

type ScalePointType = {
  tablet: Tablet;
  textStyle: PIXI.TextStyle;
  value: number;
  theme: ThemeHex;
  height: number;
  topOffset: number;
  bottomOffset: number;
  zoom: [number, number];
};

function ScalePointVertical({
  tablet, textStyle, value, theme, height, topOffset, bottomOffset, zoom,
}: ScalePointType) {
  const y = tablet.scale.dataToPoint(value) - tablet.scale.offsetY;
  if (y >= topOffset && y <= (height - bottomOffset)) {
    const isBig = isBigMark(value, zoom[0] * 1000, tablet.timeZones);
    return (
      <>
        {isBig && (
        <Text
          key={value}
          text={pointFormat(value, zoom[0], tablet.timeZones)}
          anchor={[1, 0.5]}
          x={tablet.scalePosition.mainSize - 10}
          y={y - 1}
          style={textStyle}
        />
        )}
        <Sprite
          key={`${value}-sprite`}
          x={tablet.scalePosition.mainSize - 1}
          y={y}
          height={1}
          width={isBig ? 7 : 4}
          anchor={[1, 0.5]}
          texture={PIXI.Texture.WHITE}
          tint={theme.borderColor}
        />
      </>
    );
  }
  return null;
}

function ScalePointHorizontal({
  tablet, textStyle, value, theme, height, topOffset, bottomOffset, zoom, localizationStore,
}: ScalePointType & { localizationStore: LocalizationStore; }) {
  const x = tablet.scale.dataToPoint(value) - tablet.scale.offsetY;
  if (x >= topOffset && x <= (height - bottomOffset)) {
    const isBig = isBigMark(value, zoom[0] * 1000, tablet.timeZones);
    return (
      <>
        {isBig && (
        <Text
          key={value}
          text={pointFormatMulti(value, zoom[0], localizationStore, tablet.timeZones)}
          anchor={[0.5, 0]}
          y={tablet.scalePosition.y + 7}
          x={x - 1}
          style={{
            ...textStyle,
            wordWrap: true,
            wordWrapWidth: 70,
            align: 'center',
          }}
        />
        )}
        <Sprite
          key={`${value}-sprite`}
          y={tablet.scalePosition.y + 2}
          x={x}
          width={1}
          height={isBig ? 7 : 4}
          anchor={[0.5, 0]}
          texture={PIXI.Texture.WHITE}
          tint={theme.borderColor}
        />
      </>
    );
  }
  return null;
}

export const TimeMainScale = observer(() => {
  const { tablet, themeHex, localizationStore } = useContext(TabletContext);

  const { fontSize } = tablet.baseTextStyle;

  const textStyle = new PIXI.TextStyle(tablet.baseTextStyle);
  textStyle.fill = themeHex.color;

  const topOffset = fontSize / 2;
  const bottomOffset = fontSize / 2;
  return (
    <Container x={tablet.scalePosition.mainLineX}>
      {
        tablet.params.orientation === 'vertical'
          ? tablet.scaleLine.line.map((value) => (
            <ScalePointVertical
              key={value}
              tablet={tablet}
              value={value}
              theme={themeHex}
              textStyle={textStyle}
              height={tablet.scale.containerLength}
              topOffset={topOffset}
              bottomOffset={bottomOffset}
              zoom={tablet.scaleLine.zoom}
            />
          )) : tablet.scaleLine.line.map((value) => (
            <ScalePointHorizontal
              key={value}
              tablet={tablet}
              value={value}
              theme={themeHex}
              textStyle={textStyle}
              height={tablet.scale.containerLength}
              topOffset={topOffset}
              bottomOffset={bottomOffset}
              zoom={tablet.scaleLine.zoom}
              localizationStore={localizationStore}
            />
          ))
      }
      <Sprite
        x={tablet.params.orientation === 'vertical' ? tablet.scalePosition.mainSize - 2 : 0}
        y={tablet.params.orientation === 'vertical' ? 0 : tablet.scalePosition.y}
        height={tablet.params.orientation === 'vertical' ? tablet.params.canvasSize[1] : 2}
        width={tablet.params.orientation === 'vertical' ? 2 : tablet.params.canvasSize[0]}
        texture={PIXI.Texture.WHITE}
        tint={themeHex.gridColor}
      />
    </Container>
  );
});

function isBigMark(date: number, zoom: number, timeZones: TimeZonesStore) {
  return (timeZones.selectedMs + date) % zoom === 0;
}

function pointFormat(value: number, zoom: number, timeZones: TimeZonesStore) {
  const date = timeZones.getDate(value);
  if (date.getHours() === 0 && date.getMinutes() === 0) {
    return format(date, 'yyyy-MM-dd');
  }
  if (zoom < 60) {
    return format(date, 'HH:mm:ss');
  }
  return format(date, 'HH:mm');
}

function pointFormatMulti(
  value: number,
  zoom: number,
  localizationStore: LocalizationStore,
  timeZones: TimeZonesStore,
) {
  const date = timeZones.getDate(value);
  if (date.getHours() === 0 && date.getMinutes() === 0) {
    return format(date, 'dd MMM yyyy', { locale: localizationStore.dateFns });
  }
  if (zoom < 60) {
    return format(date, 'HH:mm:ss');
  }
  return format(date, 'HH:mm');
}
