import { makeAutoObservable } from 'mobx';
import { TabletType } from '../enums/TabletType';

export class TabletParams {
  tabletType: TabletType;

  canvasSize: [number, number] = [1, 1];

  orientation: 'vertical' | 'horizontal' = 'horizontal';

  isPDF: boolean = false;

  setOrientation(orientation: 'vertical' | 'horizontal') {
    this.orientation = orientation;
  }

  constructor(tabletType: TabletType) {
    makeAutoObservable(this);
    this.tabletType = tabletType;
  }

  setCanvasSize(width: number, height: number) {
    this.canvasSize = [width, height];
  }
}
