import { css } from '@emotion/react';
import styled from '@emotion/styled';
import chroma from 'chroma-js';

export const TabletWrapper = styled.div`
  flex-shrink: 0;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  position: relative;
  background: ${(props) => props.theme.navbarBackground};
  border-radius: 2px;
  margin: 4px 10px 10px;
  border: ${(props) => props.theme.baseBorder};
  height: calc(100% - 20px);
  max-height: calc(100vh- 20px);
  overflow: hidden;
`;

export const TabletHeader = styled.div` 
  min-height: 32px;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  padding: 2px 4px;
  color: ${(props) => props.theme.color};
  font-weight: 500;
  font-size: 16px;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 2px 2px 0 0;
  gap: 12px;
  position: relative;
  z-index: 0;
`;

export const TabletHeaderLoader = styled.div<{ percent: number }>` 
  position: absolute;
  z-index: -1;
  background-color: rgba(13, 147, 161, 0.15);
  height: 100%;
  width: ${({ percent }) => `${percent}%`};
  box-shadow: inset #ffffff 0 0 3px;
  border-top-left-radius: 2px;
`;

export const TabletHeaderLoaderStub = styled.div` 
  position: absolute;
  width: 1px;
  height: 100%;
  right: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: #ffffff 0 0 5px;
`;

export const TabletHeaderName = styled.div` 
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
  min-width: 76px;
  position: relative;
`;

export const TabletHeaderNameInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  white-space: nowrap;
`;

export const TabletHeaderActions = styled.div` 
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const TabletBody = styled.div` 
  position: relative;
  z-index: 1;
  height: calc(100% - 32px);
  overflow: hidden;
  touch-action: none;
`;

export const ContextMenuContainer = styled.div`
  z-index: 2000;
`;

export const TrashcanContainer = styled.div`
  display: flex;
  width: 140px;
  height: 140px;
  position: absolute;
  bottom: 50%;
  left: 50%;
  margin-left: -70px;
  margin-bottom: -70px;
  padding: 10px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.navbarBackground};
  z-index: 1000;
`;

export const TrashcanInner = styled.div<{ isOver: boolean }>`
  display: flex;
  text-align: center;
  align-items: center;
  padding: 8px;
  border: 2px ${({ isOver }) => (isOver ? 'solid' : 'dashed')} ${(props) => props.theme.borderColor};
  border-radius: 2px;
`;

export const NavigationLineSlider = styled.div<{
  height: number;
  width: number;
  offsetX: number;
  offsetY: number;
}>`
  position: absolute;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  top: ${({ offsetY }) => `${offsetY}px`};
  left: ${({ offsetX }) => `${offsetX}px`};
`;

export const NavigationLineThumb = styled.div<{
  size: number;
  orientation: 'vertical' | 'horizontal';
  offsetY: number;
  thumbSize: number;
  thumbOffset: number;
}>`
  position: absolute;
  left: 0;
  transition: 0.2s linear opacity;
  background-color: ${({ theme }) => theme.brightColor};
  opacity: 0.6;
  border-radius: 99px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  ${({
    orientation, size, offsetY, thumbSize, thumbOffset,
  }) => (orientation === 'vertical'
    ? css`
      top: 0;
      height: ${thumbSize}px;
      width: ${size - 2}px;
      transform: translate(0, ${thumbOffset}px);
    `
    : css`
      top: ${offsetY - 5}px;
      height: ${size - 2}px;
      width: ${thumbSize}px;
      transform: translate(${thumbOffset}px, 0);
    `)}
`;

export const NavigationTimeLineSlider = styled.div<{
  height: number;
  width: number;
  offsetX: number;
  offsetY: number;
}>`
  position: absolute;
  height: ${({ height }) => `${height}px`};
  top: ${({ offsetY }) => `${offsetY}px`};
  left: ${({ offsetX }) => `${offsetX}px`};
  width: ${({ width }) => `${width}px`};
  cursor: pointer;
`;

export const NavigationTimeLineThumb = styled.div<{
  size: number;
  orientation: 'vertical' | 'horizontal';
  thumbSize: number;
  thumbOffset: number;
}>`
  position: absolute;
  left: 0;
  border-color: ${({ theme }) => theme.color};
  border-style: solid;
  border-width: 1px ;
  cursor: pointer;
  transition: 0.2s linear background-color;
  &:hover {
    background-color: ${({ theme }) => chroma(theme.color).alpha(0.2).css()};
  }
  ${({
    orientation, size, thumbSize, thumbOffset,
  }) => (orientation === 'vertical'
    ? css`
      top: 0;
      height: ${thumbSize}px;
      width: ${size - 2}px;
      transform: translate(0, ${thumbOffset}px);
    `
    : css`
      top: 3px;
      height: ${size - 1}px;
      width: ${thumbSize}px;
      transform: translate(${thumbOffset}px, 0);
    `)}
`;

export const NavigationTimeLineResizableHandleTop = styled.div`
  position: absolute;
  width: 100%;
  height: 6px;
  top: -4px ;
  cursor: ns-resize;
`;

export const NavigationTimeLineResizableHandleBottom = styled.div`
  position: absolute;
  width: 100%;
  height: 6px;
  bottom: -4px ;
  cursor: ns-resize;
`;

export const ScaleDevMonitorContainer = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  padding: 8px;
  border: 2px solid ${(props) => props.theme.borderColor};
  border-radius: 2px;
  background-color: ${(props) => props.theme.navbarBackground};
  color: ${(props) => props.theme.color};
  opacity: 0.8;
  pointer-events: none;
`;

export const PointsLoadSpiner = styled.div`
position: absolute;
top: 50%;
left: 50%;
width: 108px;
height: 108px;
transform: translate(-54px, -54px);
padding: 6px;
border-radius: 2px;
pointer-events: none;
`;

export const ScrollBar = styled.div`
  position: absolute;
  width: 8px;
  top: 0px;
  bottom: 0px;
  right: 0px;
`;

export const ScrollBarThumb = styled.div`
  position: absolute;
  width: 100%;
  height: 128px;
  top: 0px;
  left: 0px;
  background: ${({ theme }) => theme.brightColor};
  opacity: 0.6;
  border-radius: 99px;
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 1;
  }
`;
