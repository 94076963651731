import Scrollbar from 'react-scrollbars-custom';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  noScrollY?: boolean
}

export function CustomScrollbar({ children, noScrollY }: Props) {
  const thumbStyle = {
    background: 'rgba(255, 255, 255, 0.5)',
  };
  return (
    <Scrollbar
      noScrollY={noScrollY}
      disableTracksWidthCompensation
      trackYProps={{
        style: {
          backgroundColor: 'transparent',
          width: '8px',
          height: '100%',
          top: 0,
        },
      }}
      thumbYProps={{
        style: thumbStyle,
      }}
      trackXProps={{
        style: {
          backgroundColor: 'transparent',
          width: '100%',
          height: '8px',
          bottom: 0,
        },
      }}
      thumbXProps={{
        style: thumbStyle,
      }}
    >
      {children}
    </Scrollbar>
  );
}
