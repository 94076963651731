import * as PIXI from 'pixi.js';
import { Sprite, Text } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@emotion/react';
import { Curve, Track } from '../../stores/Tracks';
import { CuttingsCoordinates } from '../../stores/CoordinatesStorages/CuttingsCoordinates';
import { DepthCoordinates } from '../../stores/CoordinatesStorages/DepthCoordinates';
import { CurveCoordinates } from '../../stores/CoordinatesStorages/CurveCoordinates';
import { ValueScaleType } from '../../api/dto/TrackDto';

type Props = {
  curve: Curve;
  track: Track;
  width: number;
};

export const CurveHeaderPixi = observer(({ curve, track, width }: Props) => {
  const height = 17;
  const theme = useTheme();

  const textStyle = new PIXI.TextStyle({
    fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
    fontSize: 12,
    fontWeight: '400' as const,
  });
  if (curve.sourceData?.curveCoordinates instanceof CurveCoordinates
    || curve.sourceData?.curveCoordinates instanceof DepthCoordinates) {
    textStyle.fill = curve.params.color;
  } else if (curve.sourceData?.curveCoordinates instanceof CuttingsCoordinates) {
    textStyle.fill = curve.sourceData?.imageLithology?.color || theme.color;
  } else {
    textStyle.fill = theme.color;
  }
  if (curve.sourceData?.curveCoordinates instanceof CuttingsCoordinates) {
    return (
      <>
        {curve.sourceData?.imageLithology?.image && (
        <Sprite
          x={1}
          y={1}
          width={14}
          height={14}
          image={curve.sourceData?.imageLithology.image.src}
        />
        )}
        <Text
          key="name"
          text={curve.sourceDto.name}
          anchor={[0, 0.5]}
          x={17}
          y={height / 2}
          style={textStyle}
        />
        <Sprite
          x={0}
          y={16}
          height={1}
          width={width}
          texture={PIXI.Texture.WHITE}
          tint={PIXI.utils.string2hex(curve.sourceData?.imageLithology?.color || theme.color)}
        />
      </>
    );
  }

  return (
    <>
      {(curve.sourceData?.curveCoordinates instanceof CurveCoordinates
        || curve.sourceData?.curveCoordinates instanceof DepthCoordinates)
        && track.params.valueScaleType !== ValueScaleType.Numeric && (
        <Text
          key="min"
          text={String(curve.scaleFrom)}
          anchor={[0, 0.5]}
          x={0}
          y={height / 2}
          style={textStyle}
        />
      )}
      <Text
        key="name"
        text={curve.sourceDto.name}
        anchor={[0.5, 0.5]}
        x={width / 2}
        y={height / 2}
        style={textStyle}
      />
      {(curve.sourceData?.curveCoordinates instanceof CurveCoordinates
        || curve.sourceData?.curveCoordinates instanceof DepthCoordinates)
        && track.params.valueScaleType !== ValueScaleType.Numeric && (
        <Text
          key="max"
          text={String(curve.scaleTo)}
          anchor={[1, 0.5]}
          x={width}
          y={height / 2}
          style={textStyle}
        />
      )}
      <Sprite
        x={0}
        y={16}
        height={1}
        width={width}
        texture={PIXI.Texture.WHITE}
        tint={PIXI.utils.string2hex(textStyle.fill)}
      />
    </>
  );
});
