import { axiosApiInstance as axios } from './axiosApiInstance';

export async function getScaleUnit() {
  const { data: { result } } = await axios.get<{ result: 'm' | 'ft' }>('/User/GetScaleUnit');
  return result;
}

export async function saveScaleUnit(unit: 'm' | 'ft') {
  const { data: { result } } = await axios.post<{ result: boolean; }>('/User/SaveScaleUnit', unit);
  return result;
}
