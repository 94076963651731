import { axiosApiInstance as axios } from './axiosApiInstance';

export interface TabletInfoDto {
  tabletId: number;
  templateName: string;
  boleId: number;
  boleName?: string;
  logId?: number;
  logName?: string;
  wellboreId: number;
  wellboreName?: string;
}

export interface ScreenDto {
  id: number;
  name: string;
  userId: number;
  screenInfo:TabletInfoDto[];
}

export interface ScreenSaveDto {
  id?: string;
  name: string | null;
  screenInfo: TabletInfoDto[] | null;
}

export async function getScreen(screenId: string) {
  return axios.get<{ result: ScreenDto }>('/Screen/Get', { params: { screenId } })
    .then(({ data }) => data.result);
}

export async function getScreens() {
  return axios.get<{ result: Record<string, string> }>('/Screen/GetList')
    .then(({ data }) => data.result);
}

export async function deleteScreens(ids: string[]) {
  return axios.post<{ result: boolean }>('/Screen/Delete', ids)
    .then(({ data }) => data.result);
}

export async function saveScreen(screen: ScreenSaveDto) {
  return axios.post<{ result: ScreenDto }>('/Screen/Save', screen)
    .then(({ data }) => data.result);
}

export async function getSession() {
  return axios.get<{ result: { ids: number[] } }>('/Screen/GetSession');
}

export async function saveSession(ids: number[]) {
  return axios.post<boolean>('/Screen/SaveSession', { ids });
}
