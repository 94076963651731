import { action, makeAutoObservable } from 'mobx';
import {
  addComment, editComment, deleteComment, CommentParamsDto, CommentDto,
} from '../../api/curvesCache';
import { RecordIndex } from '../../enums/RecordIndex';
import { TabletType } from '../../enums/TabletType';
import { throwError } from '../../errorHandler';
import { Range } from '../../utils';

type CommentSource = {
  comments: {
    isOpen: boolean;
    close: () => void;
    open: () => void
  }[]
};

interface PointsDataActions {
  removePoint: (sourceId: number, key: number) => void;
  getTrackRange: (onRangeSelected: (range: Range) => void) => void;
}

export class CommentsController {
  recordIndex: RecordIndex;

  actions: PointsDataActions;

  sources = new Set<CommentSource>([]);

  currentForm: CommentParamsDto | null = null;

  prevForm: CommentParamsDto | null = null;

  type: 'edit' | 'add' | 'preview' = 'add';

  constructor(tabletType: TabletType, actions: PointsDataActions) {
    makeAutoObservable(this, { onTrackRange: action.bound });
    this.recordIndex = tabletType === TabletType.Time
      ? RecordIndex.TIME
      : RecordIndex.DEPTH;
    this.actions = actions;
  }

  async delete() {
    try {
      if (this.currentForm) {
        await deleteComment(this.currentForm);
        this.actions.removePoint(this.currentForm.curveId, this.currentForm.key);
        this.close();
      }
    } catch (e) {
      throwError('AnErrorOccurredWhileDeleting')(e);
    }
  }

  async saveComment(comment: CommentDto) {
    if (!this.currentForm) {
      return;
    }

    this.currentForm.comment = comment;

    try {
      if (this.type === 'add') {
        await addComment(this.currentForm);
      } else {
        await editComment(this.currentForm);
      }
      this.close();
    } catch (e) {
      throwError('Comment-save-error')(e);
    }
  }

  editComment(params: CommentParamsDto, disabled: boolean) {
    this.currentForm = params;
    this.currentForm.index = this.recordIndex;
    if (disabled) {
      this.type = 'preview';
    } else {
      this.type = 'edit';
    }
  }

  createComment(key: number, curveId: number) {
    const current: CommentParamsDto = {
      curveId,
      key,
      comment: { title: '', text: '', length: 0 },
      index: this.recordIndex,
    };

    this.currentForm = current;
    this.type = 'add';
  }

  close() {
    this.currentForm = null;
  }

  register(commentSource: CommentSource) {
    this.sources.add(commentSource);
  }

  unregister(commentSource: CommentSource) {
    this.sources.delete(commentSource);
  }

  expandAll() {
    Array.from(this.sources).forEach((s) => {
      s.comments.forEach((i) => i.open());
    });
  }

  collapseAll() {
    Array.from(this.sources).forEach((s) => {
      s.comments.forEach((i) => i.close());
    });
  }

  onTrackRange(range: Range) {
    if (this.prevForm) {
      this.currentForm = this.prevForm;
      const [start, end] = range.positive;
      this.currentForm.key = start;
      if (start === end) {
        this.currentForm.comment.length = 0;
      } else if (this.currentForm.index === RecordIndex.DEPTH) {
        this.currentForm.comment.length = (end - start);
      } else {
        this.currentForm.comment.length = (end - start) / 1000;
      }
    }
  }

  getRange() {
    this.prevForm = this.currentForm ? { ...this.currentForm } : null;
    this.actions.getTrackRange(this.onTrackRange);
  }
}
