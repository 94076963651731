import {
  action, makeAutoObservable, observable, runInAction,
} from 'mobx';
import { getDepthCoords, CurvePoint } from '../../api/curvesCache';
import { immutableSplice, Range } from '../../utils';

export class DepthCoordinates {
  curveId: number;

  points: CurvePoint[] = [];

  inProgress = false;

  inDownloading = false;

  errors = null;

  onData?: number;

  toData?: number;

  minValue = 0;

  maxValue = 100;

  fetched = false;

  constructor(curveId: number) {
    makeAutoObservable(this, {
      points: observable.ref,
      getPoint: action.bound,
    });
    this.curveId = curveId;
  }

  get dataRange() {
    return new Range(this.onData || 0, this.toData || 0);
  }

  async refreshCoords({ force }: { force?: boolean }) {
    if (!force && (this.points.length > 0 || this.inProgress || this.inDownloading)) {
      return;
    }
    this.inProgress = true;
    this.errors = null;
    try {
      const data = await getDepthCoords(this.curveId);
      runInAction(() => {
        if (Array.isArray(data)) {
          this.points = data;
          this.onData = data.at(0)?.key;
          this.toData = data.at(-1)?.key;
          this.fetched = true;
        } else {
          this.inDownloading = true;
        }
      });
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  binarySearch(
    data: CurvePoint[],
    target: number,
    start: number,
    end: number,
  ): CurvePoint | null {
    if (end < 1) return data[0];
    const middle = Math.floor(start + (end - start) / 2);
    if (target === data[middle].key) return data[middle];
    if ((end - 1) === start) {
      return Math.abs(data[start].key - target) > Math.abs(data[end].key - target)
        ? data[end]
        : data[start];
    }
    if (target > data[middle].key) return this.binarySearch(data, target, middle, end);
    if (target < data[middle].key) return this.binarySearch(data, target, start, middle);
    return null;
  }

  binarySearchIndex(
    data: { key: number }[],
    target: number,
    start: number,
    end: number,
  ): number | null {
    if (end < 1) return 0;
    const middle = Math.floor(start + (end - start) / 2);
    if (target === data[middle].key) return middle;
    if ((end - 1) === start) {
      return Math.abs(data[start].key - target) > Math.abs(data[end].key - target) ? end : start;
    }
    if (target > data[middle].key) return this.binarySearchIndex(data, target, middle, end);
    if (target < data[middle].key) return this.binarySearchIndex(data, target, start, middle);
    return null;
  }

  getPoint(y: number) {
    if (this.dataRange.in(y)) {
      return this.binarySearch(this.points, y, 0, this.points.length - 1);
    }
    return null;
  }

  dataSlice(from: number, to: number) {
    if (this.dataRange.start > from && this.dataRange.start > to) {
      return null;
    }
    if (this.dataRange.end < from && this.dataRange.end < to) {
      return null;
    }
    if (from === to) {
      return null;
    }
    const firstPointIndex = this.binarySearchIndex(
      this.points,
      from,
      0,
      this.points.length - 1,
    );
    const lastPointIndex = this.binarySearchIndex(
      this.points,
      to,
      0,
      this.points.length - 1,
    );
    if (firstPointIndex == null || lastPointIndex == null) {
      return null;
    }
    const firstPoint = this.points[firstPointIndex];
    const lastPoint = this.points[lastPointIndex];
    const stats = {
      firstKey: firstPoint.key,
      lastKey: lastPoint.key,
      firstValue: firstPoint.value,
      lastValue: lastPoint.value,
      min: firstPoint.value,
      max: firstPoint.value,
      sum: 0,
      count: lastPointIndex - firstPointIndex,
    };
    for (let i = firstPointIndex; i < lastPointIndex; i += 1) {
      const point = this.points[i];
      if (point.value >= 0) {
        stats.sum += point.value;
        if (stats.max < point.value) stats.max = point.value;
        if (stats.min > point.value) stats.min = point.value;
      } else { stats.count -= 1; }
    }
    return stats;
  }

  addPoint(point: CurvePoint) {
    const index = this.points.findIndex((p, i) => {
      const next = this.points.at(i + 1);
      if (next) {
        return p.key < point.key && point.key < next.key;
      }
      return false;
    });
    if (index > -1 && index < this.points.length) {
      this.points = immutableSplice(this.points, index + 1, 0, point);
    }
  }

  addPoints(points: CurvePoint[]) {
    this.points = this.points.concat(...points.filter((p) => p.key > (this.toData || 0)));
  }
}
