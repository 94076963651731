import axios from 'axios';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { CurveInfo, getCurve, getCurveMulti } from '../api/curvesCache';
import { getRatioUomMemo } from '../api/uom';
import { TabletType } from '../enums/TabletType';
import { throwError } from '../errorHandler';
import { normalizeUom } from '../mapping/normalizeUom';
import { SocketCurveMessage, stompPublisher } from '../stomp';
import { Range, waitPromise } from '../utils';
import { CommentsCoordinates } from './CoordinatesStorages/CommentsCoordinates';
import { CurveCoordinates } from './CoordinatesStorages/CurveCoordinates';
import { CuttingsCoordinates } from './CoordinatesStorages/CuttingsCoordinates';
import { DepthCoordinates } from './CoordinatesStorages/DepthCoordinates';
import { ImageCoordinates } from './CoordinatesStorages/ImageCoordinates';
import { LithologyCoordinates } from './CoordinatesStorages/LithologyCoordinates';
import { SyntheticCoordinates } from './CoordinatesStorages/SyntheticCoordinates';
import { SharedTimeCurvesCoordinates } from './CoordinatesStorages/TimeCurvesSharedStore';
import { lithologyStore } from './lithologyStore';
import { Scale } from './Scale';

const DANGER_TIME_DATA_GAT = 60000;

export enum InnerChartType {
  TimeCurve = 0,
  DepthCurve = 1,
  Block = 2,
  Comments = 3,
  Image = 4,
  Cuttings = 5,
  Lithology = 6,
}

export enum CurveStatus {
  MetaDataFetching = 'metaDataFetching',
  ServerFetching = 'serverFetching',
  Blocked = 'blocked',
  Done = 'done',
  NoData = 'noData',
}

type FetchParams = { onFrame: number; toFrame: number; force?: boolean; bounded?: boolean };

export class SourceDataMapLoading {
  inProgress = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(value: boolean) {
    this.inProgress = value;
  }
}

export class SourceData {
  curveId: number;

  tabletType: TabletType;

  inProgress = false;

  errors: null | string = null;

  info: CurveInfo | null = null;

  lastValue: string;

  curveCoordinates:
  ImageCoordinates |
  CuttingsCoordinates |
  LithologyCoordinates |
  SyntheticCoordinates |
  CommentsCoordinates |
  CurveCoordinates |
  DepthCoordinates |
  null = null;

  registered = new Set<any>();

  scale: Scale;

  lastPartToKey: number | null = null;

  subscribeHandler: (value: SocketCurveMessage) => void;

  sharedTimeStore: SharedTimeCurvesCoordinates;

  constructor(
    curveId: number,
    tabletType: TabletType,
    scale: Scale,
    sharedTimeStore: SharedTimeCurvesCoordinates,
    private sourceDataMapLoading: SourceDataMapLoading,
    private triggerReloadCurve: () => void,
  ) {
    makeAutoObservable(this, {
      subscribeHandler: false,
      setPoints: action.bound,
      pointsCache: false,
      sharedUpdate: action.bound,
    });
    this.curveId = curveId;
    this.tabletType = tabletType;
    this.scale = scale;
    this.sharedTimeStore = sharedTimeStore;
    this.subscribeHandler = async (value: SocketCurveMessage) => {
      switch (value.msgType) {
        case 'LOADED':
          // eslint-disable-next-line no-console
          console.info('LOADED', curveId);
          runInAction(() => {
            this.lastPartToKey = null;
          });
          if (this.curveCoordinates) {
            await this.fetchSource();
            await this.refreshCoords({
              onFrame: this.scale.onFrame,
              toFrame: this.scale.toFrame,
              force: true,
            });
          }
          break;
        case 'PART':
          if (this.curveCoordinates) {
            runInAction(() => {
              this.lastPartToKey = value.to;
            });
            if (this.curveCoordinates instanceof CommentsCoordinates) {
              await this.refreshCoords({
                onFrame: this.scale.onFrame,
                toFrame: this.scale.toFrame,
                force: true,
              });
            } else {
              const range = new Range(value.from, value.to);
              if (range.isRangeCrossed(
                new Range(this.scale.onFrame, this.scale.toFrame),
              )) {
                await this.refreshCoords({
                  onFrame: this.scale.onFrame,
                  toFrame: this.scale.toFrame,
                  force: true,
                });
              }
            }
          }
          break;
        case 'POINT':
          this.addPoint({ key: value.key, value: value.value });
          break;
        default:
          break;
      }
    };
    this.subscribeSocket();
  }

  get imageLithology() {
    if (!this.info) {
      return null;
    }
    const lithology = lithologyStore.getLithologyForName(this.info.mnemonic);

    if (lithology === null) {
      return null;
    }

    return {
      image: lithology.img,
      color: lithology.fillColor,
    };
  }

  get loadingPercentage() {
    if (this.lastPartToKey != null && this.info != null) {
      return Math.round(
        ((this.lastPartToKey - this.info.minKey) * 100) / (this.info.maxKey - this.info.minKey),
      );
    }
    return null;
  }

  get innerChartType(): InnerChartType | null {
    if (!this.curveCoordinates) {
      return null;
    }
    if (this.curveCoordinates instanceof CuttingsCoordinates) {
      return InnerChartType.Cuttings;
    }
    if (this.curveCoordinates instanceof LithologyCoordinates) {
      return InnerChartType.Lithology;
    }
    if (this.curveCoordinates instanceof SyntheticCoordinates) {
      return InnerChartType.Block;
    }
    if (this.curveCoordinates instanceof ImageCoordinates) {
      return InnerChartType.Image;
    }
    if (this.curveCoordinates instanceof CommentsCoordinates) {
      return InnerChartType.Comments;
    }
    if (this.tabletType === TabletType.Depth) {
      return InnerChartType.DepthCurve;
    }
    return InnerChartType.TimeCurve;
  }

  get minValue() {
    return this.info?.minValue;
  }

  get maxValue() {
    return this.info?.maxValue;
  }

  get status() {
    if (this.inProgress) {
      return CurveStatus.MetaDataFetching;
    }
    if (this.info?.status === 'BLOCKED') {
      return CurveStatus.Blocked;
    }
    if (this.lastPartToKey) {
      return CurveStatus.ServerFetching;
    }
    if (this.info && this.info.minKey == null) {
      return CurveStatus.NoData;
    }
    return CurveStatus.Done;
  }

  getCurveCoordinates() {
    if (!this.info) {
      return null;
    }
    const lithology = lithologyStore.getLithologyForName(this.info.mnemonic);
    if (lithology) {
      return new CuttingsCoordinates(this.curveId, lithology);
    }
    if (this.info.mnemonic.toLowerCase() === 'Lithology'.toLowerCase()) {
      return new LithologyCoordinates(this.curveId);
    }
    if (this.info.classWitsml === 'RIGIS') {
      return new SyntheticCoordinates(this.curveId);
    }
    if (this.info.axisDefinition) {
      return new ImageCoordinates(this.curveId);
    }
    if (this.info.typeLogData === 'STRING') {
      return new CommentsCoordinates(this.curveId, this.info.classWitsml === null);
    }
    if (this.tabletType === TabletType.Time) {
      const timeCurve = new CurveCoordinates(
        this.curveId,
        this.scale,
        this.info,
        this.sharedUpdate,
      );
      if (this.status !== CurveStatus.NoData) {
        this.sharedTimeStore.addCurve(timeCurve);
      }
      return timeCurve;
    }
    if (this.tabletType === TabletType.Depth) {
      return new DepthCoordinates(this.curveId);
    }
    return null;
  }

  updateRegistered() {
    Array.from(this.registered.values()).forEach((curve) => {
      curve.refreshParams();
    });
  }

  addCurveParamsObject(object: any) {
    this.registered.add(object);
  }

  removeCurveParamsObject(object: any) {
    this.registered.delete(object);
  }

  subscribeSocket() {
    stompPublisher.subscribe(`/curve/${this.curveId}`, this.subscribeHandler);
  }

  unsubscribeSocket() {
    stompPublisher.unsubscribe(`/curve/${this.curveId}`, this.subscribeHandler);
  }

  async fetchSource() {
    if (!this.info) {
      this.inProgress = true;
    }
    this.errors = null;
    try {
      const data = await getCurve(this.curveId);
      runInAction(() => {
        this.setInfo(data);
      });
      return data;
    } catch (e: any) {
      runInAction(() => {
        if (axios.isAxiosError(e) && e.response) {
          this.errors = e.response && e.response.data;
        } else {
          this.errors = e.message;
        }
      });
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  setInfo(info: CurveInfo) {
    if (this.info) {
      Object.assign(this.info, info, { unit: normalizeUom(info.unit) });
    } else {
      this.info = info;
      this.info.unit = normalizeUom(info.unit);
      if (this.info.status !== 'LOADED' && this.info.status !== 'BLOCKED') {
        this.lastPartToKey = info.minKey;
      }
      this.registered.forEach((s) => {
        if (s.sourceData.info.unit === s.params.currentUom) {
          s.setParams({ ratioUom: 1 });
          return;
        }
        getRatioUomMemo(s.sourceData.info.unit, s.params.currentUom).then((ration) => {
          if (ration) {
            s.setParams({ ratioUom: ration });
          } else {
            s.setParams({ ratioUom: 1 });
          }
        });
      });
    }
    this.lastValue = this.info.lastValue;
    if (this.curveCoordinates == null || this.info?.minKey === null) {
      this.curveCoordinates = this.getCurveCoordinates();
    }
    this.updateRegistered();
  }

  pointsCache: { key: number; value: any; }[] = [];

  get isMetaDataFetching() {
    return this.inProgress || this.sourceDataMapLoading.inProgress;
  }

  get isCoordinatesFetching() {
    if (!this.curveCoordinates) {
      return true;
    }
    if (this.curveCoordinates instanceof CurveCoordinates) {
      return this.sharedTimeStore.layers.closest.coordinates.inProgress;
    }
    return this.curveCoordinates.inProgress;
  }

  setPoints() {
    this.pointsCache.sort((a, b) => a.key - b.key);
    const last = this.pointsCache.at(-1);
    if (last
      && this.curveCoordinates?.fetched
      && !this.isCoordinatesFetching
      && !this.isMetaDataFetching
    ) {
      this.curveCoordinates.addPoints(this.pointsCache);
      this.lastValue = String(last.value);
      if (this.info && last.key > this.info.maxKey) {
        this.info.maxKey = last.key;
      }
      this.pointsCache = [];
    }
  }

  addPoint(point: { key: number; value: any; }) {
    if (this.info && this.curveCoordinates instanceof CurveCoordinates
      && (point.key > this.info.maxKey + DANGER_TIME_DATA_GAT)) {
      this.triggerReloadCurve();
    }
    if (this.info != null && this.info.minKey == null && this.info.maxKey == null) {
      this.info.minKey = point.key;
      this.info.maxKey = point.key;
    }
    if (this.info && point.key < this.info.maxKey) {
      this.setPoints();
      this.curveCoordinates?.addPoint(point);
      return;
    }
    this.pointsCache.push(point);
    this.setPoints();
  }

  getCurveRange() {
    return this.info ? new Range(this.info.minKey, this.info.maxKey) : null;
  }

  sharedUpdate() {
    this.sharedTimeStore.layers.refreshCoords({
      onFrame: this.scale.onFrame,
      toFrame: this.scale.toFrame,
    });
  }

  async refreshCoords(params: FetchParams) {
    if (this.info) {
      if (this.status === CurveStatus.NoData) {
        return;
      }
      if (this.lastPartToKey) {
        const loadedRange = new Range(this.info.minKey, this.lastPartToKey);
        const requestRange = new Range(this.scale.onFrame, this.scale.toFrame);
        if (!loadedRange.isRangeCrossed(requestRange)) {
          return;
        }
      }
      if (!(this.curveCoordinates instanceof CurveCoordinates)) {
        await this.curveCoordinates?.refreshCoords(params);
      }
    }
  }
}

export class SourceDataMap {
  map = new Map<number, SourceData>();

  tabletType: TabletType;

  scale: Scale;

  sharedTimeStore: SharedTimeCurvesCoordinates;

  sourceDataMapLoading = new SourceDataMapLoading();

  constructor(
    tabletType: TabletType,
    scale: Scale,
  ) {
    makeAutoObservable(this, {
      triggerReloadCurve: action.bound,
    });
    this.tabletType = tabletType;
    this.scale = scale;
    this.sharedTimeStore = new SharedTimeCurvesCoordinates(scale);
  }

  get metaInfoLoading() {
    return this.sourceDataMapLoading.inProgress;
  }

  get sourcesList() {
    return Array.from(this.map.values());
  }

  get sourcesIds() {
    return this.sourcesList.map((s) => s.curveId);
  }

  async preloadSourceData(curveId: number) {
    let sourceData = this.map.get(curveId);
    if (sourceData) {
      return sourceData;
    }
    sourceData = new SourceData(
      curveId,
      this.tabletType,
      this.scale,
      this.sharedTimeStore,
      this.sourceDataMapLoading,
      this.triggerReloadCurve,
    );
    await sourceData.fetchSource();
    runInAction(() => {
      if (!this.map.has(curveId) && sourceData) {
        this.map.set(curveId, sourceData);
      }
    });
    return sourceData;
  }

  register(curveId: number, curve: any) {
    const sourceData = this.map.get(curveId)
      || new SourceData(
        curveId,
        this.tabletType,
        this.scale,
        this.sharedTimeStore,
        this.sourceDataMapLoading,
        this.triggerReloadCurve,
      );
    sourceData?.addCurveParamsObject(curve);
    if (!this.map.has(curveId) && sourceData) {
      this.map.set(curveId, sourceData);
    }
    return sourceData;
  }

  unregister(curveId: number, curve: any) {
    const sourceData = this.map.get(curveId);
    if (sourceData) {
      sourceData.removeCurveParamsObject(curve);
      if (sourceData.registered.size === 0) {
        sourceData.unsubscribeSocket();
        if (sourceData.curveCoordinates instanceof CurveCoordinates) {
          this.sharedTimeStore.deleteCurve(sourceData.curveCoordinates);
        }
        this.map.delete(curveId);
      }
    }
  }

  async fetchSources() {
    this.sourceDataMapLoading.setLoading(true);
    if (this.sourcesIds.length === 0) {
      return;
    }
    try {
      const data = await getCurveMulti(this.sourcesIds);

      data.forEach((info) => {
        const source = this.map.get(info.id);
        if (source) {
          source.setInfo(info);
        }
      });
    } catch (e: any) {
      throwError('ErrorToFetchMetaData')(e);
    } finally {
      runInAction(() => {
        this.sourceDataMapLoading.setLoading(false);
      });
    }
  }

  triggerReloadCurve() {
    // eslint-disable-next-line no-console
    console.log('triggerReloadCurve', this.sourceDataMapLoading.inProgress);
    if (this.sourceDataMapLoading.inProgress) {
      return;
    }
    this.fetchSources().then(() => {
      this.refreshCoords(this.scale.onFrame, this.scale.toFrame, false, undefined, true);
    });
  }

  refreshCoords(
    onFrame: number,
    toFrame: number,
    force: boolean,
    bounded?: boolean,
    forceSolid?: boolean,
  ) {
    this.sourcesList.forEach((sourceData) => {
      sourceData.refreshCoords({ onFrame, toFrame, force: forceSolid || force });
    });
    if (this.sharedTimeStore.registered.size) {
      this.sharedTimeStore.layers.refreshCoords({
        onFrame, toFrame, force, bounded,
      });
    }
  }

  async sequentialCoordsFetch(onFrame: number, toFrame: number, force: boolean, bounded?: boolean) {
    // eslint-disable-next-line no-restricted-syntax
    for (const curve of this.sourcesList) {
      if (curve.curveCoordinates instanceof CurveCoordinates) {
        // eslint-disable-next-line no-await-in-loop
        await curve.curveCoordinates?.refreshCoords({
          onFrame, toFrame, force, bounded,
        });
      } else {
        // eslint-disable-next-line no-await-in-loop
        await curve.refreshCoords({
          onFrame, toFrame, force,
        });
      }
      // eslint-disable-next-line no-await-in-loop
      await waitPromise(300);
    }
  }

  subscribeAll() {
    this.sourcesList.forEach((s) => {
      s.subscribeSocket();
    });
  }

  unsubscribeAll() {
    this.sourcesList.forEach((s) => {
      s.unsubscribeSocket();
    });
  }

  get sharedCacheLoader() {
    if (this.map.size === 0) {
      return null;
    }
    let sum = 0;
    this.sourcesList.forEach((sourceData) => {
      if (sourceData.status === CurveStatus.ServerFetching) {
        sum += sourceData.loadingPercentage || 0;
      } else {
        sum += 100;
      }
    });
    let percent = ((sum) / (this.map.size * 100)) * 100;
    percent = Math.round(percent * 10) / 10;
    if (percent === 100) {
      return null;
    }
    return percent;
  }

  get sharedPointsLoader() {
    const shared = this.sharedTimeStore.layers.closest.coordinates.inProgress;
    return shared || this.sourcesList.some((s) => s.curveCoordinates?.inProgress);
  }

  get loadedRange() {
    const filtered = this.sourcesList.filter((s) => {
      if (s.curveCoordinates instanceof CurveCoordinates) {
        return s.status !== CurveStatus.NoData;
      }
      if (s.curveCoordinates?.fetched) {
        return false;
      }
      return true;
    });
    const onDataList = filtered
      .map((s) => s.curveCoordinates?.onData!)
      .filter((i) => i);
    const toDataList = filtered
      .map((s) => s.curveCoordinates?.toData!)
      .filter((i) => i);
    if (onDataList.length !== filtered.length
      || toDataList.length !== filtered.length) {
      return null;
    }
    const onDate = Math.max(...onDataList);
    const toDate = Math.min(...toDataList);
    return new Range(onDate, toDate);
  }

  async refreshCoordsPromise(onFrame: number, toFrame: number, bounded: boolean) {
    const requests = Array.from(this.map)
      .map(([, sourceData]) => sourceData.refreshCoords({ onFrame, toFrame }));
    await Promise.all(requests);
    if (this.sharedTimeStore.registered.size) {
      await this.sharedTimeStore.layers.refreshCoords({ onFrame, toFrame, bounded });
    }
  }

  get dataBaseRange() {
    return Array.from(this.map).reduce<[number, number] | []>((acc, [,item]) => {
      if (!item.info) {
        return acc;
      }
      const start = item.info.minKey != null ? item.info.minKey : null;
      const end = item.info.maxKey != null ? item.info.maxKey : null;
      if (start != null && end != null) {
        const [from = start, to = end] = acc;

        const n1 = from > start ? start : from;
        const n2 = to < end ? end : to;
        return [n1, n2];
      }
      return acc;
    }, []);
  }

  get onDataDB() {
    return this.dataBaseRange[0]
      ?? (TabletType.Time === this.tabletType ? Date.now() : 1000);
  }

  get toDataDB() {
    return this.dataBaseRange[1]
      ?? (TabletType.Time === this.tabletType ? Date.now() + 24 * 60 * 60000 : 2000);
  }
}
