import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button, Empty, List, Modal, Spin, Tooltip,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useLocalization } from '@fluent/react';
import { ScreensStore } from '../../stores/screensStore';
import { ScreensModalLine } from './ScreensModalLine';

type Props = {
  screensStore: ScreensStore;
  visible: boolean;
  onClose: () => void;
};

export const ScreensModal = observer(({ screensStore, visible, onClose }: Props) => {
  const { l10n } = useLocalization();
  useEffect(() => {
    screensStore.fetchList();
  }, [screensStore]);

  function deleteAll() {
    const ids = screensStore.list.map((s) => s.screenId);
    screensStore.deleteScreen(ids);
  }

  return (
    <Modal
      title={l10n.getString('ManagerViews')}
      open={visible}
      onCancel={onClose}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText={l10n.getString('Cancel')}
      footer={null}
      closeIcon={<Tooltip title={l10n.getString('Close-window')}><CloseOutlined /></Tooltip>}
      bodyStyle={{
        maxHeight: 600,
        overflow: 'auto',
        scrollbarColor: 'rgba(255,255,255,0.5) transparent',
        scrollbarWidth: 'thin',
      }}
    >
      {screensStore.list.length > 0 && (
      <Button block onClick={() => deleteAll()} danger>
        {l10n.getString('Delete-all')}
      </Button>
      )}
      <Spin spinning={screensStore.inProgress}>
        <List>
          {screensStore.list.length === 0 && (
            <Empty />
          )}
          {screensStore.list.map((screen) => (
            <ScreensModalLine
              key={screen.screenId}
              id={screen.screenId}
              screen={screen}
              onClose={onClose}
              screensStore={screensStore}
            />
          ))}
          {screensStore.entries.length === 0 && <Empty />}
        </List>
      </Spin>
    </Modal>
  );
});
