import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { utils } from 'pixi.js';
import { TabletsWrapper } from './TabletList.styled';
import { Tablet } from '../Tablet';
import { TabletPreloader } from '../Tablet/TabletPreloader';
import { ThemeHex } from '../../types/ThemeHex';
import { ScreensStore } from '../../stores/screensStore';
import { localizationStore, scaleUnitStore, timeZonesStore } from '../../stores/init-stores-global';

type Props = {
  screensStore: ScreensStore;
};
export const TabletsList = observer(({ screensStore }:Props) => {
  const theme = useTheme();
  const themeHex = useMemo<ThemeHex>(() => ({
    backgroundColor: utils.string2hex(theme.backgroundColor),
    color: utils.string2hex(theme.color),
    brightColor: utils.string2hex(theme.brightColor),
    borderColor: utils.string2hex(theme.borderColor),
    gridColor: utils.string2hex(theme.gridColor),
    lightPrimary: utils.string2hex(theme.lightPrimary),
  }), [theme]);

  if (!screensStore.tabletsStore) {
    return null;
  }
  const list = screensStore.tabletsStore.tablets;
  return (
    <TabletsWrapper>
      {list.map((tabletLoading) => {
        if (tabletLoading.tablet && tabletLoading.tablet.glAvailable) {
          return (
            <Tablet
              tablet={tabletLoading.tablet}
              key={tabletLoading.innerId}
              localizationStore={localizationStore}
              timeZonesStore={timeZonesStore}
              scaleUnitStore={scaleUnitStore}
              themeHex={themeHex}
            />
          );
        }
        if (tabletLoading.inProgress) {
          return <TabletPreloader key={`loader-${tabletLoading.innerId}`} />;
        }
        return null;
      })}
    </TabletsWrapper>
  );
});
