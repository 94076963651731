import { Theme } from '@emotion/react';

export const theme: Theme = {
  backgroundColor: '#0A2333',
  color: '#a5a5aa',
  brightColor: '#cfcfcf',
  navbarBackground: '#0A2333',
  borderColor: '#03405B',
  baseBorder: '1px solid #03405B',
  gridColor: '#03405B',
  lightPrimary: '#045579',
};
