/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import { useState } from 'react';
import {
  Form, InputNumber, DatePicker, Row, Col, Radio, Space, Popover, Input,
} from 'antd';
import { useLocalization } from '@fluent/react';
import { TabletType } from '../../../enums/TabletType';
import { HelpTable } from './HelperTable';
import { ExportPdfRangeType } from '../../../enums/ExportPdfRangeType';
import { calcMinScale, disabledGeneration } from '../Untils';
import { scaleUnitStore, themingStore, timeZonesStore } from '../../../stores/init-stores-global';

type Props = {
  tabletType: TabletType;
  minData: number;
  maxData: number;
  onData: number;
  toData: number;
  wellName: string;
  onFinish: (values: any) => void;
  disabled: boolean;
};

export function ExportPdfForm({
  tabletType, minData, maxData, onData, toData, wellName, onFinish, disabled,
}: Props) {
  const { l10n } = useLocalization();
  const [minScale, setMinScale] = useState(tabletType === TabletType.Time
    ? calcMinScale(onData, toData)
    : scaleUnitStore.scaleUnit === 'm' ? 1 : 3);
  const [isDisabledData, setIsDisabledData] = useState(true);
  const [form] = Form.useForm();
  const disabledDate = disabledGeneration(minData, maxData);

  const updateScale = (minValue: number) => {
    const prev = form.getFieldValue('scale');
    if (prev < minValue) {
      form.setFieldsValue({ scale: minValue });
    }
  };

  const getMinScale = (value: ExportPdfRangeType) => {
    switch (value) {
      case ExportPdfRangeType.CurrentRange:
        return calcMinScale(onData, toData);
      case ExportPdfRangeType.AllRange:
        return calcMinScale(minData, maxData);
      default:
        return calcMinScale(form.getFieldValue('onData'), form.getFieldValue('toData'));
    }
  };

  const handlerChangeRangeType = (rangeType: ExportPdfRangeType) => {
    setIsDisabledData(rangeType !== ExportPdfRangeType.SelectRange);

    if (tabletType !== TabletType.Time) {
      return;
    }

    const minValue = getMinScale(rangeType);
    updateScale(minValue);
    setMinScale(minValue);
  };

  const handlerChangeTimeData = () => {
    const minValue = calcMinScale(form.getFieldValue('onData'), form.getFieldValue('toData'));
    updateScale(minValue);
    setMinScale(minValue);
  };

  return (
    <Form
      disabled={disabled}
      form={form}
      initialValues={{
        onData: tabletType === TabletType.Time
          ? moment(timeZonesStore.getDate(onData))
          : Math.round(onData * scaleUnitStore.kUnit),
        toData: tabletType === TabletType.Time
          ? moment(timeZonesStore.getDate(toData))
          : Math.round(toData * scaleUnitStore.kUnit),
        orientation: 'portrait',
        scale: minScale,
        themeName: themingStore.current,
        rangeType: ExportPdfRangeType.CurrentRange,
        tabletType,
        wellName,
      }}
      onFinish={onFinish}
      id="ExportPdfForm"
      layout="vertical"
    >
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item label={l10n.getString('Orientation')} name="orientation" required>
            <Radio.Group className="ant-radio-group-custom">
              <Radio.Button value="portrait">{l10n.getString('Portrait')}</Radio.Button>
              <Radio.Button value="landscape">{l10n.getString('Landscape')}</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={l10n.getString('Theme')} name="themeName" required>
            <Radio.Group className="ant-radio-group-custom">
              <Radio.Button value="light">{l10n.getString('Light')}</Radio.Button>
              <Radio.Button value="dark">{l10n.getString('Dark')}</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item name="rangeType" label={l10n.getString('Data')} required>
            <Radio.Group onChange={(e) => handlerChangeRangeType(e.target.value)}>
              <Space direction="vertical" size={24}>
                <Radio value={ExportPdfRangeType.CurrentRange}>{l10n.getString('Actual')}</Radio>
                <Radio value={ExportPdfRangeType.AllRange}>{l10n.getString('Well-interval')}</Radio>
                <Radio value={ExportPdfRangeType.SelectRange}>{l10n.getString('Range')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12} style={{ fontStyle: 'italic' }}>
          {tabletType === TabletType.Depth && (
            <>
              <Form.Item name="onData" label={l10n.getString('From')} required={!isDisabledData}>
                <InputNumber
                  min={minData}
                  max={maxData}
                  style={{ width: '100%' }}
                  disabled={isDisabledData}
                />
              </Form.Item>
              <Form.Item name="toData" label={l10n.getString('To')} required={!isDisabledData}>
                <InputNumber
                  min={minData}
                  max={maxData}
                  style={{ width: '100%' }}
                  disabled={isDisabledData}
                />
              </Form.Item>
            </>
          )}
          {tabletType === TabletType.Time && (
            <>
              <Form.Item name="onData" label={l10n.getString('From')} required={!isDisabledData}>
                <DatePicker
                  onChange={handlerChangeTimeData}
                  disabledDate={disabledDate}
                  showNow={false}
                  showTime={{ format: 'HH:mm:ss' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  disabled={isDisabledData}
                  allowClear={false}
                />
              </Form.Item>
              <Form.Item name="toData" label={l10n.getString('To')} required={!isDisabledData}>
                <DatePicker
                  onChange={handlerChangeTimeData}
                  disabledDate={disabledDate}
                  showNow={false}
                  showTime={{ format: 'HH:mm:ss' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  disabled={isDisabledData}
                  allowClear={false}
                />
              </Form.Item>
            </>
          )}
        </Col>
      </Row>

      <Row gutter={[24, 24]} align="bottom">
        <Col span={12}>
          {tabletType === TabletType.Time && (
            <Popover placement="leftTop" content={<HelpTable />} trigger="focus">
              <Form.Item name="scale" label={l10n.getString('Scale')} required tooltip={l10n.getString('Scale-step')}>
                <InputNumber
                  min={minScale}
                  addonAfter={l10n.getString('min')}
                  parser={(value) => Number(value!.replace(',', '.'))}
                />
              </Form.Item>
            </Popover>
          )}
          {tabletType === TabletType.Depth && (
            <Form.Item name="scale" label={l10n.getString('Scale')} required>
              <InputNumber
                min={minScale}
                addonAfter={scaleUnitStore.scaleUnit}
                parser={(value) => Number(value!.replace(',', '.'))}
                precision={0}
              />
            </Form.Item>
          ) }
        </Col>
      </Row>
      <Form.Item name="tabletType" hidden><Input /></Form.Item>
      <Form.Item name="wellName" hidden><Input /></Form.Item>
    </Form>
  );
}

export default ExportPdfForm;
