import {
  jsonObject, jsonMember, jsonArrayMember,
} from 'typedjson';
import { SourceDto } from './SourceDto';

export enum ValueScaleType {
  Linear,
  Log,
  Comments,
  Numeric,
}

export enum Layers {
  Single = 0,
  Multi = 1,
}

export enum CommentsHeight {
  ByText = 0,
  ByRange = 1,
}

export enum TrackAlign {
  Right,
  Left,
}

@jsonObject
export class TrackDto {
  @jsonMember({ constructor: Number })
    id: number = 0;

  @jsonMember({ constructor: Number })
    pos: number;

  @jsonArrayMember(SourceDto)
    sources: SourceDto[] = new Array<SourceDto>();

  @jsonMember({ constructor: Number })
    gridBaseHoris: number = 0;

  @jsonMember({ constructor: Number })
    gridSecondHoris: number = 0;

  @jsonMember({ constructor: Number })
    gridBaseVert: number = 0;

  @jsonMember({ constructor: Number })
    gridSecondVert: number = 0;

  @jsonMember({ constructor: Boolean })
    gridShowHoris: boolean = false;

  @jsonMember({ constructor: Boolean })
    gridShowVert: boolean = false;

  @jsonMember({ constructor: Number })
    trackWidth: number = 200;

  @jsonMember({ constructor: Number })
    valueScaleType: ValueScaleType = ValueScaleType.Linear;

  @jsonMember({ constructor: Number })
    fontSize: number;

  @jsonMember({ constructor: Number })
    layersPriority: Layers = Layers.Multi;

  @jsonMember({ constructor: Number })
    commentsHeight: CommentsHeight = CommentsHeight.ByRange;

  @jsonMember({ constructor: Boolean })
    hideBorders: boolean = false;

  @jsonMember({ constructor: Boolean })
    hideCurves: boolean = false;

  @jsonMember({ constructor: Number })
    align: TrackAlign = TrackAlign.Right;

  @jsonMember({ constructor: Number })
    scaleFrom: number = 0.2;

  @jsonMember({ constructor: Number })
    scaleTo: number = 9000;
}
