import styled from '@emotion/styled';

export const TemplateModalContent = styled.div`
  display: flex;
  align-items: stretch;
`;

export const TemplateItemWrapper = styled.div`
  display: flex;
  margin: 5px 0;
  justify-content: space-between;
  align-items: center;
`;

export const TemplateEdit = styled.div`
  width: 100%;
  margin: 0 5px;
`;

export const TemplateEditButton = styled.button`
  width: 100%;
  text-align: left;
  font-weight: 400;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  user-select: none;
  height: 32px;
  padding: 4px 11px;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    border-color: #d9d9d9;
  }
`;
