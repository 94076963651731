import {
  jsonObject, jsonMember,
} from 'typedjson';

//  Тип кривой. Устанавливает host
export enum ChartType {
  Curve = 0,
  Block = 1,
  Image = 3,
  Comments = 'comments',
}

@jsonObject
export class SourceDto {
  @jsonMember({ constructor: Number })
    id: number = 0; // Fake id, for compatibility

  @jsonMember({ constructor: Number })
    externalId: number; // id кривой

  @jsonMember({ constructor: String })
    name: string; // Имя кривой

  @jsonMember({ constructor: String })
    color: string; // Цвет кривой (Устанавливается случайный или пользователем)

  @jsonMember({ deserializer: (value) => setWidthLine(value) })
    lineWidth: number = 1; // Ширина кривой

  @jsonMember({ constructor: Number })
    lineType: number; // Тип кривой

  @jsonMember({ constructor: Number })
    minVal: number; // Минимальное значение кривой

  @jsonMember({ constructor: Number })
    maxVal: number; // Максимальное значение кривой

  @jsonMember({ constructor: Number })
    chartType: ChartType = 0; // 0 - curve, 1 - block

  @jsonMember({ constructor: Number })
    pos: number; // Позиция кривой

  @jsonMember({ constructor: Number })
    logId: number; // id журнала ствола скважины

  @jsonMember({ constructor: Number })
    curveId: number; // Внутренний идентификатор кривой (Не используется)

  @jsonMember({ constructor: String })
    logPattern: string; // Паттерн имени лога (Не используется)

  @jsonMember({ constructor: String })
    namePattern: string; // Паттерн имени (Не используется)

  @jsonMember({ constructor: String })
    currentUom: string; // Выбранные пользователем единицы измерения

  @jsonMember({ constructor: Number })
    ratioUom = 1; // отношение единиц измерения кривой к выбранным единицам измерения

  @jsonMember({ constructor: Date })
    startDate: Date | null; // Начало кривой

  @jsonMember({ constructor: Date })
    lastDate: Date | null; // Конец кривой

  @jsonMember({ constructor: Number })
    startDepth: number | null; // Начало кривой

  @jsonMember({ constructor: Number })
    lastDepth: number | null; // Конец кривой

  @jsonMember({ constructor: Boolean })
    dynamicRange?: boolean;

  @jsonMember({ constructor: String })
    legendPosition: 'top' | 'bottom' = 'top';

  @jsonMember({ constructor: Boolean })
    showValueScale: boolean = false;

  @jsonMember({ constructor: Number })
    legendHeight: number = 40;

  @jsonMember({ constructor: Number })
    fractionDigits?: number;

  private workLineType: number[] | null = null;

  public getTypeLine(): number[] {
    if (this.workLineType != null) {
      return this.workLineType;
    }

    const lineTypes = setTypeLine(this.lineType);
    if (this.lineWidth === 1) {
      return lineTypes;
    }
    this.workLineType = [];
    if (lineTypes.length > 0) {
      for (let i = 0; i < lineTypes.length; i += 1) {
        this.workLineType[i] = lineTypes[i] * this.lineWidth;
      }
    }
    return this.workLineType;
  }

  public resetTypeLine() {
    this.workLineType = null;
  }
}

function setWidthLine(width: number): number {
  return width > 1 ? width : 1;
}

function setTypeLine(id: number): number[] {
  switch (id) {
    case 1:
      return [5, 2];
    case 2:
      return [1, 1];
    case 3:
      return [5, 1, 1, 1];
    case 4:
      return [5, 1, 1, 1, 1, 1];
    default:
      return [];
  }
}
