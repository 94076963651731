import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import './index.css';
import App from './App';
import { AppLocalizationProvider } from './localization';
import './stomp';
import './global';

// eslint-disable-next-line import/extensions
import './polyfills';

// eslint-disable-next-line no-console
console.info('devicePixelRatio: ', window.devicePixelRatio);

ReactDOM.render(
  <BrowserRouter>
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <AppLocalizationProvider>
        <App />
      </AppLocalizationProvider>
    </DndProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
