import {
  jsonObject, jsonMember, jsonArrayMember,
} from 'typedjson';

import { TracksetDto } from './TracksetDto';

@jsonObject
export class TabletDto {
  constructor() {
    this.tracksets = new Array<TracksetDto>();
  }

  @jsonMember({ constructor: Number })
    id: number = 0;

  @jsonMember({ constructor: String })
    name: string;

  @jsonMember({ constructor: Number })
    tabletType: number;

  @jsonArrayMember(TracksetDto)
    tracksets: TracksetDto[];

  @jsonMember({ constructor: Number })
    storageVersion: number;
}

@jsonObject
export class TemplateDto {
  @jsonMember({ constructor: String })
    filename: null;

  @jsonMember({ constructor: Number })
    id: number;

  @jsonMember({ constructor: Number })
    tabletId: number;

  @jsonMember({ constructor: String })
    tabletName: '';
}

@jsonObject
export class TemplateViewModel {
  @jsonMember({ constructor: Number })
    id: number;

  @jsonMember({ constructor: Number })
    tabletId: number;

  @jsonMember({ constructor: String })
    tabletName: string;

  @jsonMember({ constructor: Boolean })
    selected: boolean;

  @jsonMember({ constructor: Boolean })
    system: boolean;
}
