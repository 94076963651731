/* eslint-disable no-param-reassign */
import { observer } from 'mobx-react-lite';
import { Sprite } from '@inlet/react-pixi';
import { useMemo } from 'react';
import chroma, { Color } from 'chroma-js';
import { Curve } from '../../../stores/Tracks';
import { Tablet } from '../../../stores/Tablet';
import { ImageCoordinates } from '../../../stores/CoordinatesStorages/ImageCoordinates';
import { ImagePoint } from '../../../api/curvesCache';

type Props = {
  source: Curve;
  tablet: Tablet;
  width: number;
  offsetX?: number;
  coordinates: ImageCoordinates
};
export const ImageCurve = observer(({
  tablet, coordinates, offsetX, width, source,
}: Props) => {
  const { points, minMaxValues } = coordinates;
  const { dynamicRange } = source.params;
  const images = useMemo(
    () => {
      if (points.length > 0) {
        const [min, max] = dynamicRange ? minMaxValues : [source.scaleFrom, source.scaleTo];
        const imageData = drawImage(points, min, max);
        const data = (imageData || []).map((i) => ({ ...i, data: imagedataToImage(i.data) }));
        return data;
      }
      return null;
    },
    [points, dynamicRange, source.scaleFrom, source.scaleTo, minMaxValues],
  );
  const { offsetY: scalerOffsetY, yRatio } = tablet.scale;
  const offsetY = scalerOffsetY + tablet.scale.zeroPointKey * yRatio;
  return (
    <>
      {(images || []).map((image) => {
        if (image.data) {
          const height = (image.toData - image.onData) * yRatio;
          const y = image.onData * yRatio - offsetY;
          return (
            <Sprite
              image={image.data}
              y={y}
              x={offsetX}
              width={width}
              height={height}
            />
          );
        }
        return null;
      })}
    </>
  );
});

function imagedataToImage(imagedata: ImageData) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = imagedata.width;
  canvas.height = imagedata.height;
  if (ctx) {
    ctx.putImageData(imagedata, 0, 0);

    const image = new Image();
    image.src = canvas.toDataURL();
    document.body.appendChild(image);
    return image;
  }
  return null;
}

function setPixel(image: ImageData, x: number, y: number, color: Color) {
  const [r, g, b, a] = color.rgba();
  // console.log([r, g, b, a]);
  const count = (y * image.width + x) * 4;
  image.data[count + 0] = r; // r
  image.data[count + 1] = g; // g
  image.data[count + 2] = b; // b
  image.data[count + 3] = Math.round(a * 255); // a;
}

function drawLine(
  image: ImageData,
  values: number[],
  yFrom: number,
  yTo: number,
  colorMap: (v: number) => Color,
) {
  values.forEach((chanelValue, x) => {
    const color = colorMap(chanelValue);
    for (let py = yFrom; py <= yTo; py += 1) {
      setPixel(image, x, py, color);
    }
  });
}

function drawImage(points: ImagePoint[], min: number, max: number) {
  const range = ['#FEF8E5', '#FFEBBF', '#EFCB01', '#C67C00', '#804000', '#000000'];
  const domain = [0.0, 0.098, 0.205, 0.367, 0.605, 1.0];
  const f = chroma.scale(range).domain(domain);

  const getValue = (v: number) => {
    const dv = (max - min);
    if (dv > 0 && v !== -999.25) {
      return f((v - min) / dv);
    }
    return chroma('#00000000');
  };
  const xRes = points[0].value.length;
  const c = 10;
  const start = points.at(0)?.key || 0;
  const end = points.at(-1)?.key || 0;
  const height = Math.round((end - start) * c);
  if (!height) {
    return null;
  }
  const bitmap = new ImageData(xRes, height);
  let prevChunkHeight = 0;
  let prevChunkStart = 0;
  const images: {
    data: ImageData;
    onData: number;
    toData: number;
  }[] = [];
  for (let i = 0; i < points.length - 1; i += 1) {
    const point = points[i];
    const nextPoint = points[i + 1];
    const y = Math.round((point.key - start) * c);
    const h = Math.round((nextPoint.key - start) * c);
    if ((nextPoint.key - point.key) > 100) {
      const data = new ImageData(xRes, y - prevChunkHeight);
      data.data.set(bitmap.data.slice((prevChunkHeight * xRes) * 4, (y * xRes) * 4));
      images.push({
        data,
        onData: points[prevChunkStart].key,
        toData: points[i].key,
      });
      prevChunkHeight = h;
      prevChunkStart = i + 1;
      // eslint-disable-next-line no-continue
      continue;
    }
    drawLine(bitmap, point.value, y, h, getValue);
  }
  const data = new ImageData(xRes, height - prevChunkHeight);
  data.data.set(bitmap.data.slice((prevChunkHeight * xRes) * 4, bitmap.data.length));
  images.push({
    data,
    onData: points[prevChunkStart].key,
    toData: end,
  });

  return images;
}
