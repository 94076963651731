import { generateLithologyImage, LithologyImage } from '../lithologyImages';

import lithology from '../lithologyConfig.json';
import patterns from '../patterns.json';

export class LithologyStore {
  status: 'load' | 'ready' = 'load';

  map = new Map<number, LithologyImage>();

  constructor() {
    this.init();
  }

  async init() {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of lithology) {
      let image:LithologyImage | null = null;
      if (item.id != null) {
        image = generateLithologyImage(item.id, item.message);
      } else if (item.pattern) {
        // eslint-disable-next-line no-await-in-loop
        image = await getUserLithologyImage(item.pattern, item.fillColor, item.name);
      }
      if (image) {
        this.map.set(item.clientId, image);
      }
    }
    this.status = 'load';
  }

  getLithologyForName(name: string) {
    const array = Array.from(this.map, ([, value]) => value);
    const image = array.find((x) => x.name.toLowerCase().split('|').includes(name.trim().toLowerCase()));

    if (image) {
      return image;
    }

    return null;
  }
}

export const lithologyStore = new LithologyStore();

function getUserLithologyImage(
  patternCode: string,
  color: string,
  name: string,
): Promise<LithologyImage | null> {
  const pattern = (patterns as any)[patternCode];
  if (!pattern || !color) {
    return Promise.resolve(null);
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = 64;
  canvas.height = 64;

  if (!context) {
    return Promise.resolve(null);
  }

  context.fillStyle = color;
  context.fillRect(0, 0, canvas.width, canvas.height);
  const patternImg = new Image();

  patternImg.src = pattern;
  return new Promise((resolve) => {
    patternImg.onload = async () => {
      const ptrn = context.createPattern(patternImg, null);

      if (!ptrn) {
        resolve(null);
      } else {
        context.fillStyle = ptrn;
        context.fillRect(0, 0, canvas.width, canvas.height);

        const img = new Image();
        img.src = canvas.toDataURL();

        resolve({
          img,
          fillColor: color,
          name,
          message: '',
        });
      }
    };
  });
}
