import { observer } from 'mobx-react-lite';
import { CloseOutlined, FullscreenOutlined, LoadingOutlined } from '@ant-design/icons';
import Scrollbar from 'react-scrollbars-custom';
import { Button, Tooltip } from 'antd';
import { useLocalization } from '@fluent/react';
import { ScreensStore } from '../../stores/screensStore';
import {
  ScreenTabsContainer, ScreenTab, ScreenTabClose, ScreenTabsWrapper,
} from './ScreenTabs.styled';

type Props = {
  screensStore: ScreensStore;
  isFullscreen: boolean;
  setFullscreen: (value: boolean) => void;
};

export const ScreensTabs = observer(({ screensStore, isFullscreen, setFullscreen }: Props) => {
  const { l10n } = useLocalization();
  function open(id: string) {
    screensStore.openScreen(id);
  }

  function deleteScreen(
    id: string,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) {
    e.stopPropagation();
    screensStore.deleteScreen([id]);
  }

  const thumbStyle = {
    background: 'rgba(0, 0, 0, 0.5)',
  };

  return (
    <ScreenTabsWrapper>
      <Button
        size="small"
        icon={<Tooltip title={l10n.getString('Full-screen')}><FullscreenOutlined /></Tooltip>}
        type={isFullscreen ? 'primary' : 'default'}
        onClick={() => setFullscreen(!isFullscreen)}
      />
      <Scrollbar
        noScrollY
        disableTracksWidthCompensation
        trackYProps={{
          style: {
            backgroundColor: 'transparent',
            transition: 'opacity 0.2s',
            width: '8px',
            height: '100%',
            top: 0,
          },
        }}
        thumbYProps={{
          style: thumbStyle,
        }}
        trackXProps={{
          style: {
            backgroundColor: 'transparent',
            transition: 'opacity 0.2s',
            left: 0,
            width: '100%',
            height: '8px',
            bottom: 0,
          },
        }}
        thumbXProps={{
          style: thumbStyle,
        }}
      >
        <ScreenTabsContainer>
          {screensStore.list
            .map((screen) => (
              <ScreenTab
                active={screen.screenId === screensStore.current}
                key={screen.screenId}
                onClick={() => open(screen.screenId)}
              >
                {screen.name}
                <ScreenTabClose
                  onClick={(e) => deleteScreen(screen.screenId, e)}
                >
                  <CloseOutlined />
                </ScreenTabClose>
              </ScreenTab>
            ))}
          {screensStore.tabPreloader && (
          <ScreenTab active={false} key="preload">
            {screensStore.tabPreloader}
            <ScreenTabClose>
              <LoadingOutlined />
            </ScreenTabClose>
          </ScreenTab>
          )}
        </ScreenTabsContainer>
      </Scrollbar>
    </ScreenTabsWrapper>
  );
});
