import { observer } from 'mobx-react-lite';
import React from 'react';
import { Popconfirm } from 'antd';
import { useLocalization } from '@fluent/react';
import { Tablet } from '../../stores/Tablet';
import { Curve } from '../../stores/Tracks';
import { ErrorBlock } from './TabletTracksInfo.styled';

type Props = {
  tablet: Tablet;
  source: Curve;
  collapsed: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
  onContextMenu: React.MouseEventHandler<HTMLDivElement>
};

export const ErrorHeader = observer(({
  source, collapsed, onClick, onContextMenu,
} : Props) => {
  const { l10n } = useLocalization();
  function confirm() {
    source.sourceData?.fetchSource();
  }
  return (
    <ErrorBlock
      color={source.sourceData?.imageLithology?.color || '#000000'}
      key={source.sourceDto.externalId}
      onClick={onClick}
      onContextMenu={onContextMenu}
      collapsed={collapsed}
    >
      <>
        <span>{l10n.getString('Error')}</span>
        <Popconfirm
          placement="topRight"
          title={source.sourceData?.errors}
          onConfirm={() => confirm()}
          okText={l10n.getString('Reload')}
          cancelText={l10n.getString('Cancel')}
        >
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            {l10n.getString('More')}
          </span>
        </Popconfirm>
      </>
    </ErrorBlock>
  );
});
