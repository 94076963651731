import { makeAutoObservable, observable, runInAction } from 'mobx';
import { getDepthCoords, CurvePoint } from '../../api/curvesCache';
import { immutableSplice } from '../../utils';

export class LithologyCoordinates {
  curveId: number;

  points: CurvePoint[] = [];

  inProgress = false;

  inDownloading = false;

  errors = null;

  onData?: number;

  toData?: number;

  minValue = 0;

  maxValue = 100;

  fetched = false;

  constructor(curveId: number) {
    makeAutoObservable(this, {
      points: observable.ref,
    });
    this.curveId = curveId;
  }

  async refreshCoords({ force }: { force?: boolean }) {
    if (!force && (this.points.length > 0 || this.inProgress || this.inDownloading)) {
      return;
    }
    this.inProgress = true;
    this.errors = null;
    try {
      const data = await getDepthCoords(this.curveId);
      runInAction(() => {
        if (Array.isArray(data)) {
          this.points = data;
          this.onData = data.at(0)?.key;
          this.toData = data.at(-1)?.key;
        } else {
          this.inDownloading = true;
        }
      });
      this.fetched = true;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  binarySearch(data: CurvePoint[], target: number, start: number, end: number): CurvePoint | null {
    if (end < 1) return data[0];
    const middle = Math.floor(start + (end - start) / 2);
    if (target === data[middle].key) return data[middle];
    if ((end - 1) === start) {
      return Math.abs(data[start].key - target) > Math.abs(data[end].key - target)
        ? data[end]
        : data[start];
    }
    if (target > data[middle].key) return this.binarySearch(data, target, middle, end);
    if (target < data[middle].key) return this.binarySearch(data, target, start, middle);
    return null;
  }

  getPoint(y: number) {
    return this.binarySearch(this.points, y, 0, this.points.length - 1);
  }

  addPoint(point: CurvePoint) {
    const index = this.points.findIndex((p, i) => {
      const next = this.points.at(i + 1);
      if (next) {
        return p.key < point.key && point.key < next.key;
      }
      return false;
    });
    if (index > -1 && index < this.points.length) {
      this.points = immutableSplice(this.points, index + 1, 0, point);
    }
  }

  addPoints(points: CurvePoint[]) {
    this.points = this.points.concat(...points.filter((p) => p.key > (this.toData || 0)));
  }
}
