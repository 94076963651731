import { makeAutoObservable } from 'mobx';
import { Track, Tracks } from './Tracks';
import { TabletParams } from './TabletParams';
import { ScalePosition } from './ScalePosition';
import { TabletScroll } from './TabletScroll';

export class Coordinator {
  x = 0;

  y = 0;

  globalX = 0;

  globalY = 0;

  pointerMoving: boolean = false;

  tracks: Tracks;

  tabletParams: TabletParams;

  scalePosition: ScalePosition;

  tabletScroll: TabletScroll;

  dragTrack: Track | null = null;

  shiftKey: boolean = false;

  constructor(
    tracks: Tracks,
    tabletParams: TabletParams,
    scalePosition: ScalePosition,
    tabletScroll: TabletScroll,
  ) {
    this.tracks = tracks;
    this.tabletParams = tabletParams;
    this.scalePosition = scalePosition;
    this.tabletScroll = tabletScroll;
    makeAutoObservable(this);
  }

  get currentTrack() {
    if (!this.pointerMoving) {
      return null;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const [track, offset] of this.tracks.withOffset) {
      const nextOffset = this.scalePosition.tracksOffset + offset
        + track.params.trackWidth + this.tracks.tracksPadding;
      const coord = this.tabletParams.orientation === 'vertical'
        ? this.x + this.tabletScroll.scrollLeft
        : this.tabletScroll.height - this.y + this.tabletScroll.scrollTop;
      if (offset < coord && coord < nextOffset) {
        return track;
      }
    }
    return null;
  }

  get mainAxis() {
    return this.tabletParams.orientation === 'vertical' ? this.y : this.x;
  }

  setPoint(
    x :number,
    y: number,
    globalX?: number,
    globalY?: number,
    shiftKey = false,
  ) {
    this.x = x;
    this.y = y;
    if (globalX) {
      this.globalX = globalX;
    }
    if (globalY) {
      this.globalY = globalY;
    }
    if (this.dragTrack) {
      this.onTrackDrag();
    }

    this.shiftKey = shiftKey;
  }

  setWithShift(shiftKey: boolean) {
    this.shiftKey = shiftKey;
  }

  setPointerState(active: boolean) {
    this.pointerMoving = active;
  }

  setDragTrack(track: Track | null) {
    this.dragTrack = track;
  }

  onTrackDrag() {
    if (!this.dragTrack || !this.currentTrack) {
      return;
    }

    const dragBounds = getTrackBound(this.dragTrack, this.tracks);
    const hoverBounds = getTrackBound(this.currentTrack, this.tracks);
    if (!hoverBounds || !dragBounds) {
      return;
    }

    const dragIndex = this.dragTrack.pos;
    const hoverIndex = this.currentTrack.pos;

    if (dragIndex === hoverIndex) {
      return;
    }
    const hoverMiddle = (hoverBounds.right - hoverBounds.left) / 2;
    const hoverClient = (this.tabletParams.orientation === 'vertical'
      ? this.x - this.scalePosition.tracksOffset
      : this.tabletScroll.height - this.y + this.tabletScroll.scrollTop)
        - hoverBounds.left;

    if (dragIndex < hoverIndex && hoverClient < hoverMiddle) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClient > hoverMiddle) {
      return;
    }

    move(this.dragTrack, this.currentTrack);
  }
}

function move(prevIndexItem: Track, newIndexItem: Track) {
  if (prevIndexItem && newIndexItem) {
    const i = prevIndexItem.pos;
    prevIndexItem.setPosition(newIndexItem.pos);
    newIndexItem.setPosition(i);
  }
}

function getTrackBound(track: Track, tracks: Tracks) {
  const p = tracks.withOffset.find(([t]) => t === track);
  if (!p) {
    return null;
  }
  const offset = p[1];
  return {
    x: offset,
    width: track.params.trackWidth,
    left: offset,
    right: offset + track.params.trackWidth,
  };
}
