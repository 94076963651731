import { makeAutoObservable, runInAction } from 'mobx';
import { ObjectDto, ObjectType } from '../api/dto/ObjectDto';
import {
  downloadLasFile, getAllWells, getWellChildren, getWellObject,
} from '../api/wells';
import { objectDictionary } from '../dictionary';
import { throwError } from '../errorHandler';

const OPENED_TREE_NODE_SYMBOL = 'LC_TREE_OPENED_IDS';

export type ObjectStructure = {
  log?: FolderObject;
  bole?: FolderObject;
  well?: FolderObject;
};

export class FolderObject {
  state: 'open' | 'close' | 'loading' = 'close';

  object: ObjectDto;

  objectTypeId: ObjectType;

  externalId: number;

  externalParentId: number;

  children: boolean;

  tree: FolderTreeStore;

  constructor(object: ObjectDto, tree: FolderTreeStore) {
    makeAutoObservable(this);
    this.object = object;
    this.tree = tree;
    this.objectTypeId = object.objectTypeId;
    this.externalId = object.externalId;
    this.externalParentId = object.externalParentId;
    this.children = object.children;
  }

  get childNodes() {
    return this.tree.childrenMap.get(this.externalId) ?? [];
  }

  get contains() {
    return this.tree.contains(this.object);
  }

  get inHiddenList() {
    if (this.tree.listOfHidden.size) {
      return this.tree.listOfHidden.has(this.externalId);
    }
    return false;
  }

  get hidden() {
    return objectDictionary[this.objectTypeId]
      ? ((objectDictionary[this.objectTypeId].unavailable) || this.inHiddenList) : true;
  }

  setState(state: 'open' | 'close' | 'loading') {
    this.state = state;
  }

  open() {
    if (this.childNodes.length > 0) {
      this.setState('open');
    } else {
      this.setState('loading');
      this.tree.fetchChildren(this.externalId).finally(() => {
        this.setState('open');
        this.tree.syncLsState();
      });
    }
  }

  close() {
    this.setState('close');
  }

  toggle() {
    if (this.state === 'loading' || !this.children) {
      return;
    }
    if (this.state === 'open') {
      this.close();
    } else if (this.state === 'close') {
      this.open();
    }
    this.tree.syncLsState();
  }

  getObjectStructureData() {
    return this.tree.getObjectStructureData(this);
  }
}

class SelectToLasExport {
  selected: number[] = [];

  well: FolderObject;

  inProgress = false;

  constructor(well: FolderObject) {
    makeAutoObservable(this);
    this.well = well;
  }

  onChecked(id: number) {
    const index = this.selected.indexOf(id);
    if (index > -1) {
      this.selected.splice(index, 1);
    } else {
      this.selected.push(id);
    }
  }

  selectableFolder(node: FolderObject) {
    return node.objectTypeId === ObjectType.LOG_DEPTH && this.well.contains(node.object);
  }

  async download(interpolated: boolean) {
    if (this.selected.length === 0) {
      return;
    }
    this.inProgress = true;
    try {
      await downloadLasFile(this.selected, this.well.object.text, interpolated);
    } catch (e) {
      throwError('ErrorToSave')(e);
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }
}

class FolderTreeStore {
  inProgress = false;

  errors = null;

  object: ObjectDto | null = null;

  listOfHidden = new Set<number>();

  selectedWell: number | null = null;

  selectToLasExport: SelectToLasExport | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  objectsMap = new Map<number, FolderObject>();

  get list() {
    return Array.from(this.objectsMap.values());
  }

  get childrenMap() {
    const map = new Map<number, FolderObject[]>();
    this.list.forEach((object) => {
      if (object.children) {
        map.set(object.externalId, []);
      }
    });
    this.list.forEach((object) => {
      if (object.externalParentId) {
        const parent = map.get(object.externalParentId);
        if (parent) {
          parent.push(object);
        }
      }
    });
    return map;
  }

  get root() {
    return this.list.filter((object) => !object.externalParentId);
  }

  getParent(target: FolderObject) {
    return this.objectsMap.get(target.externalParentId);
  }

  contains(target: ObjectDto) {
    return (otherNode: ObjectDto) => {
      let currentNode: ObjectDto | undefined = otherNode;
      do {
        if (target.externalId === currentNode.externalId) {
          return true;
        }
        const parent = this.objectsMap.get(currentNode.externalParentId);
        currentNode = parent?.object;
      } while (currentNode);
      return false;
    };
  }

  getObjectStructureData(leafObject: FolderObject): ObjectStructure {
    const branch: FolderObject[] = [leafObject];
    let currentObject: FolderObject | undefined = leafObject;
    const isParent = (obj: FolderObject) => obj.externalId === currentObject?.externalParentId;
    while (currentObject?.externalId) {
      const parentObject = this.list.find(isParent);
      if (parentObject) { branch.push(parentObject); }
      currentObject = parentObject;
    }
    const logObject = branch.find((obj) => [
      ObjectType.LOG_DEPTH, ObjectType.LOG_TIME,
    ].includes(obj.objectTypeId));

    const boleObject = branch.find((obj) => obj.objectTypeId === ObjectType.WELLBORE);

    const wellObject = branch.find((obj) => [
      ObjectType.WELL, ObjectType.WELL_RED, ObjectType.WELL_GREEN, ObjectType.WELL_YELLOW,
    ].includes(obj.objectTypeId));

    return {
      log: logObject,
      bole: boleObject,
      well: wellObject,
    };
  }

  onSearch(str: string) {
    this.listOfHidden.clear();
    if (!str) {
      return;
    }
    const founded = this.list.filter((obj) => {
      const isRoot = isRootObject(obj);
      return isRoot && obj.object.text
        .toLowerCase()
        .includes(str.toLowerCase());
    });

    const fullList = new Set(founded.map((o) => o.externalId));
    const getParentObject = (obj: FolderObject) => {
      const parent = this.getParent(obj);
      if (parent) {
        fullList.add(parent.externalId);
        getParentObject(parent);
      }
    };

    founded.forEach((obj) => {
      getParentObject(obj);
    });

    this.list.forEach((node) => {
      const id = node.externalId;
      if (fullList.has(id)) {
        if (node.childNodes.length) {
          node.open();
        }
      } else if (isRootObject(node)) {
        this.listOfHidden.add(node.externalId);
      }
    });
  }

  createSelectToLasExport(externalId: number) {
    const object = this.objectsMap.get(externalId);
    if (object) {
      const objects = this.getObjectStructureData(object);
      const well = objects && objects.well && this.objectsMap.get(objects.well.externalId);
      if (well) {
        this.selectToLasExport = new SelectToLasExport(well);
      }
    }
  }

  removeSelectToLasExport() {
    this.selectToLasExport = null;
  }

  syncLsState() {
    const ids = this.list.filter((o) => o.state === 'open' && o.childNodes.length).map((o) => o.externalId);
    localStorage.setItem(OPENED_TREE_NODE_SYMBOL, JSON.stringify(ids));
  }

  openSyncedObjects() {
    const ids = JSON.parse(localStorage.getItem(OPENED_TREE_NODE_SYMBOL) ?? '[]') as number[];
    ids.forEach((id) => {
      const object = this.objectsMap.get(id);
      if (object) {
        object.setState('open');
      }
    });
  }

  refresh() {
    localStorage.setItem(OPENED_TREE_NODE_SYMBOL, JSON.stringify([]));
    this.fetchRoot();
  }

  async fetchRoot() {
    this.inProgress = true;
    this.errors = null;
    this.objectsMap.clear();

    try {
      const objects = (await getAllWells()).map((w) => ({ ...w, children: true }));
      runInAction(() => {
        objects
          .sort((a, b) => {
            const nameA = a.text.toLowerCase(); const
              nameB = b.text.toLowerCase();
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            return 0;
          })
          .sort((a, b) => {
            const rang = [
              ObjectType.WELL,
              ObjectType.WELL_RED,
              ObjectType.WELL_YELLOW,
              ObjectType.WELL_GREEN,
            ];
            return rang.indexOf(a.objectTypeId) > rang.indexOf(b.objectTypeId)
              ? -1 : 1;
          });
        objects.forEach((o) => {
          this.objectsMap.set(o.externalId, new FolderObject(o, this));
        });
      });
      const ids = JSON.parse(localStorage.getItem(OPENED_TREE_NODE_SYMBOL) ?? '[]') as number[];
      this.openSyncedObjects();
      await Promise.allSettled(ids.map((id) => (!this.objectsMap.get(id)?.childNodes.length
        ? this.fetchChildren(id)
        : Promise.resolve())));
      this.openSyncedObjects();
      return objects;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('Wells-tree-root-error')(e);
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  async fetchChildren(id: number) {
    this.errors = null;

    try {
      const objects = await getWellChildren(id);
      runInAction(() => {
        objects
          .sort((a, b) => {
            const nameA = a.text.toLowerCase(); const
              nameB = b.text.toLowerCase();
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            return 0;
          });
        objects.forEach((o) => this.objectsMap.set(o.externalId, new FolderObject(o, this)));
      });
      return objects;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('Wells-tree-leaf-error')(e);
      throw e;
    }
  }

  async fetchFolderObject(id: number) {
    this.errors = null;

    try {
      const object = await getWellObject(id);
      runInAction(() => {
        this.object = object;
      });
      return object;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('Wells-tree-object-info-error')(e);
      throw e;
    }
  }

  clear() {
    this.objectsMap.clear();
    localStorage.removeItem(OPENED_TREE_NODE_SYMBOL);
  }
}

function isRootObject(obj: FolderObject) {
  return [
    ObjectType.WELL,
    ObjectType.WELL_RED,
    ObjectType.WELL_GREEN,
    ObjectType.WELL_YELLOW,
    ObjectType.DB,
  ].includes(obj.object.objectTypeId);
}

export const folderTreeStore = new FolderTreeStore();
