import styled from '@emotion/styled';

export const AppWrapper = styled.div<{ wellsOpened: boolean }>` 
  display: grid;
  grid-template-columns: min-content min-content 1fr;
  height: 100%;
  grid-template-columns: ${({ wellsOpened }) => (wellsOpened
    ? 'min-content min-content 1fr'
    : 'min-content 1fr'
  )};
`;

export const SidebarContainer = styled.div<{ collapsed: boolean }>` 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: ${({ collapsed }) => (collapsed ? '85px' : '200px')};
  border-right: ${(props) => props.theme.baseBorder};
  background: ${(props) => props.theme.navbarBackground};
  & .ant-menu {
    border-right: 0;
    background: none;
  }
`;

export const SidebarTopContainer = styled.div` 
`;

export const SidebarBottomContainer = styled.div`
text-align: center;
`;

export const SidebarBottomCollapsed = styled.div`
  margin-top: 4px;
  padding: 8px;
  text-align: center;
  border-top: ${(props) => props.theme.baseBorder};
  cursor: pointer;
  user-select: none;
  line-height: 14px;
`;

export const TreePanelContainer = styled.div<{ visible: boolean }>`
  height: 100vh;
  display: ${({ visible }) => (visible ? 'grid' : 'none')};
  grid-template-rows: 53px 1fr 30%;
  width: 300px;
  border-right: ${(props) => props.theme.baseBorder};
`;

export const MainContainer = styled.div` 
`;

export const LogoBlock = styled.div` 
  padding: 10px;
  border-bottom: ${(props) => props.theme.baseBorder};
`;

export const LogoImage = styled.img` 
  display: block;
  width: 100%;
`;

export const DataBlockContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding: 8px;
  border-top: ${(props) => props.theme.baseBorder};
`;

export const ScreensContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background-color: ${(props) => props.theme.backgroundColor};
`;
