import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { ValueScaleType } from '../../api/dto/TrackDto';
import { BASE_LEGEND_HEIGHT } from '../../constants';
import { CurveStatus } from '../../stores/SourceDataMap';
import { Tablet } from '../../stores/Tablet';
import { Track, Curve } from '../../stores/Tracks';
import { eachOfInterval } from '../../utils';
import { CoordinatorBadge } from './CoordinatorBadge';
import { LoaderString } from './LoaderString';
import { CurveBlockWithScale, CurveName, RoundBlock } from './TabletTracksInfo.styled';

function ScalePoint({
  value, zoom, color, scaleFrom, textStep, orientation, reverse,
}: {
  value: number;
  zoom: number;
  color: string;
  scaleFrom: number;
  textStep: number;
  orientation: 'horizontal' | 'vertical';
  reverse: boolean;
}) {
  const x = dataToPoint(value, zoom, scaleFrom);

  return (
    <>
      {value % textStep === 0 && (
      <div
        style={{
          position: 'absolute',
          left: x + 2,
          lineHeight: '12px',
          writingMode: orientation === 'vertical' ? 'horizontal-tb' : 'vertical-rl',
          ...(reverse ? { top: 6 } : { bottom: 6 }),
        }}
      >
        {value}
      </div>
      )}
      <div
        style={{
          position: 'absolute',
          height: value % textStep === 0 ? 12 : 3,
          width: 1,
          transform: `translate(${x}px, ${0}px)`,
          backgroundColor: color,
          ...(reverse ? { top: 0 } : { bottom: 0 }),
        }}
      />
    </>
  );
}

export const CurveScale = observer(({ tablet, track, curve }:
{ tablet: Tablet, track: Track, curve: Curve }) => {
  const zoom = track.params.trackWidth / (curve.params.scaleTo - curve.params.scaleFrom);
  const step = useMemo(() => (track.params.valueScaleType === ValueScaleType.Log
    ? calculateLogarithmicStep(zoom) : calculateStep(zoom)), [track.params.valueScaleType, zoom]);
  const onData = Math.trunc((curve.params.scaleFrom) / step[1]) * step[1];
  const toData = Math.trunc((curve.params.scaleTo) / step[1]) * step[1];
  const list = eachOfInterval(onData, toData, step[1]);
  return (
    <div style={{ position: 'relative', height: '100%', overflow: 'hidden' }}>
      {list.map((value) => (
        <ScalePoint
          key={value}
          value={value}
          zoom={zoom}
          color={curve.params.color}
          scaleFrom={curve.params.scaleFrom}
          textStep={step[0]}
          orientation={tablet.params.orientation}
          reverse={curve.params.legendPosition === 'bottom'}
        />
      ))}
    </div>
  );
});

type Props = {
  track: Track;
  tablet: Tablet;
  source: Curve;
  offset: number;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onContextMenu: React.MouseEventHandler<HTMLDivElement>;
};

export const CurveHeaderWithScale = observer(({
  track, tablet, source, offset, onClick, onContextMenu,
} : Props) => (
  <CurveBlockWithScale
    color={source.params.color}
    key={source.sourceDto.externalId}
    onClick={onClick}
    onContextMenu={onContextMenu}
    legendHeight={tablet.params.orientation === 'horizontal'
      ? source.params.legendHeight
      : BASE_LEGEND_HEIGHT}
    selected={tablet.tracks.selectedSource === source}
    reverse={source.params.legendPosition === 'bottom'}
  >
    <CurveName>
      <RoundBlock>
        {source.sourceDto.name}
      </RoundBlock>
      <div>{source.params.currentUom}</div>
    </CurveName>

    {source.sourceData?.status === CurveStatus.ServerFetching
        || source.sourceData?.status === CurveStatus.MetaDataFetching
        || source.sourceData?.status === CurveStatus.NoData
      ? <LoaderString source={source} position="absolute" />
      : <CurveScale track={track} curve={source} tablet={tablet} />}

    {tablet.coordinator.currentTrack === track && tablet.editMode === false
    && source.scaleFrom != null && source.scaleTo != null
    && source.sourceData?.status !== CurveStatus.NoData && (
    <CoordinatorBadge
      x={tablet.params.orientation === 'vertical'
        ? tablet.coordinator.x - offset - tablet.scalePosition.tracksOffset
        : (tablet.tabletScroll.height - tablet.coordinator.y
          + tablet.tabletScroll.scrollTop) - offset}
      trackWidth={track.params.trackWidth}
      sourceParams={source.params}
      scaleFrom={source.scaleFrom}
      scaleTo={source.scaleTo}
      rt={tablet.params.orientation}
      showValueScale={source.params.showValueScale}
      reverse={source.params.legendPosition === 'bottom'}
      valueScaleType={track.params.valueScaleType}
    />
    )}
  </CurveBlockWithScale>
));

function dataToPoint(data: number, zoom: number, zero: number) {
  return (data - zero) * zoom;
}

function calculateStep(zoom: number) {
  let majorStep = 10.0;
  let minorStep = 1.0;

  if (zoom > 0 && Number.isFinite(zoom)) {
    const optimalStepInPixels = 40;
    let e = 1.0;
    const majorRow: number[] = [2.0, 2.5, 5.0, 10.0];
    const minorRow: number[] = [0.5, 0.5, 1.0, 1.0];
    let x = Math.abs(optimalStepInPixels / zoom);
    if (x > 1.0) {
      while (x > 10) {
        x /= 10;
        e *= 10;
      }
    } else {
      while (x < 1) {
        x *= 10;
        e /= 10;
      }
    }
    for (let i = 0; i < 4; i += 1) {
      if (x <= majorRow[i]) {
        majorStep = majorRow[i] * e;
        minorStep = minorRow[i] * e;
        break;
      }
    }
  }
  return [majorStep, minorStep];
}

function calculateLogarithmicStep(zoom: number) {
  let majorStep = 1.0;
  let minorStep = 1.0;

  if (zoom > 0 && Number.isFinite(zoom)) {
    const optimalStepInPixels = 40;
    let e = 1.0;
    const majorRow: number[] = [1.0, 10.0];
    let x = Math.abs(optimalStepInPixels / zoom);
    if (x > 1.0) {
      while (x > 10) {
        x /= 10;
        e *= 10;
      }
    } else { while (x < 1) { x *= 10; e /= 10; } }

    for (let i = 0; i < majorRow.length; i += 1) {
      if (x < majorRow[i]) {
        minorStep = majorRow[i] * e;
        majorStep = minorStep;
        break;
      }
    }
  }
  return [majorStep, minorStep];
}
