import {
  localizationStore, scaleUnitStore, themingStore, timeZonesStore,
} from './stores/init-stores-global';
import { LC_SELECTED_LOCALE } from './stores/localizationStore';
import { LC_SCALE_UNIT, Units } from './stores/scaleUnitStore';
import { LC_SELECTED_THEME } from './stores/themingStore';
import { LC_SELECTED_TIME_ZONE } from './stores/timeZonesStore';
import { ThemeTypes } from './theming';

window.addEventListener('storage', (event) => {
  if (event.newValue && event.newValue !== event.oldValue) {
    switch (event.key) {
      case LC_SELECTED_THEME:
        themingStore.changeTheme(event.newValue as ThemeTypes);
        break;
      case LC_SCALE_UNIT:
        scaleUnitStore.saveScaleUnit(event.newValue as Units);
        break;
      case LC_SELECTED_LOCALE:
        localizationStore.changeLocales([event.newValue]);
        break;
      case LC_SELECTED_TIME_ZONE:
        timeZonesStore.select(Number(event.newValue));
        break;
      default:
        break;
    }
  }
});
