import { observer } from 'mobx-react-lite';
import { TabletType } from '../../enums/TabletType';
import { TimeScale } from '../../stores/Scale';
import { Tablet } from '../../stores/Tablet';
import { round } from '../../utils';
import { ScaleDevMonitorContainer } from './Tablet.styled';

export const ScaleDevMonitor = observer(({ tablet }: { tablet: Tablet }) => {
  const { scale } = tablet;

  const { dataStep } = tablet.sourceDataMap.sharedTimeStore.layers.closest;

  return (
    <ScaleDevMonitorContainer>
      <div>
        Data per pixel:
        {' '}
        {scale.dataPerPixel}
      </div>
      {tablet.tabletType === TabletType.Time && (
        <>
          <div>
            Time data scale:
            {' '}
            {dataStep}
            min
          </div>
          <div>
            Pixels per minute:
            {' '}
            {round((scale as TimeScale).pixelsPerMinute)}
          </div>
        </>
      )}
    </ScaleDevMonitorContainer>
  );
});
