import { observer } from 'mobx-react-lite';
import { Curve } from '../../../stores/Tracks';
import { Tablet } from '../../../stores/Tablet';
import { CurvePoint } from '../../../api/curvesCache';
import { CuttingsLogPolygon } from './CuttingsLogPolygon';

export const CuttingsLog = observer(({
  source, tablet, width, coords,
}:
{ source: Curve; tablet: Tablet, width: number, offsetX?: number, coords: CurvePoint[][] }) => (
  <>
    {(coords).map((coord) => (
      <CuttingsLogPolygon
        source={source}
        tablet={tablet}
        width={width}
        coords={coord}
        key={coord.at(0)?.key}
      />
    ))}
  </>
));
