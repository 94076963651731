import { makeAutoObservable, runInAction } from 'mobx';
import {
  deleteScreens, getScreens, saveScreen, TabletInfoDto,
} from '../api/screens';
import { throwError } from '../errorHandler';
import { Screen } from './Screen';
import { getEntries, waitPromise } from '../utils';
import { FolderObject } from './folderTree';

export class ScreensStore {
  inProgress = false;

  tabPreloader: string | null = null;

  errors = null;

  screens: Map<string, Screen> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  get list() {
    return Array.from(this.screens.values());
  }

  current: string | null = null;

  get currentScreen() {
    return (this.current != null ? this.screens.get(this.current) : null) ?? null;
  }

  get currentName() {
    return this.currentScreen ? this.currentScreen.name : '';
  }

  get tabletsStore() {
    return this.currentScreen?.tabletsStore ? this.currentScreen.tabletsStore : null;
  }

  get entries() {
    return Object.entries(this.screens);
  }

  async openScreen(screenId: string) {
    const screen = this.screens.get(screenId);

    if (screen) {
      this.current = screenId;
      await screen.init();
    }
  }

  async fetchList() {
    this.inProgress = true;
    this.errors = null;
    try {
      const screens = await getScreens();
      runInAction(() => {
        getEntries(screens).forEach(([id, name]) => {
          if (!this.screens.has(id)) {
            this.screens.set(id, new Screen(id, name));
          }
        });
      });
      return screens;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('ErrorToFetch')(e);
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  async deleteScreen(ids: string[]) {
    this.inProgress = true;
    this.errors = null;
    try {
      const data = await deleteScreens(ids);
      runInAction(() => {
        ids.forEach((id) => {
          this.screens.delete(id);
        });
      });
      if (!this.currentScreen && this.list[0]) {
        this.openScreen(this.list[0].screenId);
      }
      return data;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throwError('AnErrorOccurredWhileDeleting')(e);
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  async saveScreen(name: string, screenInfo: TabletInfoDto[], id?: string) {
    try {
      const data = await saveScreen({ id, name, screenInfo });
      await this.fetchList();
      return data;
    } catch (e) {
      throwError('ErrorToSave')(e);
      throw e;
    }
  }

  async openOrCreateInWellTab(
    tabletName:string,
    well: FolderObject,
    bole:FolderObject,
    log?:FolderObject,
    tabletId?: number,
  ) {
    const wellScreen = this.list.find(((s) => s.name === well.object.text));
    if (wellScreen && tabletId) {
      this.current = wellScreen.screenId;
      (wellScreen.tabletsStore
        ? Promise.resolve(wellScreen.tabletsStore)
        : wellScreen.init()).then((tabletsStore) => {
        if (tabletsStore) {
          tabletsStore.createTabletById(
            tabletId,
            tabletName,
            well.object,
            bole.object,
            log?.object,
          )
            .then(() => {
              wellScreen.saveCurrentScreen();
            });
        }
      });
    } else {
      this.tabPreloader = well.object.text;
      await this.saveScreen(well.object.text, tabletId ? [{
        tabletId,
        boleId: bole.externalId,
        boleName: bole.object.text!,
        logId: log?.externalId,
        logName: log?.object.text,
        templateName: tabletName,
        wellboreId: well.externalId,
        wellboreName: well.object.text,
      }] : []).then((data) => {
        runInAction(() => {
          this.tabPreloader = null;
        });
        return this.openScreen(String(data.id));
      }).finally(() => {
        runInAction(() => {
          this.tabPreloader = null;
        });
      });
    }
  }

  async loadAll() {
    // eslint-disable-next-line no-restricted-syntax
    for (const screen of this.list) {
      if (!screen.inProgress && !screen.screenDto) {
        screen.init();
        // eslint-disable-next-line no-await-in-loop
        await waitPromise(3000);
      }
    }
  }
}
