import { useContext, useMemo } from 'react';
import { Sprite, TilingSprite } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import { Texture, utils } from 'pixi.js';
import { TabletContext } from './TabletProvider';

type Props = {
  width: number;
  height: number;
  from: number;
  to: number;
};

export const SelectedRange = observer(({
  width, height, from, to,
}:Props) => {
  const { themeHex } = useContext(TabletContext);
  const image = useMemo(() => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 28;
    canvas.height = 28;
    if (context) {
      context.strokeStyle = utils.hex2string(themeHex.color);
      context.lineWidth = 2;
      context.globalAlpha = 0.6;
      context.moveTo(0, canvas.height);
      context.lineTo(canvas.width, 0);
      context.stroke();
    }
    img.src = canvas.toDataURL();
    return img;
  }, [themeHex]);

  const [start, end] = from > to ? [to, from] : [from, to];

  const y = start < 0 ? 0 : start;
  const h = (end > height ? height : end) - y;
  return (
    <>
      <Sprite
        x={0}
        y={y}
        width={width}
        height={h}
        texture={Texture.WHITE}
        tint={themeHex.backgroundColor}
        alpha={0.3}
      />
      <Sprite
        x={0}
        y={y}
        width={width}
        height={1}
        texture={Texture.WHITE}
        tint={themeHex.color}
      />
      <TilingSprite
        x={0}
        y={y}
        width={width}
        height={h}
        image={image}
        tilePosition={[0, 0]}
        tileScale={0.7}
      />
      <Sprite
        x={0}
        y={y + h}
        width={width}
        height={1}
        texture={Texture.WHITE}
        tint={themeHex.color}
      />
    </>
  );
});
