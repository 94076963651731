import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

export const DragAndDropBlock = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const DragAndDropBlockOverlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.backgroundColor};
  padding: 20px;
`;

export const DragAndDropBlockInner = styled.div((props:
{ isOver: boolean; theme?: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: 20,
  borderColor: props.theme?.color,
  borderStyle: props.isOver ? 'solid' : 'dashed',
  borderWidth: 2,
}));
