/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useDrag } from 'react-dnd';
import {
  Checkbox, Dropdown, MenuProps, notification, Tooltip,
} from 'antd';
import {
  CaretDownOutlined, CaretRightOutlined, MenuOutlined,
} from '@ant-design/icons';

import { observer } from 'mobx-react-lite';
import { useLocalization } from '@fluent/react';
import { useTheme } from '@emotion/react';
import { objectDictionary } from '../../dictionary';
import { FolderObject, folderTreeStore, ObjectStructure } from '../../stores/folderTree';
import {
  TreeContentWrapper,
  TreeFolderImage, TreeIconWrapper, TreeNodeBlock, TreeNodeInner,
} from './FolderTree.styled';
import { Track } from '../../stores/Tracks';
import { templatesStore } from '../../stores/templatesStore';
import { ScreensStore } from '../../stores/screensStore';
import { Tablet } from '../../stores/Tablet';
import { TabletType } from '../../enums/TabletType';
import { ObjectType } from '../../api/dto/ObjectDto';
import RotatingLines from '../Loader/RotatingLines';

type ValidatorParams = {
  track: Track;
  folderObject: FolderObject;
  tablet: Tablet;
  structure: ObjectStructure;
};

const validators = [
  {
    validator: ({ tablet, structure }: ValidatorParams) => {
      if (tablet.tabletType === TabletType.Time) {
        return structure.log?.objectTypeId === ObjectType.LOG_DEPTH;
      }
      if (tablet.tabletType === TabletType.Depth) {
        return structure.log?.objectTypeId === ObjectType.LOG_TIME;
      }
      return false;
    },
    message: 'DoesNotMatchTabletTypes',
  },
  {
    // eslint-disable-next-line arrow-body-style
    validator: ({ tablet, structure }: ValidatorParams) => {
      return tablet.templateData.bole.externalId !== structure.bole?.externalId;
    },
    message: 'MixingCurvesFromDifferentWellbore',
  },
  {
    validator: ({ track, folderObject }: ValidatorParams) => {
      const index = track.sources
        .findIndex((s) => s.sourceDto.externalId === folderObject.object.externalId);
      return index > -1;
    },
    message: 'CurveAlreadyExists',
  },
];
interface TreeNodeProps {
  screensStore: ScreensStore;
  node: FolderObject;
  level: number;
  menuItems: Required<MenuProps>['items'];
  hideActions?: boolean;
  onSelect: (key: number) => void;
  onChecked?: ((id: boolean) => void);
}

export const TreeNode = observer(({
  node, level, menuItems, hideActions = false, onSelect, onChecked, screensStore,
}: TreeNodeProps) => {
  const { l10n } = useLocalization();
  const theme = useTheme();
  const { childNodes } = node;

  const selectableFolder = folderTreeStore.selectToLasExport
    && folderTreeStore.selectToLasExport.selectableFolder(node);

  function onChildChecked(id: number) {
    folderTreeStore.selectToLasExport?.onChecked(id);
  }

  const isChecked = folderTreeStore.selectToLasExport?.selected.includes(node.externalId);

  const [, drag] = useDrag(() => ({
    type: 'well',
    item: { folderNode: node },
    end: (item, monitor) => {
      const structure = node.getObjectStructureData();
      const dropResult = monitor.getDropResult<{ name: string, track?: Track, tablet?: Tablet }>();
      if (dropResult?.name === 'FolderData') {
        if (structure && structure.well && structure.bole) {
          const wellScreen = screensStore.list
            .find(((s) => s.name === structure.well?.object.text));
          if (wellScreen && screensStore?.currentScreen?.tabletsStore) {
            screensStore.openScreen(wellScreen.screenId);
            screensStore.currentScreen.tabletsStore.createTabletByCurve(
              l10n.getString('NewTablet'),
              item.folderNode,
              structure.well.object,
              structure.bole.object,
              structure.log?.object,
            );
          } else {
            screensStore.openOrCreateInWellTab(
              structure.well.object.text,
              structure.well,
              structure.bole,
              structure.log,
              undefined,
            ).then(() => {
              if (screensStore?.currentScreen?.tabletsStore && structure.well && structure.bole) {
                screensStore.currentScreen.tabletsStore.createTabletByCurve(
                  l10n.getString('NewTablet'),
                  item.folderNode,
                  structure.well.object,
                  structure.bole.object,
                  structure.log?.object,
                );
              }
            });
          }
        }
      }
      if (dropResult?.name === 'TabletBody') {
        const { track, tablet } = dropResult;
        if (track && tablet) {
          const error = validators.find((v) => v.validator({
            track, folderObject: item.folderNode, tablet, structure,
          }));

          if (error) {
            notification.error({
              placement: 'bottomRight',
              message: l10n.getString(error.message),
            });
            return;
          }
          track.addSourceFromRequest(item.folderNode.object);
        }
      }
    },
    canDrag: () => !node.children,
  }));

  const handleMenuClick: MenuProps['onClick'] = async (e) => {
    const id = Number(e.key);
    const { well, bole, log } = node.getObjectStructureData();
    const tabletName = templatesStore.list.find((t) => t.id === id)?.tabletName ?? '';
    if (well && bole) {
      screensStore.openOrCreateInWellTab(tabletName, well, bole, log, id);
    }
  };

  const menu = { onClick: handleMenuClick, items: menuItems };

  const meta = objectDictionary[node.object.objectTypeId];

  if (node.hidden) {
    return null;
  }

  return (
    <TreeNodeBlock level={level}>
      <TreeNodeInner onClick={() => onSelect(node.externalId)}>
        {!hideActions && (
          <TreeIconWrapper>
            {meta.actions && (
            <Dropdown menu={menu} trigger={['click', 'contextMenu']} placement="bottomLeft" arrow={{ pointAtCenter: true }}>
              <MenuOutlined />
            </Dropdown>
            )}
          </TreeIconWrapper>
        )}
        <div onClick={() => node.toggle()} role="button" tabIndex={0} style={{ display: 'flex' }} ref={drag}>
          {node.object.children && (
            <TreeIconWrapper>
              {node.children && (
                <>
                  {node.state === 'open' && <CaretDownOutlined />}
                  {node.state === 'close' && <CaretRightOutlined />}
                  {node.state === 'loading' && (<RotatingLines strokeColor={theme.lightPrimary} width="16" />)}
                </>
              )}
            </TreeIconWrapper>
          )}
          <TreeContentWrapper>
            <TreeIconWrapper>
              {meta.iconImage && (
                <Tooltip
                  title={meta.title ? l10n.getString(meta.title?.(node.object.typeExt)) : ''}
                  mouseEnterDelay={0.5}
                >
                  <TreeFolderImage
                    src={meta.iconImage?.(node.object.typeExt)}
                  />
                </Tooltip>
              )}
            </TreeIconWrapper>
            {onChecked && (
            <Checkbox
              checked={isChecked || undefined}
              onChange={(e) => onChecked(e.target.checked)}
            />
            )}
            {node.object.text}
          </TreeContentWrapper>
        </div>
      </TreeNodeInner>
      <div style={{ display: node.state === 'open' ? 'block' : 'none' }}>
        {childNodes.map(((object) => (
          <TreeNode
            key={object.externalId}
            node={object}
            level={level + (hideActions ? 0 : 1)}
            menuItems={menuItems}
            onSelect={onSelect}
            onChecked={selectableFolder ? () => onChildChecked(object.externalId) : undefined}
            screensStore={screensStore}
          />
        )))}
      </div>
    </TreeNodeBlock>
  );
});
