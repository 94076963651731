import qs from 'qs';
import { TypedJSON } from 'typedjson';
import { axiosApiInstance as axios } from './axiosApiInstance';
import { ObjectDto } from './dto/ObjectDto';

const wellSerializer = new TypedJSON(ObjectDto);

export function getAllWells() {
  return axios.get<{ result: object[] }>('/Geosteering/GetAllWells')
    .then(({ data }) => data.result.map((t) => wellSerializer.parse(t)!));
}

export function getWellChildren(id: number) {
  return axios.get<{ result: object[] }>('/Object/GetWellChilds', { params: { id } })
    .then(({ data }) => data.result.map((t) => wellSerializer.parse(t)!));
}

export function getWellObject(id: number) {
  return axios.get<{ result: object[] }>('/Object/GetOne', { params: { id } })
    .then(({ data }) => wellSerializer.parse(data.result)!);
}

export function downloadLasFile(ids: number[], wellName: string, interpolated: boolean) {
  return axios.get<Blob>('/Curve/GetLasFile', {
    params: {
      ids,
      wellName,
      interpolated,
    },
    responseType: 'blob',
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
  }).then(({ data }) => {
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${wellName}.las`);
    link.click();
    URL.revokeObjectURL(url);
  });
}
