import { useLocalization } from '@fluent/react';
import { observer } from 'mobx-react-lite';
import { CurveStatus } from '../../stores/SourceDataMap';
import { Curve } from '../../stores/Tracks';
import { LoaderBlock } from './TabletTracksInfo.styled';

type Props = {
  source: Curve;
  position: 'absolute' | 'right';
};

export const LoaderString = observer(({ source, position } : Props) => {
  const { l10n } = useLocalization();
  if (source.sourceData?.status === CurveStatus.ServerFetching) {
    return (
      <LoaderBlock position={position}>
        {l10n.getString('LoadingShort')}
        {source.sourceData.loadingPercentage != null && `(${source.sourceData.loadingPercentage}%)`}
        ...
      </LoaderBlock>
    );
  }
  if (source.sourceData?.status === CurveStatus.Blocked) {
    return (
      <LoaderBlock position={position}>
        {l10n.getString('Padding')}
        ...
      </LoaderBlock>
    );
  }
  if (source.sourceData?.status === CurveStatus.MetaDataFetching) {
    return (
      <LoaderBlock position={position}>
        {l10n.getString('LoadingShort')}
        ...
      </LoaderBlock>
    );
  }
  if (source.sourceData?.status === CurveStatus.NoData) {
    return (
      <LoaderBlock position={position}>
        {l10n.getString('No-data')}
      </LoaderBlock>
    );
  }
  return null;
});
