export function normalizeUom(uom: string) {
  if (['kdn', 'tons'].includes(uom)) {
    return 't';
  }
  if (['m/hr'].includes(uom)) {
    return 'm/h';
  }
  if (['knm'].includes(uom)) {
    return 'kN.m';
  }
  if (['spm', 'epm'].includes(uom)) {
    return '1/min';
  }
  if (['l/m', 'L/m'].includes(uom)) {
    return 'L/min';
  }
  if (['l/s'].includes(uom)) {
    return 'L/s';
  }
  if (['kgm3'].includes(uom)) {
    return 'kg/m3';
  }
  if (['gcm3', 'gcc', 'gr/cm3'].includes(uom)) {
    return 'g/cm3';
  }
  if (['lb-ft'].includes(uom)) {
    return 'lbf.ft';
  }
  if (['ft/hr'].includes(uom)) {
    return 'ft/h';
  }
  if (['klb'].includes(uom)) {
    return 'klbm';
  }
  if (['degc'].includes(uom)) {
    return 'degC';
  }
  if (['mmho', 'ohmm'].includes(uom)) {
    return 'ohm.m';
  }
  if (['kpa'].includes(uom)) {
    return 'kPa';
  }
  if (['hr'].includes(uom)) {
    return 'h';
  }
  if (['api'].includes(uom)) {
    return 'gAPI';
  }
  if (['gcount'].includes(uom)) {
    return 'gn';
  }
  if (['gal/min'].includes(uom)) {
    return 'gal[US]/min';
  }
  if (['galUS/min'].includes(uom)) {
    return 'gal[US]/min';
  }
  return uom;
}
