import { ReactNode } from 'react';
import { DragAndDropBlock, DragAndDropBlockOverlay, DragAndDropBlockInner } from './DragAndDropPlaceholder.styled';

interface DragAndDropPlaceholderProps {
  canDrop: boolean;
  isOver: boolean;
  children: ReactNode;
  text: string;
}
export function DragAndDropPlaceholder({
  children, text, canDrop, isOver,
}: DragAndDropPlaceholderProps) {
  return (
    <DragAndDropBlock>
      {children}
      {canDrop && (
        <DragAndDropBlockOverlay>
          <DragAndDropBlockInner isOver={isOver}>{text}</DragAndDropBlockInner>
        </DragAndDropBlockOverlay>
      )}
    </DragAndDropBlock>
  );
}
