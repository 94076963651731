import { observer } from 'mobx-react-lite';
import {
  useFloating, shift, flip, offset,
} from '@floating-ui/react-dom';
import { useLayoutEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Tablet } from '../../stores/Tablet';
import { Tooltip } from './CoordinatorTooltip.styled';
import { useLines } from './useLines.hook';
import { CoordinatorTooltipBody } from './CoordinatorTooltipBody';
import { Track } from '../../stores/Tracks';

export const CoordinatorTooltip = observer(({ tablet }: { tablet: Tablet }) => {
  const {
    x: tooltipX, y: tooltipY, reference, floating, strategy, update,
  } = useFloating({
    placement: 'right-start',
    middleware: [offset(5), flip(), shift()],
  });

  const { globalX, globalY } = tablet.coordinator;

  useLayoutEffect(() => {
    reference({
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x: globalX,
          y: globalY,
          left: globalX,
          right: globalX,
          top: globalY,
          bottom: globalY,
        };
      },
    });
  }, [reference, globalX, globalY]);

  const offsetY = tablet.scale.offsetY + tablet.scale.zeroPointKey * tablet.scale.yRatio;
  const y = (tablet.coordinator.mainAxis + offsetY) / tablet.scale.yRatio;

  const tracks: Track[] = useMemo(() => {
    if (!tablet.coordinator.pointerMoving) {
      return [];
    }
    if (tablet.coordinator.shiftKey) {
      return tablet.tracks.list;
    }
    if (tablet.coordinator.currentTrack) {
      return [tablet.coordinator.currentTrack];
    }
    return [];
  }, [
    tablet.coordinator.currentTrack,
    tablet.coordinator.pointerMoving,
    tablet.coordinator.shiftKey,
    tablet.tracks.list,
  ]);
  const lines = useLines(
    y,
    tracks,
  );

  useLayoutEffect(() => {
    update();
  }, [lines, update]);

  return lines.length
    ? createPortal(
      <Tooltip
        ref={floating}
        style={{
          position: strategy,
          top: tooltipY ?? '',
          left: tooltipX ?? '',
        }}
      >
        <CoordinatorTooltipBody lines={lines} />
      </Tooltip>,
      document.body,
    )
    : null;
});
