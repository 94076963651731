import { makeAutoObservable } from 'mobx';
import { TabletType } from '../enums/TabletType';
import { TabletParams } from './TabletParams';

export class ScalePosition {
  tabletType: TabletType;

  tabletParams: TabletParams;

  x = 0;

  supLineX = 0;

  constructor(tabletType: TabletType, tabletParams: TabletParams) {
    makeAutoObservable(this);
    this.tabletType = tabletType;
    this.tabletParams = tabletParams;
  }

  get y() {
    return this.tabletParams.orientation === 'horizontal'
      ? this.tabletParams.canvasSize[1] - (this.mainSize + this.subSize)
      : 0;
  }

  get mainSize() {
    if (this.tabletParams.orientation === 'horizontal') {
      return 42;
    }
    return this.tabletType === TabletType.Time ? 90 : 65;
  }

  get subSize() {
    if (this.tabletType === TabletType.Depth) {
      return 0;
    }

    return this.tabletParams.orientation === 'vertical' ? 90 : 40;
  }

  get width() {
    if (this.tabletParams.orientation === 'vertical') {
      return this.mainSize + this.subSize;
    }
    return this.tabletParams.canvasSize[0];
  }

  get height() {
    if (this.tabletParams.orientation === 'horizontal') {
      return this.mainSize + this.subSize;
    }
    return this.tabletParams.canvasSize[1];
  }

  get tracksOffset() {
    if (this.tabletParams.isPDF) {
      return this.mainSize;
    }
    if (this.tabletParams.orientation === 'vertical') {
      return this.width;
    }
    return 0;
  }

  get mainLineX() {
    if (this.tabletParams.isPDF) {
      return 0;
    }
    if (this.tabletParams.orientation === 'vertical') {
      return this.x + this.subSize;
    }
    return 0;
  }

  get mainLineY() {
    if (this.tabletParams.orientation === 'horizontal') {
      return this.y;
    }
    return 0;
  }

  get supLineY() {
    if (this.tabletParams.orientation === 'horizontal') {
      return this.y + this.subSize;
    }
    return 0;
  }

  get supLineWidth() {
    if (this.tabletParams.orientation === 'vertical') {
      return this.subSize;
    }
    return this.width;
  }

  get supLineHeight() {
    if (this.tabletParams.orientation === 'horizontal') {
      return this.subSize;
    }
    return this.height;
  }
}
