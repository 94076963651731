import {
  Form, InputNumber, Input, DatePicker, Row, Col,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useLocalization } from '@fluent/react';
import { runInAction } from 'mobx';
import { CommentParamsDto } from '../../api/curvesCache';
import { Tablet } from '../../stores/Tablet';
import { mapCommentDtoToFormData, mapFormDataToCommentDto } from '../../mapping/CommentDtoProfile';
import { TabletType } from '../../enums/TabletType';

type Props = {
  tablet: Tablet,
  params: CommentParamsDto;
  tabletType: TabletType;
  onFinish: (values: any) => void;
  onPickRange: () => void;
  type: 'edit' | 'add' | 'preview';
};
export const CommentForm = observer(({
  tablet, params, tabletType, onFinish, type,
} : Props) => {
  const { l10n } = useLocalization();

  const [form] = Form.useForm();

  const { commentsController: controller } = tablet;

  return (
    <Form
      form={form}
      initialValues={params.comment && mapCommentDtoToFormData(params)}
      size="small"
      onFinish={onFinish}
      id="CommentForm"
      layout="vertical"
      onValuesChange={(_, values: any) => {
        runInAction(() => {
          if (controller.currentForm?.comment) {
            controller.currentForm.comment = (mapFormDataToCommentDto(values));
          }
        });
        if (values.onDate) {
          if (!values.toDate) {
            form.setFieldsValue({ toDate: values.onDate });
          }
          if (values.toDate && values.onDate.valueOf() > values.toDate.valueOf()) {
            form.setFieldsValue({ toDate: values.onDate });
          }
        }
        if (values.onDepth) {
          if (!values.toDepth && values.toDepth) {
            form.setFieldsValue({ toDepth: values.toDepth });
          }
          if (values.toDepth && values.onDepth.valueOf() > values.toDepth.valueOf()) {
            form.setFieldsValue({ toDepth: values.toDepth });
          }
        }
      }}
    >
      <Form.Item
        name="title"
        label={l10n.getString('Title')}
        required
        rules={[{ required: true, message: l10n.getString('Field-text-required') }]}
      >
        <Input disabled={type === 'preview'} />
      </Form.Item>

      <Form.Item
        name="text"
        label={l10n.getString('Comment')}
        required
        rules={[{ required: true, message: l10n.getString('Field-text-required') }]}
      >
        <Input.TextArea disabled={type === 'preview'} />
      </Form.Item>

      {tabletType === TabletType.Depth && (
      <Form.Item style={{ marginBottom: 0 }}>
        <Row>
          <Col span={11}>
            <Form.Item
              name="onDepth"
              label={l10n.getString('From')}
              required
              rules={[{ required: true, message: l10n.getString('Field-number-required') }]}
            >
              <InputNumber
                disabled={type === 'edit' || type === 'preview'}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              name="toDepth"
              label={l10n.getString('To')}
              required
              rules={[{ required: true, message: l10n.getString('Field-number-required') }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                disabled={type === 'preview'}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      )}
      {tabletType === TabletType.Time && (
      <Row>
        <Col span={11}>
          <Form.Item
            name="onDate"
            label={l10n.getString('From')}
            required
            rules={[{ required: true, message: l10n.getString('Field-date-required') }]}
          >
            <DatePicker
              showTime={{ format: 'HH:mm:ss' }}
              format="YYYY-MM-DD HH:mm:ss"
              disabled={type === 'edit' || type === 'preview'}
            />
          </Form.Item>
        </Col>
        <Col span={11} offset={2}>
          <Form.Item
            name="toDate"
            label={l10n.getString('To')}
            required
            rules={[{ required: true, message: l10n.getString('Field-date-required') }]}
          >
            <DatePicker
              showTime={{ format: 'HH:mm:ss' }}
              format="YYYY-MM-DD HH:mm:ss"
              disabled={type === 'preview'}
            />
          </Form.Item>
        </Col>
      </Row>
      )}
    </Form>
  );
});
