import { RefObject, useState, useEffect } from 'react';
import screenfull from 'screenfull';

export const useFullscreen = (
  ref: RefObject<Element>,
  enabled: boolean,
  onClose: (error?: Error) => void = () => {},
): boolean => {
  const [isFullscreen, setIsFullscreen] = useState(enabled);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (!ref.current) {
      return;
    }

    const onChange = () => {
      if (screenfull.isEnabled) {
        const isScreenfullFullscreen = screenfull.isFullscreen;
        setIsFullscreen(isScreenfullFullscreen);
        if (!isScreenfullFullscreen) {
          onClose();
        }
      }
    };

    if (screenfull.isEnabled) {
      try {
        screenfull.request(ref.current);
        setIsFullscreen(true);
      } catch (error: any) {
        onClose(error);
        setIsFullscreen(false);
      }
      screenfull.on('change', onChange);
    } else {
      onClose();
      setIsFullscreen(false);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      setIsFullscreen(false);
      if (screenfull.isEnabled) {
        try {
          screenfull.off('change', onChange);
          screenfull.exit();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    };
  }, [enabled, ref, onClose]);

  return isFullscreen;
};
