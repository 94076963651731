import { observer } from 'mobx-react-lite';
import { CurvePoint } from '../../../api/curvesCache';
import { ValueScaleType } from '../../../api/dto/TrackDto';
import { CommentsCoordinates } from '../../../stores/CoordinatesStorages/CommentsCoordinates';
import { CurveCoordinates } from '../../../stores/CoordinatesStorages/CurveCoordinates';
import { CuttingsCoordinates } from '../../../stores/CoordinatesStorages/CuttingsCoordinates';
import { DepthCoordinates } from '../../../stores/CoordinatesStorages/DepthCoordinates';
import { ImageCoordinates } from '../../../stores/CoordinatesStorages/ImageCoordinates';
import { LithologyCoordinates } from '../../../stores/CoordinatesStorages/LithologyCoordinates';
import { SyntheticCoordinates } from '../../../stores/CoordinatesStorages/SyntheticCoordinates';
import { Tablet } from '../../../stores/Tablet';
import { Curve, Track } from '../../../stores/Tracks';
import { CommentsChart, StringChart } from './Comments';
import { CurveChart } from './CurveChart';
import { CurveNumeric } from './CurveNumeric';
import { CuttingsLog } from './CuttingsLog';
import { ImageCurve } from './ImageCurve';
import { Kpi } from './Kpi';
import { Lithology } from './Lithology';

type CurveResolverProps = {
  source: Curve;
  tablet: Tablet,
  track: Track,
  width: number,
  cuttingsLogValues: Map<number, CurvePoint[][]> | undefined
  offsetX: number,
  trackSelected: boolean,
};

export const CurveResolver = observer(({
  source, track, tablet, width, cuttingsLogValues, offsetX, trackSelected,
}: CurveResolverProps) => {
  if (source.sourceData?.curveCoordinates instanceof CuttingsCoordinates) {
    return (
      <CuttingsLog
        source={source}
        tablet={tablet}
        width={width}
        coords={cuttingsLogValues?.get(source.sourceDto.externalId) || []}
      />
    );
  }
  if (source.sourceData?.curveCoordinates instanceof LithologyCoordinates) {
    return (
      <Lithology
        source={source}
        coordinates={source.sourceData?.curveCoordinates}
        tablet={tablet}
        width={width}
      />
    );
  }
  if (source.sourceData?.curveCoordinates instanceof SyntheticCoordinates) {
    return (
      <Kpi
        source={source}
        coordinates={source.sourceData?.curveCoordinates}
        tablet={tablet}
        width={width}
      />
    );
  }
  if (source.sourceData?.curveCoordinates instanceof CommentsCoordinates) {
    if (source.sourceData?.curveCoordinates.flat) {
      return (
        <StringChart
          coordinates={source.sourceData?.curveCoordinates}
          tablet={tablet}
          width={width}
          track={track}
          trackSelected={trackSelected}
        />
      );
    }
    return (
      <CommentsChart
        source={source}
        coordinates={source.sourceData?.curveCoordinates}
        tablet={tablet}
        width={width}
        track={track}
      />
    );
  }
  if (source.sourceData?.curveCoordinates instanceof ImageCoordinates) {
    return (
      <ImageCurve
        source={source}
        coordinates={source.sourceData?.curveCoordinates}
        tablet={tablet}
        width={width}
        offsetX={offsetX}
      />
    );
  }
  if (source.sourceData?.curveCoordinates instanceof DepthCoordinates) {
    if (track.params.valueScaleType === ValueScaleType.Numeric) {
      return (
        <CurveNumeric
          coordinates={source.sourceData?.curveCoordinates}
          tablet={tablet}
          source={source}
          width={width}
          track={track}
        />
      );
    }
    return (
      <CurveChart
        coordinates={source.sourceData?.curveCoordinates}
        tablet={tablet}
        source={source}
        log10={track.params.valueScaleType === ValueScaleType.Log}
        width={width}
      />
    );
  }
  if (source.sourceData?.curveCoordinates instanceof CurveCoordinates) {
    if (track.params.valueScaleType === ValueScaleType.Numeric) {
      return (
        <CurveNumeric
          coordinates={source.sourceData?.curveCoordinates}
          tablet={tablet}
          source={source}
          width={width}
          track={track}
        />
      );
    }
    return (
      <CurveChart
        coordinates={source.sourceData?.curveCoordinates}
        tablet={tablet}
        source={source}
        log10={track.params.valueScaleType === ValueScaleType.Log}
        width={width}
      />
    );
  }
  return null;
});
