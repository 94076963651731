import { observer } from 'mobx-react-lite';
import {
  useFloating, shift, flip, offset,
} from '@floating-ui/react-dom';
import { useLayoutEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from './TracksAnalysis.styled';
import { useLines } from './useLines.hook';
import { TracksAnalysisBody } from './TracksAnalysisBody';
import { TracksAnalysisCoordinator } from '../../stores/TracksAnalysisCoordinator';
import { Tablet } from '../../stores/Tablet';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

type Props = {
  tablet: Tablet;
  tracksAnalysis: TracksAnalysisCoordinator;
};
export const TracksAnalysis = observer(({ tablet, tracksAnalysis }: Props) => {
  const {
    x: tooltipX, y: tooltipY, reference, floating, strategy, refs,
  } = useFloating({
    placement: 'right-start',
    middleware: [offset({ mainAxis: 5, crossAxis: 6 }), flip(), shift()],
  });

  useLayoutEffect(() => {
    reference({
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x: tracksAnalysis.x,
          y: tracksAnalysis.y,
          left: tracksAnalysis.x,
          right: tracksAnalysis.x,
          top: tracksAnalysis.y,
          bottom: tracksAnalysis.y,
        };
      },
    });
  }, [reference, tracksAnalysis.x, tracksAnalysis.y]);

  const onClickOutside = useCallback(() => {
    if (tracksAnalysis.endedPosition) {
      tracksAnalysis.close();
    }
  }, [tracksAnalysis]);

  useOnClickOutside(refs.floating, onClickOutside);

  const lines = useLines(
    tracksAnalysis.from ?? null,
    tracksAnalysis.to ?? null,
    tracksAnalysis.selectedTracks || [],
  );

  return lines.length
    ? createPortal(
      <Tooltip
        ref={floating}
        style={{
          position: strategy,
          top: tooltipY ?? '',
          left: tooltipX ?? '',
        }}
      >
        <TracksAnalysisBody
          lines={lines}
          close={tracksAnalysis.close}
          from={tracksAnalysis.from ?? null}
          to={tracksAnalysis.to ?? null}
          tabletType={tablet.tabletType}
        />
      </Tooltip>,
      document.body,
    )
    : null;
});
