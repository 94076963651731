import { useEffect, useState } from 'react';
import { useDragDropManager, XYCoord } from 'react-dnd';
import { createPortal } from 'react-dom';
import { BASE_LEGEND_HEIGHT, COLLAPSED_LEGEND_HEIGHT } from '../../constants';
import { Tablet } from '../../stores/Tablet';
import { Curve, Track } from '../../stores/Tracks';
import { DragBlock } from './TabletTracksInfo.styled';

export function DragHeader({ tablet }: { tablet: Tablet }) {
  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();

  const itemType = monitor.getItemType();

  const isCurve: boolean = itemType === 'curve';

  const [offset, setOffset] = useState<XYCoord | null>(null);

  useEffect(() => {
    monitor.subscribeToOffsetChange(() => {
      setOffset(monitor.getClientOffset());
    });
  }, [monitor]);

  if (!isCurve) {
    return null;
  }

  const item = monitor.getItem() as { curve?: Curve, track?: Track };

  if (!item.track || !item.curve || !offset) {
    return null;
  }

  let height = tablet.trackInfoCollapsed ? COLLAPSED_LEGEND_HEIGHT : BASE_LEGEND_HEIGHT;
  if (item.curve.params.showValueScale) {
    if (tablet.params.orientation === 'vertical') {
      height = BASE_LEGEND_HEIGHT;
    } else {
      height = item.curve.params.legendHeight;
    }
  }
  const transform = offset ? `translate(${offset.x}px, ${offset.y}px)` : '';
  return createPortal((
    <DragBlock
      width={item.track.params.trackWidth}
      height={height}
      style={{
        transform,
      }}
    >
      {item.curve.sourceDto.name}
    </DragBlock>), document.body);
}
