import { Tablet } from '../../../stores/Tablet';

export type ScaleSelectorType = 'all' | 'min' | 'max' | '1h' | '2h' | '4h' | '8h' | '12h' | '24h' | 'range';

export const house = 60 * 60 * 1000;

export function setScale(type: ScaleSelectorType, tablet: Tablet) {
  if (type === 'max') {
    tablet.scale.setDensity(tablet.maxZoom);
  }
  if (type === 'min') {
    tablet.scale.setDensity(tablet.minZoom);
  }
  if (type === '1h') {
    tablet.fitToRange(house);
  }
  if (type === '2h') {
    tablet.fitToRange(house * 2);
  }
  if (type === '4h') {
    tablet.fitToRange(house * 4);
  }
  if (type === '8h') {
    tablet.fitToRange(house * 8);
  }
  if (type === '12h') {
    tablet.fitToRange(house * 12);
  }
  if (type === '24h') {
    tablet.fitToRange(house * 24);
  }
}

export function getScale(tablet: Tablet): ScaleSelectorType | null {
  if (tablet.maxZoom === tablet.scale.dataPerPixel) {
    return 'max';
  }
  let range = Math.round(
    ((tablet.scale.dataPerPixel * 60000) * tablet.scale.containerLength) / 10000,
  ) * 10000;

  if (house === range) {
    return '1h';
  }
  if (house * 2 === range) {
    return '2h';
  }
  if (house * 4 === range) {
    return '4h';
  }
  if (house * 8 === range) {
    return '8h';
  }
  if (house * 12 === range) {
    return '12h';
  }
  if (house * 24 === range) {
    return '24h';
  }
  range = Math.round(
    ((tablet.scale.dataPerPixel * 60000) * tablet.scale.containerLength) / 100000,
  ) * 100000;
  const dataRange = Math.round((tablet.toDataDB - tablet.onDataDB) / 100000) * 100000;
  if (dataRange === range) {
    return 'min';
  }
  if (tablet.minZoom === tablet.scale.dataPerPixel) {
    return 'min';
  }
  return null;
}
