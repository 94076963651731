import { Container, Sprite } from '@inlet/react-pixi';
import { observer } from 'mobx-react-lite';
import * as PIXI from 'pixi.js';
import { useContext } from 'react';
import { Track } from '../../stores/Tracks';
import { CurveHeaderPixi } from './CurveHeaderPixi';
import { TabletContext } from './TabletProvider';

type Props = {
  track: Track,
  offset: number,
};

export const CurveHeadersPixi = observer(({ track, offset }: Props) => {
  const { themeHex } = useContext(TabletContext);
  return (
    <>
      {track.sourcesSorted.map((curve, index) => (
        <Container x={offset} y={index * 17} key={curve.sourceDto.externalId}>
          <Sprite
            x={0}
            y={0}
            width={track.params.trackWidth}
            height={17}
            texture={PIXI.Texture.WHITE}
            tint={themeHex.backgroundColor}
          />
          <CurveHeaderPixi
            curve={curve}
            track={track}
            width={track.params.trackWidth}
            key={curve.sourceDto.externalId}
          />
        </Container>
      ))}
    </>
  );
});
