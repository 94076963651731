import { action, makeAutoObservable, runInAction } from 'mobx';
import { nanoid } from 'nanoid';
import { getCurve } from '../api/curvesCache';
import { ObjectDto } from '../api/dto/ObjectDto';
import { TabletDto } from '../api/dto/TabletDto';
import { TrackDto, ValueScaleType } from '../api/dto/TrackDto';
import { TracksetDto } from '../api/dto/TracksetDto';
import { getTablet } from '../api/tablets';
import { throwError } from '../errorHandler';
import { curveInfoToCurveParams } from '../mapping/curveInfoToCurveParams';
import { FolderObject } from './folderTree';
import { timeZonesStore } from './init-stores-global';
import { FolderShort, Tablet } from './Tablet';

export class TabletLoading {
  innerId: string;

  inProgress = false;

  errors = null;

  tablet: Tablet | null = null;

  closeTabletCb: (tablet: TabletLoading) => void;

  constructor(closeTablet: (tablet: TabletLoading) => void) {
    makeAutoObservable(this, { closeTablet: action.bound });
    this.innerId = nanoid();
    this.closeTabletCb = closeTablet;
  }

  createTablet(
    tabletDto: TabletDto,
    well: FolderShort,
    bole: FolderShort,
    log?: FolderShort,
    templateName?:string,
  ) {
    this.tablet = new Tablet(timeZonesStore, tabletDto, this.closeTablet, {
      well, bole, log, templateName,
    });
  }

  closeTablet() {
    this.closeTabletCb(this);
  }

  async createTabletByCurve(
    tabletName: string,
    object: FolderObject,
    well: ObjectDto,
    bole: ObjectDto,
    log?: ObjectDto,
  ) {
    this.inProgress = true;
    this.errors = null;

    try {
      const data = await getCurve(object.externalId);
      const source = curveInfoToCurveParams(data);
      const tabletDto = new TabletDto();
      tabletDto.name = tabletName;
      tabletDto.storageVersion = 3;

      const trackset = new TracksetDto();
      trackset.pos = 0;
      trackset.tracksetType = log?.objectTypeId === 13 ? 0 : 1;

      const track = new TrackDto();
      track.pos = 0;
      track.trackWidth = 300;
      track.gridBaseVert = 2;
      track.gridSecondVert = 5;
      track.valueScaleType = data.classWitsml === 'COMMENTS'
        ? ValueScaleType.Comments
        : ValueScaleType.Linear;

      track.sources.push(source);
      trackset.tracks.push(track);
      tabletDto.tracksets.push(trackset);

      runInAction(() => {
        this.createTablet(tabletDto, well, bole, log);
      });
      return tabletDto;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      this.closeTablet();
      throwError('Curve-info-loading-error')(e);
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  async createTabletById(
    tabletId: number,
    templateName: string,
    well: FolderShort,
    bole: FolderShort,
    log?: FolderShort,
  ) {
    this.inProgress = true;
    this.errors = null;

    try {
      const tablet = await getTablet(tabletId, well.externalId, bole.externalId, log?.externalId);
      runInAction(() => {
        this.createTablet(tablet, well, bole, log, templateName);
      });
      return tablet;
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      this.closeTablet();
      throwError('Error-loading-tablet')(e);
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }
}
