import * as PIXI from 'pixi.js';
import { Graphics, Text, Container } from '@inlet/react-pixi';
import { useState, useCallback, useMemo } from 'react';
import { lightenColor } from '../../../utils';

type Props = {
  x: number;
  y: number;
  width: number;
  height: number;
  text: string;
  onClick: () => void;
  color?: number;
  backgroundColor?: number;
  borderColor?: number;
  borderWidth?: number;
  fontSize?: number
};

export function Button({
  x, y, width, height, text, onClick, color = 0x000_000,
  backgroundColor = 0xddd_ddd, borderColor, borderWidth = 1, fontSize,
}: Props) {
  const [isOver, setIsOver] = useState(false);

  const hoverColor = useMemo(
    () => parseInt(lightenColor(backgroundColor.toString(16), 5), 16),
    [backgroundColor],
  );

  const draw = useCallback(
    (g: PIXI.Graphics) => {
      g.clear();
      g.beginFill(isOver
        ? hoverColor
        : backgroundColor);

      if (borderWidth) {
        g.lineStyle(borderWidth, borderColor);
      }

      g.drawRect(0, 0, width, height);
      g.endFill();
    },
    [backgroundColor, borderWidth, borderColor, width, height, isOver, hoverColor],
  );

  return (
    // @ts-ignore: React remove children from React.FC
    <Container
      x={x}
      y={y}
    >
      <Graphics
        draw={draw}
        buttonMode
        interactive
        click={() => onClick()}
        pointerover={() => setIsOver(true)}
        pointerout={() => setIsOver(false)}
      />
      <Text
        text={text}
        style={{
          fill: color,
          align: 'center',
          textBaseline: 'alphabetic',
          fontSize,
        }}
        x={width / 2}
        y={height / 2}
        anchor={0.5}
      />
    </Container>
  );
}
