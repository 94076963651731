import { observer } from 'mobx-react-lite';
import { Children, ReactNode } from 'react';
import { LocalizationProvider } from '@fluent/react';
import { ConfigProvider } from 'antd';
import { localizationStore } from './stores/init-stores-global';

type AppLocalizationProviderProps = {
  children: ReactNode;
};

export const AppLocalizationProvider = observer((props: AppLocalizationProviderProps) => {
  if (localizationStore.l10n === null) {
    return <div>Loading…</div>;
  }
  return (
    <LocalizationProvider l10n={localizationStore.l10n}>
      <ConfigProvider locale={localizationStore.antd}>
        {Children.only(props.children)}
      </ConfigProvider>
    </LocalizationProvider>
  );
});
