import { memo, useCallback, useContext } from 'react';
import * as PIXI from 'pixi.js';
import { Graphics } from '@inlet/react-pixi';
import { DashLine } from 'pixi-dashed-line';
import { TabletContext } from './TabletProvider';

type Props = {
  offsetX: number;
  offsetY: number;
  width: number;
  height: number;
  x: number;
  y: number;
};

export const Coordinator = memo(({
  offsetX, offsetY, width, height, x, y,
}:Props) => {
  const { themeHex } = useContext(TabletContext);

  const draw = useCallback((g: PIXI.Graphics) => {
    g.clear();
    const dashG = new DashLine(g, {
      dash: [5, 5],
      width: 1,
      color: themeHex.color,
      alpha: 0.7,
    });
    if (x < offsetX || x > width || y < 0 || y > height) {
      return;
    }
    dashG.moveTo(
      offsetX,
      y,
    );
    dashG.lineTo(
      width,
      y,
    );
    dashG.moveTo(
      x,
      offsetY,
    );
    dashG.lineTo(
      x,
      height,
    );
  }, [themeHex.color, offsetX, offsetY, height, width, x, y]);
  return (<Graphics draw={draw} />);
});
