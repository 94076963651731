/* eslint-disable no-param-reassign */
import { makeAutoObservable, observable, runInAction } from 'mobx';
import { getImageCoords, ImagePoint } from '../../api/curvesCache';
import { immutableSplice } from '../../utils';

export class ImageCoordinates {
  curveId: number;

  points: ImagePoint[] = [];

  inProgress = false;

  inDownloading = false;

  errors = null;

  onData?: number;

  toData?: number;

  minValue = null;

  maxValue = null;

  fetched = false;

  constructor(curveId: number) {
    makeAutoObservable(this, {
      points: observable.ref,
    });
    this.curveId = curveId;
  }

  async refreshCoords({ force }: { force?: boolean }) {
    if (!force && (this.points.length > 0 || this.inProgress || this.inDownloading)) {
      return;
    }
    this.inProgress = true;
    this.errors = null;
    try {
      const data = await getImageCoords(this.curveId);
      runInAction(() => {
        if (Array.isArray(data)) {
          this.points = data;
          this.onData = data.at(-1)?.key;
          this.toData = data.at(-1)?.key;
          this.fetched = true;
        } else {
          this.inDownloading = true;
        }
      });
    } catch (e: any) {
      runInAction(() => {
        this.errors = e.response && e.response.body && e.response.body.errors;
      });
      throw e;
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  get minMaxValues() {
    let min = Number.MAX_VALUE;
    let max = Number.MIN_VALUE;
    this.points.forEach((point) => {
      point.value.forEach((value) => {
        if (value === -999.25) {
          return;
        }
        min = value < min ? value : min;
        max = value > max ? value : max;
      });
    });
    return [min, max];
  }

  getPoint(y: number) {
    return this.points.find((point, index, array) => {
      const nextPoint = array.at(index + 1);
      if (!nextPoint) return true;
      if (point.key <= y && y < nextPoint.key) return true;
      return false;
    });
  }

  addPoint(point: ImagePoint) {
    const index = this.points.findIndex((p, i) => {
      const next = this.points.at(i + 1);
      if (next) {
        return p.key < point.key && point.key < next.key;
      }
      return false;
    });
    if (index > -1 && index < this.points.length) {
      this.points = immutableSplice(this.points, index + 1, 0, point);
    }
  }

  addPoints(points: ImagePoint[]) {
    this.points = this.points.concat(...points.filter((p) => p.key > (this.toData || 0)));
  }
}
