import React from 'react';
import type { ColumnsType } from 'antd/es/table';
import { useLocalization } from '@fluent/react';
import { Table } from 'antd';

export function HelpTable() {
  const { l10n } = useLocalization();

  const columns: ColumnsType<any> = [
    {
      key: 'data',
      title: l10n.getString('Data'),
      children: [
        {
          key: 'volume',
          title: l10n.getString('Volume'),
          dataIndex: 'volumeData',
        },
        {
          key: 'units',
          title: l10n.getString('Units'),
          dataIndex: 'unitsData',
        },
      ],
    },
    {
      key: 'scale',
      title: l10n.getString('Scale'),
      children: [
        {
          key: 'volume',
          title: l10n.getString('Volume'),
          dataIndex: 'volumeScale',
        },
        {
          key: 'units',
          title: l10n.getString('Units'),
          dataIndex: 'unitsScale',
        },
      ],
    },
  ];

  const data = [
    {
      key: '1',
      volumeData: '< 0.25',
      unitsData: l10n.getString('days'),
      volumeScale: '> 0.1',
      unitsScale: l10n.getString('min'),
    },
    {
      key: '2',
      volumeData: '< 1',
      unitsData: l10n.getString('days'),
      volumeScale: '> 0.5',
      unitsScale: l10n.getString('min'),
    },
    {
      key: '3',
      volumeData: '< 5',
      unitsData: l10n.getString('days'),
      volumeScale: '> 2',
      unitsScale: l10n.getString('min'),
    },
    {
      key: '4',
      volumeData: '< 10',
      unitsData: l10n.getString('days'),
      volumeScale: '> 3',
      unitsScale: l10n.getString('min'),
    },
    {
      key: '5',
      volumeData: '< 20',
      unitsData: l10n.getString('days'),
      volumeScale: '> 5',
      unitsScale: l10n.getString('min'),
    },
    {
      key: '6',
      volumeData: '< 30',
      unitsData: l10n.getString('days'),
      volumeScale: '> 10',
      unitsScale: l10n.getString('min'),
    },
    {
      key: '7',
      volumeData: '> 30',
      unitsData: l10n.getString('days'),
      volumeScale: '> 20',
      unitsScale: l10n.getString('min'),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      size="small"
      pagination={false}
    />
  );
}
