import { useState } from 'react';
import { themingStore } from '../stores/init-stores-global';
import { InfoModal } from './InfoModal';
import { LogoBlock as LogoContainer, LogoImage } from './MainLayout';

export function LogoBlock() {
  const [infoVisible, setInfoVisible] = useState(false);

  return (
    <LogoContainer key="logo">
      <LogoImage
        src={themingStore.current === 'dark'
          ? process.env.REACT_APP_LOGO_URL_DARK
          : process.env.REACT_APP_LOGO_URL_LIGHT}
        onClick={() => setInfoVisible(true)}
      />
      <InfoModal visible={infoVisible} onClose={() => setInfoVisible(false)} />
    </LogoContainer>
  );
}
