import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { MenuProps } from 'antd';
import { useTheme } from '@emotion/react';
import { folderTreeStore } from '../../stores/folderTree';
import { TreeWrapper } from './FolderTree.styled';
import { TreeNode } from './TreeNode';
import { templatesStore } from '../../stores/templatesStore';
import { LasLoaderActions } from './LasLoaderActions';
import RotatingLines from '../Loader/RotatingLines';
import { ScreensStore } from '../../stores/screensStore';
import { CustomScrollbar } from '../CustomScrollbar';

const OBJECTS_TREE_SCROLL_POSITION = 'OBJECTS_TREE_SCROLL_POSITION';

export const FolderTree = observer(({ screensStore }: { screensStore: ScreensStore }) => {
  const theme = useTheme();
  const container = useRef<HTMLDivElement>(null);
  const [scrollTo, setScrollTo] = useState(0);

  useEffect(() => {
    folderTreeStore.fetchRoot().then(() => {
      const scrollPosition = sessionStorage.getItem(OBJECTS_TREE_SCROLL_POSITION) ?? '0';
      return parseInt(scrollPosition, 10);
    }).then((scrollPosition) => {
      setScrollTo(scrollPosition);
    });
  }, []);

  useLayoutEffect(() => {
    if (scrollTo) {
      container.current?.scrollTo({ top: scrollTo });
    }
  }, [scrollTo]);

  useEffect(() => {
    const ref = container.current;
    let requestRunning: number | null = null;
    const setPosition = () => {
      if (requestRunning === null) {
        requestRunning = requestAnimationFrame(() => {
          sessionStorage.setItem(OBJECTS_TREE_SCROLL_POSITION, String(ref?.scrollTop ?? '0'));
          requestRunning = null;
        });
      }
    };

    ref?.addEventListener('scroll', setPosition);
    return () => ref?.removeEventListener('scroll', setPosition);
  }, []);

  const onSelect = (keys: (string | number)[]) => {
    if (keys[0] && typeof keys[0] === 'number') { folderTreeStore.fetchFolderObject(keys[0]); }
  };

  const menuItems: Required<MenuProps>['items'] = templatesStore.selectedList.map((template) => ({
    label: template.tabletName,
    key: template.id,
  }));

  return (
    <TreeWrapper ref={container}>
      <CustomScrollbar>
        <LasLoaderActions />
        {folderTreeStore.inProgress && (
          <RotatingLines strokeColor={theme.lightPrimary} width="20" />
        )}
        {(folderTreeStore.inProgress ? [] : folderTreeStore.root).map((node) => (
          <TreeNode
            key={node.externalId}
            node={node}
            level={0}
            menuItems={menuItems}
            onSelect={(key) => onSelect([key])}
            hideActions
            screensStore={screensStore}
          />
        ))}
      </CustomScrollbar>
    </TreeWrapper>
  );
});
