import { addMilliseconds, milliseconds } from 'date-fns';
import { makeAutoObservable } from 'mobx';
import { eachOfInterval } from '../utils';

export const LC_SELECTED_TIME_ZONE = 'LC_SELECTED_TIME_ZONE';

const defaultTimeZone = Number(localStorage.getItem(LC_SELECTED_TIME_ZONE) || '0');

export class TimeZonesStore {
  selected = defaultTimeZone;

  list = eachOfInterval(-12, 14, 1);

  constructor() {
    makeAutoObservable(this);
  }

  get current() {
    return (new Date().getTimezoneOffset() + this.selected * 60) * 60000;
  }

  get selectedMs() {
    return this.selected * milliseconds({ hours: 1 });
  }

  getDate(date: number) {
    return addMilliseconds(date, this.current);
  }

  getInverDate(date: number) {
    return addMilliseconds(date, -this.current);
  }

  select(value: number) {
    this.selected = value;
    localStorage.setItem(LC_SELECTED_TIME_ZONE, String(value));
  }
}
