import { observer } from 'mobx-react-lite';
import { useDrop } from 'react-dnd';
import { useLocalization } from '@fluent/react';
import { folderTreeStore } from '../../stores/folderTree';
import { templatesDictionary } from './map';
import { DragAndDropPlaceholder } from '../DragAndDropPlaceholder';

export const FolderData = observer(() => {
  const { l10n } = useLocalization();

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'well',
    drop: () => ({ name: 'FolderData' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  const { object } = folderTreeStore;
  const ObjectComponent = object && object.objectTypeId != null
    && templatesDictionary[object.objectTypeId];
  return (
    <div ref={drop}>
      <DragAndDropPlaceholder isOver={isOver} canDrop={canDrop} text={l10n.getString('CreateLogBoard')}>
        {ObjectComponent ? (<ObjectComponent well={object} />) : null}
      </DragAndDropPlaceholder>
    </div>
  );
});
